import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import {
  GET_DISTRIBUTORS,
  UPDATE_DISTRIBUTOR,
  DELETE_DISTRIBUTOR,
} from "../../../queries";
import { useQuery, useMutation } from "@apollo/client";
import styles from "./MyDistributors.module.scss";
import { BsSearch } from "react-icons/bs";
import { CircularProgress } from "@mui/material";
import Pagination from "@mui/material/Pagination";

export default function MyDistributors({setDistributors}) {
  // ESTADOS LOCALES ----------------------------------------------------------------------------
  //estados para paginación
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //estado para editar distribuidor
  const [editDistributor, setEditDistributor] = useState("");

  //estado para guardar la data de los input de edit distributor
  const [actualDistributorData, setActualDistributorData] = useState("");

  //estado para el input de busqueda por nombre
  const [searchInput, setSearchInput] = useState("");

  // QUERIES / MUTATIONS --------------------------------------------------------
  //query para traerme los distribuidores
  const {
    // loading: distributorsLoading,
    error: distributorsError,
    data: distributorsData,
  } = useQuery(GET_DISTRIBUTORS);

  //mutation para updatear distributors
  const [
    updateDistributor,
    {
      loading: updateDistributorLoading,
      error: updateDistributorError,
      // data: updateDistributorData,
    },
  ] = useMutation(UPDATE_DISTRIBUTOR);

  //mutation para eliminar distribuidores
  const [
    deleteDistributor,
    {
      // loading: deleteDistributorLoading,
      error: deleteDistributorError,
      // data: deleteDistributorData,
    },
  ] = useMutation(DELETE_DISTRIBUTOR);

  // ME GUARDO LA INFO EN UNA VARIABLE COPIA -------------------------------------------------------
  let info = distributorsData?.distributors;

  //CUANDO BUSCO EN LA BARRA DE BUSQUEDA QUE ME LLEVE A LA PAG 1
  useEffect(() => {
    //seteo distribuidores para pasar al componente config y de ahi a crate users
    setDistributors(info);
    setPage(1);
  }, [searchInput]);

  //   HANDLERS -------------------------------------------------------------------------
  //handler del edit distributor
  const handleEditDistributor = (id) => {
    setEditDistributor(id);
    setActualDistributorData({
      ...actualDistributorData,
      distributor_id: id,
    });
  };

  //handler del on change para cuando edito distribuidor
  const handleChangeDistributorData = (e) => {
    setActualDistributorData({
      ...actualDistributorData,
      [e.target.name]: e.target.value,
    });
  };

  //handler del save edit distributor
  const handleSaveEditDistributor = async () => {
    setEditDistributor("");
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        await updateDistributor({
          variables: actualDistributorData,
        });
        setActualDistributorData("");
        window.location.reload("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handler del cancel edit distributor
  const handleCancelEditDistributor = () => {
    setEditDistributor("");
    setActualDistributorData("");
  };

  //handler para eliminar distribuidor
  const handleDeleteDistributor = async (id) => {
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que deseas eliminar el dispositivo?"
            : "Are you sure you want to delete device?"
        )
      ) {
        await deleteDistributor({
          variables: {
            distributor_id: id,
          },
        });
        window.location.reload("/");
      }
    } catch (error) {
      console.log("error en la mutation de deleteDistributor", error);
    }
  };

  //handler del input de busqueda por qr
  const handlerQRChange = (e) => {
    setSearchInput(e.target.value);
  };

  if (searchInput !== "") {
    let infoCopy = info;
    info = infoCopy?.filter((d) =>
      d?.name?.toLowerCase().includes(searchInput.toLowerCase())
    );
  }

  //handler de la paginacion
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  //PARA PAGINAR -------------------------------------------------------------------------
  let indexOfLastElement = page * rowsPerPage;
  let indexOfFirstElement = indexOfLastElement - rowsPerPage;
  let currentElements = info?.slice(indexOfFirstElement, indexOfLastElement);


  // MANEJO DE ERRORES ------------------------------------------------------------------
  if (distributorsError)
    console.log(
      "hubo un error en la query de get distributors",
      distributorsError
    );
  if (updateDistributorError)
    console.log("error updateando el distribuidor", updateDistributorError);
  if (deleteDistributorError)
    console.log("error borrando el distribuidor", deleteDistributorError);

  //LOADINGS
  if (updateDistributorLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.distributorsContainer}>
      {/* TABLA ----------------------------------------------------------------------------- */}
      <div className={styles.inputContainer}>
        <label className={styles.searchQrLabel}>
          <BsSearch size={window.screen.width > 800 ? "1vw" : "3vw"}/>
        </label>
        <input
          type="text"
          onChange={handlerQRChange}
          className={styles.searchQrInput}
          placeholder={
            localStorage.getItem("language") === "en" ? "NAME:" : "NOMBRE:"
          }
        />
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* HEAD ID ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                ID
              </TableCell>
              {/* HEAD NOMBRE ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="name_config_may">NAME </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD LOCATION ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="location_config_company">
                  LOCATION{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD STATUS ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="status_tab_title">
                  STATUS{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD CONTACT INFO ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="contact_info_config_company">
                  CONTACT INFO{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD CREATED ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="created_config">
                  CREATED{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD REGION ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="region_config">REGION </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD ULT MODIF ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="last_mod_may">
                  LAST MODIFIED{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD COMPAÑIAS ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="companies_may">
                  COMPANIES{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD USUARIOS ----------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                <FormattedMessage id="users_may">USERS </FormattedMessage>{" "}
              </TableCell>
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  
                  color: "#00ABC8",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentElements?.map((d) => {
              return (
                <TableRow key={d?.distributor_id}>
                  {/* ID  --------------------------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {d.distributor_id.split("-")[1]}
                  </TableCell>
                  {/* NAME --------------------------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {editDistributor === d.distributor_id ? (
                      <input
                        type="text"
                        defaultValue={d.name}
                        name="name"
                        onChange={handleChangeDistributorData}
                        style={{
                          border: "none",
                          borderBottom: "0.15vw solid grey",
                          width: "auto",
                          textAlign: "center",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
      
                          color: "#1B1464",
                        }}
                      />
                    ) : (
                      d.name
                    )}
                  </TableCell>
                  {/* LOCATION --------------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {editDistributor === d.distributor_id ? (
                      <input
                        type="text"
                        defaultValue={d.location}
                        name="location"
                        onChange={handleChangeDistributorData}
                        style={{
                          border: "none",
                          borderBottom: "0.15vw solid grey",
                          width: "auto",
                          textAlign: "center",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
      
                          color: "#1B1464",
                        }}
                      />
                    ) : (
                      d.location
                    )}
                  </TableCell>
                  {/* ESTADO -------------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {editDistributor === d.distributor_id ? (
                      <select
                        onChange={handleChangeDistributorData}
                        defaultValue="default select"
                        name="status"
                        className={styles.select}
                      >
                        <option
                          disabled
                          hidden
                          value="default select"
                          style={{ color: "#1B1464" }}
                        >
                          {d.status === "ACTIVE"
                            ? localStorage.getItem("language") === "en"
                              ? "Active"
                              : "Activo"
                            : localStorage.getItem("language") === "en"
                            ? "Inactive"
                            : "Inactivo"}
                        </option>
                        <option value={"ACTIVE"} style={{ color: "#1B1464" }}>
                          {localStorage.getItem("language") === "en"
                            ? "Active"
                            : "Activo"}
                        </option>
                        <option value={"ACTIVE"} style={{ color: "#1B1464" }}>
                          {localStorage.getItem("language") === "en"
                            ? "Inactive"
                            : "Inactivo"}
                        </option>
                      </select>
                    ) : (
                      <>
                        {d.status === "ACTIVE" && (
                          <FormattedMessage id="active">
                            Active
                          </FormattedMessage>
                        )}
                        {d.status === "ACTIVE" && (
                          <FormattedMessage id="inactive">
                            Inactive
                          </FormattedMessage>
                        )}
                      </>
                    )}
                  </TableCell>
                  {/* CONTACT INFO --------------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {editDistributor === d.distributor_id ? (
                      <input
                        type="text"
                        defaultValue={d.contact_info}
                        name="contact_info"
                        onChange={handleChangeDistributorData}
                        style={{
                          border: "none",
                          borderBottom: "0.15vw solid grey",
                          width: "auto",
                          textAlign: "center",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
      
                          color: "#1B1464",
                        }}
                      />
                    ) : (
                      d.contact_info
                    )}
                  </TableCell>
                  {/* CREATED ------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {d.created
                      ? d.created.split("T")[0] +
                        " " +
                        d.created.split("T")[1].split(":")[0] +
                        ":" +
                        d.created.split("T")[1].split(":")[1]
                      : "-"}
                  </TableCell>
                  {/* REGION ------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {editDistributor === d.distributor_id ? (
                      <input
                        type="text"
                        defaultValue={d.region}
                        name="contact_info"
                        onChange={handleChangeDistributorData}
                        style={{
                          border: "none",
                          borderBottom: "0.15vw solid grey",
                          width: "auto",
                          textAlign: "center",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
      
                          color: "#1B1464",
                        }}
                      />
                    ) : (
                      d.region
                    )}
                  </TableCell>
                  {/* ULT MODIF --------------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {d.last_modified
                      ? d.last_modified.split("T")[0] +
                        " " +
                        d.last_modified.split("T")[1].split(":")[0] +
                        ":" +
                        d.last_modified.split("T")[1].split(":")[1]
                      : "-"}
                  </TableCell>
                  {/* COMPAÑIAS --------------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    <div className={styles.companiesContainer}>
                      {d?.companies_id?.[0]
                        ? d?.companies_id?.map((c) => {
                            return <span>{c}</span>;
                          })
                        : "-"}
                    </div>
                  </TableCell>
                  {/* USUARIOS --------------------------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    <div className={styles.companiesContainer}>
                      {d?.users_id?.[0]
                        ? d?.users_id?.map((u) => {
                            return <span>{u}</span>;
                          })
                        : "-"}
                    </div>
                  </TableCell>
                  {/* EDITAR/BORRAR DISTRI--------------------------------------------------------------------- */}
                  <TableCell
                    align="center"
                    size="small"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      backgroundColor:
                        currentElements?.indexOf(d) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",
                      
  
                      color: "#1B1464",
                    }}
                  >
                    {editDistributor !== d.distributor_id ? (
                      <>
                        <button
                          onClick={() =>
                            handleEditDistributor(d.distributor_id)
                          }
                          className={styles.editDistributorsButton}
                        >
                          <FormattedMessage id="edit_device_button">
                            Edit
                          </FormattedMessage>
                        </button>
                        <br />
                        <button
                          onClick={() =>
                            handleDeleteDistributor(d.distributor_id)
                          }
                          className={styles.deleteDistributorButton}
                        >
                          <FormattedMessage id="delete_device_button">
                            Delete
                          </FormattedMessage>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={handleSaveEditDistributor}
                          className={styles.editDistributorsButton}
                        >
                          <FormattedMessage id="save_button">
                            Save
                          </FormattedMessage>
                        </button>
                        <br />
                        <button
                          onClick={handleCancelEditDistributor}
                          className={styles.deleteDistributorButton}
                        >
                          <FormattedMessage id="cancel_button">
                            Cancel
                          </FormattedMessage>
                        </button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {info?.length > 10 ? (
        <div className={styles.pagination}>
          <Pagination
            count={Math.ceil(info?.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            size={window.screen.width <= 1000 ? "small" : "large"}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
