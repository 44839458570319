import React, { createContext, useState } from "react";
import { formatDate } from "../../helpers";

// Crea el contexto
export const DatesContext = createContext();

// Crea el proveedor del contexto
export const DatesProvider = ({ children }) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0); // Aseguramos que la fecha sea solo el día actual a las 00:00:00

  const fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15); // Restamos 15 días a la fecha actual
  fifteenDaysAgo.setHours(0, 0, 0, 0); // Aseguramos que la fecha sea solo el día 15 días atrás a las 00:00:00

  const [from, setFrom] = useState(formatDate(fifteenDaysAgo));
  const [to, setTo] = useState(formatDate(date));
  const [rcStaticFrom, setRcStaticFrom] = useState(formatDate(date));
  const [rcStaticTo, setRcStaticTo] = useState(formatDate(date));
  console.log(formatDate(date))
  return (
    <DatesContext.Provider
      value={{
        from,
        to,
        setFrom,
        setTo,
        rcStaticFrom,
        setRcStaticFrom,
        rcStaticTo,
        setRcStaticTo,
      }}
    >
      {children}
    </DatesContext.Provider>
  );
};
