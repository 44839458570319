import React from "react";
import { Chart, ArcElement } from "chart.js";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { Doughnut } from "react-chartjs-2";
import styles from "./ViajesTrazadosGraph.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { FormattedMessage } from "react-intl";

export default function ViajesTrazadosGraph({
  data,
  loading,
  initialFilter,
  prevData,
  succeeded,
  failed,
  uncertain,
}) {
  Chart.register(ArcElement);

  const getCssVariable = (variable) => {
    return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
  };

  //formato data para el grafico
  const graphicData = {
    datasets: [
      {
        data: [data?.succShips, data?.uncertShips, data?.failShips],
        backgroundColor: [  getCssVariable('--green'),
          getCssVariable('--yellow'),
          getCssVariable('--red')],
        borderWidth: 2,
        cutout: "78%",
        radius: "80%",
      },
    ],
  };

  //formato data para el grafico
  const graphicData2 = [
    { name: "Succeeded", value: data?.succShips },
    { name: "Uncertain", value: data?.uncertShips },
    { name: "Failed", value: data?.failShips },
  ];

  const COLORS = ["#28B463", "#F4D03F", "#FF5733"];
  const total = graphicData2.reduce((acc, item) => acc + item.value, 0);

    return (
      <div className={styles.graphContainer}>
   
          <div className={styles.title}>
            <FormattedMessage id="completed_ships_graph_title">
              Completed shipments
            </FormattedMessage>
          </div>
          {loading ? (
            <div className={styles.loading} >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ width: "100%", display: "flex", height: "87%" }}>
              <div className={styles.chartContainer}>
                <Doughnut
                  data={graphicData}
                  options={{
                    responsive: true
                  }}
                />
                <div className={styles.centered}>
                  <span
                    className={
                      data?.completedShips >= 1000
                        ? styles.textCenteredMin
                        : styles.textCentered
                    }
                  >
                    {data?.completedShips}
                  </span>
                </div>
              </div>

              {/* Semáforo */}
              <div className={styles.cardsContainer}>
                <div className={styles.circle_green}>{succeeded}</div>
                <div className={styles.circle_yellow}>{uncertain}</div>
                <div className={styles.circle_red}>{failed}</div>
              </div>
            </div>
          )}
    
      </div>
    );
}
