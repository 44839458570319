import React, { useState, useEffect } from "react";
import {
  // GET_ALL_USERS,
  GET_DEVICES_QUANTITY,
  GET_COMPANY_DETAIL,
  UPDATE_COMPANY,
  GET_BARCODES,
  GET_REFRIGERATIONS_QUANTITY,
} from "../../../queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import styles from "./ConfigLevel2.module.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BiCaretDown, BiChevronDown } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CreateUser from "../CreateUser/CreateUser";
import MyBranches from "../MyBranches/MyBranches";
import MyDevices from "../MyDevices/MyDevices";
import MyUsers from "../MyUsers/MyUsers";
import { company_id } from "../../../const";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MyOperators from "../MyOperators/MyOperators";
import CreateOperator from "../CreateOperator/CreateOperator";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CircularProgress, Menu, MenuItem } from "@mui/material";


import MyCheckpoints from "./customCheckpoints/MyCheckpoints";
import CustomTempAlerts from "./customTempAlerts/CustomTempAlerts";

import MyFreezers from "./myFreezers/MyFreezers";
import {
  closeWebSocketConnection,
  isTokenExpired,
  logOut,
} from "../../../helpers";
import CheckpointsDatatable from "./customCheckpoints/CheckpointsDatatable";
import MyCompanyDataTable from "../../MyCompanyDataTable/MyCompanyDataTable";
import OldCheckpoints from "./customCheckpoints/OldCheckpoints";

export default function ConfigLevel2({ level, access_parced }) {
  // QUERIES/MUTATIONS// ----------------------------------------------------------------------------------------------------
  //query para traerme los datos de la compañia

  const [
    getCompanyInfo,
    {
      loading: companyDetailLoading,
      error: companyDetailError,
      data: companyDetailData,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL, {
    fetchPolicy: "network-only",
  });

  //mutation para updatear company
  const [
    updateCompany,
    {
      data: updatedCompanyData,
      loading: updatedCompanyLoading,
      error: updatedCompanyError,
    },
  ] = useMutation(UPDATE_COMPANY);

  //query para traerme los dispositivos
  const {
    // loading: devicesLoading,
    // error: devicesError,
    data: devicesData,
  } = useQuery(GET_DEVICES_QUANTITY, {
    variables: {
      assigned_to: company_id ? company_id : "",
    },
  });

  const {
    // loading: devicesLoading,
    // error: devicesError,
    data: refrigerationsDataQuantity,
  } = useQuery(GET_REFRIGERATIONS_QUANTITY, {
    variables: {
      assigned_to: company_id ? company_id : "",
    },
  });
  const [
    getBarcodes,
    { data: barcodesList, error: barcodesError, loading: barcodesLoading },
  ] = useLazyQuery(GET_BARCODES);
  const intl = useIntl();
  // ESTADOS LOCALES -----------------------------------------------------------------------
  const barcodesArray = [];
  const [barcodes, setBarcodes] = useState([]);
  const [alertNameState, setAlertNameState] = useState("");
  const [customAlerts, setCustomAlerts] = useState({});

  const [updateTitleCheckNum, setUpdateCheckNum] = useState(false);

  let [user_created, setUser_created] = useState(false);
  //estado de los edit
  const [editData, setEditData] = useState(false);
  // const [editBranches, setEditBranches] = useState(false);
  const [editUsers, setEditUsers] = useState(false);

  //estado para el modal de create user
  const [modalCreateUser, setModalCreateUser] = useState(false);

  //estado para el modal de create operator
  const [modalCreateOperator, setModalCreateOperator] = useState(false);

  //estado para el modal de create shipper
  const [modalCreateShipper, setModalCreateShipper] = useState(false);

  //estado para el modal de create calibrator
  const [modalCreateCalibrator, setModalCreateCalibrator] = useState(false);

  //estado que guarda los input del edit data
  const [companyDataState, setCompanyDataState] = useState({
    company_id,
    name: companyDetailData?.company?.name,
    location: companyDetailData?.company?.location,
    contact_info: companyDetailData?.company?.contact_info,
    barcode: companyDetailData?.company?.barcode,
    alert_params: companyDetailData?.company?.alert_params,
    custom_checks: companyDetailData?.company?.custom_checks,
    tolerance: companyDetailData?.company?.tolerance,
  });
  const [branchesCount, setBranchesCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [opesCount, setOpesCount] = useState(0);
  //estado para el filtrado de tipos de usuarios
  const [myUsersFilter, setMyUsersFilter] = useState("Admin");
  const [refetch, setRefetch] = useState(false);
  const minWidth = useMediaQuery("(min-width:801px)");
  const minWidth2 = useMediaQuery("(min-width:1550px)");
  let heigth = "30vw";

  if (minWidth2 === false) {
    heigth = "40vw";
  }

  if (minWidth === false) {
    heigth = "55vw";
  }
  const updateNumber = () => {
    setUpdateCheckNum(!updateTitleCheckNum);
  };
  //CUANDO SE INICIA EL COMPONENTE -----------------------------------------------------------------

  useEffect(() => {
    if (isTokenExpired()) {
      closeWebSocketConnection();
      logOut();
    }
    let response = {};
    const fetchBarcodes = async () => {
      response = await getBarcodes();
      response?.data?.__type?.enumValues?.forEach((barcode) => {
        barcodesArray.push(Object.values(barcode)[0]);
        setBarcodes([...barcodes, barcodesArray]);
      });
    };
    fetchBarcodes();

    handleModalCreateUserClose();
    async function fetchData() {
      const response = await getCompanyInfo({
        variables: {
          company_id,
        },
      });
      setCompanyDataState({
        company_id,
        name: response?.data?.company?.name,
        location: response?.data?.company?.location,
        contact_info: response?.data?.company?.contact_info,
        barcode: response?.data?.company?.barcode,
        alert_params: response?.data?.company?.alert_params,
        custom_checks: response?.data?.company?.custom_checks,
        tolerance: response?.data?.company?.tolerance,
      });
    }
    fetchData();

    // setCompanyDataState({
    //   company_id,
    //   name: companyDetailData?.company?.name,
    //   location: companyDetailData?.company?.location,
    //   contact_info: companyDetailData?.company?.contact_info,
    //   barcode: companyDetailData?.company?.barcode,
    //   alert_params: companyDetailData?.company?.alert_params,
    //   custom_checks: companyDetailData?.company?.custom_checks,
    //   tolerance: companyDetailData?.company?.tolerance
    // });
  }, [
    //  companyDetailData,
    /*  updatedCompanyData, */
    user_created,
    barcodesList,
    updateTitleCheckNum,
    refetch,
  ]);

  //HANDLERS DEL EDIT COMPAÑIA ---------------------------------------------------------------------------

  //handler del boton edit
  const handleEditData = () => {
    setEditData(true);
  };

  const handleUserCreated = () => {
    if (user_created === true) {
      setUser_created(false);
    }
    if (user_created === false) {
      setUser_created(true);
    }
  };

  //handler de los input de edit
  const handleEditDataChange = (e) => {
    if (e.target.name === "barcode") {
      setCompanyDataState({
        ...companyDataState,
        barcode: companyDataState.barcode.includes(e.target.value)
          ? companyDataState.barcode.filter((b) => b !== e.target.value)
          : [...companyDataState.barcode, e.target.value],
      });
    } else {
      setCompanyDataState({
        ...companyDataState,
        [e.target.name]: e.target.value,
      });
    }
  };

  //handler del save de edit
  const handleSaveCompanyData = async () => {
    setEditData(false);
    try {
      if (
        window.confirm(
          intl.formatMessage({ id: "are_you_sure_confirmChanges" })
        )
      ) {
        await updateCompany({
          variables: {
            company_id,
            name: companyDataState?.name,
            location: companyDataState?.location,
            contact_info: companyDataState?.contact_info,
            barcode: companyDataState?.barcode,
            alert_params: companyDataState?.alert_params,
            custom_checks: companyDataState?.custom_checks,
          },
        });
        // window.location.reload();
      }
    } catch (error) {
      console.log("error en la mutation de updateCompany", error);
    }
  };

  //handler del cancel edit data
  const handleCancelEditData = () => {
    setEditData(false);
    setCompanyDataState({
      company_id,
      name: companyDetailData?.company?.name,
      location: companyDetailData?.company?.location,
      contact_info: companyDetailData?.company?.contact_info,
      barcode: companyDetailData?.company?.barcode,
      alert_params: companyDetailData?.company?.alert_params,
      custom_checks: companyDetailData?.company?.custom_checks,
    });
  };

  // HANDLERS DEL CREATE USER ----------------------------------------------------------
  //handler del modal de create user
  const handleModalCreateUser = () => {
    setModalCreateUser(true);
  };

  //handler del close del modal de create user
  const handleModalCreateUserClose = () => {
    setModalCreateUser(false);
  };

  // HANDLERS DEL CREATE OPERATOR ----------------------------------------------------------
  //handler del modal de create operator
  const handleModalCreateOperator = () => {
    setModalCreateOperator(true);
  };

  //handler del close del modal de create operator
  const handleModalCreateOperatorClose = () => {
    setModalCreateOperator(false);
  };

  const [anchorElBarcodes, setAnchorElBarcodes] = useState(null);
  const open_barcodes = Boolean(anchorElBarcodes);

  const handleDropdown_barcodes = (e) => {
    setAnchorElBarcodes(e.currentTarget);
  };

  const handleCloseDropdown_barcodes = () => {
    setAnchorElBarcodes(null);
  };
  //branches number(depende de si muestro activas o inactivas)
  const branchesNumber = (number) => {
    console.log(number);
    setBranchesCount(number);
  };
  const usersNumber = (number) => {
    setUsersCount(number);
  };
  const opesNumber = (number) => {
    setOpesCount(number);
  };
  //MANEJO DE ERRORES DE LAS QUERIES/MUTATIONS
  if (companyDetailError)
    console.log(
      "error en la busqueda de datos de la compañia",
      companyDetailError
    );
  if (updatedCompanyError)
    console.log("error en el update de la compañia", updatedCompanyError);

  //LOADINGS
  // if (updatedCompanyLoading) return <div>Loading...</div>

  return (
    <div className={styles.container}>
      {/* este div y el del final son para poder redondear los bordes del primer y ultimo acordion(sino no se puede) */}
      <div></div>
      {level < 3 && (
        <>
          {/* MI COMPAÑIA ------------------------------------------------------------------------------------ */}
          <Accordion
            sx={{
              textAlign: "center",
              borderRadius: 4,
            }}
            className={styles.accordionTop}
          >
            <AccordionSummary
              expandIcon={<BiChevronDown />}
              aria-controls="panel2a-content"
              sx={{
                fontFamily: "var(--font1)",
                fontSize: window.screen.width > 800 ? "1.5vw" : "3vw",
                color: "#1B1464",
              }}
            >
              <FormattedMessage id="company_config">
                My company
              </FormattedMessage>
            </AccordionSummary>
            {companyDetailLoading || updatedCompanyLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <>
                <AccordionDetails>
                  {companyDetailData?.company ? (
                    <>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                size="medium"
                                align="center"
                                sx={{
                                  fontSize:
                                    window.screen.width > 800 ? "1.2vw" : "2vw",

                                  color: "#00ABC8",

                                  width: "20%",
                                }}
                              >
                                <FormattedMessage id="name_config_company">
                                  NAME
                                </FormattedMessage>{" "}
                              </TableCell>

                              <TableCell
                                size="medium"
                                align="center"
                                sx={{
                                  fontSize:
                                    window.screen.width > 800 ? "1.2vw" : "2vw",

                                  color: "#00ABC8",

                                  width: "20%",
                                }}
                              >
                                <FormattedMessage id="location_config_company">
                                  LOCATION{" "}
                                </FormattedMessage>{" "}
                              </TableCell>

                              <TableCell
                                size="medium"
                                align="center"
                                sx={{
                                  fontSize:
                                    window.screen.width > 800 ? "1.2vw" : "2vw",

                                  color: "#00ABC8",

                                  width: "20%",
                                }}
                              >
                                <FormattedMessage id="contact_info_config_company">
                                  CONTACT INFO{" "}
                                </FormattedMessage>{" "}
                              </TableCell>

                              <TableCell
                                size="medium"
                                align="center"
                                sx={{
                                  fontSize:
                                    window.screen.width > 800 ? "1.2vw" : "2vw",

                                  color: "#00ABC8",

                                  width: "20",
                                }}
                              >
                                <FormattedMessage id="billing_config_company">
                                  BILLING{" "}
                                </FormattedMessage>{" "}
                              </TableCell>

                              <TableCell
                                size="medium"
                                align="center"
                                sx={{
                                  fontSize:
                                    window.screen.width > 800 ? "1.2vw" : "2vw",

                                  color: "#00ABC8",

                                  width: "20%",
                                }}
                              >
                                <FormattedMessage id="barcode_config_company">
                                  BARCODE/S{" "}
                                </FormattedMessage>{" "}
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  border: "none",

                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "1vw",
                                  color: "#1B1464",
                                }}
                              >
                                {companyDataState?.name}
                              </TableCell>

                              <TableCell
                                align="center"
                                size="medium"
                                sx={{
                                  border: "none",

                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                  color: "#1B1464",
                                }}
                              >
                                {!editData ? (
                                  <div>{companyDataState?.location}</div>
                                ) : (
                                  <input
                                    type="text"
                                    defaultValue={companyDataState?.location}
                                    onChange={handleEditDataChange}
                                    name="location"
                                    className={styles.input_location}
                                    style={{
                                      fontSize:
                                        window.screen.width > 800
                                          ? "1vw"
                                          : "2vw",
                                    }}
                                  />
                                )}
                              </TableCell>

                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  border: "none",

                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                  color: "#1B1464",
                                }}
                              >
                                {!editData ? (
                                  <div className={styles}>
                                    {companyDataState?.contact_info}
                                  </div>
                                ) : (
                                  <input
                                    type="text"
                                    defaultValue={
                                      companyDataState?.contact_info
                                    }
                                    onChange={handleEditDataChange}
                                    name="contact_info"
                                    style={{
                                      fontSize:
                                        window.screen.width > 800
                                          ? "1vw"
                                          : "2vw",
                                    }}
                                    className={styles.input_contact_info}
                                  />
                                )}
                              </TableCell>

                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  border: "none",

                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                  color: "#1B1464",
                                }}
                              >
                                {companyDetailData?.company?.billing}
                              </TableCell>

                              <TableCell
                                align="left"
                                size="small"
                                sx={{
                                  border: "none",

                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                  color: "#1B1464",
                                }}
                              >
                                {editData ? (
                                  <div className={styles.barcodes_list}>
                                    {barcodes[0]?.map((b, i) => {
                                      return (
                                        <label key={i + 66.2}>
                                          <input
                                            type="checkbox"
                                            value={b}
                                            name="barcode"
                                            sx={{
                                              color: "#1B1464",
                                            }}
                                            onChange={
                                              editData
                                                ? handleEditDataChange
                                                : null
                                            }
                                            defaultChecked={
                                              companyDataState?.barcode?.includes(
                                                b
                                              )
                                                ? true
                                                : false
                                            }
                                            disabled={editData ? false : true}
                                            className={styles.barcodesInput}
                                          />
                                          <span className={styles.eachBarcode}>
                                            {b}
                                          </span>
                                          <br />
                                        </label>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className={styles.barcodes_see}>
                                    <FormattedMessage id="barcodes_list">
                                      barcodes list{" "}
                                    </FormattedMessage>{" "}
                                    <button
                                      className={styles.dropdownButton}
                                      onClick={handleDropdown_barcodes}
                                    >
                                      <BiCaretDown />
                                    </button>
                                  </div>
                                )}

                                <Menu
                                  disableScrollLock={true}
                                  aria-labelledby="demo-positioned-button"
                                  anchorEl={anchorElBarcodes}
                                  open={open_barcodes}
                                  onClose={handleCloseDropdown_barcodes}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  sx={{
                                    borderRadius: "2vw",
                                    width:
                                      window.screen.width > 800
                                        ? "20vw"
                                        : "15vw",
                                  }}
                                >
                                  <MenuItem>
                                    <div
                                      className={styles.barcodes_dropdowList}
                                    >
                                      {companyDataState?.barcode?.map(
                                        (b, i) => {
                                          return (
                                            <label key={i + 55.2}>
                                              <span
                                                className={styles.eachBarcode}
                                              >
                                                {b}
                                              </span>
                                              <br />
                                            </label>
                                          );
                                        }
                                      )}
                                    </div>
                                  </MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {!editData ? (
                        <button
                          onClick={handleEditData}
                          className={styles.editButton}
                        >
                          <FormattedMessage id="edit_data_button">
                            Edit data
                          </FormattedMessage>
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={handleSaveCompanyData}
                            className={styles.editButton}
                          >
                            <FormattedMessage id="save_button">
                              Save
                            </FormattedMessage>{" "}
                          </button>
                          <button
                            onClick={handleCancelEditData}
                            className={styles.cancelButton}
                          >
                            <FormattedMessage id="cancel_button">
                              Cancel
                            </FormattedMessage>{" "}
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      <FormattedMessage id="no_data_register">
                        No data
                      </FormattedMessage>
                    </div>
                  )}
                </AccordionDetails>
              </>
            )}
          </Accordion>
          {/* <MyCompanyDataTable/> */}

          {/* MIS BRANCHES ---------------------------------------------------------------------------------------- */}
          <Accordion
            sx={{ textAlign: "center", borderRadius: 4 }}
            className={styles.accordion}
          >
            <AccordionSummary
              expandIcon={<BiChevronDown />}
              aria-controls="panel2a-content"
              sx={{
                fontFamily: "var(--font1)",
                fontSize: window.screen.width > 800 ? "1.5vw" : "3vw",
                color: "#1B1464",
              }}
            >
              <FormattedMessage id="branches_config">
                My branches
              </FormattedMessage>{" "}
              {(companyDetailData?.company?.branches?.length ?? 0) > 0 &&
              branchesCount
                ? `(${branchesCount})`
                : ""}
            </AccordionSummary>
            <AccordionDetails>
              {/* MIS BRANCHES => EDITAR BRANCH ------------------------------------------------------------------------------- */}
              <MyBranches
                company_id={company_id}
                branchesNumber={branchesNumber}
              />
            </AccordionDetails>
          </Accordion>
          {/* MIS USUARIOS -----------------------------------------------------------------------------  */}
          <Accordion
            sx={{ textAlign: "center", borderRadius: 4 }}
            className={styles.accordion}
          >
            <AccordionSummary
              expandIcon={<BiChevronDown />}
              aria-controls="panel2a-content"
              sx={{
                fontFamily: "var(--font1)",
                fontSize: window.screen.width > 800 ? "1.5vw" : "3vw",
                color: "#1B1464",
              }}
            >
              <FormattedMessage id="users_config">My users</FormattedMessage>

              {(companyDetailData?.company?.users_id?.length ?? 0) > 0 &&
              usersCount
                ? ` (${usersCount})`
                : ""}
            </AccordionSummary>
            <AccordionDetails>
              {/* MIS USUARIOS => EDITAR USUARIO ----------------------------------------------------------------- */}
              <MyUsers
                company_id={company_id}
                usersFilter={myUsersFilter}
                userCreated={user_created}
                usersNumber={usersNumber}
              />
              {/* MIS USUARIOS => CREAR USUARIO -------------------------------------------------------------------- */}
              {!editUsers && (
                <>
                  <button
                    onClick={handleModalCreateUser}
                    className={styles.createUserButton}
                  >
                    <FormattedMessage id="create_user_button">
                      Create new user
                    </FormattedMessage>
                  </button>
                  <Modal
                    open={modalCreateUser}
                    onClose={handleModalCreateUserClose}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "white",
                        border: "0.15vw solid #00ABC8",
                        borderRadius: "2vw",

                        boxShadow: 10,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        backgroundColor: "white",
                        width: "30vw",
                        height: "30vw",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <CreateUser
                        company_id={company_id}
                        onAction={handleUserCreated}
                      />
                    </Box>
                  </Modal>
                </>
              )}
            </AccordionDetails>
          </Accordion>
          {/* MIS OPERARIOS  ------------------------------------------------------------------------------------- */}
          <Accordion
            sx={{ textAlign: "center", borderRadius: 4 }}
            className={styles.accordion}
          >
            <AccordionSummary
              expandIcon={<BiChevronDown />}
              aria-controls="panel2a-content"
              sx={{
                fontFamily: "var(--font1)",
                fontSize: window.screen.width > 800 ? "1.5vw" : "3vw",
                color: "#1B1464",
              }}
            >
              <FormattedMessage id="operators_config">
                My operators
              </FormattedMessage>{" "}
              {companyDetailData?.company?.operators?.length > 0 && opesCount
                ? ` (${opesCount})`
                : ""}
            </AccordionSummary>
            <AccordionDetails>
              <MyOperators
                company_id={company_id}
                type="operator"
                opesNumber={opesNumber}
              />
              {/* MIS OPERARIOS => CREAR OPERARIO ---------------------------------------------------------------------------- */}
              <button
                onClick={handleModalCreateOperator}
                className={styles.createUserButton}
              >
                <FormattedMessage id="create_operator">
                  Create operator
                </FormattedMessage>
              </button>
              <Modal
                open={modalCreateOperator}
                onClose={handleModalCreateOperatorClose}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",

                    border: "0.15vw solid #00ABC8",
                    borderRadius: "2vw",

                    boxShadow: 10,
                    pt: 2,
                    px: 4,
                    pb: 3,
                    backgroundColor: "white",
                    width: "30vw",
                    height: "35vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CreateOperator company_id={company_id} type="Operator" />
                </Box>
              </Modal>
            </AccordionDetails>
          </Accordion>

          {/* MIS CHECKPOINTS------------------------------------------------------------ */}
          <Accordion
            sx={{ textAlign: "center", borderRadius: 4 }}
            className={styles.accordion}
          >
            <AccordionSummary
              expandIcon={<BiChevronDown />}
              aria-controls="panel2a-content"
              sx={{
                fontFamily: "var(--font1)",
                fontSize: window.screen.width > 800 ? "1.5vw" : "3vw",
                color: "#1B1464",
              }}
            >
              <FormattedMessage id="checkpoints_config">
                My checkpoints
              </FormattedMessage>

              {companyDetailData?.company?.custom_checks.length > 0
                ? ` (${companyDetailData?.company?.custom_checks?.length})`
                : ""}
            </AccordionSummary>
            <AccordionDetails>
              <MyCheckpoints
                customChecks={companyDetailData?.company?.custom_checks || []}
                defaultCheckpoints={
                  companyDetailData?.company?.custom_checks || []
                }
                updateNumber={updateNumber}
                refetch={refetch}
                setRefetch={setRefetch}
                loading={companyDetailLoading}
                startup_alerts={companyDetailData?.company?.startup_alerts}
              />
              {/* <CheckpointsDatatable/> */}
              {/* <OldCheckpoints company_id={company_id} /> */}
            </AccordionDetails>
          </Accordion>
          {/* MIS ALERTAS DE TEMPERATURA---------------------------------------------------------------------- */}

          <Accordion
            sx={{ textAlign: "center", borderRadius: 4 }}
            className={styles.accordion}
          >
            <AccordionSummary
              expandIcon={<BiChevronDown />}
              aria-controls="panel2a-content"
              sx={{
                fontFamily: "var(--font1)",
                fontSize: window.screen.width > 800 ? "1.5vw" : "3vw",
                color: "#1B1464",
              }}
            >
              <FormattedMessage id="my_temperature_alerts">
                my_temperature_alerts
              </FormattedMessage>

              {companyDetailData?.company?.alert_params?.temperature_alerts
                ?.length > 0
                ? ` (${companyDetailData?.company?.alert_params?.temperature_alerts?.length})`
                : ""}
            </AccordionSummary>
            <AccordionDetails>
              <CustomTempAlerts
                company_id={company_id}
                companyAlerts={companyDataState?.alert_params}
                editData={editData}
                tolerance={companyDataState?.tolerance}
              />
            </AccordionDetails>
          </Accordion>

          {/* MY FREEZERS */}
          {refrigerationsDataQuantity?.refrigerations?.length !== 0 && (
            <Accordion
              sx={{ textAlign: "center", borderRadius: 4 }}
              className={styles.accordion}
            >
              <AccordionSummary
                expandIcon={<BiChevronDown />}
                aria-controls="panel2a-content"
                sx={{
                  fontFamily: "var(--font1)",
                  fontSize: window.screen.width > 800 ? "1.5vw" : "3vw",
                  color: "#1B1464",
                }}
              >
                RC+Static
                {refrigerationsDataQuantity?.refrigerations?.length > 0
                  ? ` (${refrigerationsDataQuantity?.refrigerations?.length})`
                  : ""}
              </AccordionSummary>
              <AccordionDetails>
                {refrigerationsDataQuantity?.refrigerations?.length > 0 && (
                  <MyFreezers company_id={company_id} />
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
      {/* MIS DISPOSITIVOS ------------------------------------------------------------------------------------- */}
      <Accordion
        sx={{ textAlign: "center", borderRadius: 4 }}
        className={styles.accordionBottom}
      >
        <AccordionSummary
          expandIcon={<BiChevronDown />}
          aria-controls="panel2a-content"
          sx={{
            fontFamily: "var(--font1)",
            fontSize: window.screen.width > 800 ? "1.5vw" : "3vw",
            color: "#1B1464",
          }}
        >
          <FormattedMessage id="devices_config">My devices</FormattedMessage>

          {devicesData?.devices?.length > 0
            ? ` (${devicesData?.devices?.length})`
            : ""}
        </AccordionSummary>
        <AccordionDetails>
          <MyDevices company_id={company_id} level={level} />
        </AccordionDetails>
      </Accordion>

      {/* este div y el del inicio son para poder redondear los bordes del primer y ultimo acordion(sino no se puede) */}
      <div></div>
    </div>
  );
}
