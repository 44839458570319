import {
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import AlertComments from "../alertsComments/AlertComments";
import {
  GET_REFRIGERATION,
  GET_REFRIGERATIONS,
  GET_REFRIGERATION_ALERTS,
  UPDATE_MANY_REFRIGERATION_ALERTS,
  UPDATE_REFRIGERATION_ALERTS,
} from "../../../queries";
import { useState } from "react";
import { useEffect } from "react";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import styles from "./freezerAlertsModal.module.scss";
import { convertirHoraLocal, formatDate } from "../../../helpers";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { language } from "../../../const";
import { FormattedMessage } from "react-intl";
import { DatesContext } from "../../../context/dates/Dates";
import DatePicker from "react-datepicker";
import { set } from "date-fns";

const FreezerAlertsModal = ({ freezer, open, refetch }) => {
  const [refetchQueryAlertComments, serRefetchAlertComments] = useState(false);
  const { rcStaticFrom, setRcStaticFrom, rcStaticTo, setRcStaticTo } =
    useContext(DatesContext);
  //estados para la paginacion
  const [dataLength, setDataLength] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const refetchAlertComments = () => {
    serRefetchAlertComments(!refetchQueryAlertComments);
  };
  const [
    getRefrigeration,
    {
      loading: refrigerationLazyLoading,
      error: refrigerationLazyError,
      data: refrigerationLazy,
    },
  ] = useLazyQuery(GET_REFRIGERATION_ALERTS, { fetchPolicy: "no-cache" });

  const [
    updateAlert,
    { loading: updateAlertLoading, error: updateAlertError },
  ] = useMutation(UPDATE_REFRIGERATION_ALERTS, {
    refetchQueries: [
      {
        query: GET_REFRIGERATION,
        variables: {
          from: null,
          to: null,
          qr: freezer.qr,
        },
      },
    ],
  });
  const [
    updateManyRefAlerts,
    { loading: updateManyRefAlertsLoading, error: updateManyRefAlertsError },
  ] = useMutation(UPDATE_MANY_REFRIGERATION_ALERTS, {
    refetchQueries: [
      {
        query: GET_REFRIGERATION_ALERTS,
        variables: {
          from: null,
          to: null,
          qr: freezer.qr,
        },
      },
    ],
  });

  const [alerts, setAlerts] = useState([]);
  const [alertsToUpdate, setAlertsToUpdate] = useState([]);
  const [text, setText] = useState("");
  const [solved, setSolved] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [buttonText, setButtonText] = useState(false);
  const [showUnsolved, setShowUnsolved] = useState(false);
  

  useEffect(() => {
    const fetch = async () => {
   

      try {
        const response = await getRefrigeration({
          variables: {
            from: rcStaticFrom,
            to: rcStaticTo,
            qr: freezer?.qr,
            page: page,
            per_page: rowsPerPage,
            solved:solved
          },
        });
       
        const newAlerts = response?.data?.refrigeration?.alerts;
       console.log(response?.data?.refrigeration?.alerts)
        setAlerts(newAlerts);  
        setDataLength(response?.data?.refrigeration?.totalAlerts);
       
      } catch (error) {
        console.log(`Error al cargar modal: ${error}`);
      }
    };
    fetch();
  }, [
    freezer,
    solved,
    fromDate,
    toDate,
    refetchQueryAlertComments,
    rcStaticTo,
    rcStaticFrom,
    page,
    rowsPerPage,
   solved
  ]);

  const handleIntervalFilter = (date, name) => {
    if (name === "from") setRcStaticFrom(formatDate(date));
    if (name === "to") setRcStaticTo(formatDate(date));
    console.log(date, name);
  };

  const orderSolved = () => {
    setButtonText(!buttonText);
    setShowUnsolved(!showUnsolved); // Cambia el estado para alternar el filtrado
    refetchAlertComments();
    setSolved(!solved);
  };

  //nueva logica de modificacion de alertas

  const handleAlertsToUpdate = (e, alert) => {
    setAlertsToUpdate((prevAlerts) => {
      const existingAlertIndex = prevAlerts.findIndex(
        (a) => a.type === alert.type && a.timestamp === alert.timestamp
      );

      if (e.target.checked) {
        if (existingAlertIndex !== -1) {
          const updatedAlerts = [...prevAlerts];
          updatedAlerts[existingAlertIndex].solved = true;
          return updatedAlerts;
        } else {
          return [
            ...prevAlerts,
            {
              solved: true,
              comment: alert.comment || null,
              type: alert.type,
              timestamp: alert.timestamp,
            },
          ];
        }
      } else {
        if (existingAlertIndex !== -1) {
          const updatedAlerts = [...prevAlerts];
          updatedAlerts[existingAlertIndex].solved = false;
          if (updatedAlerts[existingAlertIndex].comment === null) {
            updatedAlerts.splice(existingAlertIndex, 1);
          }
          return updatedAlerts;
        } else {
          return [
            ...prevAlerts,
            {
              solved: false,
              comment: null,
              type: alert.type,
              timestamp: alert.timestamp,
            },
          ];
        }
      }
    });
  };

  const onActionNewAlertComment = (e, alert) => {
    const newComment = e.target.value.trim() === "" ? null : e.target.value;
    setAlertsToUpdate((prevAlerts) => {
      const existingAlertIndex = prevAlerts.findIndex(
        (a) => a.timestamp === alert.timestamp
      );

      if (existingAlertIndex !== -1) {
        const updatedAlerts = [...prevAlerts];
        updatedAlerts[existingAlertIndex].comment = newComment;
        if (!updatedAlerts[existingAlertIndex].solved && newComment === null) {
          updatedAlerts.splice(existingAlertIndex, 1);
        }
        return updatedAlerts;
      } else if (newComment !== null) {
        return [
          ...prevAlerts,
          {
            solved: alert.solved,
            comment: newComment,
            type: alert.type,
            timestamp: alert.timestamp,
          },
        ];
      } else {
        return prevAlerts;
      }
    });
  };

  const handleSendChanges = async () => {
    //realizar mutation para updatear alertas
    console.log(alertsToUpdate);
    try {
      await updateManyRefAlerts({
        variables: {
          refrigeration_id: freezer.refrigeration_id,
          alerts: alertsToUpdate,
        },
      });
      window.alert(
        language === "es"
          ? "Los cambios fueron guardados con éxito!"
          : "Changes saved successfully"
      );
      setAlerts([]);
      refetch();
      refetchAlertComments();
      setAlertsToUpdate([]);
    } catch (error) {
      console.log(error);
      //toast
    }
  };
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleTogle = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
    console.log(isDatePickerOpen);
  };

  /* por alguna razon que desconozco el datePiker levanta del context 
  la fecha con un dia menos asi que hice esta funcion falopa que le suma un dia */
  function crearFechaDesdeCadena(cadenaFecha) {
    const partesDeFecha = cadenaFecha.split("-");
    const año = parseInt(partesDeFecha[0], 10);
    const mes = parseInt(partesDeFecha[1], 10) - 1;
    const día = parseInt(partesDeFecha[2], 10);
    return new Date(año, mes, día);
  }

  const handleChangePage2 = (event, value) => {
    setPage(value);
  };

  if (refrigerationLazyLoading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <>
        <div className={styles.filterContainer}>
          {/* DESDE */}
          <div className={styles.filterLabel}>
            <FormattedMessage id="from" />
          </div>
          <DatePicker
            id="alertDatePicker"
            selected={crearFechaDesdeCadena(rcStaticFrom)}
            type="date"
            name="from"
            onChange={(date) => handleIntervalFilter(date, "from")}
            dateFormat="yyyy-MM-dd"
            className={styles.datePicker}
            onCalendarClose={handleTogle}
            onCalendarOpen={handleTogle}
            maxDate={new Date()}
          />

          <div className={styles.filterLabel}>
            <FormattedMessage id="to" />
          </div>

          {/* HASTA */}
          <DatePicker
            id="alertDatePicker"
            selected={crearFechaDesdeCadena(rcStaticTo)}
            type="date"
            name="to"
            onChange={(date) => handleIntervalFilter(date, "to")}
            dateFormat="yyyy-MM-dd"
            className={styles.datePicker}
            onCalendarClose={handleTogle}
            onCalendarOpen={handleTogle}
            maxDate={new Date()}
          />

          <button
            onClick={handleSendChanges}
            disabled={alertsToUpdate.length !== 0 ? false : true}
            className={styles.sendButton}
          >
            <FormattedMessage id="send" />
            {/* send button */}
          </button>

          <button
            onClick={orderSolved}
            className={
              !buttonText ? styles.solvedButton : styles.unsolvedButton
            }
          >
            {language === "es"
              ? !buttonText
                ? "Resueltas"
                : "No Resueltas"
              : !buttonText
              ? "Solved"
              : "Unsolved"}
          </button>
        </div>
        {alerts !== undefined && (
          <>
          {console.log(alerts)}
            {[...alerts]
              .reverse()
             ?.length === 0 ? (
              <div className={styles.noAlerts}>
                {language === "es"
                  ? "No hay alertas en ese rango de fechas."
                  : "No alerts in that date range."}
              </div>
            ) : (
              <TableContainer style={{maxHeight: "37vw"}}>
                <Table stickyHeader={!isDatePickerOpen}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        size="medium"
                        align="left"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",
                          width: "10%",
                         
                        }}
                      >
                       
                        <FormattedMessage id="type" />
                      </TableCell>
                      <TableCell
                        size="medium"
                        align="left"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",
                          color: "#00ABC8",
                          // backgroundColor: "transparent",
                          width: "10%",
                        }}
                      >
                        <FormattedMessage id="temperature" />
                      </TableCell>
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",
                          // backgroundColor: "transparent",
                          color: "#00ABC8",
                          width: "10%",
                        }}
                      >
                        <FormattedMessage id="date" />
                      </TableCell>
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",
                          // backgroundColor: "transparent",
                          color: "#00ABC8",

                          // paddingLeft:"5%",
                          width: "50%",
                          // border: "solid red 1px",
                        }}
                      >
                        <FormattedMessage id="alert_comments" />
                      </TableCell>
                      <TableCell
                        size="medium"
                        align="right"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",

                          // paddingLeft:"5%",
                          width: "5%",
                          // border: "solid red 1px",
                        }}
                      >
                        <FormattedMessage id="solved" />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {alerts !== undefined && (
                    <TableBody>
                      {console.log(alerts)}
                      {[...alerts]
                        .reverse() //doy vuelta el array para mostrar las ultimas alertas primero
                        .filter((alert) =>
                          showUnsolved
                            ? alert.solved === true
                            : alert.solved === false 
                       )
                        .map((alert, index) => (
                          <TableRow key={index + 0.75}>
                            {/* type -------------------------------------*/}
                            <TableCell
                              align="left"
                              sx={{
                                color: "#1B1464",
                                width: "10%",
                                // border: "solid red 1px",
                              }}
                              style={{ height: 100 }}
                            >
                              <div className={styles.dataToShow}>
                                {alert?.type}
                              </div>
                            </TableCell>
                            {/* temp -------------------------------------------*/}
                            <TableCell
                              align="left"
                              sx={{
                                color: "#1B1464",
                                width: "10%",

                                alignContent: "center",
                              }}
                            >
                              {alert?.value !== null && (
                                <div className={styles.dataToShow}>
                                  {alert?.value?.toFixed(2)} °C
                                </div>
                              )}
                            </TableCell>
                            {/* date --------------------------------------------*/}
                            <TableCell
                              align="left"
                              sx={{
                                color: "#1B1464",
                                width: "10%",
                                // border: "solid red 1px",
                              }}
                            >
                              {alert?.timestamp && (
                                <>
                                  <div className={styles.dataToShow}>
                                    {convertirHoraLocal(
                                      alert.timestamp,
                                      freezer.gmt
                                    )
                                      .split(" ", 2)
                                      .join(" ")}
                                  </div>
                                  <div className={styles.dataToShow}>
                                    {convertirHoraLocal(
                                      alert.timestamp,
                                      freezer.gmt
                                    )
                                      .split(" ")
                                      .slice(2)
                                      .join(" ")}
                                  </div>
                                </>
                              )}
                            </TableCell>

                            {/* comments --------------------------------------------*/}
                            <TableCell
                              align="center"
                              sx={{
                                color: "#1B1464",
                                width: "50%",
                                // border: "solid red 1px",
                              }}
                            >
                              <AlertComments
                                alert={alert}
                                freezer={freezer}
                                from={rcStaticFrom}
                                to={rcStaticTo}
                                refetchAlertComments={refetchAlertComments}
                                onActionNewAlertComment={
                                  onActionNewAlertComment
                                }
                                loading={refrigerationLazyLoading}
                              />
                            </TableCell>
                            {/* solved */}
                            <TableCell
                              align="center"
                              sx={{
                                color: "#1B1464",
                                width: "5%",
                                // border: "solid red 1px",
                              }}
                            >
                              <input
                                type="checkbox"
                                name="solved"
                                // onChange={(e) => handleSolved(e, alert)}
                                onChange={(e) => handleAlertsToUpdate(e, alert)}
                                className={styles.check}
                                defaultChecked={!alert.solved ? false : true}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                  
                </Table>
             
              </TableContainer>
            )}
               {dataLength > rowsPerPage && (
                  <Pagination
                    count={Math.ceil(dataLength / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage2}
                    style={{
                      display: "flex",
                      width: "94%",
                      justifyContent: "end",
                      margin: "0 auto",
                    }}
                  />
                )}
          </>
        )}
      </>
    );
  }
};

export default FreezerAlertsModal;
