import React from 'react';
import { company_id } from '../../../const';
import styles from './CompareButton.module.scss';
import { useCompare } from './hooks';
import { FormattedMessage } from "react-intl";



const CompareButton = ({ arrayState, onAction, errorMsj, type }) => {
  
  const { compareShipments } = useCompare();
  return (
    <div className={styles.buttonContainer}>
      <button 
      className={styles.button} 
      onClick={() => { compareShipments(arrayState, company_id, onAction, errorMsj, type) }}
      disabled={arrayState.length<2 ? true : false}
      >
        <FormattedMessage id="compare" />
      </button>
    </div>
  )
}

export default CompareButton