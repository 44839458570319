import { useMutation } from "@apollo/client";
import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { UPDATE_ACC_ALERT_PARAMS, UPDATE_TEMP_ALERT_PARAMS } from "../../../../../queries";
import styles from "./addAlertModal.module.scss";
import { FormattedMessage, useIntl } from "react-intl";

const AddAlertModal = ({
  modalAddAlert,
  handleModalAddAlertClose,
  companyAlerts,
  company_id,
  type

}) => {
const intl=useIntl();
  const [
    updateCompany,
    {
      data: updatedCompanyData,
      loading: updatedCompanyLoading,
      error: updatedCompanyError,
    },
  ] = useMutation(
    type === 'temperature' ?
      UPDATE_TEMP_ALERT_PARAMS
      : UPDATE_ACC_ALERT_PARAMS
  );


  const [newAlert, setNewAlert] = useState(
    type === 'temperature' ? {
      name: "",
      max: 0,
      min: 0,
    } : {
      name: "",
      value: 0
    });
  
  const [newAlerts, setNewAlerts] = useState([]);
  
  const handleCreateNewAlert = (e) => {
    const { name, value } = e.target;
    
    let updatedAlert = { ...newAlert };
  
    if (name === "alertName") {
      updatedAlert.name = value;
    }
    if (name === "max" || name === "min" || name === "value") {
      updatedAlert[name] = parseInt(value);
    }
  
    setNewAlert(updatedAlert);
    setNewAlerts([...companyAlerts, updatedAlert]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
     setNewAlerts([...companyAlerts, newAlert]);
    let alerts =
      type === 'temperature' ?
        {
          temperature_alerts: newAlerts,
          acceleration_alerts: null
        } :
        {
          temperature_alerts: null,
          acceleration_alerts: newAlerts
        }
    try {
      if (
        window.confirm(
          intl.formatMessage({ id: "are_you_sure_confirmChanges" }))
        
      ) {
        await updateCompany({
          variables: {
            company_id,
            alert_params: alerts,
          },
        });

        window.location.reload();
      }
    } catch (error) {
      console.log("error en la mutation de updateCompany", error);
    }

  };

  return (
    <Modal open={modalAddAlert} onClose={handleModalAddAlertClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          border: "0.15vw solid #00ABC8",
          borderRadius: "2vw",
          fontFamily: "'Quattrocento Sans', sans-serif",
          boxShadow: 10,
          pt: 2,
          px: 4,
          pb: 3,
          backgroundColor: "white",
          width: "30vw",
          height: "20vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form action="" onSubmit={handleSubmit} className={styles.AddAlertForm}>
          <input
            type="text"
            placeholder="Alert name"
            className={styles.newAlertinputName}
            name="alertName"
            onChange={handleCreateNewAlert}
            maxLength={5}
          />
          {/* si la alerta es de temperatura */}
          {type === 'temperature' ? (
            <div className={styles.max_min}>
              <div className={styles.spanAndTemp}>
                <span
                  className={styles.spanTemp}
                  style={{
                    fontSize: window.screen.width > 800 ? "1.3vw" : "2vw",
                    

                    color: "#1B1464",
                  }}
                >
                  Max
                </span>
                <input
                  type="number"
                  defaultValue={0}
                  name="max"
                  onChange={handleCreateNewAlert}
                  style={{
                    border: "none",
                    borderBottom: "0.15vw solid grey",
                    width: "20%",
                    textAlign: "center",
                    fontSize: window.screen.width > 800 ? "1.2vw" : "2vw",
                    

                    color: "#1B1464",
                  }}
                />
              </div>
              <div className={styles.spanAndTemp}>
                <span
                  className={styles.spanTemp}
                  style={{
                    fontSize: window.screen.width > 800 ? "1.3vw" : "2vw",
                    

                    color: "#1B1464",
                  }}
                >
                  Min
                </span>
                <input
                  type="number"
                  defaultValue={0}
                  name="min"
                  onChange={handleCreateNewAlert}
                  style={{
                    border: "none",
                    borderBottom: "0.15vw solid grey",
                    width: "20%",
                    textAlign: "center",
                    fontSize: window.screen.width > 800 ? "1.2vw" : "2vw",
                    

                    color: "#1B1464",
                  }}
                />
              </div>
            </div>
          ) : (<div className={styles.spanAndTemp}>
            <span
              className={styles.spanTemp}
              style={{
                fontSize: window.screen.width > 800 ? "1.3vw" : "2vw",
                
                fontFamily: "'Quattrocento Sans', sans-serif",
                color: "#1B1464",
              }}
            >
              Value
            </span>
            <input
              type="number"
              defaultValue={0}
              name="value"
              onChange={handleCreateNewAlert}
              style={{
                border: "none",
                borderBottom: "0.15vw solid grey",
                width: "20%",
                textAlign: "center",
                fontSize: window.screen.width > 800 ? "1.2vw" : "2vw",
                
                fontFamily: "'Quattrocento Sans', sans-serif",
                color: "#1B1464",
              }}
            />
          </div>)}


          <button
            type="submit"
            onClick={handleSubmit}
            className={styles.addAlertButton}
          >
          
            <FormattedMessage id="create_alert"/>
          </button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddAlertModal;
