import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  LOGIN,
  GET_USER,
  GET_COMPANY_DETAIL,
  ASSIGN_USER,
} from "../../queries";
import { CircularProgress } from "@mui/material";
import styles from "./SelectOperatorToLogin.module.scss";
import { BASE_URL } from "../../const";

const SelectOperatorToLogin = ({ user, handleSelectOpeModalClose, token }) => {
  //estados----------------------------------------
  const [operators, setOperators] = useState([]);
  const [assignSelect, setAssignSelect] = useState("");
  const [typeOfOperForSpanish, setTypeOfOperForSpanish] = useState("");

  let ope;
  let access;

  //mutations/queries-----------------------------------------------------------
  //traer operadores libres
  const [
    getFreeOperators,
    {
      data: freeOperators,
      loading: freeOperatorsLoading,
      // error: freeOperatorsError,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL);

  //asignar un operador si el usuario es mono = false

  useEffect(() => {
    getFreeOperators({
      variables: {
        belong_id: user?.user?.belong_id,
        type: user?.user?.type.toLowerCase() + "s",
      },
    });
    setOperators(freeOperators?.company?.operators);
    switch (user?.user?.type.toLowerCase()) {
      case "calibrator":
        setTypeOfOperForSpanish("calibrador");
        break;
      case "operator":
        setTypeOfOperForSpanish("operador");
        break;
      case "shipper":
        setTypeOfOperForSpanish("transportista");
        break;

      default:
        break;
    }
  }, [freeOperators, operators, typeOfOperForSpanish]);

  const handleAssignChange = (e) => {
    setAssignSelect(e.target.value);
  };

  const handleSubmit = async () => {
    //busco los permisos del operador que elegi

    ope = operators?.filter((o) => o?.operator_id === assignSelect);
    access = ope[0]?.access_to;

    if (access !== null && access !== undefined) {
      localStorage.setItem("access", JSON.stringify(access));
      localStorage.setItem("assigned_to", ope[0]?.operator_id);
      localStorage.setItem("assigned_to_name",ope[0]?.name)
      console.log(ope[0])

      if (access.dash_control === false && access.dash_intransit === true) {
        window.location.replace(`${BASE_URL}/#in-coming`);
        window.location.reload();
      } else if (access.dash_control === false && access.calibrate === true) {
        window.location.replace(`${BASE_URL}/#configuration`);
        window.location.reload();
      } else if (access.dash_control === false && access.dash_report === true) {
        window.location.replace(`${BASE_URL}/#reports`);
        window.location.reload();
      } else if (access.dash_control === true) window.location.replace("/");
      else if (
        access.dash_control === false &&
        access.dash_report === false &&
        access.dash_intransit === false
      ) {
        alert("Operator without access ");
      }
    } else {
      alert("Select operator!");
    }
  };

  if (freeOperatorsLoading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <select
        defaultValue="default select"
        onChange={handleAssignChange}
        className={styles.assignUserInput}
      >
        <option disabled hidden value="default select">
          {localStorage.getItem("language") === "en"
            ? `Select  ${user?.user?.type.toLowerCase()}`
            : `Seleccione un  ${typeOfOperForSpanish}`}
        </option>

        {/* mapeo operadores libres filtrados por tipo de usuario */}
        {operators
          ?.filter((o) => o?.type === user?.user?.type)
          .map((o) => {
            return (
              <option key={o.operator_id} value={o.operator_id}>
                {o?.name} ({o?.operator_id})
              </option>
            );
          })}
      </select>
      <div className={styles.buttonsContainer}>
        <button
          type="submit"
          onClick={handleSelectOpeModalClose}
          className={styles.cancelButton}
        >
          {localStorage.getItem("language") === "es" ? "Cancelar" : "Cancel"}
        </button>

        <button
          type="submit"
          onClick={handleSubmit}
          className={styles.confirmAssignButton}
        >
          {localStorage.getItem("language") === "es" ? "Entrar" : "Log in"}
        </button>
      </div>
    </>
  );
};

export default SelectOperatorToLogin;
