import React, { useContext, useEffect, useState } from "react";
import ViajesTrazadosGraph from "../ViajesTrazadosGraph/ViajesTrazadosGraph";
import ViajesNoConformesArea from "../ViajesNoConformesArea/ViajesNoConformesArea";
import styles from "./Dashboard.module.scss";
import { useApolloClient, useSubscription } from "@apollo/client";
import {
  SHIPMENTS_CREATED_SUBSCRIPTION,
  SHIPMENTS_UPDATED_SUBSCRIPTION,
} from "../../queries";
import axios from "axios";

import { company_id, SERVER_URL } from "../../const";
import { closeWebSocketConnection, expiredAction, logOut, parseJwt } from "../../helpers.js";

import Reports2 from "../Reports/Reports2.jsx";
import DatePickerFromTo from "./dateFromTo/DatePickerFromTo.jsx";
import { DatesContext } from "../../context/dates/Dates.jsx";
import PrivateRoute, { isTokenExpired } from "../PrivateRoute/PrivateRoute.jsx";
import { useNavigate } from "react-router-dom";

export default function Dashboard({ access_parced, level, operator_access }) {
  const token = localStorage.getItem("token");
  if (!parseJwt(localStorage.getItem("token"))?.SUDO) {
    if (access_parced.dash_control === false) logOut();
  }


  //importa datos de context
  const { from, to } = useContext(DatesContext);
  // ESTADOS LOCALES ------------------------------------------------------------------------
  //estados donde me guardo la info traida de la query
  const [inTransitShipsState, setInTransitShipsState] = useState(0);
  const [allData, setAllData] = useState({
    completedShipsState: 0,
    succShipsState: 0,
    uncertShipsState: 0,
    failShipsState: 0,
    branchesWithMoreAlerts: [],
    causes: [],
  });
  const [buttonError, setButtonError] = useState(false);
  //estado para los loading
  const [loading, setLoading] = useState(true);

  //estados y variables para el seteo de fechas y filtros mes/dia
  const date = new Date();
  const firstDayOfMonth = new Date().setDate("01");
  const [initialDay, setInitialDay] = useState(
    new Date(new Date().setHours("00", "00", "00"))
  );

  const [initialMonth, setInitialMonth] = useState(
    new Date(new Date(firstDayOfMonth).setHours("00", "00", "00"))
  );
  const [yesterday, setYesterday] = useState(
    new Date(new Date() - 86400000).toISOString().split("T")[0]
  );

  //TRAIGO LA DATA DESDE EL SERVIDOR SEGUN FILTRO MES/DIA ------------------------------------------------------------------
  async function fetchData(company_id) {
    if (from && to) {
      const result = await axios.get(
        `${SERVER_URL}/statistics/${company_id}/${from}/${to}`,
        { headers: { authorization: `Bearer ${token}` } }
      );

      try {
        let successfulCount = 0;
        let failedCount = 0;
        let transitCount = 0;
        let uncertainCount = 0;
        let branches = result?.data?.branches?.sort(
          (a, b) => b.count - a.count
        );

        result.data.traced.forEach((item) => {
          if (item._id === "SUCCESSFUL") {
            successfulCount = item.count;
          }
          if (item._id === "FAILED") {
            failedCount = item.count;
          }
          if (item._id === "TRANSIT") {
            transitCount = item.count;
          }
          if (item._id === "UNCERTAIN") {
            uncertainCount = item.count;
          }
        });
        setInTransitShipsState(transitCount);

        setAllData({
          ...allData,
          completedShipsState: failedCount + successfulCount + uncertainCount,
          succShipsState: successfulCount,
          uncertShipsState: uncertainCount,
          failShipsState: failedCount,

          branchesWithMoreAlerts: branches.slice(0, 4),

          causes: result?.data?.causes,
        });

        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        console.log(error);
        setLoading(false);
      }
    } else {
      setButtonError(true);
      alert("Por favor seleccione un rango de fechas válido");
    }
  }

  // QUERIES / SUBSCRIPTIONS / MUTATIONS --------------------------------------------------------------------------
  //subscription para viajes iniciados
  const {
    data: createdShipData,
    error: createdShipError,
    // loading: createdShipLoading,
  } = useSubscription(SHIPMENTS_CREATED_SUBSCRIPTION);

  //subscription para viajes cerrados
  const {
    // loading: updatedShipLoading,
    error: updatedShipError,
    data: updatedShipData,
  } = useSubscription(SHIPMENTS_UPDATED_SUBSCRIPTION);

  // CUANDO SE INICIA EL COMPONENTE ---------------------------------------------------------------------------------
  //  TRAER DATA DE LOS ULTIMOS 15 DIAS!!!!!

  useEffect(() => {
    expiredAction();
    const fetchDataAsync = async () => {
      setLoading(true);
      if (company_id) {
        await fetchData(company_id);
      }
      setLoading(false);
    };

    fetchDataAsync();
  }, [company_id, updatedShipData, createdShipData, from, to]);

  //MANEJO DE ERRORES ------------------------------------------------------------------------------------------------
  if (createdShipError) {
    console.log(
      "Hubo un error en la subscription de viajes iniciados",
      createdShipError
    );
    closeWebSocketConnection()
    logOut();
  }

  if (updatedShipError) {
    console.log(
      "Hubo un error en la subscription de viajes cerrados",
      updatedShipError
    );
    closeWebSocketConnection()
    logOut();
  }

  return (
    <PrivateRoute>
      <div className={styles.allContainer}>
        <div className={styles.nav}>
          {/* //picker container */}

          <DatePickerFromTo
            getData={fetchData}
            error={buttonError}
            setError={setButtonError}
          />

          {/* //butons container */}
        </div>

        <div className={styles.cardsContainer}>
          <ViajesTrazadosGraph
            data={{
              completedShips: allData?.completedShipsState,
              succShips: allData?.succShipsState,
              uncertShips: allData?.uncertShipsState,
              failShips: allData?.failShipsState,
            }}
            loading={loading}
            // initialFilter={initialFilter}
            company_id={company_id}
            succeeded={allData?.succShipsState}
            uncertain={allData?.uncertShipsState}
            failed={allData?.failShipsState}
          />

          <ViajesNoConformesArea
            data={{
              uncertShips: allData?.uncertShipsState,
              failShips: allData?.failShipsState,
            }}
            loading={loading}
            branchesData={allData?.branchesWithMoreAlerts}
            company_id={company_id}
            causes={allData?.causes}
            allData={allData}
          />
        </div>

        <div className={styles.reportsContainer}>
          <Reports2 />
        </div>
      </div>{" "}
    </PrivateRoute>
  );
}
