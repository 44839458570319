import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_COMPANY_DETAIL, UPDATE_COMPANY_SUDO, GET_BARCODES } from "../../../queries";
import styles from "./CompanyDetail.module.scss";
import MyBranches from "../MyBranches/MyBranches";
import { FormattedMessage, useIntl } from "react-intl";
import MyUsers from "../MyUsers/MyUsers";
import CreateUser from "../CreateUser/CreateUser";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function CompanyDetail({ company_id, defaultEditData }) {
  const intl=useIntl()
  //QUERIES / MUTATIONS ---------------------------------------------------------------
  //query para traerme el detalle de la compañia
  const {
    data: companyDetailData,
    error: companyDetailError,
    loading: companyDetailLoading,
  } = useQuery(GET_COMPANY_DETAIL, {
    variables: {
      company_id,
    },
  });

  //query para traerme los barcodes
  const {
    data: barcodesList,
    error: barcodesError,
    loading: barcodesLoading,
  } = useQuery(GET_BARCODES);

  //mutation para updatear company
  const [
    updateCompany,
    {
      data: updatedCompanyData,
      loading: updatedCompanyLoading,
      error: updatedCompanyError,
    },
  ] = useMutation(UPDATE_COMPANY_SUDO);

  //ESTADOS LOCALES ----------------------------------------------------------------
  //estado de los edit
  const [editData, setEditData] = useState(defaultEditData);

  //estado para el modal de create user
  const [modalCreateUser, setModalCreateUser] = useState(false);

  //estado para editar usuarios
  const [editUsers, setEditUsers] = useState(false);

  //estado que guarda los input del edit data
  const [companyDataState, setCompanyDataState] = useState({
    company_id,
    name: companyDetailData?.company?.name,
    location: companyDetailData?.company?.location,
    billing: companyDetailData?.company?.billing,
    contact_info: companyDetailData?.company?.contact_info,
    status: companyDetailData?.company?.status,
    distributor_id: companyDetailData?.company?.distributor_id,
    barcode: companyDetailData?.company?.barcode,
    alert_params: companyDetailData?.company?.alert_params,
  });

  //CUANDO SE INICIA/ACTUALIZA EL COMPONENTE -----------------------------------------------------------------
  useEffect(() => {

  
    setCompanyDataState({
      company_id,
      name: companyDetailData?.company?.name,
      location: companyDetailData?.company?.location,
      billing: companyDetailData?.company?.billing,
      contact_info: companyDetailData?.company?.contact_info,
      status: companyDetailData?.company?.status,
      distributor_id: companyDetailData?.company?.distributor_id,
      barcode: companyDetailData?.company?.barcode,
      alert_params: companyDetailData?.company?.alert_params,
    });
  }, [companyDetailData]);

  //HANDLERS --------------------------------------------------------------------------
  //handler del edit company
  const handleEditData = () => {
    setEditData(true);
  };

  //handler de los input de edit
  const handleEditDataChange = (e) => {

    if (e.target.name === "barcode") {
      if (companyDataState.barcode) {
        setCompanyDataState({
          ...companyDataState,
          barcode: companyDataState.barcode.includes(e.target.value)
            ? companyDataState.barcode.filter((b) => b !== e.target.value)
            : [...companyDataState.barcode, e.target.value],
        });
      } else {
        setCompanyDataState({
          ...companyDataState,
          barcode: [e.target.value],
        });
      }
    } else {
     
      setCompanyDataState({
        ...companyDataState,
        [e.target.name]: e.target.value,
      });
    }
  };

  //handler del save de edit
  const handleSaveCompanyData = async () => {

    setEditData(false);
  
    try {
      if (
        window.confirm(
        intl.formatMessage({id:"are_you_sure_confirmChanges"})
        )
      ) {
        await updateCompany({
          variables: companyDataState,
        });
       // window.location.reload();
      }
    } catch (error) {
      console.log("error en la mutation de updateCompany", error);
    }
  };

  //handler del cancel edit data
  const handleCancelEditData = () => {
    setEditData(false);
    setCompanyDataState({
      company_id,
      name: companyDetailData?.company?.name,
      location: companyDetailData?.company?.location,
      billing: companyDetailData?.company?.billing,
      contact_info: companyDetailData?.company?.contact_info,
      status: companyDetailData?.company?.status,
      distributor_id: companyDetailData?.company?.distributor_id,
      barcode: companyDetailData?.company?.barcode,
      alert_params: companyDetailData?.company?.alert_params,
    });
  };

  //handler para setear las alertas de temperatura y aceleracion de la compañia
  const handleEditDataChangeAlerts = (e) => {
    let type = e.target.name.split("_")[0];
    let index = parseInt(e.target.name.split("_")[1]);

    if (type === "temp") {
      let property = e.target.name.split("_")[2];
      let temp_copy = [...companyDataState?.alert_params.temperature_alerts];
      let oldParam = temp_copy[index];
      let newParam = {
        name: oldParam.name,
        max: property === "max" ? parseInt(e?.target?.value) : oldParam.max,
        min: property === "min" ? parseInt(e?.target?.value) : oldParam.min,
      };
      temp_copy[index] = newParam;
      setCompanyDataState({
        ...companyDataState,
        alert_params: {
          ...companyDataState.alert_params,
          temperature_alerts: temp_copy,
        },
      });
    }

    if (type === "acc") {
      let acc_copy = [...companyDataState?.alert_params.acceleration_alerts];
      let oldParam = acc_copy[index];
      let newParam = {
        name: oldParam.name,
        value: parseInt(e?.target?.value),
      };
      acc_copy[index] = newParam;
      setCompanyDataState({
        ...companyDataState,
        alert_params: {
          ...companyDataState.alert_params,
          acceleration_alerts: acc_copy,
        },
      });
    }
  };

  //handler del modal de create user
  const handleModalCreateUser = () => {
    setModalCreateUser(true);
  };

  //handler del close del modal de create user
  const handleModalCreateUserClose = () => {
    setModalCreateUser(false);
  };

  //MANEJO DE ERRORES DE LAS QUERIES/MUTATIONS
  if (companyDetailError)
    console.log(
      "error en la busqueda de datos de la compañia",
      companyDetailError
    );
  if (updatedCompanyError)
    console.log("error en el update de la compañia", updatedCompanyError);

  //LOADINGS
  if (companyDetailLoading || updatedCompanyLoading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <span className={styles.dataTitle}>
        <FormattedMessage id="company_data">COMPANY DATA</FormattedMessage>
      </span>
      {/* TABLA DE DATOS PRINCIPALES ------------------------------------------------------------------------------ */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* HEAD ID ------------------------------------------------------------ */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: "1.1vw",
                  
                  backgroundColor: "#BCE2EA",
                 
                }}
              >
                ID
              </TableCell>
              {/* HEAD NAME -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: "1.1vw",
                  
                  backgroundColor: "#BCE2EA",
                  
                }}
              >
                <FormattedMessage id="name_config_company">
                  NAME
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD LOCATION -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: "1.1vw",
                  
                  backgroundColor: "#BCE2EA",
                 
                }}
              >
                <FormattedMessage id="location_config_company">
                  LOCATION{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD CONTACT INFO -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: "1.1vw",
                  
                  backgroundColor: "#BCE2EA",
                 
                }}
              >
                <FormattedMessage id="contact_info_config_company">
                  CONTACT INFO{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD BILLING -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: "1.1vw",
                  
                  backgroundColor: "#BCE2EA",
                 
                }}
              >
                <FormattedMessage id="billing_config_company">
                  BILLING{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD BARCODES -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="left"
                sx={{
                  fontSize: "1.1vw",
                 
                  backgroundColor: "#BCE2EA",
                }}
              >
                <FormattedMessage id="barcode_config_company">
                  BARCODE/S{" "}
                </FormattedMessage>{" "}
              </TableCell>
            </TableRow>
            {/* ESPACIO ENTRE EL HEAD Y BODY --------------------------------------------------------------------------- */}
            <TableRow>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* ID --------------------------------------------------------------------------------------- */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                
                }}
              >
                {companyDetailData?.company?.company_id}
              </TableCell>
              {/* NOMBRE ------------------------------------------------------------------------------------------ */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {companyDetailData?.company?.name}
              </TableCell>
              {/* LOCATION ---------------------------------------------------------------------------------- */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {!editData ? (
                  <span className={styles.editData}>
                    {companyDetailData?.company?.location}
                  </span>
                ) : (
                  <input
                    type="text"
                    defaultValue={companyDetailData?.company?.location}
                    onChange={handleEditDataChange}
                    name="location"
                    className={styles.editData}
                    style={{
                      border: "none",
                      borderBottom: "0.15vw solid grey",
  
                    }}
                  />
                )}
              </TableCell>
              {/* INFO DE CONTACTO ----------------------------------------------------------------------------------- */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {!editData ? (
                  <span className={styles.editData}>
                    {companyDetailData?.company?.contact_info}
                  </span>
                ) : (
                  <input
                    type="text"
                    defaultValue={companyDetailData?.company?.contact_info}
                    onChange={handleEditDataChange}
                    name="contact_info"
                    className={styles.editData}
                    style={{
                      border: "none",
                      borderBottom: "0.15vw solid grey",
  
                    }}
                  />
                )}
              </TableCell>
              {/* BILLING -------------------------------------------------------------------------------------------------- */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {companyDetailData?.company?.billing}
              </TableCell>
              {/* BARCODES ----------------------------------------------------------------------------------------------------- */}
              <TableCell
                align="left"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
               
                }}
              >
                {(editData
                  ? barcodesList?.barcodes
                  : companyDetailData?.company?.barcode
                )?.map((b) => {
                  return (
                    <label key={b}>
                      <input
                        type="checkbox"
                        value={b}
                        name="barcode"
                        onChange={editData ? handleEditDataChange : null}
                        defaultChecked={
                          companyDetailData?.company?.barcode?.includes(b)
                            ? true
                            : false
                        }
                        disabled={editData ? false : true}
                        className={styles.barcodesInput}
                      />
                      <span className={styles.eachBarcode}>{b}</span>
                      <br />
                    </label>
                  );
                })}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              {/* HEAD CREATED ------------------------------------------------------------ */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                <FormattedMessage id="created_config">
                  CREATED{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD LAST MODIFIED -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                <FormattedMessage id="last_mod_may">
                  LAST MODIFIED{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD STATUS -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                <FormattedMessage id="status_tab_title">
                  STATUS{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD BELONGS TO -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                <FormattedMessage id="belongs_to_may">
                  BELONGS TO{" "}
                </FormattedMessage>{" "}
              </TableCell>
              {/* HEAD LANGUAGE -------------------------------------------------------------------------------------- */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                <FormattedMessage id="language_may">LANGUAGE </FormattedMessage>{" "}
              </TableCell>
              <TableCell
                size="medium"
                align="center"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              ></TableCell>
            </TableRow>
            {/* ESPACIO ENTRE EL HEAD Y BODY --------------------------------------------------------------------------- */}
            <TableRow>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* CREATED --------------------------------------------------------------------------------------- */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {companyDetailData?.company?.created?.split("T")[0] +
                  " " +
                  companyDetailData?.company?.created
                    ?.split("T")[1]
                    .split(".")[0]}
              </TableCell>
              {/* LAST MODIFIED ------------------------------------------------------------------------------------------ */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {companyDetailData?.company?.last_modified ? (companyDetailData?.company?.last_modified?.split("T")[0] +
                  " " +
                  companyDetailData?.company?.last_modified
                    ?.split("T")[1]
                    .split(".")[0]) : ("-")}
              </TableCell>
              {/* STATUS ---------------------------------------------------------------------------------- */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {!editData ? (
                  <span className={styles.editData}>
                    {companyDetailData?.company?.status}
                  </span>
                ) : (
                  <select
                    name="status"
                    defaultValue={companyDetailData?.company?.status}
                    onChange={handleEditDataChange}
                    className={styles.select}
                  >
                    <option value="ACTIVE">
                     <FormattedMessage id="active">Active</FormattedMessage>
                    </option>
                    <option value="INACTIVE">
                      <FormattedMessage id="inactive">Inactive</FormattedMessage>
                    </option>
                  </select>
                )}
              </TableCell>
              {/* BELONGS TO ----------------------------------------------------------------------------------- */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {!editData ? (
                  <span className={styles.editData}>
                    {companyDetailData?.company?.distributor_id}
                  </span>
                ) : (
                  <input
                    type="text"
                    defaultValue={companyDetailData?.company?.distributor_id}
                    onChange={handleEditDataChange}
                    name="distributor_id"
                    className={styles.editData}
                    style={{ border: "none", borderBottom: "0.15vw solid grey" }}
                  />
                )}
              </TableCell>
              {/* LANGUAGE -------------------------------------------------------------------------------------------------- */}
              <TableCell
                align="center"
                size="small"
                sx={{
                  border: "none",
                  fontSize: "1.1vw",
                  
                 
                }}
              >
                {companyDetailData?.company?.language
                  ? companyDetailData?.company?.language
                  : "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* ALERT PARAMS  */}
      <div className={styles.dataAlertParams}>
        <div className={styles.temp_alerts_container}>
          <div className={styles.type_alert_title}>
            <FormattedMessage id="temperature_alerts_config_company">
              TEMPERATURE ALERTS{" "}
            </FormattedMessage>{" "}
          </div>
          <div className={styles.editDataTempContainer}>
            {companyDataState?.alert_params?.temperature_alerts?.map((t) => {
              return (
                <div className={styles.editDataTemp} key={t?.name}>
                  <div className={styles.tempTitle}>
                    {t.name === "AMB" && "RT"}
                    {t.name === "REF" && (
                      <FormattedMessage id="refrigeration_config">
                        Refrigeration{" "}
                      </FormattedMessage>
                    )}
                    {t.name === "CON" && (
                      <FormattedMessage id="freezing_config">
                        Freezing{" "}
                      </FormattedMessage>
                    )}
                  </div>
                  <div className={styles.dataTempContainer}>
                    <span className={styles.eachTempDataContainer}>
                      <span className={styles.eachDataTempTitle}>
                        M{localStorage.getItem("language") === "en" ? "a" : "á"}
                        x{" "}
                      </span>
                        {editData ? (
                          <input
                            type="number"
                            defaultValue={t.max}
                            name={`temp_${companyDataState?.alert_params?.temperature_alerts?.indexOf(
                              t
                            )}_max`}
                            onChange={handleEditDataChangeAlerts}
                            style={{
                              border: "none",
                              borderBottom: "0.15vw solid grey",
                              width: "2.5vw",
                              textAlign: "left",
                              fontSize: "1.1vw",
                              
          
                            }}
                          />
                        ) : (
                          <span className={styles.dataNumber}>{t.max}</span>
                        )}
                    </span>
                    <span className={styles.eachTempDataContainer}>
                      <span className={styles.eachDataTempTitle}>
                        M{localStorage.getItem("language") === "en" ? "i" : "í"}
                        n{" "}
                      </span>
                      {editData && t.name !== "CON" ? (
                        <input
                          type="number"
                          defaultValue={t.min}
                          name={`temp_${companyDataState?.alert_params?.temperature_alerts?.indexOf(
                            t
                          )}_min`}
                          onChange={handleEditDataChangeAlerts}
                          style={{
                            border: "none",
                            borderBottom: "0.15vw solid grey",
                            width: "2.5vw",
                            textAlign: "left",
                            fontSize: "1.1vw",
                            
        
                          }}
                        />
                      ) : (
                        <span className={styles.dataNumber}>{t.min}</span>
                      )}
                    </span>
                  </div>
                  <br />
                </div>
              );
            })}
          </div>
        </div>
        {/* ACCELERATION ALERTS ------------------------------------------------------------------------------------------ */}
        <div className={styles.acc_alerts_container}>
          <div className={styles.type_alert_title}>
            <FormattedMessage id="acceleration_alerts_config_company">
              ACCELERATION ALERTS:{" "}
            </FormattedMessage>
          </div>
          <div className={styles.editDataTempContainer}>
            {companyDataState?.alert_params?.acceleration_alerts?.map((a) => {
              return (
                <span className={styles.editDataTemp} key={a?.name}>
                  <div className={styles.accTitle}>
                    {localStorage.getItem("language") === "en"
                      ? a.name
                      : a.name === "Blood" && "Sangre"}
                  </div>
                  <div className={styles.eachAccDataContainer}>
                    <span className={styles.eachDataTempTitle}>
                      <FormattedMessage id="acceleration_value_config">
                        Value{" "}
                      </FormattedMessage>{" "}
                    </span>
                      {editData ? (
                        <input
                          type="number"
                          defaultValue={a.value}
                          min={0}
                          name={`acc_${companyDataState?.alert_params?.acceleration_alerts?.indexOf(
                            a
                          )}`}
                          onChange={handleEditDataChangeAlerts}
                          style={{
                            border: "none",
                            borderBottom: "0.15vw solid grey",
                            width: "2.5vw",
                            textAlign: "left",
                            fontSize: "1.1vw",
                            
        
                          }}
                        />
                      ) : (
                        <span className={styles.dataNumber}>{a.value}</span>
                      )}
                  </div>
                </span>
              );
            })}
          </div>
        </div>
      </div>
      {/* EDITAR COMPAÑIA ---------------------------------------------------------------------------- */}
      {!editData ? (
        <button onClick={handleEditData} className={styles.editButton}>
          <FormattedMessage id="edit_company">Edit company</FormattedMessage>
        </button>
      ) : (
        <div className={styles.buttonContainer}>
          <button onClick={handleSaveCompanyData} className={styles.saveButton}>
            <FormattedMessage id="save_button">Save</FormattedMessage>
          </button>
          <button onClick={handleCancelEditData} className={styles.cancelButton}>
            <FormattedMessage id="cancel_button">Cancel</FormattedMessage>
          </button>
        </div>
      )}
      {/* ---------------------------------------------------------------------------------------------------------------- */}
      {/* USUARIOS  */}
      <div className={styles.eachData}>
        <div className={styles.usersContainer}>
          <span className={styles.dataTitle}>
            <FormattedMessage id="users_may">USERS</FormattedMessage>
          </span>
          {/* {companyDetailData?.company?.users_id?.map((u) => {
              return <span>{u}</span>;
            })} */}
          <MyUsers company_id={company_id} usersFilter="Admin" />
          {!editUsers && (
            <>
              <button
                onClick={handleModalCreateUser}
                className={styles.createUserButton}
              >
                <FormattedMessage id="create_user_button">
                  Create new user
                </FormattedMessage>
              </button>
              <Modal
                open={modalCreateUser}
                onClose={handleModalCreateUserClose}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "white",
                    border: "0.15vw solid #00ABC8",
                    borderRadius: "2vw",

                    boxShadow: 10,
                    pt: 2,
                    px: 4,
                    pb: 3,
                    backgroundColor: "white",
                    width: "20vw",
                    height: "22vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CreateUser company_id={company_id} />
                </Box>
              </Modal>
            </>
          )}
        </div>
      </div>
      {/* BRANCHES  */}
      <div className={styles.eachData}>
        <div className={styles.branchesContainer}>
          <span className={styles.dataTitle}>
            <FormattedMessage id="branches_may">BRANCHES</FormattedMessage>
          </span>
          <MyBranches company_id={company_id} />
        </div>
      </div>
    </div>
  );
}
