import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import styles from "./Causas.module.scss";
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";

export default function Causas({ causes, loading }) {
  // Inicializar las variables con un valor por defecto de 0
  const counts = {
    temperature: 0,
    intrusion: 0,
    acceleration: 0
  };

  // Recorrer el array causes y asignar los valores correspondientes
  causes.forEach(item => {
    if (counts.hasOwnProperty(item._id)) {
      counts[item._id] = item.count;
    }
  });

  // Calcular el total
  const total = counts.temperature + counts.intrusion + counts.acceleration;

  const renderProgressBar = (id, color, title, isOldStyle) => (
    <>
      <span className={isOldStyle ? styles.barTitle : styles.barTitle2}>
        <FormattedMessage id={`causes_${id}`}>
          {`${title}`}
        </FormattedMessage>
      </span>
      <ProgressBar
        completed={Math.floor((100 * counts[id]) / total)}
        width="100%"
        height={isOldStyle ? (window.screen.width > 800 ? "1.2vw" : "2vw") : "10px"}
        baseBgColor={"#eeeeee"}
        isLabelVisible={true}
        bgColor={color}
        borderRadius={isOldStyle ? ".3vw" : "0px"}
        className={isOldStyle ? styles.bar : styles.bar2}
      />
    </>
  );
  return (
    <div className={styles.barContainer}>
      <div className={styles.title}>
        <FormattedMessage id="causes">Causes</FormattedMessage>
      </div>
      {!loading ? (
        total ? (
          <>
            {renderProgressBar('temperature', 'var(--cyan)', 'Temperature', true)}
            {renderProgressBar('intrusion', 'var(--cyan)', 'Intrusion', true)}
            {renderProgressBar('acceleration', 'var(--cyan)', 'Acceleration', true)}
          </>
        ) : (
          <div className={styles.noData}>
            <FormattedMessage id="no_alerts">No alerts</FormattedMessage>
          </div>
        )
      ) : (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
