import React from "react";
import ViajesNoConformesGraph from "./ViajesNoConformesGraph/ViajesNoConformesGraph";
import Causas from "./Causas/Causas";
import BranchesConMasAlertas from "./BranchesConMasAlertas/BranchesConMasAlertas";
import styles from "./ViajesNoConformesArea.module.scss";
import { FormattedMessage } from "react-intl";

export default function ViajesNoConformesArea({
  data,
  loading,
  branchesData,
  company_id,
  causes,
  allData,
}) {

    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <FormattedMessage id="viajes_no_conformes_uncertain"></FormattedMessage>
        </div>

        <div className={styles.causes_branches_container}>
          <Causas causes={causes} loading={loading} />
          <BranchesConMasAlertas
            branchesData={branchesData}
            company_id={company_id}
            loading={loading}
            allData={allData}
          />
        </div>
      </div>
    ); 
}