import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { AiFillCodeSandboxCircle } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import { FormattedMessage } from "react-intl";
import { RECOVERY_CODE, CHANGE_PASSWORD } from "../../queries";
import styles from "./forgotMyPass.module.scss";
import useMediaQuery from "@mui/material/useMediaQuery";

const ForgotMyPass = () => {
  // media query para el modal de recuperacion de cotraseña
  const minWidth = useMediaQuery("(min-width:950px)");

  //QUERIES/MUTATIONS -----------------------------------------------------------------------------

  const [recoveryCode, { data, loading, error }] = useMutation(RECOVERY_CODE);
  const [changePassword] = useMutation(CHANGE_PASSWORD);

  //Estados........................................................................................

  const [input, setInput] = useState({
    username: "",
    email: "",
  });

  const [new_input, setNew_input] = useState({
    recoveryCode: "",
    username: "",
    newPassword: "",
    reTypePass: "",
  });

  const [message, setMessage] = useState(null);
  const [passChanged_message, setPassChanged_Message] = useState(null);
  const [errorMsj, setErrorMsj] = useState(null);
  const [passMatch, setPassMatch] = useState(true);

  // Handlers------------------------------------

  const handleOnChange = function (e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit_recoveryCode_request = async (e) => {
    e.preventDefault();
    try {
      const result = await recoveryCode({
        variables: { username: input.username, email: input.email },
      });

      setMessage(result.data.recoveryCode.status);
    } catch (error) {
      console.log("error al logearse", error);
    }
  };

  const handleOnChange_newData = function (e) {
    setErrorMsj(null);
    setPassMatch(true);
    setNew_input({
      ...new_input,
      [e.target.name]: e.target.value,
      username: input.username,
    });
  };

  const handleSubmit_send_recoveryCode = async (e) => {
    e.preventDefault(e);

    try {
      if (new_input.newPassword === new_input.reTypePass) {
        setPassMatch(true);

        const result_changePassword = await changePassword({
          variables: {
            username: input.username,
            newPassword: new_input.newPassword,
            recoveryCode: new_input.recoveryCode,
          },
        });
        setErrorMsj(null);
        setPassChanged_Message(
          result_changePassword.data.changePassword.status
        );
        setPassMatch(true);
        alert("Password changed!");
        window.location.reload();
        
      } else {
        setPassMatch(false);
      }
    } catch (error) {
      console.log(error);
      setErrorMsj("error");
    }
  };

  return (
    <div className={styles.container}>
      {/* ----------pido el recovery code------------- */}
      <form
        onSubmit={handleSubmit_recoveryCode_request}
        className={styles.user_and_mail_form}
      >
        <TextField
          id="standard-helperText"
          helperText={
            localStorage.getItem("language") === "es"
              ? "Nombre de usuario"
              : "Username"
          }
          variant="standard"
          fullWidth={true}
          onChange={handleOnChange}
          name="username"
        />

        <TextField
          id="standard-helperText"
          helperText={
            navigator.userLanguage === "es-ES" || navigator.language === "es-ES"
              ? "Email"
              : "Email"
          }
          variant="standard"
          fullWidth={true}
          onChange={handleOnChange}
          name="email"
          /*    style={{ width: "50%" }} */
        />

        <button
          type="submit"
          onClick={handleSubmit_recoveryCode_request}
          className={styles.recoveryCode_Button}
        >
          {localStorage.getItem("language") === "es"
            ? "Pedir código"
            : "Request code"}
        </button>
      </form>

      {/* ----------ingreso recovery code------------- */}

      <form
        onSubmit={handleSubmit_send_recoveryCode}
        className={styles.new_data_container}
      >
        <div className={styles.message}>
          {message &&
            (localStorage.getItem("language") === "es"
              ? "Ingrese el código que fue enviado a su email"
              : " Enter the code that was sent to your email")}
        </div>

        <TextField
          id="demo-helper-text-aligned-no-helper"
          helperText=""
          label={
            localStorage.getItem("language") === "es"
              ? "Código de recuperación"
              : "Recovery code"
          }
          variant="outlined"
          fullWidth={true}
          onChange={handleOnChange_newData}
          name="recoveryCode"
          style={{ width: "70%", margin: "1vw" }}
          size={minWidth ? "normal" : "small"}
        />
        <TextField
          id="standard-helperText"
          helperText={
            localStorage.getItem("language") === "es"
              ? " Nueva contraseña"
              : "New password"
          }
          type="password"
          variant="standard"
          fullWidth={true}
          onChange={handleOnChange_newData}
          name="newPassword"
        />
        <TextField
          id="standard-helperText"
          helperText={
            localStorage.getItem("language") === "es"
              ? " Escriba de nuevo su contraseña"
              : "Type again your new password"
          }
          type="password"
          variant="standard"
          fullWidth={true}
          onChange={handleOnChange_newData}
          name="reTypePass"
        />
        {passChanged_message && (
          <span className={styles.success_message}>
            {localStorage.getItem("language") === "es"
              ? "La contraseña fue cambiada con éxito!"
              : "The password has been successfully changed!"}
          </span>
        )}
        {errorMsj && (
          <span className={styles.error_message}>
            {localStorage.getItem("language") === "es"
              ? "Error al tratar de cambiar la contraseña"
              : "Error trying to change password!"}
          </span>
        )}
        {!passMatch && (
          <span className={styles.error_message}>
            {localStorage.getItem("language") === "es"
              ? "Las contraseñas no coinciden, por favor intente de nuevo!"
              : " Your passwords doesn´t match, please try again!"}
          </span>
        )}
        <button
          type="submit"
          onClick={handleSubmit_send_recoveryCode}
          className={styles.loginButton}
        >
          {localStorage.getItem("language") === "es" ? "Enviar" : "Send"}
        </button>
      </form>
    </div>
  );
};

export default ForgotMyPass;
