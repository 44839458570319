import React, { useState } from "react";
import { CREATE_OPERATOR, UPDATE_COMPANY } from "../../../queries";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import styles from "./CreateOperator.module.scss";
import Dialog from "@mui/material/Dialog";

export default function CreateOperator({
  company_id,
  type,
  createOperatorAction,
}) {
  const [access, setAccess] = useState({
    send: true,
    receive: true,
    checkpoint: true,
    calibrate: true,
    dash_report: true,
    dash_intransit: true,
    dash_control: true,
    recursive: true,
    geolocation: true,
  });

  // ESTADOS LOCALES -----------------------------------------------------------------------------

  //estado para guardarme la data de los input
  const [inputsData, setInputsData] = useState({
    access_to: {
      send: true,
      receive: true,
      checkpoint: true,
      calibrate: true,
      dash_report: true,
      dash_intransit: true,
      dash_control: true,
      recursive: true,
      geolocation: true,
    },
    type: "",
    code: "",
    mail: "",
    name: "",
  });

  //estado para la confirmación del create operator
  const [confirmMessage, setConfirmMessage] = useState(false);

  //QUERIES/MUTATIONS ----------------------------------------------------------------------------

  //mutation para crear usuarios
  const [
    createOperator,
    { createdOperatorData, createdOperatorLoading, createdOperatorError },
  ] = useMutation(CREATE_OPERATOR);

  // HANDLERS ------------------------------------------------------------------------------------
  //handler del onChange de los input
  const handleOnChangeInputs = (e) => {
    setAccess(access);
    setInputsData({
      ...inputsData,
      access_to: access,
    });
    if (e.target.name === "access_to") {
      setAccess({ ...access, [e.target.value]: e.target.checked });

      setInputsData({
        ...inputsData,
        access_to: {
          ...inputsData.access_to,
          [e.target.value]: e.target.checked,
        },
      });
    } else {
      setAccess(access);
      setInputsData({
        ...inputsData,
        [e.target.name]: e.target.value,
      });
    }
  };

  //handler del confirm changes
  const handleConfirm = async (e) => {
    e.preventDefault();

    try {
      if (!company_id) {
        //si no company_id es pq soy sudo y mando la data la componente <CreateCompany/>, sino creo el usuario
        createOperatorAction(inputsData);
      } else {
        if (
          window.confirm(
            localStorage.getItem("language") === "es"
              ? "¿Estás seguro que quieres guardar cambios?"
              : "Are you sure you want to confirm changes?"
          )
        ) {
          await createOperator({
            variables: {
              operator: inputsData,
            },
          });

          alert(`${type} created`);
          window.location.reload();
        }
      }
    } catch (error) {
      console.log("hubo un error en el create operator", error);
      if (createdOperatorError) alert(createdOperatorError);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.createTitle}>
        {" "}
        {type === "Operator" && (
          <FormattedMessage id="create_operator">
            Create new operator
          </FormattedMessage>
        )}
        {type === "Shipper" && (
          <FormattedMessage id="create_shipper">
            Create new shipper
          </FormattedMessage>
        )}
        {type === "Calibrator" && (
          <FormattedMessage id="create_calibrator">
            Create new calibrator
          </FormattedMessage>
        )}
      </div>
      <form style={{ width: "100%" }}>
        {/* NOMBRE -------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="name_config">Name</FormattedMessage>{" "}
          </label>
          <input
            type="text"
            name="name"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* MAIL -------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>Email</label>
          <input
            type="email"
            name="mail"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* CODIGO -------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="code">Code</FormattedMessage>
          </label>
          <input
            type="text"
            name="code"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        <div className={styles.type_and_accessTo}>
          <div className={styles.type}>
            {/* TYPE------------------------------------------------------------------------- */}
            <span className={styles.eachSection}>
              <label className={styles.type_title}>
                <FormattedMessage id="type">Type</FormattedMessage>
              </label>
              <select
                name="type"
                defaultValue="default"
                onChange={handleOnChangeInputs}
                className={styles.select_default}
              >
                <option value="default" disabled hidden>
                  {localStorage.getItem("language") === "en"
                    ? "Operator type"
                    : "Tipo de operador"}
                </option>
                <option value="Calibrator">
                  {localStorage.getItem("language") === "en"
                    ? "Calibrator"
                    : "Calibrador"}
                </option>

                <option value="Operator">
                  {localStorage.getItem("language") === "en"
                    ? "Operator"
                    : "Operador"}
                </option>
                <option value="Shipper">
                  {localStorage.getItem("language") === "en"
                    ? "Shipper"
                    : "Transportista"}
                </option>
              </select>
            </span>
          </div>
          <div className={styles.access_to}>
            {/* ACCESS_TO------------------------------------------------------------------------- */}

            <span className={styles.eachSection}>
              <label className={styles.access_to_title}>
                <FormattedMessage id="access_to">Access to</FormattedMessage>
              </label>
              {Object.keys(access).map((a) => {
                return (
                  <span key={a}>
                    <input
                      type="checkbox"
                      name="access_to"
                      value={a}
                      onChange={handleOnChangeInputs}
                      defaultChecked={true}
                    />
                    {a === "send" && (
                      <label className={styles.access_to_list}>
                        {localStorage.getItem("language") === "es"
                          ? "Enviar"
                          : "Send"}
                      </label>
                    )}
                    {a === "receive" && (
                      <label className={styles.access_to_list}>
                        {localStorage.getItem("language") === "es"
                          ? "Recibir"
                          : "Receive"}
                      </label>
                    )}
                    {a === "checkpoint" && (
                      <label className={styles.access_to_list}>
                        Checkpoints
                      </label>
                    )}
                    {a === "recursive" && (
                      <label className={styles.access_to_list}>
                        {localStorage.getItem("language") === "es"
                          ? "Recursivo"
                          : "Recursive"}
                      </label>
                    )}
                    {a === "calibrate" && (
                      <label className={styles.access_to_list}>
                        {localStorage.getItem("language") === "es"
                          ? "Calibrar"
                          : "Calibrate"}
                      </label>
                    )}
                    {a === "dash_report" && (
                      <label className={styles.access_to_list}>
                        {localStorage.getItem("language") === "es"
                          ? "Reportes"
                          : "Reports"}
                      </label>
                    )}
                    {a === "dash_intransit" && (
                      <label className={styles.access_to_list}>
                        {localStorage.getItem("language") === "es"
                          ? "En tránsito"
                          : "In transit"}
                      </label>
                    )}
                    {a === "dash_control" && (
                      <label className={styles.access_to_list}>
                        {localStorage.getItem("language") === "es"
                          ? "Panel de control"
                          : "Dashboard"}
                      </label>
                    )}
                    {a === "geolocation" && (
                      <label className={styles.access_to_list}>
                        {localStorage.getItem("language") === "es"
                          ? "Geolocalización"
                          : "Geolocation"}
                      </label>
                    )}
                  </span>
                );
              })}
            </span>
          </div>
        </div>

        {/* GUARDAR ------------------------------------------------------------------------------- */}
        <button
          type="submit"
          onClick={handleConfirm}
          className={styles.createButton}
          disabled={
            inputsData.username === "" ||
            inputsData.mail === "" ||
            inputsData.code === "" ||
            inputsData.type === "" ||
            inputsData.access_to === ""
              ? true
              : false
          }
        >
          <FormattedMessage id="save_button">Save</FormattedMessage>
        </button>
        <Dialog open={confirmMessage}>
          {localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"}
        </Dialog>
      </form>
    </div>
  );
}
