import React, { useState, useEffect } from "react";
import {
  CALIBRATE_DEVICE,
  GET_COMPANY_DETAIL,
  GET_DEVICE,
} from "../../../../queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  InputAdornment,
  Grid,
  CircularProgress, // Importa makeStyles de Material-UI
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import CustomButton from "../../../Buttons/CustomButton";
import calibrationStyles from "./deviceCAlibratioStyles.module.scss";
import { language } from "../../../../const";
import { FormattedMessage } from "react-intl";



const DeviceCalibrationModal = ({
  deviceToCalibrate,
  company_id,
  onClose,
  last_calibration_info,
}) => {
  const myCalibrator = localStorage.getItem("assigned_to");
  const myCalibratorName = localStorage.getItem("assigned_to_name");
  const [selectedDate, setSelectedDate] = useState("");
  const [calibrationParams, setCalibrationParams] = useState({
    calibrator_id: "",
    due_date: "",
    report_id: "",
    thermometer: "",
    first_point: { std_temperature: "", temperature: "" },
    second_point: { std_temperature: "", temperature: "" },
    third_point: { std_temperature: "", temperature: "" },
    report_id: "",
  });

  const [
    getFreeOperators,
    {
      data: freeOperators,
      loading: freeOperatorsLoading,
      error: freeOpesError,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL);

  //mutation calibrar

  const [
    calibrateDevice,
    {
      data: calibratedDevice,
      loading: calibratedDeviceLoading,
      error: calibratedDeviceError,
    },
  ] = useMutation(CALIBRATE_DEVICE);
  //query get device

  const [
    getDevice,
    { data: device, loading: deviceLoading, error: deviceError },
  ] = useLazyQuery(GET_DEVICE, { fetchPolicy: "network-only" });

  useEffect(() => {
    console.log(deviceToCalibrate);

    const fetchFreeOpes = async () => {
      await getFreeOperators({
        variables: {
          company_id: company_id,
        },
      });
    };
    fetchFreeOpes();

    const fetchDevice = async () => {
      await getDevice({
        variables: {
          qr: deviceToCalibrate.qr,
        },
      });
    };

    fetchDevice();
  }, [company_id, deviceToCalibrate]);

  const handleChange = (event) => {
    if (event.target.name === "operator") {
      setCalibrationParams({
        ...calibrationParams,
        calibrator_id: event.target.value,
      });
    }
    if (event.target.name === "date") {
      setCalibrationParams({
        ...calibrationParams,
        due_date: event.target.value,
      });
      setSelectedDate(event.target.value);
    }
    if (event.target.name === "temp_1") {
      if (event.target.value === "") {
        setCalibrationParams({
          ...calibrationParams,
          first_point: {
            ...calibrationParams.first_point,
            temperature: "",
          },
        });
      }

      setCalibrationParams({
        ...calibrationParams,
        first_point: {
          ...calibrationParams.first_point,
          temperature: parseFloat(event.target.value),
        },
      });
    }
    if (event.target.name === "correct_1") {
      if (event.target.value === "") {
        setCalibrationParams({
          ...calibrationParams,
          first_point: {
            ...calibrationParams.first_point,
            std_temperature: "",
          },
        });
      }
      setCalibrationParams({
        ...calibrationParams,
        first_point: {
          ...calibrationParams.first_point,
          std_temperature: parseFloat(event.target.value),
        },
      });
    }
    if (event.target.name === "temp_2") {
      if (event.target.value === "") {
        setCalibrationParams({
          ...calibrationParams,
          second_point: {
            ...calibrationParams.second_point,
            temperature: "",
          },
        });
      }
      setCalibrationParams({
        ...calibrationParams,
        second_point: {
          ...calibrationParams.second_point,
          temperature: parseFloat(event.target.value),
        },
      });
    }
    if (event.target.name === "correct_2") {
      if (event.target.value === "") {
        setCalibrationParams({
          ...calibrationParams,
          second_point: {
            ...calibrationParams.second_point,
            std_temperature: "",
          },
        });
      }
      setCalibrationParams({
        ...calibrationParams,
        second_point: {
          ...calibrationParams.second_point,
          std_temperature: parseFloat(event.target.value),
        },
      });
    }
    if (event.target.name === "temp_3") {
      if (event.target.value === "") {
        setCalibrationParams({
          ...calibrationParams,
          third_point: {
            ...calibrationParams.third_point,
            temperature: "",
          },
        });
      }
      setCalibrationParams({
        ...calibrationParams,
        third_point: {
          ...calibrationParams.third_point,
          temperature: parseFloat(event.target.value),
        },
      });
    }
    if (event.target.name === "correct_3") {
      if (event.target.value === "") {
        setCalibrationParams({
          ...calibrationParams,
          third_point: {
            ...calibrationParams.third_point,
            std_temperature: "",
          },
        });
      }
      setCalibrationParams({
        ...calibrationParams,
        third_point: {
          ...calibrationParams.third_point,
          std_temperature: parseFloat(event.target.value),
        },
      });
    }
    if (event.target.name === "report_id") {
      setCalibrationParams({
        ...calibrationParams,
        report_id: event.target.value,
      });
    }

    if (event.target.name === "thermometer") {
      setCalibrationParams({
        ...calibrationParams,
        thermometer: event.target.value,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        window.confirm(
          language === "es"
            ? `seguro desea enviar estos datos para calibrar?`
            : "Are you sure you want to send this data for calibration?"
        )
      ) {
        //mutation calibrar
        await calibrateDevice({
          variables: {
            device_id: deviceToCalibrate.device_id,
            calibrator_id: calibrationParams.calibrator_id,
            due_date: calibrationParams.due_date,
            first_point: {
              std_temperature: calibrationParams.first_point.std_temperature,
              temperature: calibrationParams.first_point.temperature,
            },
            second_point: {
              std_temperature: calibrationParams.second_point.std_temperature,
              temperature: calibrationParams.second_point.temperature,
            },
            third_point: {
              std_temperature: calibrationParams.third_point.std_temperature,
              temperature: calibrationParams.third_point.temperature,
            },
            report_id: calibrationParams.report_id,
            thermometer_id: calibrationParams.thermometer,
          },
        });

        window.alert(
          language === "es"
            ? "dispositivo calibrado con exito!"
            : "Device successfully calibrated!"
        );

        //limpio los campos despues del submit
        setCalibrationParams({
          calibrator_id: "",
          third_point: { std_temperature: "", temperature: "" },
          second_point: { std_temperature: "", temperature: "" },
          first_point: { std_temperature: "", temperature: "" },
          due_date: "",
          report_id: "",
          thermometer: "",
        });
        // localStorage.setItem("assigned_to", null);
        // localStorage.setItem("assigned_to_name", null);
        onClose();
        window.location.reload("/");
      } else {
        //limpio los campos despues del submit
        setCalibrationParams({
          calibrator_id: "",
          third_point: { std_temperature: "", temperature: "" },
          second_point: { std_temperature: "", temperature: "" },
          first_point: { std_temperature: "", temperature: "" },
          due_date: "",
          report_id: "",
          thermometer: "",
        });
      }
    } catch (error) {
      console.error(error);
      alert(error.toString().split(":")[1]);
    }
  };

  if (last_calibration_info) {
    return (
      <div className={calibrationStyles.container}>
        <h2 className={calibrationStyles.title}>
          {
            <FormattedMessage id="last_calibration_info">
              last calibration info
            </FormattedMessage>
          }
        </h2>
        {/* { */}
        {/* device?.device?.calibrations?.length > 0 ? ( */}
        {deviceLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <table
            style={{
              fontSize: "16px",
              lineHeight: "1.5",
              fontFamily: "'Quattrocento Sans', sans-serif",
            }}
          >
            <tbody>
              <tr>
                <td style={{ paddingRight: "20px" }}>
                  <FormattedMessage id="qr_code">Qr Code</FormattedMessage>
                </td>
                
                <td>#{device?.device?.qr}</td>
              </tr>

              <tr>
                <td style={{ paddingRight: "20px" }}>
                  <FormattedMessage id="due_date">due date</FormattedMessage>
                </td>
                <td>
                  {new Date(
                    device?.device?.calibrations?.[
                      device.device?.calibrations.length - 1
                    ]?.due_date
                  ).toLocaleDateString("en-CA")}{" "}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: "20px" }}>
                  <FormattedMessage id="issue_date">
                    issue date
                  </FormattedMessage>
                </td>
                <td>
                  {new Date(
                    device?.device?.calibrations?.[
                      device.device?.calibrations.length - 1
                    ]?.issue_date
                  ).toLocaleDateString("en-CA")}{" "}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: "20px" }}>
                  <FormattedMessage id="report_id">report id</FormattedMessage>
                </td>
                <td>
                  {
                    device?.device?.calibrations?.[
                      device?.device?.calibrations?.length - 1
                    ]?.report_id
                  }
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: "20px" }}>
                  <FormattedMessage id="thermometer_id">
                    thermometer id
                  </FormattedMessage>
                </td>
                <td>
                  {
                    device?.device?.calibrations?.[
                      device.device?.calibrations?.length - 1
                    ]?.thermometer_id
                  }{" "}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  } else {
    
    return (
      <div className={calibrationStyles.container}>
        {/* Titulo */}
        <h2 className={calibrationStyles.title}>
          {
            <FormattedMessage id={"calibrate_device"}>
              calibrate_device
            </FormattedMessage>
          }{" "}
          #{device?.device?.qr}
        </h2>

        <FormHelperText sx={{ color: "red" }}>
          <FormattedMessage id={"mandatory_field"}>
            mandatory field
          </FormattedMessage>
        </FormHelperText>

        <FormControl
          sx={{
            // marginTop: "2%",
            width: "100%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            // border: "1px solid red",
            overflowY: "scroll",
          }}
        >
          <Grid container spacing={1}>
            {/* select calibrator */}
            <Grid item xs={12} sm={6}>
              <InputLabel
                id="Select_calibrator"
                sx={{
                  position: "realtive",
                  top: "17px",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
              >
                {language === "es"
                  ? "Seleccione un calibrador"
                  : "Select calibrator"}
              </InputLabel>

              <Select
                labelId="Select_calibrator"
                id="Select calibrator"
                value={calibrationParams.calibrator_id}
                label={
                  language === "es"
                    ? "Seleccione un calibrador"
                    : "Select calibrator"
                }
                fontSize="small"
                onChange={handleChange}
                name={"operator"}
                sx={{ marginTop: 2, width: "100%" }}
              >
                <MenuItem value="">
                  <em>{language === "es" ? "Ninguno" : "None"}</em>
                </MenuItem>
                <MenuItem value={myCalibrator}>
                  {myCalibrator && `${myCalibratorName} (${myCalibrator})`}
                </MenuItem>
                {freeOperators?.company?.free_operators
                  .filter((ope) => ope.access_to.calibrate === true&&ope.status==="ACTIVE")
                  .map((ope) => (
                    <MenuItem value={ope.operator_id} key={ope.operator_id}>
                      {`${ope.name} (${ope.operator_id})`}
                     
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText sx={{ color: "red" }}>*</FormHelperText>
            </Grid>

            {/* due date */}
            <Grid item xs={12} sm={6}>
              <TextField
                type="date"
                label={language === "es" ? "Fecha expiración" : "Due date"}
                sx={{ marginTop: 2, width: "100%" }}
                InputLabelProps={{
                  shrink: true, // Encoge la etiqueta cuando se selecciona una fecha
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                name="date"
                placeholder={
                  calibrationParams.due_date
                    ? calibrationParams.due_date
                    : localStorage.getItem("language") === "es"
                    ? "Seleccione una fecha"
                    : "Select date"
                }
              />

              <FormHelperText sx={{ color: "red" }}>*</FormHelperText>

              <span className={calibrationStyles.pointTitle}>
                {language === "es" ? "Primer punto" : "First point"}
              </span>
            </Grid>

            {/* temp 1  */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleChange}
                name="temp_1"
                sx={{ marginTop: 2, width: "100%" }}
                type="number"
                label={language === "es" ? "Temp 1" : "Temp 1"}
                inputProps={{
                  step: "0.01",
                  min: "-999999999",
                  max: "999999999",
                }}
              />
              <FormHelperText sx={{ color: "red" }}>*</FormHelperText>
            </Grid>

            {/*  standard 1 */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleChange}
                name="correct_1"
                sx={{ marginTop: 2, width: "100%" }}
                type="number"
                label={language === "es" ? "Patrón 1" : "Standard 1"}
                inputProps={{
                  step: "0.01",
                  min: "-999999999",
                  max: "999999999",
                }}
              />
              <FormHelperText sx={{ color: "red" }}>*</FormHelperText>
              <span className={calibrationStyles.pointTitle}>
                {language === "es" ? "Segundo punto" : "Second point"}
              </span>
            </Grid>

            {/* temp 2 */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleChange}
                name="temp_2"
                sx={{ marginTop: 2, width: "100%" }}
                type="number"
                label={language === "es" ? "Temp 2 " : "Temp 2"}
                inputProps={{
                  step: "0.01",
                  min: "-999999999",
                  max: "999999999",
                }}
              />
              <FormHelperText sx={{ color: "red" }}>*</FormHelperText>
            </Grid>
            {/* standard 2 */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleChange}
                name="correct_2"
                sx={{ marginTop: 2, width: "100%" }}
                type="number"
                label={language === "es" ? "Patrón 2" : "Standard 2"}
                inputProps={{
                  step: "0.01",
                  min: "-999999999",
                  max: "999999999",
                }}
              />
              <FormHelperText sx={{ color: "red" }}>*</FormHelperText>
            </Grid>
            {/* temp 3 */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleChange}
                name="temp_3"
                sx={{ marginTop: 2, width: "100%" }}
                type="number"
                label={language === "es" ? "Temp 3 " : "Temp 3"}
                inputProps={{
                  step: "0.01",
                  min: "-999999999",
                  max: "999999999",
                }}
              />
              <FormHelperText sx={{ color: "red" }}>*</FormHelperText>
            </Grid>
            {/* standard 3 */}
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleChange}
                name="correct_3"
                sx={{ marginTop: 2, width: "100%" }}
                type="number"
                label={language === "es" ? "Patrón 3" : "Standard 3"}
                inputProps={{
                  step: "0.01",
                  min: "-999999999",
                  max: "999999999",
                }}
              />
              <FormHelperText sx={{ color: "red" }}>*</FormHelperText>
              <span className={calibrationStyles.pointTitle}>
                {language === "es" ? "Tercer punto " : "Third point"}
              </span>
            </Grid>
            {/* report id */}
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                label={language === "es" ? "Id del reporte" : "Report Id"}
                sx={{ marginTop: 2, width: "100%" }}
                onChange={handleChange}
                name="report_id"
              />
            </Grid>
            {/* thermometer */}
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                label={language === "es" ? "Termómetro" : "Thermometer"}
                sx={{ marginTop: 2, width: "100%" }}
                onChange={handleChange}
                name="thermometer"
              />
            </Grid>
            {/*submit Button  */}
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                margin: "auto",
                // border:"1px solid red",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                top: "5%",
              }}
            >
              <CustomButton
                type={"submitCalibrateParams"}
                submitCalibrationParams={{
                  text: (
                    <FormattedMessage id={"submit"}>submit</FormattedMessage>
                  ),
                  action: handleSubmit,
                }}
                disabled={
                  calibrationParams.calibrator_id === "" ||
                  calibrationParams?.first_point?.temperature === "" ||
                  calibrationParams?.first_point?.temperature === NaN ||
                  calibrationParams?.first_point?.std_temperature === "" ||
                  calibrationParams?.first_point?.std_temperature === NaN ||
                  calibrationParams?.second_point?.temperature === "" ||
                  calibrationParams?.second_point?.temperature === NaN ||
                  calibrationParams?.second_point?.std_temperature === "" ||
                  calibrationParams?.second_point?.std_temperature === NaN ||
                  calibrationParams?.third_point?.temperature === "" ||
                  calibrationParams?.third_point?.temperature === NaN ||
                  calibrationParams?.third_point?.std_temperature === "" ||
                  calibrationParams?.third_point?.std_temperature === NaN ||
                  calibrationParams.due_date === ""
                    ? true
                    : false
                }
              />
            </Grid>
          </Grid>
        </FormControl>
      </div>
    );
  }
};

export default DeviceCalibrationModal;
