//File: FreezeTempGraph.jsx

import React, { useState, useEffect, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  ReferenceArea,
  Brush,
  ReferenceDot,
} from "recharts";
import moment from "moment";
import styles from "./styles.module.scss";
import { language } from "../../const";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import { Form } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { getTimestamp } from "../../helpers";
import { ADD_REFRIGERATION_COMMENT, GET_REFRIGERATION } from "../../queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import commentIcon from "../../components/RegistroTemperaturaIntrusion/icons8-comment-48 (1).png";
import { intlFormat } from "date-fns";

const getAxisYDomain = (from, to, ref, data, offset) => {
  const refData = data
    .slice(from, to + 1)
    .filter((item) => item && item[ref] !== null && item[ref] !== undefined);
  if (refData.length === 0) return [0, 0];
  let [bottom, top] = [refData[0][ref], refData[0][ref]];

  refData.forEach((d) => {
    if (d[ref] > top) top = d[ref];
    if (d[ref] < bottom) bottom = d[ref];
  });

  return [(bottom | 0) - offset, (top | 0) + offset];
};

const timeFormatter = (time) => {
  return moment(time).format("DD/MM hh:mm a");
};

const CustomTick = ({ x, y, payload }) => {
  const date = moment(payload.value).format("DD/MM");
  const time = moment(payload.value).format("hh:mm a");

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        <tspan x="0" dy="1.2em">
          {date}
        </tspan>
        <tspan x="0" dy="1em">
          {time}
        </tspan>
      </text>
    </g>
  );
};

const CustomTooltip2 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const tooltipStyle = {
      background: "#ffffff",
      padding: "10px",
      border: "1px solid #ccc",
    };

  
    const commentData = payload.find(
      (item) => item.payload.comment !== undefined
    );
    const tempData = payload.find((item) => item.dataKey === "value");
   
    // Use the temperature from tempData or from commentData if available
    const temperature = tempData
      ? tempData.value
      : commentData && commentData.payload.value
      ? commentData.payload.value
      : null;

    return (
      <div style={tooltipStyle}>
        <p>
          <FormattedMessage id="date">fecha</FormattedMessage>:
          {getTimestamp(label)}
        </p>
        {commentData && (
          <p style={{ color: "grey" }}>
            <FormattedMessage id="comment">comentario</FormattedMessage>:"
            {commentData.payload.comment}"
          </p>
        )}
        {temperature !== null && (
          <p style={{ color: "#00ABC8" }}>Temp: {temperature}°C</p>
        )}
      </div>
    );
  }

  return null;
};
const CustomLegend = () => <div style={{ height: "1vw" }}></div>;

const FreezeTempGraph = ({
  refrigeration_id,
  /* intervalReadings, */ freezer_temp_range,
  loading,
  interlvaFilter,
  qr,
  refetchGralComments
}) => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [top, setTop] = useState("dataMax+1");
  const [bottom, setBottom] = useState("dataMin-1");
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [clickTimeout, setClickTimeout] = useState(null);
  const [commentObject, setCommentObject] = useState({
    timestamp: null,
    value: null,
    text: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [intervalReadings, setIntervalReadings] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [comments, setComments] = useState([]);

  const [
    getRefrigeration,
    {
      loading: refrigerationsLoading,
      error: refrigerationsError,
      data: refrigerationsResult,
    },
  ] = useLazyQuery(GET_REFRIGERATION);
  const [
    addComment,
    {
      loading: commentAddedLoading,
      error: commentAddedError,
      data: commentAddedData,
    },
  ] = useMutation(
    ADD_REFRIGERATION_COMMENT,

    { fetchPolicy: "network-only" }
  );
  useEffect(() => {
    const fetchRefrigeration = async () => {
      try {
        await getRefrigeration({
          variables: {
            from: interlvaFilter.from,
            to: interlvaFilter.to,
            qr: qr,
          },
        });
        setIntervalReadings(refrigerationsResult?.refrigeration?.readings);
        setAlerts(refrigerationsResult?.refrigeration?.alerts);
        // getInterval(refrigerationsResult.refrigeration?.readings);
        setComments(refrigerationsResult?.refrigeration?.comments);
      } catch (error) {
        console.log(`Error al enviar intervalo de fechas: ${error}`);
      }
    };
    fetchRefrigeration();
  }, [
    interlvaFilter,
    refrigerationsResult,
    intervalReadings,
    refetch,
    comments,
  ]);

  const processData = useCallback(() => {
    const dataObjArray = intervalReadings?.reduce((acc, item, index) => {
      const currentTime = moment(item.time).valueOf();
      if (index > 0) {
        const previousTime = moment(intervalReadings[index - 1].time).valueOf();
        const diffMinutes = (currentTime - previousTime) / (1000 * 60);
        if (diffMinutes > 35) {
          acc.push({
            time: previousTime + 30 * 60 * 1000,
            value: null,
          });
        }
      }
      acc.push({
        time: currentTime,
        value: item.cv !== null ? item.cv : item.temp,
      });

      return acc;
    }, []);

    // Procesar comentarios y agregar a dataObjArray
    comments
      ?.filter((comment) => comment.timestamp !== null)
      ?.forEach((comment) => {
        const commentTimestamp = moment(comment.timestamp).valueOf();
        const matchingData = dataObjArray?.find(
          (entry) => entry.time === commentTimestamp
        );

        // Si no hay coincidencia exacta, encontrar la más cercana
        const closestData = matchingData
          ? matchingData
          : dataObjArray?.length > 0
          ? dataObjArray?.reduce((prev, curr) => {
              const prevDiff = Math.abs(prev.time - commentTimestamp);
              const currDiff = Math.abs(curr.time - commentTimestamp);
              return currDiff < prevDiff ? curr : prev;
            })
          : null;

        // Agregar el comentario al objeto de datos más cercano
        if (closestData) {
          const index = dataObjArray.indexOf(closestData);
          if (index !== -1) {
            dataObjArray[index] = {
              ...dataObjArray[index],
              comment: comment.text,
            
            };
          }
        }
      });

    // Ordenar el array por tiempo
    dataObjArray?.sort((a, b) => a.time - b.time);


    setData(dataObjArray);
    setOriginalData(dataObjArray);
    setEndIndex(dataObjArray?.length - 1);
  }, [intervalReadings, comments]);

  useEffect(() => {
    processData();
  }, [intervalReadings, processData, comments]);

  const zoom = () => {
    if (refAreaLeft === refAreaRight || !refAreaRight) {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    let from = refAreaLeft;
    let to = refAreaRight;

    if (from > to) [from, to] = [to, from];

    const fromIndex = data.findIndex((item) => item.time === from);
    const toIndex = data.findIndex((item) => item.time === to);

    if (fromIndex === -1 || toIndex === -1) {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    const [bottom, top] = getAxisYDomain(fromIndex, toIndex, "value", data, 3);
    const newData = data.slice(fromIndex, toIndex + 1);

    setLeft(data[fromIndex].time);
    setRight(data[toIndex].time);
    setBottom(bottom);
    setTop(top);
    setData(newData);
    setRefAreaLeft("");
    setRefAreaRight("");
  };

  const zoomOut = () => {
    setLeft("dataMin");
    setRight("dataMax");
    setTop("dataMax+1");
    setBottom("dataMin-1");
    setRefAreaLeft("");
    setRefAreaRight("");
    setData(originalData);
    setStartIndex(0);
    setEndIndex(data?.length - 1);
  };
  // const handleBrushChange = (e) => {
  //   setStartIndex(e.startIndex);
  //   setEndIndex(e.endIndex);
  // };
  const handleChange = (event) => {
    setCommentObject({ ...commentObject, text: event.target.value });
  };
  const handleChartClick = (e) => {
    if (clickTimeout) {
      //doble click
      clearTimeout(clickTimeout);
      setClickTimeout(null);

      if (e && e.activeLabel && e.activePayload) {
        setCommentObject({
          timestamp: e.activeLabel,
          value: e.activePayload[0].payload.value,
          text: "",
        });

        setOpenDialog(true);
      }
    } else {
      //simple click
      const timeout = setTimeout(() => {
        setClickTimeout(null);
      }, 300);
      setClickTimeout(timeout);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleConfirmDialog = async () => {
    try {
      const newComment = commentObject.text.trim();

      setOpenSnackbar(true);
      //  setLoading(true);
      setOpenDialog(false);
      if (newComment) {
        await addComment({
          variables: {
            refrigeration_id: refrigeration_id,
            comment_text: newComment,
            timestamp: commentObject.timestamp,
          },
          refetchQueries: [
            {
              query: GET_REFRIGERATION,
              variables: {
                from: interlvaFilter.from,
                to: interlvaFilter.to,
                qr: qr,
              },
            },
          ],
        });
        setRefetch(!refetch);
        refetchGralComments();
      }
    } catch (error) {
      console.error("Error al enviar comentario:", error);
    }
  };
const intl=useIntl()
  return (
    <>
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            <FormattedMessage id="add_comment">Agregar comentario</FormattedMessage>
          </DialogTitle>
          {/* //traducir */}
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="comment-input"
              label={intl.formatMessage({id:"ingrese_comentario"})}
              type="text"
              fullWidth
              value={commentObject.text}
              onChange={handleChange}
              placeholder={`${getTimestamp(commentObject.timestamp)}- ${
                commentObject.value
              }°C`}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              <FormattedMessage id="cancel">Cancelar</FormattedMessage>
            </Button>
            <Button onClick={handleConfirmDialog} color="primary">
            <FormattedMessage id="save">save</FormattedMessage>
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            Comentario enviado con éxito
          </Alert>
        </Snackbar>
      </>
      <div className={styles.chartContainer}>
        {(left !== "dataMin" || right !== "dataMax") && (
          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              onClick={zoomOut}
              sx={{ backgroundColor: "#1B1464", textTransform: "none" }}
            >
              {language === "es" ? "Quitar zoom" : "Zoom out"}
            </Button>
          </div>
        )}
        {refrigerationsLoading||commentAddedLoading ? (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ width: "100%", height: "90%" }}>
            {" "}
            {/* Add this div */}
            <ResponsiveContainer width="100%" height="100%">
              {data?.length === 0 ? (
                <div className={styles.noData}>
                  <FormattedMessage id="no_data_in_range" />
                </div>
              ) : (
                <LineChart
                  width="100%"
                  height="100%"
                  data={data}
                  onClick={handleChartClick}
                  // onMouseDown={(e) => setRefAreaLeft(e.activeLabel)}
                  // onMouseMove={(e) => setRefAreaRight(e.activeLabel)}
                  // onMouseUp={zoom}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="time"
                    tick={<CustomTick />}
                    tickMargin={6}
                    tickLine={true}
                    domain={[left, right]}
                    scale="time"
                    type="number"
                  />

                  <YAxis
                    dataKey="value"
                    tickFormatter={(tick) => tick.toFixed(2)}
                    unit="°C"
                    domain={[bottom, top]}
                  />
                  <Tooltip content={<CustomTooltip2 />} />
                  <Legend content={<CustomLegend />} />
                  <Line
                    type="natural"
                    dataKey="value"
                    stroke="#00ABC8"
                    dot={false}
                    connectNulls={false}
                  />
                  <ReferenceLine
                    y={freezer_temp_range?.max}
                    stroke="orange"
                    strokeDasharray="5 5"
                    label={`Max: ${freezer_temp_range?.max}`}
                  />
                  <ReferenceLine
                    y={freezer_temp_range?.min}
                    stroke="orange"
                    strokeDasharray="5 5"
                    label={`Min: ${freezer_temp_range?.min}`}
                  />
                  {/* {refAreaLeft && refAreaRight ? (
                    <ReferenceArea
                      x1={refAreaLeft}
                      x2={refAreaRight}
                      strokeOpacity={0.3}
                    />
                  ) : null} */}
                  {data
                    ?.filter((data) => data.comment)
                    ?.map((data, index) => (
                      <ReferenceDot
                        key={index}
                        x={data.time}
                        y={data.value}
                      //  yAxisId="left"
                        r={0}
                        label={({ viewBox }) => (
                          <image
                            x={viewBox.x - 12}
                            y={viewBox.y - 12}
                            href={commentIcon}
                            width={20}
                            height={20}
                            style={{ color: "red", border: "1px solid red" }}
                          />
                        )}
                      />
                    ))}
                  <Brush y={500} />
                </LineChart>
              )}
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </>
  );
};

export default FreezeTempGraph;


