import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import styles from "./MyUsers.module.scss";
import {
  UPDATE_USER,
  GET_COMPANY_DETAIL,
  ASSIGN_USER,
  GET_ALL_USERS,
  DELETE_USER,
} from "../../../queries";
import { Modal } from "@mui/material";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Pagination from "@mui/material/Pagination";
import { parseJwt } from "../../../helpers";
import { CircularProgress } from "@mui/material";
import { BiCaretDown } from "react-icons/bi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsSearch } from "react-icons/bs";
import CustomButton from "../../Buttons/CustomButton";
import { set } from "date-fns";

export default function MyUsers({
  company_id,
  usersFilter,
  userCreated,
  usersNumber,
}) {
  //ME GUARDO EL COMPANY_ID DEL USUARIO REGISTRADO
  const registered_company_id = parseJwt(
    localStorage.getItem("token")
  ).belong_id;

  //estado para guardarme el filtro de tipo de usuario
  const [typeFilter, setTypeFilter] = useState("");

  // QUERIES/MUTATIONS -----------------------------------------------------------------------
  //mutation para updatear usuario
  const [
    updateUser,
    {
      // data: updatedUserData,
      loading: updatedUserLoading,
      error: updatedUserError,
    },
  ] = useMutation(UPDATE_USER);

  //lazy query para traerme los operadores según tipo
  const [
    getCompanyDetail,
    {
      // loading: companyDetailLoading,
      error: companyDetailError,
      data: companyDetailData,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL);

  //mutation para eliminar usuario
  const [
    deleteUser,
    {
      // data: deleteUserData,
      // loading: deleteUserLoading,
      error: deleteUserError,
    },
  ] = useMutation(DELETE_USER);

  //lazy query para traerme la data de usuarios
  const [
    getUsers,
    {
      data: usersDataLazyQuery,
      loading: usersDataLazyQueryLoading,
      error: usersDataLazyQueryError,
    },
  ] = useLazyQuery(GET_ALL_USERS, {
    variables: {
      belong_id: company_id ? company_id : "",
    },
    fetchPolicy: "network-only",
  });

  //query para traerme los operadores no asignados
  const [
    getFreeOperators,
    {
      data: companyData,
      // loading: freeOperatorsLoading,
      // error: freeOperatorsError,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL);

  //ESTADOS LOCALES ------------------------------------------------------------------
  //estado para manejar los botones edit/save
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [editUser, setEditUser] = useState(false);

  //estado para guardar la data de los usuarios
  const [usersData, setUsersData] = useState(
    // usersDataLazyQuery?.users.filter((u) =>
    //   showAllUsers ? u : u.status === "ACTIVE"
    // )
    []
  );

  //estado para el modal de assign con filtro
  const [modalAssign, setModalAssign] = useState(false);

  //estado para guardar el usuario a asignar
  const [userAssigned, setUserAssigned] = useState("");

  //estado para guardar el tipo de usuario a asignar
  const [userAssignedType, setUserAssignedType] = useState("");

  //estado para guardar la seleccion del assign
  const [assignSelect, setAssignSelect] = useState("");

  //estado para guardar la data de los input de edit user
  const [actualUserData, setActualUserData] = useState("");

  //estados para paginación
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //estado para guardar el id de la compañia que tiene el user que esta en edicion asignado
  const [companyOfUserSelected, setCompanyOfUserSelected] = useState("");

  //estado y variable para el dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //estado para el input del search por username
  const [searchInput, setSearchInput] = useState("");

  const [selectOpeCategoryForSelect, setSelectOpeCategoryForSelect] =
    useState("");

  const [selectedType, setSelectedType] = useState("");
  const [refetch, setRefetch] = useState(false);
  //CUANDO SE INICIA EL COMPONENTE -----------------------------------------------------------------

  useEffect(() => {
    console.log("estoy en el effect");
    const fetchData = async () => {
      await getCompanyDetail();
      const result = await getUsers();
      setUsersData(
        result?.data?.users.filter((u) =>
          showAllUsers ? u : u.status === "ACTIVE"
        )
      );
      usersNumber(
        result?.data?.users.filter((u) =>
          showAllUsers ? u : u.status === "ACTIVE"
        ).length
      );
      await getFreeOperators({
        variables: {
          belong_id: company_id,
          type: selectedType,
        },
      });
    };

    fetchData();
  }, [
    /* usersDataLazyQuery, */ typeFilter,
    userCreated,
    selectedType,
    showAllUsers,
    refetch,
  ]);

  //CUANDO CAMBIA EL FILTRO -----------------------------------------------------------------
  useEffect(() => {
    setPage(1);
  }, [typeFilter, searchInput]);

  // HANDLERS ------------------------------------------------------------------------------------
  //handler del change de los input
  const handleChangeUserData = (e) => {
    setActualUserData({
      ...actualUserData,
      [e.target.name]: e.target.value,
    });
  };

  //para cambiar el tipo de usuario y en funcion a este devolver una lista de free_operators
  const handleChangeType = (e) => {
    if (
      ["Admin", "Calibrator", "Shipper", "Operator"].includes(e.target.value)
    ) {
      setSelectedType(e.target.value);
    }
  };
  //handler del edit user
  const handleEditUser = (id) => {
    setEditUser(id);
    setActualUserData({
      ...actualUserData,
      user_id: id,
    });
  };

  //handler del cancel edit user
  const handleCancelEditUser = () => {
    setEditUser("");
    setCompanyOfUserSelected("");
    setSelectedType("");
  };

  //handler del save del edit user
  const handleSaveData = async () => {
    setEditUser("");
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        await updateUser({
          variables: actualUserData,
        });
        setSelectedType("");
        // await getUsers();
        // setUsersData(usersDataLazyQuery?.users);
     
        setRefetch(!refetch);
        setPage(1);
        setShowAllUsers(false)
      }
    } catch (error) {
      console.log("hubo un error en el update user", error);
    }
  };

  //handler del modal de assign

  const handleAssign = async (user_id, company_id, type) => {
    //setModalAssign(true);
    //setUserAssigned(user_id);
    setUserAssignedType(type);

    switch (type) {
      case "Operator":
        setSelectOpeCategoryForSelect(
          localStorage.getItem("language") === "en" ? "operator" : "operador"
        );
        break;
      case "Shipper":
        setSelectOpeCategoryForSelect(
          localStorage.getItem("language") === "en"
            ? "shipper"
            : "transportista"
        );
        break;
      case "Calibrator":
        setSelectOpeCategoryForSelect(
          localStorage.getItem("language") === "en"
            ? "calibrator"
            : "calibrador"
        );
        break;

      default:
        break;
    }

    await getFreeOperators({
      variables: {
        belong_id: company_id,
        type: selectedType,
      },
    });
    await getCompanyDetail({
      variables: {
        company_id,
      },
    });
  };

  //handler del select de assign
  const handleAssignChange = (e) => {
    setAssignSelect(e.target.value);
    setActualUserData({ ...actualUserData, assigned_to: e.target.value });
  };

  //handler de la paginacion
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  //handler para eliminar usuario
  const handleDeleteUser = async (id) => {
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que deseas eliminar el usuario?"
            : "Are you sure you want to delete user?"
        )
      ) {
        await deleteUser({
          variables: {
            user_id: id,
          },
        });
        window.location.reload("/");
      }
    } catch (error) {
      console.log("error en la mutation de deleteUser", error);
    }
  };

  //handler del dropdown de type
  const handleDropdown = (e) => {
    setAnchorEl(e.currentTarget);
  };

  //handler del close del dropdown
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  //handler del type filter
  const handleTypeFilter = (value) => {
    setTypeFilter(value);
  };

  //handler del search por username
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  // ME GUARDO LA INFO EN UNA VARIABLE COPIA -------------------------------------------------------
  let info = usersData;

  //PARA LA BARRA DE BUSQUEDA -----------------------------------------------------------
  if (searchInput !== "") {
    let infoCopy = info;
    info = infoCopy?.filter((u) =>
      u?.username?.toLowerCase().includes(searchInput.toLowerCase())
    );
  }

  //MANEJO DE ERRORES --------------------------------------------------------------------
  if (updatedUserError)
    console.log("error en el update user", updatedUserError);
  if (usersDataLazyQueryError)
    console.log("error en la query de get users", usersDataLazyQueryError);
  if (usersDataLazyQueryError)
    console.log("error en la lazy query de get users", usersDataLazyQueryError);
  if (companyDetailError)
    console.log("error en el detalle de la compañia", companyDetailError);
  // if (assignError)
  //   console.log("error en la asignación del usuario", assignError);
  if (deleteUserError) console.log("error en la eliminación del usuario");

  //LOADINGS
  // if (
  //   updatedUserLoading ||
  //   // assignLoading ||
  //   usersDataLazyQueryLoading ||
  //   usersDataLazyQueryLoading
  // )
  //   return (
  //     <div className={styles.loading}>
  //       <CircularProgress />
  //     </div>
  //   );

  //PARA PAGINAR -------------------------------------------------------------------------
  const indexOfLastElement = page * rowsPerPage;
  const indexOfFirstElement = indexOfLastElement - rowsPerPage;
  const currentElements =
    typeFilter !== ""
      ? info
          ?.filter((u) => u.type === typeFilter)
          ?.slice(indexOfFirstElement, indexOfLastElement)
      : info?.slice(indexOfFirstElement, indexOfLastElement);

  const handleShowAllUsers = () => {
    setShowAllUsers(!showAllUsers);
    setPage(1);
  };

  return (
    <div className={styles.usersContainer}>
      <CustomButton
        type={"userFilter"}
        action={handleShowAllUsers}
        text={
          showAllUsers
            ? localStorage.getItem("language") === "en"
              ? "Show active users"
              : "Mostrar usuarios activos"
            : localStorage.getItem("language") === "en"
            ? "Show all users"
            : "Mostrar todos los usuarios"
        }
        togle={showAllUsers}
      />
      {usersData?.[0] ? (
        <>
          {/* BUSQUEDA POR NOMBRE DE USUARIO ---------------------------------------------------------------- */}
          <div className={styles.inputContainer}>
            <label className={styles.searchQrLabel}>
              <BsSearch size={window.screen.width > 800 ? "1vw" : "3vw"} />
            </label>
            <input
              type="text"
              onChange={handleSearch}
              className={styles.searchQrInput}
              placeholder={
                localStorage.getItem("language") === "en"
                  ? "USERNAME:"
                  : "NOMBRE DE USUARIO:"
              }
            />
          </div>
          <TableContainer>
            {updatedUserLoading ? (
              <div className={styles.loading}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* HEAD ID (SOLO SUDO) ------------------------------------------------------------------ */}
                      {!registered_company_id && (
                        <TableCell
                          size="medium"
                          align="center"
                          sx={{
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",

                            color: "#00ABC8",
                          }}
                        >
                          ID
                        </TableCell>
                      )}
                      {/* HEAD USERNAME ------------------------------------------------------------------- */}
                      <TableCell
                        size="medium"
                        align="left"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",

                          // paddingLeft: "5%",
                          width: "10%",
                        }}
                      >
                        <FormattedMessage id="username_may">
                          USERNAME
                        </FormattedMessage>
                      </TableCell>
                      {/* HEAD BELONG TO (SOLO SUDO) ---------------------------------------------------------------- */}
                      {!company_id && (
                        <TableCell
                          size="medium"
                          align="center"
                          sx={{
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",

                            color: "#00ABC8",
                          }}
                        >
                          <FormattedMessage id="belongs_to_may">
                            Belongs to
                          </FormattedMessage>
                        </TableCell>
                      )}
                      {/* HEAD EMAIL --------------------------------------------------------------------------- */}
                      <TableCell
                        size="medium"
                        align="left"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",

                          // paddingLeft: "10%",
                          width: "10%",
                        }}
                      >
                        EMAIL
                      </TableCell>
                      {/* HEAD TIPO ------------------------------------------------------------------------------- */}
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",

                          // paddingLeft: "5%",
                          width: "8.33%",
                        }}
                      >
                        <div className={styles.dropdown_type_div}>
                          <FormattedMessage id="type_config_may">
                            TYPE
                          </FormattedMessage>
                          {"     "}
                          <button
                            className={styles.dropdownButton}
                            onClick={handleDropdown}
                          >
                            <BiCaretDown />
                          </button>
                        </div>

                        <Menu
                          disableScrollLock={true}
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleCloseDropdown}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            borderRadius: "5vw",
                            width: window.screen.width > 800 ? "20vw" : "22vw",
                          }}
                        >
                          {typeFilter !== "" && (
                            <MenuItem
                              onClick={() => handleTypeFilter("")}
                              sx={{
                                borderRadius: "2vw",
                                color: "#1B1464",

                                fontSize:
                                  window.screen.width > 800 ? "1.1vw" : "1.5vw",
                              }}
                            >
                              <FormattedMessage id="all_select">
                                All
                              </FormattedMessage>
                            </MenuItem>
                          )}
                          {typeFilter !== "Admin" && (
                            <MenuItem
                              onClick={() => handleTypeFilter("Admin")}
                              sx={{
                                borderRadius: "2vw",
                                color: "#1B1464",

                                fontSize:
                                  window.screen.width > 800 ? "1.1vw" : "1.5vw",
                              }}
                            >
                              <FormattedMessage id="admin_select">
                                Admin
                              </FormattedMessage>
                            </MenuItem>
                          )}
                          {typeFilter !== "Distributor" && (
                            <MenuItem
                              onClick={() => handleTypeFilter("Distributor")}
                              sx={{
                                borderRadius: "2vw",
                                color: "#1B1464",

                                fontSize:
                                  window.screen.width > 800 ? "1.1vw" : "1.5vw",
                              }}
                            >
                              <FormattedMessage id="distributor_select">
                                Distributor
                              </FormattedMessage>
                            </MenuItem>
                          )}
                          {typeFilter !== "Operator" && (
                            <MenuItem
                              onClick={() => handleTypeFilter("Operator")}
                              sx={{
                                borderRadius: "2vw",
                                color: "#1B1464",

                                fontSize:
                                  window.screen.width > 800 ? "1.1vw" : "1.5vw",
                              }}
                            >
                              <FormattedMessage id="operators_select">
                                Operator
                              </FormattedMessage>
                            </MenuItem>
                          )}
                          {typeFilter !== "Shipper" && (
                            <MenuItem
                              onClick={() => handleTypeFilter("Shipper")}
                              sx={{
                                borderRadius: "2vw",
                                color: "#1B1464",

                                fontSize:
                                  window.screen.width > 800 ? "1.1vw" : "1.5vw",
                              }}
                            >
                              <FormattedMessage id="shippers_select">
                                Carrier
                              </FormattedMessage>
                            </MenuItem>
                          )}
                          {typeFilter !== "Calibrator" && (
                            <MenuItem
                              onClick={() => handleTypeFilter("Calibrator")}
                              sx={{
                                borderRadius: "2vw",
                                color: "#1B1464",

                                fontSize:
                                  window.screen.width > 800 ? "1.1vw" : "1.5vw",
                              }}
                            >
                              <FormattedMessage id="calibrators_select">
                                Calibrator
                              </FormattedMessage>
                            </MenuItem>
                          )}
                        </Menu>
                      </TableCell>
                      {/* HEAD ASIGNADO ------------------------------------------------------------------------------------ */}
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",

                          // paddingLeft: "4%",
                          width: "10%",
                        }}
                      >
                        <FormattedMessage id="assigned_to_may">
                          ASSIGNED TO{" "}
                        </FormattedMessage>
                      </TableCell>
                      {/* Status */}
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",

                          // paddingLeft: "4%",
                          width: "10%",
                        }}
                      >
                        <div>
                          <FormattedMessage id="status_header">
                            Status{" "}
                          </FormattedMessage>
                        </div>
                      </TableCell>

                      {/* columnas vacias para botones------------------------- */}
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",

                          width: "8%",
                        }}
                      ></TableCell>

                      {/*columnas vacias para botones------------------------- */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentElements?.[0] ? (
                      currentElements
                        .filter((u) =>
                          showAllUsers ? u : u.status === "ACTIVE"
                        )
                        .map((u) => {
                          return (
                            <TableRow key={u?.user_id + 111}>
                              {/* ID (SOLO SUDO) ----------------------------------------------------------------------------------------- */}
                              {!registered_company_id && (
                                <TableCell
                                  align="center"
                                  size="small"
                                  sx={{
                                    border: "none",
                                    padding: "0.5%",
                                    fontSize:
                                      window.screen.width > 800
                                        ? "1vw"
                                        : "1.5vw",
                                    backgroundColor:
                                      currentElements?.indexOf(u) % 2 === 0
                                        ? "#FAFAFA"
                                        : "#D9F1F5",
                                    //

                                    color: "#1B1464",
                                  }}
                                >
                                  {editUser === u.user_id &&
                                  !registered_company_id ? (
                                    <input
                                      type="text"
                                      defaultValue={u.user_id}
                                      name="user_id"
                                      onChange={handleChangeUserData}
                                      style={{
                                        border: "none",
                                        borderBottom: "2px solid grey",
                                        width: "100%",
                                        textAlign: "center",
                                        backgroundColor: "transparent",
                                        fontSize:
                                          window.screen.width > 800
                                            ? "1vw"
                                            : "1.5vw",
                                        fontFamily:
                                          "'Quattrocento Sans', sans-serif",
                                        color: "#1B1464",
                                      }}
                                    />
                                  ) : (
                                    u.user_id.split("-")[1]
                                  )}
                                </TableCell>
                              )}
                              {/* USERNAME (SOLO SUDO)-------------------------------------------------------------- */}
                              <TableCell
                                align="left"
                                size="small"
                                sx={{
                                  border: "none",
                                  padding: "0.5%",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "1.5vw",
                                  backgroundColor:
                                    currentElements?.indexOf(u) % 2 === 0
                                      ? "#FAFAFA"
                                      : "#D9F1F5",
                                  //

                                  color: "#1B1464",
                                  paddingLeft: "1%",
                                }}
                              >
                                {editUser === u.user_id &&
                                !registered_company_id ? (
                                  <input
                                    type="text"
                                    defaultValue={u.username}
                                    name="username"
                                    onChange={handleChangeUserData}
                                    style={{
                                      border: "none",
                                      borderBottom: "2px solid grey",
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "transparent",
                                      fontSize:
                                        window.screen.width > 800
                                          ? "1vw"
                                          : "1.5vw",

                                      color: "#1B1464",
                                    }}
                                  />
                                ) : (
                                  u.username
                                )}
                              </TableCell>
                              {/* BELONGS TO (SOLO SUDO) ------------------------------------------------------------------------- */}
                              {!registered_company_id && (
                                <TableCell
                                  align="center"
                                  size="small"
                                  sx={{
                                    border: "none",
                                    padding: "0.5%",
                                    fontSize:
                                      window.screen.width > 800
                                        ? "1vw"
                                        : "1.5vw",
                                    backgroundColor:
                                      currentElements?.indexOf(u) % 2 === 0
                                        ? "#FAFAFA"
                                        : "#D9F1F5",
                                    //

                                    color: "#1B1464",
                                  }}
                                >
                                  {u.belong_id}
                                </TableCell>
                              )}
                              {/* EMAIL --------------------------------------------------------------------------------------- */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  border: "none",
                                  padding: "0.5%",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "1.5vw",
                                  backgroundColor:
                                    currentElements?.indexOf(u) % 2 === 0
                                      ? "#FAFAFA"
                                      : "#D9F1F5",
                                  //

                                  color: "#1B1464",
                                  // paddingLeft: "10%",
                                }}
                              >
                                {editUser === u.user_id ? (
                                  <input
                                    type="email"
                                    defaultValue={u.email}
                                    name="email"
                                    onChange={handleChangeUserData}
                                    style={{
                                      border: "none",
                                      borderBottom: "2px solid grey",
                                      width: "100%",
                                      textAlign: "center",
                                      backgroundColor: "transparent",
                                      fontSize:
                                        window.screen.width > 800
                                          ? "1.1vw"
                                          : "1.5vw",

                                      color: "#1B1464",
                                    }}
                                  />
                                ) : (
                                  <div className={styles.emailToShow}>
                                    {u.email}
                                  </div>
                                )}
                              </TableCell>

                              {/* TIPO --------------------------------------------------------------------------------- */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  border: "none",
                                  padding: "0.5%",
                                  paddingLeft: "1.5%",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "1.5vw",
                                  backgroundColor:
                                    currentElements?.indexOf(u) % 2 === 0
                                      ? "#FAFAFA"
                                      : "#D9F1F5",
                                  //

                                  color: "#1B1464",
                                  // paddingLeft: "5%",
                                }}
                              >
                                {/* TYPE */}
                                {/* si el usuario es admin no se puede editar el type ni el assigned_to */}
                                {u.type !== "Admin" &&
                                editUser === u.user_id ? (
                                  <div className={styles.type}>
                                    <span className={styles.eachSection}>
                                      <select
                                        name="assigned_to"
                                        defaultValue={u.type}
                                        // onChange={handleChangeUserData}
                                        onChange={(e) => {
                                          handleAssignChange(e);
                                          handleChangeUserData(e);
                                          handleChangeType(e);
                                        }}
                                        className={styles.select}
                                        //ciando clickeo en el type traigo los operarios libres
                                        onClick={() =>
                                          handleAssign(
                                            u.user_id,
                                            u.belong_id,
                                            selectedType !== ""
                                              ? selectedType
                                              : u.type
                                          )
                                        }
                                      >
                                        <option value="Calibrator">
                                          {localStorage.getItem("language") ===
                                          "en"
                                            ? "Calibrator"
                                            : "Calibrador"}
                                        </option>
                                        <option value="Admin">Admin</option>
                                        <option value="Operator">
                                          {localStorage.getItem("language") ===
                                          "en"
                                            ? "Operator"
                                            : "Operador"}
                                        </option>
                                        <option value="Shipper">
                                          {localStorage.getItem("language") ===
                                          "en"
                                            ? "Shipper"
                                            : "Transportista"}
                                        </option>
                                      </select>
                                    </span>
                                  </div>
                                ) : (
                                  <div className={styles.typeToShow}>
                                    {u.type === "Operator" &&
                                      localStorage.getItem("language") ===
                                        "es" &&
                                      "Operario"}
                                    {u.type === "Shipper" &&
                                      localStorage.getItem("language") ===
                                        "es" &&
                                      "Transportista"}
                                    {u.type === "Calibrator" &&
                                      localStorage.getItem("language") ===
                                        "es" &&
                                      "Calibrador"}
                                    {u.type === "Admin" &&
                                      localStorage.getItem("language") ===
                                        "es" &&
                                      "Administrador"}
                                    {u.type === "Distributor" &&
                                      localStorage.getItem("language") ===
                                        "es" &&
                                      "Distribuidor"}
                                    {localStorage.getItem("language") ===
                                      "en" && u.type}
                                  </div>
                                )}
                              </TableCell>

                              {/* ASIGNADO ---------------------------------------------------------------------------------- */}

                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  border: "none",
                                  padding: "0.5%",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "1.5vw",
                                  backgroundColor:
                                    currentElements?.indexOf(u) % 2 === 0
                                      ? "#FAFAFA"
                                      : "#D9F1F5",
                                  //

                                  color: "#1B1464",
                                  // paddingLeft: "5%",
                                }}
                              >
                                {/* si el usuario es admin no se puede editar el type ni el assigned_to */}
                                {u.type !== "Admin" &&
                                editUser === u.user_id ? (
                                  registered_company_id && (
                                    <>
                                      <select
                                        name="assigned_to"
                                        defaultValue="default select"
                                        onChange={(e) => {
                                          handleAssignChange(e);
                                          handleChangeUserData(e);
                                          handleChangeType(e);
                                        }}
                                        className={styles.assignUserInput}
                                        onClick={() =>
                                          handleAssign(
                                            u.user_id,
                                            u.belong_id,
                                            selectedType !== ""
                                              ? selectedType
                                              : u.type
                                          )
                                        }
                                      >
                                        <option
                                          disabled
                                          hidden
                                          value="default select"
                                        >
                                          {localStorage.getItem("language") ===
                                          "en"
                                            ? `All`
                                            : `Todos`}
                                        </option>

                                        {companyData?.company?.free_operators
                                          .filter(
                                            (o) => o.type === userAssignedType
                                          )
                                          .map((us) => {
                                            return (
                                              <option
                                                value={us?.operator_id}
                                                key={us.operator_id + 555}
                                              >
                                                {us?.name}({us?.operator_id})
                                              </option>
                                            );
                                          })}
                                        <option value={userAssignedType}>
                                          {localStorage.getItem("language") ===
                                          "en"
                                            ? `All`
                                            : `Todos`}
                                        </option>
                                      </select>
                                      <br />
                                    </>
                                  )
                                ) : (
                                  <>
                                    {u.assigned_to ? (
                                      <div className={styles.assignedToShow}>
                                        {`${
                                          companyDetailData?.company?.operators?.find(
                                            (o) =>
                                              o.operator_id === u.assigned_to
                                          ).name
                                        } #${u.assigned_to.split("-")[1]}`}
                                      </div>
                                    ) : (
                                      <div className={styles.assignedToShow}>
                                        --
                                      </div>
                                    )}
                                  </>
                                )}
                              </TableCell>
                              {/* Status ---------------------------------------------------------------------------------- */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  border: "none",
                                  padding: "0.5%",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "1.5vw",
                                  backgroundColor:
                                    currentElements?.indexOf(u) % 2 === 0
                                      ? "#FAFAFA"
                                      : "#D9F1F5",
                                  //

                                  color: "#1B1464",
                                }}
                              >
                                {editUser === u?.user_id ? (
                                  <select
                                    name="status"
                                    defaultValue={u?.status}
                                    onChange={handleChangeUserData}
                                    className={styles.select}
                                  >
                                    <option value="ACTIVE">
                                      {localStorage.getItem("language") === "en"
                                        ? "Active"
                                        : "Activo"}
                                    </option>
                                    <option value="INACTIVE">
                                      {localStorage.getItem("language") === "en"
                                        ? "Inactive"
                                        : "Inactivo"}
                                    </option>
                                  </select>
                                ) : localStorage.getItem("language") ===
                                  "en" ? (
                                  u?.status.toUpperCase()
                                ) : u?.status === "ACTIVE" ? (
                                  "ACTIVO"
                                ) : (
                                  "INACTIVO"
                                )}
                              </TableCell>
                              {/* EDITAR / ELIMINAR USUARIO --------------------------------------------------------------------------- */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  border: "none",
                                  padding: "0.5%",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "1.5vw",
                                  backgroundColor:
                                    currentElements?.indexOf(u) % 2 === 0
                                      ? "#FAFAFA"
                                      : "#D9F1F5",
                                  //

                                  color: "#1B1464",
                                }}
                              >
                                {editUser !== u.user_id ? (
                                  <div
                                    className={
                                      registered_company_id
                                        ? styles.buttonsContainer
                                        : styles.buttonsContainer2
                                    }
                                  >
                                    <button
                                      onClick={() => handleEditUser(u?.user_id)}
                                      className={styles.editUserButton}
                                    >
                                      <FormattedMessage id="edit_device_button">
                                        Edit
                                      </FormattedMessage>
                                    </button>

                                    {/* el boton de eliminar usuario aparece solo si soy sudo */}
                                    {!registered_company_id && (
                                      <button
                                        onClick={() =>
                                          handleDeleteUser(u?.user_id)
                                        }
                                        className={styles.deleteUserButton}
                                      >
                                        <FormattedMessage id="delete_device_button">
                                          Delete
                                        </FormattedMessage>
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      registered_company_id
                                        ? styles.buttonsContainer
                                        : styles.buttonsContainer2
                                    }
                                  >
                                    <button
                                      onClick={handleSaveData}
                                      className={styles.editUserButton}
                                    >
                                      <FormattedMessage id="save_button">
                                        Save
                                      </FormattedMessage>
                                    </button>
                                    <br />
                                    <button
                                      onClick={handleCancelEditUser}
                                      className={styles.cancelButton}
                                    >
                                      <FormattedMessage id="cancel_button">
                                        Cancel
                                      </FormattedMessage>
                                    </button>
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <div className={styles.noUsers}>No users to show</div>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </TableContainer>
          {/* PAGINACION ------------------------------------------------------------------------------------- */}
          {(typeFilter !== ""
            ? usersData?.filter((u) => u.type === typeFilter)?.length
            : usersData?.length) > 10 ? (
            <div className={styles.pagination}>
              <Pagination
                count={
                  typeFilter !== ""
                    ? Math.ceil(
                        usersData?.filter((u) => u.type === typeFilter)
                          ?.length / rowsPerPage
                      )
                    : Math.ceil(usersData?.length / rowsPerPage)
                }
                // count={Math.ceil(info?.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                size={window.screen.width <= 1000 ? "small" : "large"}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className={styles.noUsersFromStart}>
          <FormattedMessage id="no_users_to_show">
            No users to show
          </FormattedMessage>
        </div>
      )}
    </div>
  );
}
