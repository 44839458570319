import React, { useReducer } from "react";
import { Map, Marker, Popup } from "mapbox-gl";
import { MapContext } from "./MapContext";
import { mapReducer } from "./mapRreducer";
import { useContext } from "react";
import { PlacesContext } from "../places/PlacesContext";

import markerIcon from "./Way_Icono.png";
import styles from "./marker.module.css";

const INITIAL_STATE = {
  isMapReady: false,
  map: undefined,
};

const MapProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mapReducer, INITIAL_STATE);
  const { isLoading, devices } = useContext(PlacesContext);

  
  const setMap = (map) => {
    devices.forEach((dev) => {
      if (dev.active == true) {
        if (dev.geolocation !== null) {
          //MARKER---------------------------
          const el = document.createElement("img");
          el.className = styles.marker;
          el.src = markerIcon;

          const marker = new Marker({
            element: el,
          });

          let lng = dev.geolocation.lng;
          let lat = dev.geolocation.lat;

          //primera ubicacion del marker//
          map.on("load", async () => {
            let point = {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [lng, lat],
                  },
                },
              ],
            };

            //agrego source
            map.addSource(`s ${dev.geo_id}`, {
              type: "geojson",
              data: point,
            });

            //POPUP----------------------------

          //   const myLocationPopup = new Popup().setLngLat([lng, lat]).setHTML(`
          // <h4>Aca estoy, device_id: ${dev.geo_id}</h4>
          // <p>En una galaxia muy muy lejana</p>
          // `);

            marker.setLngLat([lng, lat])/* .setPopup(myLocationPopup) */.addTo(map);

            // llamo a la funcion  q acualiza location cada 30 min

            const updateSource = setInterval(async () => {
              const point = await getLocation(updateSource);
              map.getSource(`s ${dev.geo_id}`).setData(point);
            }, 900000);

            async function getLocation(updateSource) {
              marker.setLngLat([lng, lat])/* .setPopup(myLocationPopup) */.addTo(map);

              // Make a GET request to the API and return the location of the device.
              try {
                // Fly the map to the location.
                // map.flyTo({
                //     center: [lng, lat + 0.01],
                //     speed: 0.5
                // });

                // Return the new location  as GeoJSON.
                return {
                  type: "FeatureCollection",
                  features: [
                    {
                      type: "Feature",
                      geometry: {
                        type: "Point",
                        coordinates: [lng, lat],
                      },
                    },
                  ],
                };
              } catch (err) {
                // If the updateSource interval is defined, clear the interval to stop updating the source.
                if (updateSource) clearInterval(updateSource);
                throw new Error(err);
              }
            }
          });

          dispatch({ type: "setMap", payload: map });
        }
      }
    });
  };
  return (
    <MapContext.Provider
      value={{
        ...state,
        setMap,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
