import React from "react";
import styles from "./alertComments.module.scss";
import Comments from "../../Comments/Comments";
import { useState } from "react";
import { useEffect } from "react";
import { GET_REFRIGERATION, GET_REFRIGERATION_ALERTS } from "../../../queries";
import { useLazyQuery } from "@apollo/client";
import { convertirHoraLocal } from "../../../helpers";
import { CircularProgress } from "@mui/material";

const AlertComments = ({ freezer, alert ,from, to,refetchAlertComments, onActionNewAlertComment,loading}) => {
  const [state, setState] = useState([]);

  const [
    getRefrigeration,
    {
      loading: refrigerationLazyLoading,
      error: refrigerationLazyError,
      data: refrigerationLazy,
    },
  ] = useLazyQuery(GET_REFRIGERATION_ALERTS, { fetchPolicy: "network-only" });
  

  

  if (loading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  } else  {
   
    return (
    
      <div className={styles.container}>
         <Comments
          alertComments={true}
          alert={alert}
          freezer={freezer}
          refetchAlertComments={refetchAlertComments}
          onActionNewAlertComment={onActionNewAlertComment}
        />
        <div className={styles.oldCommentsContainer}>
          <div className={styles.eachAlertContainer}>
            {alert?.comments ? (
              alert?.comments?.map((al, i) => (
                <div className={styles.eachComment} key={i + 0.04}>
                  <div className={styles.text}>{al.text}</div>
                  <div className={styles.date}>
                    
                    {al.author} {convertirHoraLocal(al.date, freezer.gmt)}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noText}>{"--"}</div>
            )}
          </div>
        </div>
       
      </div>
    );
  }
};

export default AlertComments;
