import { Navigate } from "react-router-dom";
import { wsLink } from "../../apolloClient";

export const isTokenExpired = () => {
  const expiration = localStorage.getItem("expiration");
  const now = new Date().getTime();
  
  console.log('Expiration time:', new Date(parseInt(expiration)));
  console.log('Current time:',  new Date());
  
  if (!expiration) return true;
  
  const isExpired = now > Number(expiration); // Asegúrate de que expiration es un número
  console.log('Is token expired?', isExpired);
  
  return isExpired;
};

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const tokenExpired = isTokenExpired();
  
  console.log('Token expired:', tokenExpired);
  
  if (tokenExpired) {
    console.log('Token is expired, closing WebSocket connection...');
    // Cierra la conexión WebSocket solo si está abierta
    if (wsLink?.subscriptionClient) {
      wsLink.subscriptionClient.close();
    }
  }
  
  console.log('WebSocket status:', wsLink.subscriptionClient?.status);

  return !tokenExpired && token?.length > 0 ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
