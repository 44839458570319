import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./MyDevices.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_DEVICES,
  GET_COMPANIES,
  GET_COMPANY_DETAIL,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  GET_SHIPMENTS,
  GET_SHIPMENTS_PAG2,
} from "../../../queries";

import { devicesList, devicesTempRanges, devicesSensors } from "../../../const";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DevicesComments from "../DevicesComments/DevicesComments";
import { Checkbox, CircularProgress, Tooltip } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { AiOutlineDownload } from "react-icons/ai";
import { FcInfo } from "react-icons/fc";
import CustomButton from "../../Buttons/CustomButton";
import DeviceCalibrationModal from "./deviceCalibrationModal/DeviceCalibrationModal";
import { set } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { fetchCalibrationReport } from "../../../helpers";

// ESTILOS PREDETERMINADOS ---------------------------------------------------------------------------------
//estilo el modal de los comments de devices
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 300,
  bgcolor: "white",
  border: "3px solid #00ABC8",
  borderRadius: 10, // Se ha ajustado el valor del borderRadius
  fontFamily: "'Quattrocento Sans', sans-serif",
  boxShadow: 24,
  pt: 3, // Se ha ajustado el padding-top
  px: 4,
  pb: 4, // Se ha ajustado el padding-bottom
};

export default function MyDevices({ company_id, level }) {
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: "18px", // Tamaño de fuente deseado
    },
  }))(Tooltip);
  // ESTADOS LOCALES ----------------------------------------------------------------------------
  //estados para paginación
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shipsInTransit, setShipsInTransit] = useState({});

  const [page_ship, setPage_ship] = useState(1);
  const [last_calibration_info, setLast_calibration_info] = useState(false);
  //estado para orden de bateria
  const [orderBattery, setOrderBattery] = useState("desc");

  //estado para el input de busqueda por qr
  const [QRInput, setQRInput] = useState("");

  //estado para editar device (SUDO)
  const [editDevice, setEditDevice] = useState("");

  //estado para guardar la data de los input de edit device
  const [actualDeviceData, setActualDeviceData] = useState("");

  //estado para el modal de comentarios
  const [comments, setComments] = useState(false);

  //estado para modal de calibracion
  const [calibrationModal, setCalibrationModal] = useState(false);
  const [deviceToCalibrate, setDeviceToCalibrate] = useState({});

  //estado para guardar el id del device para ver sus comentarios
  const [devToSeeComments, setDevToSeeComments] = useState("");

  //estado para guardar los items seleccionados
  const [checkedItems, setCheckedItems] = useState([]);
  //QUERIES / MUTATIONS ------------------------------------------------------------------------

  //query para traer shipments in transit
  let ships_in_transit;

  const { data: contentData } = useQuery(
    GET_SHIPMENTS_PAG2,

    {
      variables: {
        in_transit: true,
        company_id: company_id,
        per_page: 500,
        page: 1,
      },
    }
  );

  ships_in_transit = contentData?.shipments?.selectedItems;

  //lazy query para traer todas las compañias
  const [
    getAllCompanies,
    {
      data: allCompaniesData,
      // loading: allCompaniesLoading,
      error: allCompaniesError,
    },
  ] = useLazyQuery(GET_COMPANIES);

  //mutation para updatear devices
  const [
    updateDevice,
    {
      loading: updateDeviceLoading,
      error: updateDeviceError,
      // data: updateDeviceData,
    },
  ] = useMutation(UPDATE_DEVICE);

  //lazy query para traer devices
  const [
    getDevices,
    {
      // loading: devicesLoading,
      error: devicesError,
      data: devicesData,
    },
  ] = useLazyQuery(GET_DEVICES, { fetchPolicy: "network-only" });

  //mutation para eliminar devices
  const [
    deleteDevice,
    {
      // loading: deleteDeviceLoading,
      error: deleteDeviceError,
      // data: deleteDeviceData,
    },
  ] = useMutation(DELETE_DEVICE);

  //CUANDO SE INICIA EL COMPONENTE SE OBTIENE DATO DE TODAS LAS COMPAÑIAS (SUDO) -----------------------------------------------------------
  useEffect(() => {
    if (!company_id) {
      console.log("getting data");
      getAllCompanies();
    }
    const fetchDevices = async () => {
      console.log("getting data");
      console.log(devicesData);
      await getDevices({
        variables: {
          assigned_to: company_id ? company_id : "",
        },
      });
    };
    fetchDevices();
  }, []);

  //CUANDO BUSCO EN LA BARRA DE BUSQUEDA QUE ME LLEVE A LA PAG 1
  useEffect(() => {
    setPage(1);
  }, [QRInput]);

  // ME GUARDO LA INFO EN UNA VARIABLE COPIA -------------------------------------------------------
  let info = devicesData?.devices;

  // HANDLERS --------------------------------------------------------------------------
  //handler de la paginacion
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  //handler del input de busqueda por qr
  const handlerQRChange = (e) => {
    setQRInput(e.target.value);
  };

  if (QRInput !== "") {
    let infoCopy = info;
    info = infoCopy?.filter((d) =>
      d?.qr?.toLowerCase().includes(QRInput.toLowerCase())
    );
  }

  //handler del edit devices (SUDO)
  const handleEditDevices = (id, company_id, sensors) => {
    setEditDevice(id);
    setActualDeviceData({
      ...actualDeviceData,
      device_id: id,
      sensors,
    });
  };

  //handler del save edit devices (SUDO)
  const handleSaveEditDevices = async (device_id, data) => {
    if (device_id) {
      try {
        await updateDevice({
          variables: {
            device_id: device_id,
            temperature_range: data.temperature_range,
          },
          refetchQueries: [
            {
              query: GET_DEVICES,
              variables: {
                assigned_to: company_id ? company_id : "",
              },
            },
          ],
        });
        // window.location.reload("/");
      } catch (error) {
        console.log(error);
      }
    } else {
      setEditDevice("");
      if (!actualDeviceData?.sensors[0])
        return alert(
          localStorage.getItem("language") === "en"
            ? "Please mark at least one sensor type"
            : "Por favor marca al menos un tipo de sensor"
        );
      try {
        if (
          window.confirm(
            localStorage.getItem("language") === "es"
              ? "¿Estás seguro que quieres guardar cambios?"
              : "Are you sure you want to confirm changes?"
          )
        ) {
          await updateDevice({
            variables: actualDeviceData,
          });
          window.location.reload("/");
          console.log("mando esta data");
          console.log(actualDeviceData);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //handler del cancel edit devices (SUDO)
  const handleCancelEditDevices = () => {
    setEditDevice("");
    setActualDeviceData("");
  };

  //handler del on change para cuando edito devices (SUDO)
  const handleChangeDeviceData = async (e, device_id) => {
    if (e.target.name === "sensors") {
      setActualDeviceData({
        ...actualDeviceData,
        sensors: !e.target.checked
          ? actualDeviceData.sensors.filter((s) => s !== e.target.value)
          : [...actualDeviceData.sensors, e.target.value],
      });
    } else if (e.target.name === "assigned_to") {
      setActualDeviceData({
        ...actualDeviceData,
        assigned_to: e.target.value,
      });
    } else if (e.target.name === "was_serviced") {
      setActualDeviceData({
        ...actualDeviceData,
        was_serviced: e.target.checked,
      });
    } else if (e.target.name === "battery_level") {
      setActualDeviceData({
        ...actualDeviceData,
        battery_level: parseFloat(e.target.value),
      });
    } else if (e.target.name === "temperature_range") {
      let data = { temperature_range: e.target.value };

      //realiza la mutation para modificar devices
      await handleSaveEditDevices(device_id, data);
    } else {
      setActualDeviceData({
        ...actualDeviceData,
        [e.target.name]: e.target.value,
      });
    }
  };

  //handler para eliminar device (SUDO)
  const handleDeleteDevice = async (id) => {
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que deseas eliminar el dispositivo?"
            : "Are you sure you want to delete device?"
        )
      ) {
        await deleteDevice({
          variables: {
            device_id: id,
          },
        });
        window.location.reload("/");
      }
    } catch (error) {
      console.log("error en la mutation de deleteDevice", error);
    }
  };

  //handler del modal de comments
  const handleCommentsModal = (id) => {
    setComments(true);
    setDevToSeeComments(id);
  };

  //handler del close del modal de comments
  const handleOnCloseComments = () => {
    setComments(false);
  };

  //PARA ORDENAR POR NIVEL DE BATERIA (ASC O DESC)
  if (devicesData?.devices[0]) {
    if (orderBattery === "asc") {
      info = info.slice().sort(function (a, b) {
        if (a.battery_level > b.battery_level) {
          return 1;
        }
        if (a.battery_level < b.battery_level) {
          return -1;
        }
        return 0;
      });
    } else {
      info = info.slice().sort(function (a, b) {
        if (b.battery_level > a.battery_level) {
          return 1;
        }
        if (b.battery_level < a.battery_level) {
          return -1;
        }
        return 0;
      });
    }
  }

  //Funcion bajar QR

  const handleCheckboxChange = (event, qrValue) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, qrValue]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== qrValue));
    }
  };
  const printQRCode = async () => {
    // if (checkedItems.length === 0) {
    //   toast.error(
    //     localStorage.getItem("language") === "en"
    //       ? "Please select at least one QR code to download"
    //       : "Por favor selecciona al menos un código QR para descargar"
    //   );
    //   return;
    // }

    const pdf = new jsPDF();
    const qrSize = 50; // Ajusta el tamaño según sea necesario
    const padding = 10;
    const extraHeight = 20; // Espacio adicional para el número
    const qrPerRow = Math.floor(
      (pdf.internal.pageSize.getWidth() - padding) / (qrSize + padding)
    );
    //const qrRows = Math.ceil(checkedItems.length / qrPerRow);

    const drawQR = async (qr, x, y) => {
      const qrCanvas = document.createElement("canvas");
     QRCode.toCanvas(qrCanvas, qr, { width: qrSize, height: qrSize });

      const qrDataUrl = qrCanvas.toDataURL("image/png");

      pdf.addImage(qrDataUrl, "PNG", x, y, qrSize, qrSize);

      const fontSize = 30; // Tamaño de la fuente para el número
      pdf.setFontSize(fontSize);

      // Centrar el texto debajo del QR
      pdf.text(qr, x + qrSize / 2, y + qrSize + padding, { align: "center" });
    };

    for (let i = 0; i < checkedItems.length; i++) {
      const qr = checkedItems[i];
      const x = padding + (i % qrPerRow) * (qrSize + padding);
      const y =
        padding + Math.floor(i / qrPerRow) * (qrSize + extraHeight + padding);

      if (
        y + qrSize + extraHeight + padding >
        pdf.internal.pageSize.getHeight()
      ) {
        pdf.addPage();
      }

      await drawQR(qr, x, y);
    }

    pdf.save("qrs.pdf");
  };

  //funciones de calibracion----------------------------------------------------------------
  const handleOpenCalModal = async (e, device) => {
    console.log(device);
    e.preventDefault();
    setCalibrationModal(true);
    setDeviceToCalibrate(device);
  };
  const handleDownload = async (e, device) => {
    await fetchCalibrationReport(device);
  };
  const handleCloseCalModal = () => {
    setCalibrationModal(false);
    setLast_calibration_info(false);
  };
  //PARA PAGINAR -------------------------------------------------------------------------
  let indexOfLastElement = page * rowsPerPage;
  let indexOfFirstElement = indexOfLastElement - rowsPerPage;
  let currentElements = info?.slice(indexOfFirstElement, indexOfLastElement);

  //MANEJO DE ERRORES -----------------------------------------------------------------------
  if (devicesError) console.log("error en el get devices", devicesError);
  if (allCompaniesError)
    console.log("error en el get all companies", allCompaniesError);
  if (updateDeviceError)
    console.log("error en el update de devices", updateDeviceError);
  if (deleteDeviceError)
    console.log("error en el delete del device", deleteDeviceError);

  //LOADINGS
  if (updateDeviceLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.devicesContainer}>
      {devicesData?.devices?.[0] ? (
        <>
          <div className={styles.inputContainer}>
            <label className={styles.searchQrLabel}>
              <BsSearch size={window.screen.width > 800 ? "1vw" : "3vw"} />
            </label>
            <input
              type="text"
              onChange={handlerQRChange}
              className={styles.searchQrInput}
              placeholder="QR:"
            />
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* HEAD ID (SUDO) -------------------------------------------------------------------------------------- */}
                  {!company_id && (
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                      }}
                    >
                      ID
                    </TableCell>
                  )}
                  {/* HEAD logo descarga qr -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                      color: "#00ABC8",
                      //  fontFamily: "'Quattrocento Sans', sans-serif",
                      // border:"solid red 1px",
                      position: "relative",
                      left: "3px",
                    }}
                  >
                     <CustomTooltip title={<FormattedMessage id="descaga_qrs"/>}>
                     <span>
                        <button
                      onClick={printQRCode}
                      className={styles.qrDownloadButton}
                      disabled={checkedItems.length === 0 ? true : false}
                      border="none"
                    >
                      <AiOutlineDownload
                        size="1.5vw"
                        color={
                          checkedItems.length !== 0 ? "#1B1464" : "#999999"
                        }
                      />
                    </button>
                     </span>
                  
                    </CustomTooltip>
                  </TableCell>
                  {/* HEAD QR -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                      color: "#00ABC8",
                    }}
                  >
                    <FormattedMessage id="qr_code_may">
                      QR CODE
                    </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD NIVEL DE BATERIA -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                      color: "#00ABC8",
                    }}
                  >
                    <div className={styles.batteryLevelLabel}>
                      <FormattedMessage id="battery_level_may">
                        BATTERY LEVEL{" "}
                      </FormattedMessage>{" "}
                      {orderBattery === "asc" ? (
                        <button
                          onClick={() => setOrderBattery("desc")}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "#00ABC8",
                          }}
                        >
                          <AiOutlineArrowDown
                            size={window.screen.width > 800 ? "1vw" : "3vw"}
                          />
                        </button>
                      ) : (
                        <button
                          onClick={() => setOrderBattery("asc")}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "#00ABC8",
                          }}
                        >
                          <AiOutlineArrowUp
                            size={window.screen.width > 800 ? "1vw" : "3vw"}
                          />
                        </button>
                      )}
                    </div>
                  </TableCell>
                  {/* HEAD ASIGNADO A (SUDO) -------------------------------------------------------------------------------------- */}
                  {!company_id && (
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                      }}
                    >
                      <FormattedMessage id="assigned_to_may">
                        ASSIGNED TO
                      </FormattedMessage>{" "}
                    </TableCell>
                  )}
                  {/* HEAD ULT SERVICIO -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                      color: "#00ABC8",
                    }}
                  >
                    <FormattedMessage id="last_use">LAST USE </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD ULT LOCATION -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                      color: "#00ABC8",
                    }}
                  >
                    <FormattedMessage id="last_location_may">
                      LAST LOCATION{" "}
                    </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD TIPO/TEMP RANGE -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                      color: "#00ABC8",
                    }}
                  >
                    <FormattedMessage id="type_config_may">
                      TYPE
                    </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD FORMATO -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                      color: "#00ABC8",
                    }}
                  >
                    <FormattedMessage id="format_may">FORMAT </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD ENVIO ASOCIADOS---------------------------------------- */}
                  {company_id && (
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                      }}
                    >
                      <FormattedMessage id="associated_shipment_may">
                        ASSOCIATED SHIPMENT{" "}
                      </FormattedMessage>{" "}
                    </TableCell>
                  )}

                  {/* HEAD CALIBRATE BUTTON---------------------------------------- */}
                  {company_id && (
                    <TableCell
                      size="medium"
                      align="center"
                      sx={{
                        fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                        color: "#00ABC8",
                      }}
                    ></TableCell>
                  )}
                  {/* -------------------------------------------------------- */}
                  {!company_id && (
                    // HEAD SENSORES (SUDO)-------------------------------------------------------------------------------------
                    <>
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",
                        }}
                      >
                        <FormattedMessage id="sensors_may">
                          SENSORS
                        </FormattedMessage>{" "}
                      </TableCell>
                      {/* HEAD VERSION (SUDO) -------------------------------------------------------------------------------------- */}
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",
                        }}
                      >
                        <FormattedMessage id="version_may">
                          VERSION
                        </FormattedMessage>{" "}
                      </TableCell>
                      {/* HEAD ESTADO (SUDO) -------------------------------------------------------------------------------------- */}
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",
                        }}
                      >
                        <FormattedMessage id="status_tab_title">
                          Status
                        </FormattedMessage>{" "}
                      </TableCell>
                      {/* HEAD COMMENTS ------------------------------------------------------------------------ */}
                      <TableCell
                        size="medium"
                        align="center"
                        sx={{
                          fontSize:
                            window.screen.width > 800 ? "1.1vw" : "1.5vw",

                          color: "#00ABC8",
                        }}
                      >
                        <FormattedMessage id="comments">
                          Comments
                        </FormattedMessage>{" "}
                      </TableCell>
                    </>
                  )}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                      color: "#00ABC8",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentElements?.map((d) => {
                  return (
                    <TableRow key={d?.qr}>
                      {/* ID (SUDO) --------------------------------------------------------------------------------------------------- */}
                      {!company_id && (
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(d) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",

                            color: "#1B1464",
                          }}
                        >
                          {d.device_id.split("-")[1]}
                        </TableCell>
                      )}
                      {/* CHECKBOX QR DESCARGA --------------------------------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(d) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          //

                          color: "#1B1464",
                        }}
                      >
                        <Checkbox
                          onChange={(e) => handleCheckboxChange(e, d.qr)}
                        />
                      </TableCell>
                      {/* QR --------------------------------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(d) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          //

                          color: "#1B1464",
                        }}
                      >
                        {editDevice === d.device_id ? (
                          <input
                            type="text"
                            defaultValue={d.qr}
                            name="qr"
                            onChange={handleChangeDeviceData}
                            style={{
                              border: "none",
                              borderBottom: "0.15vw solid grey",
                              width: "6vw",
                              textAlign: "center",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",

                              color: "#1B1464",
                              background: "transparent",
                            }}
                          />
                        ) : (
                          <div className={styles.qrContainer}>
                            <div className={styles.qr}>{d.qr}</div>
                          </div>
                        )}
                      </TableCell>

                      {/* NIVEL DE BATERIA --------------------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(d) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          //

                          color:
                            parseInt(d.battery_level) <= 15
                              ? "#D60707"
                              : "#33B27F",
                        }}
                      >
                        {editDevice === d.device_id ? (
                          <input
                            type="number"
                            defaultValue={d.battery_level}
                            name="battery_level"
                            max="100"
                            min="0"
                            onChange={handleChangeDeviceData}
                            style={{
                              border: "none",
                              borderBottom: "0.15vw solid grey",
                              width: "6vw",
                              textAlign: "center",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",

                              color: "#1B1464",
                              background: "transparent",
                            }}
                          />
                        ) : d.battery_level ? (
                          d.battery_level + "%"
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      {/* ASIGNADO A (SUDO) --------------------------------------------------------------------------------- */}
                      {!company_id && (
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(d) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //

                            color: "#1B1464",
                          }}
                        >
                          {editDevice === d.device_id ? (
                            <select
                              onChange={handleChangeDeviceData}
                              defaultValue="default select"
                              name="assigned_to"
                              className={styles.select}
                            >
                              <>
                                <option
                                  disabled
                                  hidden
                                  value="default select"
                                  style={{ color: "#1B1464" }}
                                >
                                  {d.assigned_to
                                    ? d.assigned_to
                                    : "Select a company"}
                                </option>
                                {allCompaniesData?.companies?.map((c) => {
                                  return (
                                    <option
                                      value={c.company_id}
                                      key={c.company_id}
                                      style={{
                                        color: "#1B1464",
                                      }}
                                    >
                                      {c.company_id}
                                    </option>
                                  );
                                })}
                              </>
                            </select>
                          ) : d.assigned_to ? (
                            d.assigned_to
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      )}
                      {/* LAST USE -------------------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(d) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          //

                          color: "#1B1464",
                        }}
                      >
                        {d.last_use
                          ? d.last_use.split("T")[0] +
                            " " +
                            d.last_use.split("T")[1].split(":")[0] +
                            ":" +
                            d.last_use.split("T")[1].split(":")[1]
                          : "-"}
                        <br />
                        {editDevice === d.device_id && (
                          <label>
                            <input
                              name="was_serviced"
                              type="checkbox"
                              defaultChecked={false}
                              onChange={handleChangeDeviceData}
                              style={{
                                color: "#1B1464",
                              }}
                            />
                            <FormattedMessage id="was_serviced">
                              Was serviced
                            </FormattedMessage>
                          </label>
                        )}
                      </TableCell>
                      {/* ULT LOCATION --------------------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(d) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          //

                          color: "#1B1464",
                        }}
                      >
                        {d.last_location ? d.last_location : "-"}
                      </TableCell>
                      {/* TIPO/TEMP RANGE ------------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(d) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          //

                          color: "#1B1464",
                        }}
                      >
                        {editDevice === d.device_id ? (
                          <select
                            onChange={handleChangeDeviceData}
                            defaultValue="default select"
                            name="temperature_range"
                            className={styles.select}
                          >
                            <>
                              <option
                                disabled
                                hidden
                                value="default select"
                                style={{ color: "#1B1464" }}
                              >
                                {d.temperature_range.name
                                  ? d.temperature_range.name
                                  : "Select a temperature range"}
                              </option>
                              {devicesTempRanges?.map((t) => {
                                return (
                                  <option
                                    value={t}
                                    key={t}
                                    style={{ color: "#1B1464" }}
                                  >
                                    {t}
                                  </option>
                                );
                              })}
                            </>
                          </select>
                        ) : // si es level 2 pude editar el rango de temperatura con un dropdown
                        level === 2 ? (
                          <select
                            onChange={(e) =>
                              handleChangeDeviceData(e, d.device_id)
                            }
                            defaultValue="default select"
                            name="temperature_range"
                            className={styles.select}
                          >
                            <>
                              <option
                                disabled
                                hidden
                                value="default select"
                                style={{ color: "#1B1464" }}
                              >
                                {d?.temperature_range?.name
                                  ? d?.temperature_range?.name
                                  : "Select a temperature range"}
                              </option>
                              {devicesTempRanges?.map((t) => {
                                return (
                                  <option
                                    value={t}
                                    key={t}
                                    style={{ color: "#1B1464" }}
                                  >
                                    {t}
                                  </option>
                                );
                              })}
                            </>
                          </select>
                        ) : d?.temperature_range?.name ? (
                          d?.temperature_range?.name
                        ) : (
                          "--"
                        )}
                      </TableCell>

                      {/*                        
                          d?.temperature_range ? (
                          d?.temperature_range?.name
                        ) : (
                          "--"
                        )} 
                      </TableCell>*/}
                      {/* FORMATO ------------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(d) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          //

                          color: "#1B1464",
                        }}
                      >
                        {editDevice === d.device_id ? (
                          <select
                            onChange={handleChangeDeviceData}
                            defaultValue="default select"
                            name="device_format"
                            className={styles.select}
                          >
                            <>
                              <option
                                disabled
                                hidden
                                value="default select"
                                style={{ color: "#1B1464" }}
                              >
                                {d.device_format}
                              </option>
                              {devicesList?.map((d) => {
                                return (
                                  <option
                                    value={devicesList?.indexOf(d)}
                                    key={d}
                                    style={{
                                      color: "#1B1464",
                                    }}
                                  >
                                    {d}
                                  </option>
                                );
                              })}
                            </>
                          </select>
                        ) : d.device_format ? (
                          d.device_format
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      {/* ENVIOSS ASOCIADOS --------------------------------------------- */}

                      {company_id && (
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(d) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //

                            color: "#1B1464",
                          }}
                        >
                          {ships_in_transit?.find((ship) => ship?.qr === d?.qr)
                            ? "#" +
                              ships_in_transit
                                .find((ship) => ship?.qr === d?.qr)
                                ?.shipment_id.split("-")[1]
                            : "--"}
                        </TableCell>
                      )}

                      {/*CALIBRATE BUTTON --------------------------------------------- */}

                      {company_id && (
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            fontSize:
                              window.screen.width > 800 ? "1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(d) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",
                            //

                            color: "#1B1464",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                             // border:"solid red 1px"
                            }}
                          >
                            <CustomButton
                              type={"calibrate"}
                              calibrateDeviceParams={{
                                text: (
                                  <FormattedMessage id={"calibrate"}>
                                    calibrate
                                  </FormattedMessage>
                                ),
                                action: (e) => handleOpenCalModal(e, d),
                              }}
                            />
                            {/* si hay calibraciones muestro el icono, sino lo hago invisible (meto un div para mantener el centrado y aleneado de los botones)*/}
                            {d?.calibrations?.length > 0 ? (
                              <CustomTooltip title={<FormattedMessage id="descaga_pdf_cal"/>}>
                             
                                <button
                                  onClick={(e) => {
                                    handleDownload(e, d);
                                    setLast_calibration_info(true);
                                  }}
                                  className={styles.qrDownloadButton}
                                  border="none"
                                >
                                  <AiOutlineDownload
                                    size="1.5vw"
                                    color={"#1B1464"}
                                  />
                                  
                                </button>
                              </CustomTooltip>
                            ) : (
                              <div
                                style={{
                                  width: "1.5vw",
                                  height: "1.2vw",
                                  color: "transparent",
                                  border:"solid transparent 1px"
                                }}
                              />
                            )}
                          </div>
                        </TableCell>
                      )}

                      {/* --------------------------------------------------------------- */}
                      {!company_id && (
                        <>
                          {/* SENSORES (SUDO) -------------------------------------------------------------------------------------- */}
                          <TableCell
                            align="center"
                            size="small"
                            sx={{
                              border: "none",
                              fontSize:
                                window.screen.width > 800 ? "1.1vw" : "1.5vw",
                              backgroundColor:
                                currentElements?.indexOf(d) % 2 === 0
                                  ? "#FAFAFA"
                                  : "#D9F1F5",

                              color: "#1B1464",
                            }}
                          >
                            {editDevice === d.device_id
                              ? devicesSensors.map((s) => {
                                  return (
                                    <div
                                      className={styles.sensorsInputContainer}
                                      key={s}
                                    >
                                      <label>
                                        <input
                                          type="checkbox"
                                          value={s}
                                          name="sensors"
                                          onChange={
                                            editDevice === d.device_id
                                              ? handleChangeDeviceData
                                              : null
                                          }
                                          defaultChecked={
                                            d?.sensors?.includes(s)
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            editDevice === d.device_id
                                              ? false
                                              : true
                                          }
                                          style={{
                                            color: "#1B1464",
                                            background: "transparent",
                                          }}
                                        />
                                        {s}
                                      </label>
                                    </div>
                                  );
                                })
                              : d?.sensors.map((s) => {
                                  return (
                                    <div
                                      className={styles.sensorsInputContainer}
                                      key={s}
                                    >
                                      {s}
                                    </div>
                                  );
                                })}
                          </TableCell>
                          {/* VERSION (SUDO) -------------------------------------------------------------------------------------- */}
                          <TableCell
                            align="center"
                            size="small"
                            sx={{
                              border: "none",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",
                              backgroundColor:
                                currentElements?.indexOf(d) % 2 === 0
                                  ? "#FAFAFA"
                                  : "#D9F1F5",
                              //

                              color: "#1B1464",
                            }}
                          >
                            {editDevice === d.device_id ? (
                              <input
                                type="text"
                                defaultValue={d.version}
                                name="version"
                                onChange={handleChangeDeviceData}
                                style={{
                                  border: "none",
                                  borderBottom: "0.15vw solid grey",
                                  width: "6vw",
                                  textAlign: "center",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "1.5vw",

                                  color: "#1B1464",
                                  background: "transparent",
                                }}
                              />
                            ) : (
                              d.version
                            )}
                          </TableCell>
                          {/* ESTADO (SUDO) -------------------------------------------------------------------------------------- */}
                          <TableCell
                            align="center"
                            size="small"
                            sx={{
                              border: "none",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",
                              backgroundColor:
                                currentElements?.indexOf(d) % 2 === 0
                                  ? "#FAFAFA"
                                  : "#D9F1F5",

                              color: "#1B1464",
                            }}
                          >
                            {editDevice === d.device_id ? (
                              <select
                                onChange={handleChangeDeviceData}
                                defaultValue="default select"
                                name="status"
                                className={styles.select}
                              >
                                <option
                                  disabled
                                  hidden
                                  value="default select"
                                  style={{ color: "#1B1464" }}
                                >
                                  {d.status === "ACTIVE"
                                    ? localStorage.getItem("language") === "en"
                                      ? "Active"
                                      : "Activo"
                                    : localStorage.getItem("language") === "en"
                                    ? "Inactive"
                                    : "Inactivo"}
                                </option>
                                <option
                                  value={"ACTIVE"}
                                  style={{ color: "#1B1464" }}
                                >
                                  {localStorage.getItem("language") === "en"
                                    ? "Active"
                                    : "Activo"}
                                </option>
                                <option
                                  value={"INACTIVE"}
                                  style={{ color: "#1B1464" }}
                                >
                                  {localStorage.getItem("language") === "en"
                                    ? "Inactive"
                                    : "Inactivo"}
                                </option>
                              </select>
                            ) : (
                              <>
                                {d.status === "ACTIVE" && (
                                  <FormattedMessage id="active">
                                    Active
                                  </FormattedMessage>
                                )}
                                {d.status === "INACTIVE" && (
                                  <FormattedMessage id="inactive">
                                    Inactive
                                  </FormattedMessage>
                                )}
                              </>
                            )}
                          </TableCell>
                          {/* COMENTARIOS --------------------------------------------------------------------------------- */}
                          <TableCell
                            align="center"
                            size="small"
                            sx={{
                              border: "none",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",
                              backgroundColor:
                                currentElements?.indexOf(d) % 2 === 0
                                  ? "#FAFAFA"
                                  : "#D9F1F5",

                              color: "#1B1464",
                            }}
                          >
                            <button
                              className={styles.contentButton}
                              onClick={() => handleCommentsModal(d.device_id)}
                            >
                              <FormattedMessage id="content_button">
                                OPEN
                              </FormattedMessage>
                            </button>
                          </TableCell>
                          {/* EDITAR/BORRAR DEVICE (SUDO) --------------------------------------------------------------------- */}
                          <TableCell
                            align="center"
                            size="small"
                            sx={{
                              border: "none",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",
                              backgroundColor:
                                currentElements?.indexOf(d) % 2 === 0
                                  ? "#FAFAFA"
                                  : "#D9F1F5",

                              color: "#1B1464",
                            }}
                          >
                            {editDevice !== d.device_id ? (
                              <>
                                <button
                                  onClick={() =>
                                    handleEditDevices(
                                      d.device_id,
                                      d.assigned_to,
                                      d.sensors
                                    )
                                  }
                                  className={styles.editDevicesButton}
                                >
                                  <FormattedMessage id="edit_device_button">
                                    Edit
                                  </FormattedMessage>
                                </button>
                                <br />
                                <button
                                  onClick={() =>
                                    handleDeleteDevice(d.device_id)
                                  }
                                  className={styles.deleteDevicesButton}
                                >
                                  <FormattedMessage id="delete_device_button">
                                    Delete
                                  </FormattedMessage>
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={handleSaveEditDevices}
                                  className={styles.editDevicesButton}
                                >
                                  <FormattedMessage id="save_button">
                                    Save
                                  </FormattedMessage>
                                </button>
                                <br />
                                <button
                                  onClick={handleCancelEditDevices}
                                  className={styles.deleteDevicesButton}
                                >
                                  <FormattedMessage id="cancel_button">
                                    Cancel
                                  </FormattedMessage>
                                </button>
                              </>
                            )}
                          </TableCell>
                        </>
                      )}
                      {company_id && (
                        <TableCell
                          align="center"
                          size="small"
                          sx={{
                            border: "none",
                            padding: "0.5%",
                            fontSize:
                              window.screen.width > 800 ? "1vw" : "1.5vw",
                            backgroundColor:
                              currentElements?.indexOf(d) % 2 === 0
                                ? "#FAFAFA"
                                : "#D9F1F5",

                            color: "#1B1464",
                          }}
                        ></TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* PAGINACION ---------------------------------------------------------------------------------------- */}
          {/* {devicesData?.devices?.length > 10 ? ( */}
          {info?.length > 10 ? (
            <div className={styles.pagination}>
              <Pagination
                count={Math.ceil(info?.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                size={window.screen.width <= 1000 ? "small" : "large"}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className={styles.noDevices}>
          <FormattedMessage id="no_devices">
            No devices assigned
          </FormattedMessage>{" "}
        </div>
      )}
      {/* MODAL DE COMENTARIOS DE DEVICES ------------------------------------------------------------ */}
      <Modal open={comments} onClose={handleOnCloseComments}>
        <Box
          sx={{
            ...modalStyle,
            height: 500,
            width: "20%",
            overflowY: "auto",
          }}
        >
          <DevicesComments device_id={devToSeeComments} />
        </Box>
      </Modal>

      {/* MODAL DE CALIBRACION DE DEVICES ------------------------------------------------------------ */}
      <Modal open={calibrationModal} onClose={handleCloseCalModal}>
        <Box
          sx={{
            ...modalStyle,
            height: !last_calibration_info ? "70%" : 250,
            width: !last_calibration_info ? "25%" : "20%",
            // overflow: "auto",
          }}
        >
          <DeviceCalibrationModal
            deviceToCalibrate={deviceToCalibrate}
            company_id={company_id}
            onClose={handleCloseCalModal}
            last_calibration_info={last_calibration_info}
          />
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
