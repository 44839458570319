import React, { useState } from "react";
import styles from "./CreateDistributor.module.scss";
import { FormattedMessage } from "react-intl";
import { Dialog } from "@mui/material";
import { CREATE_DISTRIBUTOR } from "../../../queries";
import { useMutation } from "@apollo/client";

export default function CreateDistributor() {
  // ESTADOS LOCALES ------------------------------------------------------------------------------------
  //estado para la confirmación del create device
  const [confirmMessage, setConfirmMessage] = useState(false);

  //estado para guardarme la data que va ingresando a los input
  const [inputsData, setInputsData] = useState({
    name: "",
    location: "",
    contact_info: "",
    region: "",
  });

  //QUERIES/MUTATIONS -----------------------------------------------------------------------------------
  //mutation para crear dispositivo
  const [
    createDistributor,
    {
      // data: createdDistributor,
      // loading: createdDistributorLoading,
      error: createdDistributorError,
    },
  ] = useMutation(CREATE_DISTRIBUTOR);

  // HANDLERS -----------------------------------------------------------------------------------------------
  //handler del onChange de los input
  const handleOnChangeInputs = (e) => {
    setInputsData({
      ...inputsData,
      [e.target.name]: e.target.value,
    });
  };

  //handler del confirm changes
  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      if (
        !inputsData.name ||
        !inputsData.location ||
        !inputsData.contact_info ||
        !inputsData.region
      ) {
        return alert(
          localStorage.getItem("language") === "en"
            ? "Please complete all the data spaces"
            : "Por favor, completa todos los campos"
        );
      }
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        // aca va la llamada al create device
        await createDistributor({
          variables: inputsData,
        });
        alert("Distributor created");
        window.location.reload();
      }
    } catch (error) {
      console.log("hubo un error en el create distributor", error);
    }
  };

  //   MANEJO DE ERRORES ------------------------------------------------------------------------------
  if (createdDistributorError)
    console.log("error en el distribuidor creado", createdDistributorError);

  return (
    <div className={styles.container}>
      <h2
        className={styles.createTitle}
      >
        {" "}
        <FormattedMessage id="create_distributor">
          Create new distributor
        </FormattedMessage>
      </h2>
      <form style={{ width: "100%" }}>
        {/* NOMBRE ------------------------------------------------------------------------ */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="name_config">Name</FormattedMessage>{" "}
          </label>
          <input
            type="text"
            name="name"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* LOCATION --------------------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="location_config">
              Location
            </FormattedMessage>{" "}
          </label>
          <input
            type="text"
            name="location"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* CONTACT INFO --------------------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="contact_info_config">
              Contact info
            </FormattedMessage>
          </label>
          <input
            type="text"
            name="contact_info"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* REGION --------------------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="region_config_min">Region</FormattedMessage>
          </label>
          <input
            type="text"
            name="region"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* GUARDAR ---------------------------------------------------------------------------------------------- */}
        <button
          type="submit"
          onClick={handleConfirm}
          className={styles.createButton}
        >
          <FormattedMessage id="save_button">Save</FormattedMessage>
        </button>
        <Dialog open={confirmMessage}>
          {localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"}
        </Dialog>
      </form>
    </div>
  );
}
