import React from "react";
import { useContext } from "react";
import { MapContext } from "../../../context/map/MapContext";
import { PlacesContext } from "../../../context/places/PlacesContext";
import styles from "./lateralBtn.module.css";

const LateralBtn = ({ timestamp, id, lng, lat }) => {
 
  const { map, isMapReady } = useContext(MapContext);

  const onClick = () => {
    // repetir esta logica  para cada dispositivo al que quiera hacer zoom
    if (!isMapReady) throw new Error("Mapa no esta listo");

    map?.flyTo({
      zoom: 14,
      center: [lng, lat],
      pitch: 60,
      duration: 10000,
    });
  };

  return (
    <>
      <div onClick={onClick} className={styles.btnContainer}>
        {id.split("-")[1] < 10
          ? `W_00${id.split("-")[1]}`
          : ` W_${id.split("-")[1]} `}

        <span className={styles.lastUpdate}>
          {timestamp.toString().split("T")[0]}{" "}
          {timestamp.toString().split("T")[1].split(":")[0]}:
          {timestamp.toString().split("T")[1].split(":")[1]}
        </span>
      </div>
    </>
  );
};

export default LateralBtn;
