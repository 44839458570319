import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Pagination,
  CircularProgress,
  Modal,
  Box,
  Switch,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./calibrations.module.scss";

import {
  GET_CALIBRATION,
  GET_CALIBRATIONS,
  GET_COMPANY_DETAIL,
} from "../../queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  logOut,
  parseJwt,
  fetchReportFile,
  convertirHoraLocal,
  isTokenExpired,
  closeWebSocketConnection,
  expiredAction,
} from "../../helpers";
import { company_id } from "../../const";
import { AiOutlineDownload } from "react-icons/ai";
import { saveAs } from "file-saver";
import DropdownCalibrations from "./dropdownCalibrations/DropdownCalibrations";
import { on } from "ws";
import { useCompare } from "../Reports/compareButton/hooks";
import ShipmentsToCompare from "../Reports/shipmentsToCompare/ShipmentsToCompare";
import CompareButton from "../Reports/compareButton/CompareButton";

//estilo el modal de los contents de los viajes en transito
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 300,
  bgcolor: "white",
  border: "2px solid #00ABC8",
  borderRadius: 10,
  fontFamily: "'Quattrocento Sans', sans-serif",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Calibrations = () => {
  const {
    handleCheck,
    arrayState,
    clearCalibrationsSelection,
  } = useCompare();
  const intl = useIntl();


  //ESTADO PARA MODAL DE COMPARAR ENVIOS
  const [compareModal, setCompareModal] = useState(false);
  const [dataToCompare, setDataTocompare] = useState([]);
  const [errorText, setErrorText] = useState("");
  const [expanded, setExpanded] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const onAction = async (data) => {
    setDataTocompare(data);
    handleOpenCompare();
  };

  //si trato de comparar y no seleccione envios o seleccione solo uno tira este msj
  const errorMsj = () => {
    setErrorText(intl.formatMessage({ id: "select_shipments" }));

    setTimeout(() => {
      setErrorText("");
    }, 4000);
  };
  //handler open modal compare shipments
  const handleOpenCompare = () => {
    setCompareModal(true);
  };
  //handler close comparar shipments
  const handleCloseCompare = () => {
    setCompareModal(false);
    clearCalibrationsSelection();
    setErrorText("");
  };
  //estados para la paginacion
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [inTransit, setInTransit] = useState(false);

  //estado para el modal de comentarios
  const [comments, setComments] = useState(false);

  //estado para guardar el id del shipment para ver sus comentarios
  const [shipToSeeComments, setShipToSeeComments] = useState("");
  //estado para el input de busqueda por id
  const [searchInput, setSearchInput] = useState("");

  //estado para guardarme la info filtrada
  const [filters, setFilters] = useState({
    from: "",
    to: "",
    qr: "",
  });
  const [onFilter, setOnFilter] = useState(false);

  const useStyles = makeStyles((theme) => ({
    customAccordionSummary: {
      padding: 0,
      margin: 0,
    },
  }));
  const classes = useStyles();
  // QUERIES/MUTATIONS ---------------------------------------------------------------------------------
  //query para traerme las branches de la compañia al principio
  const {
    // loading: companyDetailLoading,
    // error: companyDetailError,
    data: company_detail,
  } = useQuery(GET_COMPANY_DETAIL, {
    variables: {
      company_id,
    },
  });
  const {
    // loading: companyDetailLoading,
    // error: companyDetailError,
    data: calibrationsDetail,
  } = useQuery(GET_CALIBRATIONS, {
    variables: {
      company_id,
    },
  });
  const [
    dataToCsv,
    {
      loading: toCsvLoading,
      // error: filtersError,
      data: toCsv,
    },
  ] = useLazyQuery(GET_CALIBRATION); // CALIBRATION DETAIL

  const [
    getFilterData,
    { data: filterData, loading: filterLoading, error: filterError },
  ] = useLazyQuery(GET_CALIBRATIONS);

  // VARIABLE PARA GUARDAR LA DATA -----------------------------------------------------
  let dataLength = "";
  let data = "";
  let dataById = "";
  useEffect(() => {
    expiredAction();
    const fetch = async () => {
      let result = await getFilterData({
        variables: {
          company_id: company_id,
          qr: filters.qr,
          from: filters.from,
          to: filters.to,
          page: page,
          per_page: rowsPerPage,
        },
      });
    };
    fetch();
  }, [page, calibrationsDetail, filterData]);
  //HANDLERS ---------------------------------------------------------------------------------------------
  //handler cuando cambio filtros
  const handleChange = function (e) {
    if (e.target.value === "") {
      setFilters({
        ...filters,
        [e.target.name]: null,
      });
    } else {
      setFilters({
        ...filters,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSwitch = () => {
    setInTransit(!inTransit);
  };
  //handler cuando submiteo los filtros
  const handleFilter = async (e) => {
    //setSearchInput(null);
    e.preventDefault();
    setOnFilter(true);

    try {
      let result = await getFilterData({
        variables: {
          company_id: company_id,
          qr: filters.qr,
          from: filters.from,
          to: filters.to,
          page: page,
          per_page: rowsPerPage,
        },
      });

      // dataLength = result?.data?.calibrations?.selectedItems;
      // data = result?.data?.calibrations?.selectedItems;
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeAccordion = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = isExpanded;
      return newExpanded;
    });
  };
  //handler de la paginacion
  const handleChangePage = async (event, value) => {
    setPage(value);
    setExpanded([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
  };
  const getTimestamp = (tick, csv) => {
    let date = new Date(tick);
    const timezoneOffset = date.getTimezoneOffset();
    date.setHours(date.getHours() /*  - (timezoneOffset / 60) */);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Asegura que tenga 2 dígitos
    const day = String(date.getDate()).padStart(2, "0"); // Asegura que tenga 2 dígitos
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Asegura que tenga 2 dígitos
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12; // Si hours es 0, se muestra como 12

    let strTime = "";

    if (csv) {
      strTime = `${year}/${month}/${day} ${formattedHours}:${minutes} ${ampm}`;
    } else {
      strTime = `${month}/${day} ${formattedHours}:${minutes} ${ampm}`;
    }
    return strTime;
  };

  const downloadCsv2 = async (e, s) => {
    let response;
    e.stopPropagation();
    try {
      response = await dataToCsv({
        variables: {
          calibration_id: s.calibration_id,
        },
      });
    } catch (error) {
      console.log(error);
    }
    // const origin_branch = company_detail?.company?.branches
    //   ?.map((b) => b?.branch_id === s?.origin_id && b?.name)
    //   .filter((branch) => branch !== false);
    const operator = company_detail?.company?.operators
      ?.map(
        (o) =>
          o?.operator_id === s?.operator_id &&
          `${o.name} (${o.operator_id.split("-")[0]}-${
            o.operator_id.split("-")[1]
          })`
      )
      .filter((ope) => ope !== false);
    // const destination_branch = company_detail?.company?.branches
    //   ?.map((b) => b.branch_id === s?.destination_id && b.name)
    //   .filter((branch) => branch !== false);
    // const ope_receiver = company_detail?.company?.operators
    //   ?.map(
    //     (o) =>
    //       o.operator_id === s?.destination_op_id &&
    //       `${o.name} (${o.operator_id.split("-")[0]}-${
    //         o.operator_id.split("-")[1]
    //       })`
    //   )
    //   .filter((ope) => ope !== false);

    let csvContent = "DATA\n";
    csvContent += "calibration_id,qr,operator,start,end\n";
    csvContent += `${s.calibration_id},${s.qr},${operator},${s.departure},${s.arrival}\n\n`;
    csvContent += "READINGS\n";
    csvContent += "timestamp,value\n";

    response?.data?.calibration?.temperature_readings?.forEach((reading) => {
      csvContent += `\n${getTimestamp(reading?.timestamp, true)},${
        reading?.value
      }`;

      //csvContent += `\n${convertirHoraLocal(reading?.timestamp,company_detail?.company?.gmt)},${reading?.value}`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `${s.calibration_id}.csv`);
  };

  return (
    <div className={styles.container}>
      {/* BUSCADOR------------------------------------------------------------------ */}
      <h3 className={styles.titleCalibrations}>
        <FormattedMessage id="calibrations_title">
          Calibrations
        </FormattedMessage>
      </h3>

      {/* FILTRADOS */}
      <div className={styles.formContainer}>
        <form className={styles.filter} onSubmit={handleFilter}>
          {/* DESDE */}
          <input
            className={styles.select_from_to}
            onChange={handleChange}
            id="from_to"
            type="date"
            name="from"
            placeholder={intl.formatMessage({ id: "from" })}
          />

          {/* HASTA */}
          <input
            className={styles.select_from_to}
            onChange={handleChange}
            id="from_to"
            type="date"
            name="to"
            placeholder={intl.formatMessage({ id: "to" })}
          />

          {/* BUSQUEDA X QR ----------------------------------------------------------------- */}
          <input
            className={styles.select_qr}
            onChange={handleChange}
            type="text"
            name="qr"
            placeholder="Qr"
          />
        </form>
        <div className={styles.switchContainer}>
          <h3 className={styles.statusTitle}>
            <FormattedMessage id="status">Status</FormattedMessage>
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              style={{
                display: "inline-block",
                marginRight: "10px",
                color: "#1B1464",
                //fontFamily: "Quattrocento Sans",
              }}
            >
              <FormattedMessage id="completed">Completed</FormattedMessage>
            </Typography>
            <Switch
              //checked={checked}
              onChange={handleSwitch}
            />
            <Typography
              variant="body1"
              style={{
                display: "inline-block",
                marginLeft: "10px",
                color: "#1B1464",
                //fontFamily: "Quattrocento Sans",
              }}
            >
              <FormattedMessage id="in_transit">In transit</FormattedMessage>
            </Typography>
          </div>
        </div>
      </div>

      {/* BUSCAR */}
      <div className={styles.searchButtonContainer}>
        <button
          type="submit"
          className={styles.searchButton}
          onClick={handleFilter}
        >
          <FormattedMessage id="search_button">Search</FormattedMessage>
        </button>
        {/* BOTON COMPARAR------------------------------------------------------ */}

        {/* si selecciono 2 envio o mas aparecen los botones de comparar y eliminar seleccion */}

        <CompareButton
          arrayState={arrayState}
          onAction={onAction}
          errorMsj={errorMsj}
          type="calibrations"
        />

        <div className={styles.clearButtonContainer}>
          {/* BUTTON CLEAR............................... */}
          <button
            onClick={clearCalibrationsSelection}
            className={styles.clearButton}
            disabled={arrayState.length < 1 ? true : false}
          >
            <FormattedMessage id="clear">Clear</FormattedMessage>
          </button>
        </div>
      </div>

      {/* TABLA */}

      <div className={styles.tableContainer}>
        {/* mensaje de error------------------------------ */}
        <div className={styles.error_text}>{errorText}</div>

        <div>
          {/* Por si no hay filtros puestos */}
          {!onFilter ? (
            <div className={styles.message1}>
              <FormattedMessage id="initial_message">
                Please select searching settings
              </FormattedMessage>
            </div>
          ) : (
            <>
              {filterLoading ? (
                <div className={styles.loading}>
                  <CircularProgress />
                </div>
              ) : //si no ha filtro y no hay data no muestro el header de la tabla
              !filterData || filterData?.calibrations?.total === 0 ? (
                <div className={styles.message2}>
                  <FormattedMessage id="no_results">
                    No results found
                  </FormattedMessage>
                </div>
              ) : (
                <>
                  {/* HEADER----------------------------------------------------  */}
                  {/* filtro x in transit, si el array no esta vacio muestro el header */}
                  {inTransit &&
                    filterData?.calibrations?.selectedItems?.filter(
                      (c) => c?.status === "TRANSIT"
                    ).length !== 0 && (
                      <div className={styles.headerContainerInTransit}>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "5%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          Id
                        </div>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "10%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          Qr
                        </div>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "14.28%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="operator">
                            OPERATOR
                          </FormattedMessage>
                        </div>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "14.28%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="thermometers" />
                        </div>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "14.28%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="start">START</FormattedMessage>
                        </div>

                        <div
                          className={styles.header_item}
                          style={{
                            width: "14.28%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="end">END</FormattedMessage>
                        </div>

                        <div
                          className={styles.header_item}
                          style={{
                            width: "10%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="reports">
                            REPORTS
                          </FormattedMessage>
                        </div>
                      </div>
                    )}

                  {/* filtro x completed, si el array no esta vacio muestro el header */}
                  {!inTransit &&
                    filterData?.calibrations?.selectedItems?.filter(
                      (c) => c?.status === "SUCCESSFUL"
                    ).length !== 0 && (
                      <div className={styles.headerContainer}>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "5%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          Id
                        </div>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "10%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          Qr
                        </div>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "14.28%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="operator">
                            OPERATOR
                          </FormattedMessage>
                        </div>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "14.28%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="thermometers" />
                        </div>
                        <div
                          className={styles.header_item}
                          style={{
                            width: "14.28%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="start">START</FormattedMessage>
                        </div>

                        <div
                          className={styles.header_item}
                          style={{
                            width: "14.28%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="end">END</FormattedMessage>
                        </div>

                        <div
                          className={styles.header_item}
                          style={{
                            width: "10%",
                            textAlign: "center",
                            // border: "solid red 1px",
                          }}
                        >
                          <FormattedMessage id="reports">
                            REPORTS
                          </FormattedMessage>
                        </div>
                      </div>
                    )}

                  {/* si no hay data recorro un array vacio x ende no se muestra la tabla */}
                  {/* CADA ENVIO----------------------------------------------------  */}
                  {inTransit &&
                    filterData?.calibrations?.selectedItems
                      ?.filter((c) => c?.status === "TRANSIT")
                      .map((s, index) => (
                        <div
                          className={styles.check_data_container}
                          key={s?.calibration_id}
                        >
                          <Accordion
                            disableGutters={true}
                            sx={{
                              width: "97%",
                              position: "relative",
                              left: "0.5%",
                            }}
                            style={
                              index % 2 === 0
                                ? { background: "#FAFAFA" }
                                : { background: "#D9F1F5" }
                            }
                            key={index}
                            expanded={expanded[index]}
                            onChange={handleChangeAccordion(index)}
                          >
                            <AccordionSummary
                              className={`${classes.customAccordionSummary} MuiAccordionSummary-root MuiAccordionSummary-gutters css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root`}
                            >
                              <div className={styles.dataContainer}>
                                {/* id--------- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width: "5%" /* border: "solid red 1px" */,
                                  }}
                                >
                                  {s?.calibration_id.split("-")[1]}
                                </div>
                                {/* qr--------- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width: "10%" /*  border: "solid red 1px" */,
                                  }}
                                >
                                  {s?.qr}
                                </div>
                                {/* OPERATOR----- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width:
                                      "14.28%" /* border: "solid red 1px" */,
                                  }}
                                >
                                  {/* {company_detail?.company?.branches?.map(
                            (b) => b?.branch_id === s?.origin_id && b?.name
                          )} */}
                                  {company_detail?.company?.operators?.map(
                                    (o) =>
                                      o?.operator_id === s?.operator_id &&
                                      `${o.name} (${
                                        o.operator_id.split("-")[0]
                                      }-${o.operator_id.split("-")[1]})`
                                  )}
                                </div>
                                {/* sender-------/THERMOMETERS-- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width:
                                      "14.28%" /* border: "solid red 1px" */,
                                  }}
                                >
                                  {/* {company_detail?.company?.operators?.map(
                            (o) =>
                              o?.operator_id === s?.origin_op_id &&
                              `${o.name} (${o.operator_id.split("-")[0]}-${
                                o.operator_id.split("-")[1]
                              })`
                          )} */}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      // border: "solid red 1px",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {s?.thermometer_ids &&
                                    s?.thermometer_ids.length !== 0
                                      ? s?.thermometer_ids.map((t) => (
                                          <div key={t}>{t}</div>
                                        ))
                                      : s?.thermometer_ids === null
                                      ? "--"
                                      : s?.thermometer_ids.length == 0
                                      ? "--"
                                      : null}
                                  </div>
                                </div>
                                {/* START----- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width:
                                      "14.28%" /*  border: "solid red 1px"  */,
                                  }}
                                >
                                  {/* {getTimestamp(s?.departure,false)} */}
                                  {
                                    convertirHoraLocal(
                                      s?.departure,
                                      company_detail?.company?.gmt
                                    )?.split("  ")[0]
                                  }
                                  <br />
                                  {
                                    convertirHoraLocal(
                                      s?.departure,
                                      company_detail?.company?.gmt
                                    )?.split("  ")[1]
                                  }
                                </div>

                                {/* END--------- */}
                                {s?.arrival ? (
                                  <div
                                    className={styles.data}
                                    style={{
                                      width:
                                        "14.28%" /* border: "solid red 1px" */,
                                    }}
                                  >
                                    {/* {getTimestamp(s?.arrival,false)} */}
                                    {
                                      convertirHoraLocal(
                                        s?.arrival,
                                        company_detail?.company?.gmt
                                      ).split("  ")[0]
                                    }
                                    <br />
                                    {
                                      convertirHoraLocal(
                                        s?.arrival,
                                        company_detail?.company?.gmt
                                      ).split("  ")[1]
                                    }
                                  </div>
                                ) : (
                                  <div
                                    className={styles.data}
                                    style={{
                                      width:
                                        "14.28%" /* border: "solid red 1px" */,
                                    }}
                                  >
                                    --
                                  </div>
                                )}

                                {/* reports------------- */}

                                <div
                                  className={styles.downloadContainer}
                                  style={{
                                    width: "10%" /* border: "solid red 1px" */,
                                  }}
                                >
                                  {/* CSV */}
                                  {s?.arrival ? (
                                    <button
                                      onClick={(e) => downloadCsv2(e, s)}
                                      className={styles.button}
                                    >
                                      <AiOutlineDownload size="2vw" />
                                      <br />
                                      CSV
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </AccordionSummary>

                            {!inTransit && (
                              <AccordionDetails
                                className={classes.accordionDetails}
                                sx={{
                                  height: "35vw",
                                  // border: "solid red 1px",
                                  backgroundColor: "#fafafa",
                                }}
                              >
                                <DropdownCalibrations
                                  calibration_id={s.calibration_id}
                                  expanded={expanded}
                                  index={index}
                                  gmt={company_detail.company.gmt}
                                />
                              </AccordionDetails>
                            )}
                          </Accordion>
                        </div>
                      ))}

                  {!inTransit &&
                    filterData?.calibrations?.selectedItems
                      ?.filter((c) => c?.status === "SUCCESSFUL")
                      .map((s, index) => (
                        <div
                          className={styles.check_data_container}
                          key={s?.calibration_id}
                        >
                          <div className={styles.checkbox}>
                            <input
                              type="checkbox"
                              name="calibrationsTocompare"
                              onChange={handleCheck}
                              value={s?.calibration_id}
                            />
                          </div>
                          <Accordion
                            disableGutters={true}
                            sx={{
                              width: "97%",
                              position: "relative",
                              left: "0.5%",
                            }}
                            style={
                              index % 2 === 0
                                ? { background: "#FAFAFA" }
                                : { background: "#D9F1F5" }
                            }
                            key={index}
                            expanded={expanded[index]}
                            onChange={handleChangeAccordion(index)}
                          >
                            <AccordionSummary
                              className={`${classes.customAccordionSummary} MuiAccordionSummary-root MuiAccordionSummary-gutters css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root`}
                            >
                              <div className={styles.dataContainer}>
                                {/* id--------- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width: "5%" /* border: "solid red 1px" */,
                                  }}
                                >
                                  {s?.calibration_id.split("-")[1]}
                                </div>
                                {/* qr--------- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width: "10%" /*  border: "solid red 1px" */,
                                  }}
                                >
                                  {s?.qr}
                                </div>
                                {/* OPERATOR----- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width:
                                      "14.28%" /* border: "solid red 1px" */,
                                  }}
                                >
                                  {/* {company_detail?.company?.branches?.map(
                            (b) => b?.branch_id === s?.origin_id && b?.name
                          )} */}
                                  {company_detail?.company?.operators?.map(
                                    (o) =>
                                      o?.operator_id === s?.operator_id &&
                                      `${o.name} (${
                                        o.operator_id.split("-")[0]
                                      }-${o.operator_id.split("-")[1]})`
                                  )}
                                </div>
                                {/* sender-------/THERMOMETERS-- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width: "14.28%",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      // border: "solid red 1px",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {s?.thermometer_ids &&
                                    s?.thermometer_ids.length !== 0
                                      ? s?.thermometer_ids.map((t) => (
                                          <div key={t}>{t}</div>
                                        ))
                                      : s?.thermometer_ids === null
                                      ? "--"
                                      : s?.thermometer_ids.length == 0
                                      ? "--"
                                      : null}
                                  </div>
                                </div>
                                {/* START----- */}
                                <div
                                  className={styles.data}
                                  style={{
                                    width:
                                      "14.28%" /*  border: "solid red 1px"  */,
                                  }}
                                >
                                  {/* {getTimestamp(s?.departure,false)} */}
                                  {
                                    convertirHoraLocal(
                                      s?.departure,
                                      company_detail?.company?.gmt
                                    )?.split("  ")[0]
                                  }
                                  <br />
                                  {
                                    convertirHoraLocal(
                                      s?.departure,
                                      company_detail?.company?.gmt
                                    )?.split("  ")[1]
                                  }
                                </div>

                                {/* END--------- */}
                                {s?.arrival ? (
                                  <div
                                    className={styles.data}
                                    style={{
                                      width:
                                        "14.28%" /* border: "solid red 1px" */,
                                    }}
                                  >
                                    {/* {getTimestamp(s?.arrival,false)} */}
                                    {
                                      convertirHoraLocal(
                                        s?.arrival,
                                        company_detail?.company?.gmt
                                      ).split("  ")[0]
                                    }
                                    <br />
                                    {
                                      convertirHoraLocal(
                                        s?.arrival,
                                        company_detail?.company?.gmt
                                      ).split("  ")[1]
                                    }
                                  </div>
                                ) : (
                                  <div
                                    className={styles.data}
                                    style={{
                                      width:
                                        "14.28%" /* border: "solid red 1px" */,
                                    }}
                                  >
                                    --
                                  </div>
                                )}

                                {/* reports------------- */}

                                <div
                                  className={styles.downloadContainer}
                                  style={{
                                    width: "10%" /* border: "solid red 1px" */,
                                  }}
                                >
                                  {/* CSV */}
                                  {s?.arrival ? (
                                    <button
                                      onClick={(e) => downloadCsv2(e, s)}
                                      className={styles.button}
                                    >
                                      <AiOutlineDownload size="2vw" />
                                      <br />
                                      CSV
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </AccordionSummary>

                            {!inTransit && (
                              <AccordionDetails
                                className={classes.accordionDetails}
                                sx={{
                                  height: "35vw",
                                  // border: "solid red 1px",
                                  backgroundColor: "#fafafa",
                                }}
                              >
                                <DropdownCalibrations
                                  calibration_id={s.calibration_id}
                                  expanded={expanded}
                                  index={index}
                                  gmt={company_detail.company.gmt}
                                />
                              </AccordionDetails>
                            )}
                          </Accordion>
                        </div>
                      ))}
                </>
              )}
            </>
          )}
          {/* ------pagination--------- */}
          <div className={styles.pagination}>
            {/* si estoy en in transit y hay elementos,  muestro la paginacion */}
            {inTransit &&
              filterData?.calibrations?.selectedItems?.filter(
                (c) => c?.status === "TRANSIT"
              ).length !== 0 &&
              filterData?.calibrations.total > 15 &&
              onFilter && (
                <Pagination
                  count={Math.ceil(
                    filterData?.calibrations.total / rowsPerPage
                  )}
                  page={page}
                  onChange={handleChangePage}
                  size={window.screen.width <= 1000 ? "small" : "large"}
                />
              )}
            {/* si estoy en completed  hay elementos,  muestro la paginacion */}
            {!inTransit &&
              filterData?.calibrations?.selectedItems?.filter(
                (c) => c?.status === "SUCCESSFUL"
              ).length !== 0 &&
              filterData?.calibrations.total > 15 &&
              onFilter && (
                <Pagination
                  count={Math.ceil(
                    filterData?.calibrations.total / rowsPerPage
                  )}
                  page={page}
                  onChange={handleChangePage}
                  size={window.screen.width <= 1000 ? "small" : "large"}
                />
              )}
          </div>
        </div>
      </div>
      {/* MODAL GRAFICO DE COMPARACION DE ENVIOS --------------------------------------------------------*/}
      <Modal open={compareModal} onClose={handleCloseCompare}>
        <Box
          sx={{
            ...modalStyle,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            border: "0.15vw solid #00ABC8",
            borderRadius: 10,
            fontFamily: "'Quattrocento Sans', sans-serif",
            boxShadow: 10,
            pt: 2,
            px: 4,
            pb: 3,
            width: window.screen.width > 800 ? "60%" : "70%",
            height: window.screen.width > 800 ? "40vw" : "60vw",
          }}
        >
          <ShipmentsToCompare
            dataToCompare={dataToCompare}
            gmt={company_detail?.company?.gmt}
            type="calibrations"
          />
          {/* <Ejemplo/> */}
        </Box>
      </Modal>
    </div>
  );
};

export default Calibrations;
