import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useEffect } from "react";
import { company_id } from "../../../../const";
import { GET_COMPANY_DETAIL, UPDATE_COMPANY } from "../../../../queries";
import AddCheckpointForm from "./addCheckpointForm/AddCheckpointForm";

import styles from "./EditCheckpointsContainer.module.scss";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { RiPlayListAddFill } from "react-icons/ri";

const MyCheckpoints = ({
  customChecks,
  updateNumber,
  defaultCheckpoints,
  setRefetch,
  refetch,
  loading,
  startup_alerts,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 100,
    height: 100,
    bgcolor: "white",
    border: "2px solid #00ABC8",
    borderRadius: 10,
    // fontFamily: "'Quattrocento Sans', sans-serif",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    margin: "auto",
  };
  const intl = useIntl();
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: "18px", // Tamaño de fuente deseado
    },
  }))(Tooltip);
  //recibir una lista de checkpoints actuales

  const harcodedCheckpoints = [
    {
      name: intl.formatMessage({ id: "checkpoint" }),
      behavior: "NO_CHANGE",
      mandatory: false,
    },
    {
      name: intl.formatMessage({ id: "origin" }),
      behavior: startup_alerts ? "ENABLE_ALERTS" : "NO_CHANGE",
      mandatory: true,
    },
    {
      name: intl.formatMessage({ id: "destination" }),
      behavior: "DISABLE_ALERTS",
      mandatory: true,
    },
  ];
  const [cancel, setCancel] = useState(false);
  const [newChecks, setNewChecks] = useState([]);
  const [checkName, setCheckName] = useState("");
  const [mandatory, setMandatory] = useState(true);
  const [behavior, setBehavior] = useState(null);
  const [newCheck, setNewCheck] = useState({
    mandatory: mandatory,
    name: "",
    behavior: behavior,
  });
  const [isDeleted, SetIsDeleted] = useState(false);
  const [isBlocked, SetIsBlocked] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [startUpAlerts, setStartUpAlerts] = useState(startup_alerts);
  const [someCheckHasEnableAlerts, setSomeCheckHasEnableAlerts] =
    useState(startup_alerts);

  useEffect(() => {
 
    setStartUpAlerts(startup_alerts);
    setNewChecks([...harcodedCheckpoints, ...customChecks]);
  }, [customChecks, cancel]);

  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const handleChangeInputName = (e, check) => {
    if (e.target.name === "name") {
      const { value } = e.target;

      setCheckName(value);
      setNewCheck({
        mandatory: mandatory,
        name: value,
        behavior: behavior ? behavior : "NO_CHANGE",
      });
    }
    if (e.target.name === "behavior") {
      const { value } = e.target;
      setBehavior(value);
      setNewCheck({ mandatory: mandatory, name: checkName, behavior: value });
      setSomeCheckHasEnableAlerts(value);
    }
    if (e.target.name === "behavior_selectList") {
      const { value } = e.target;

      const newArray = newChecks.map((c) =>
        c.name === check.name ? { ...c, behavior: value } : c
      );
      setNewChecks(newArray);
      SetIsBlocked(false);
    }
    if (e.target.name === "origin_behavior_selectList") {
      SetIsBlocked(false);
      console.log(e.target.value);

      if (e.target.value === "ENABLE_ALERTS") {
        setStartUpAlerts(true);
        setSomeCheckHasEnableAlerts(true);
      }
      if (e.target.value === "NO_CHANGE") {
        setStartUpAlerts(false);
      }
    }
  };

  const handleChangeInputMandatory = (e, i) => {
    const { checked } = e.target;
    const updatedChecks = newChecks.map((check, index) =>
      index === i ? { ...check, mandatory: checked } : check
    );
    setNewChecks([
      ...harcodedCheckpoints,
      ...updatedChecks.filter(
        (c) => !harcodedCheckpoints.some((hc) => hc.name === c.name)
      ),
    ]);
    SetIsBlocked(false);
  };
  const addCheckpoint = (e) => {
    e.preventDefault();
    if (checkName !== "") {
      const newCheck = {
        mandatory: mandatory,
        name: checkName,
        behavior: behavior ? behavior : "NO_CHANGE",
      };
      setNewChecks([
        ...harcodedCheckpoints,
        ...newChecks.filter(
          (c) => !harcodedCheckpoints.some((hc) => hc.name === c.name)
        ),
        newCheck,
      ]);
      SetIsBlocked(false);
    }
    setCheckName("");
    setBehavior(null);
    setMandatory(true);
    setOpenModal(false);
  };
  const handleUpdateCheckpoints = async () => {
    try {
      const message = intl.formatMessage({ id: "confirm_changes" });
      let checksToSave = newChecks?.filter(
        (c) => !harcodedCheckpoints?.some((hc) => hc.name === c.name)
      );
      console.log("checksToSave", checksToSave);
      console.log(startUpAlerts)
      if (window.confirm(message)) {
        if (
          checksToSave?.some((c) => c.behavior === "ENABLE_ALERTS") ||
          startUpAlerts === true
        ) {
          console.log("checksToSave", checksToSave);
          await updateCompany({
            variables: {
              company_id,
              custom_checks: newChecks?.filter(
                (c) => !harcodedCheckpoints?.some((hc) => hc.name === c.name)
              ),
              startup_alerts: startUpAlerts,
            },
          });

          const successMessage = intl.formatMessage({
            id: "changes_successfully",
          });
          SetIsDeleted(false);
          SetIsBlocked(true);
          window.alert(successMessage);
          setRefetch(!refetch);
        } else {
          toast.error(
            intl.formatMessage({
              id: "checkpoint_must_have_enable_alerts",
            })
          );
        }
      }
    } catch (error) {
      console.error("Error en la mutación de updateCheckpoints", error);
    }
  };
  const deleteCheckpoint = (i) => {
    SetIsDeleted(true);
    setNewChecks(newChecks.filter((check, index) => index !== i));
    SetIsBlocked(false);
  };
  const handleCancelUpdateCheckpoints = () => {
    SetIsDeleted(false);
    setCancel(!cancel);
    SetIsBlocked(true);
    setRefetch(!refetch);
    setStartUpAlerts(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setCheckName("");
    setBehavior(null);
    setMandatory(true);
    setNewCheck({ mandatory: mandatory, name: "", behavior: null });
  };
  if (loading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {/* TABLA --------------------------------------------------------------- */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* description */}
              <TableCell
                size="medium"
                align="left"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.5vw" : "1.5vw",

                  color: "#00ABC8",
                  // fontFamily: "'Quattrocento Sans', sans-serif",
                  paddingLeft: "5%",
                }}
              >
                <FormattedMessage id="description">
                  description
                </FormattedMessage>{" "}
              </TableCell>
              {/* behavior */}
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.5vw" : "1.5vw",
                  color: "#00ABC8",
                }}
              >
                <FormattedMessage id="behavior">behavior </FormattedMessage>{" "}
              </TableCell>
              {/* mandatory */}
              <TableCell
                size="medium"
                align="right"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.5vw" : "1.5vw",
                  color: "#00ABC8",
                  paddingRight: "6%",
                }}
              >
                <FormattedMessage id="mandatory">mandatory </FormattedMessage>{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newChecks?.map((check, i) => {
              return (
                <TableRow key={i + 78}>
                  <TableCell
                    size="small"
                    sx={{
                      border: "none",

                      backgroundColor:
                        newChecks?.indexOf(check) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",

                      color: "#1B1464",
                    }}
                  >
                    <span className={styles.checkName}>{check.name}</span>
                  </TableCell>

                  <TableCell
                    size="small"
                    align="center"
                    sx={{
                      border: "none",
                      backgroundColor:
                        newChecks?.indexOf(check) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",

                      color: "#1B1464",
                    }}
                  >
                    <form className={styles.behavior}>
                      {/* condicion para los 3 primeros checkpoints harcodeados  */}
                      {newChecks &&
                      [0, 1, 2].includes(newChecks.indexOf(check)) ? (
                        // punto de control y destino
                        newChecks &&
                        [0, 2].includes(newChecks.indexOf(check)) ? (
                          newChecks &&
                          [2].includes(newChecks.indexOf(check)) ? (
                            <FormattedMessage id="disable_alerts" />
                          ) : (
                            <FormattedMessage id="no_change" />
                          )
                        ) : (
                          // origen
                          <select
                            defaultValue={
                              startup_alerts === false
                                ? "NO_CHANGE"
                                : "ENABLE_ALERTS"
                            }
                            onChange={(e) => handleChangeInputName(e, check)}
                            name="origin_behavior_selectList"
                            className={styles.select}
                          >
                            <option value="NO_CHANGE">
                              {intl.formatMessage({
                                id: "no_change",
                                defaultMessage: "No change",
                              })}
                            </option>
                            <option value="ENABLE_ALERTS">
                              {intl.formatMessage({
                                id: "enable_alerts",
                                defaultMessage: "ENABLE_ALERTS",
                              })}
                            </option>
                          </select>
                        )
                      ) : (
                        // custom checkpoints
                        <select
                          defaultValue={check.behavior}
                          onChange={(e) => handleChangeInputName(e, check)}
                          name="behavior_selectList"
                          className={styles.select}
                        >
                          <option value="NO_CHANGE">
                            {intl.formatMessage({
                              id: "no_change",
                              defaultMessage: "No change",
                            })}
                          </option>
                          <option value="ENABLE_ALERTS">
                            {intl.formatMessage({
                              id: "enable_alerts",
                              defaultMessage: "ENABLE_ALERTS",
                            })}
                          </option>
                          <option value="DISABLE_ALERTS">
                            {intl.formatMessage({
                              id: "disable_alerts",
                              defaultMessage: "DISABLE_ALERTS",
                            })}
                          </option>
                        </select>
                      )}
                    </form>
                  </TableCell>

                  <TableCell
                    size="small"
                    align="center"
                    sx={{
                      border: "none",
                      fontSize: window.screen.width > 800 ? "1vw" : "1vw",
                      backgroundColor:
                        newChecks?.indexOf(check) % 2 === 0
                          ? "#FAFAFA"
                          : "#D9F1F5",

                      color: "#1B1464",
                    }}
                  >
                    {/* hola que tal,m cini ti v ---ultimo aporte de juli 3/3/2023--*/}
                    <form className={styles.checkAndDeleteButton}>
                      <input
                        type="checkbox"
                        onChange={(e) => handleChangeInputMandatory(e, i)}
                        name="mandatory"
                        defaultChecked={newChecks[i].mandatory}
                        disabled={
                          newChecks &&
                          [0, 1, 2].includes(newChecks.indexOf(check))
                            ? true
                            : false
                        }
                        className={styles.checkbox}
                      />

                      {/* si el nomnre es distinto a 'Checkpoint puedo borrar y editar' */}
                      {newChecks &&
                      ![0, 1, 2].includes(newChecks.indexOf(check)) ? (
                        <div
                          onClick={() => deleteCheckpoint(i)}
                          className={styles.deleteButton}
                        >
                        <FormattedMessage id="delete">delete</FormattedMessage>
                        </div>
                      ) : (
                        <button
                          disabled={
                            newChecks &&
                            [0, 1, 2].includes(newChecks.indexOf(check))
                          }
                          className={styles.deleteButtonDisabled}
                        >
                          <FormattedMessage id="delete">delete</FormattedMessage>
                        </button>
                      )}
                    </form>
                  </TableCell>
                </TableRow>
              );
            })}
            <tr>
              <td>
                <CustomTooltip
                  title={intl.formatMessage({ id: "add_new_checkpoint" })}
                >
                  <div
                    style={{
                      width: "2vw",
                      height: "2vw",
                      position: "relative",
                      left: "4.3vw",
                      marginTop: "2%",
                    }}
                  >
                    <RiPlayListAddFill
                      onClick={handleOpenModal}
                      style={{
                        cursor: "pointer",
                        color: "var(--blue)",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                </CustomTooltip>
              </td>
            </tr>
            {/* <button onClick={handleOpenModal} style={{border:"solid red 1px",position:"relative",left:"-30%",marginTop:"5%"}}>agregar</button> */}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ADD CHECKPOINT FORM----------------------------------------- */}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            ...modalStyle,
            position: "absolute",

            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            border: "0.15vw solid #00ABC8",
            borderRadius: 5,
            // fontFamily: "'Quattrocento Sans', sans-serif",
            boxShadow: 10,
            pt: 2,
            px: 4,
            pb: 3,
            width: 300,
            height: 250,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              // border: "solid red 1px",
              display: "flex",
              flexDirection: "column",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              top: "5%",
            }}
          >
            <TextField
              fullWidth
              label={intl.formatMessage({ id: "name_config" })}
              variant="outlined"
              onChange={handleChangeInputName}
              name="name"
              value={checkName}
              maxLength={16}
              //placeholder={intl.formatMessage({ id: "name" })}
            />
            <FormControl
              fullWidth
              sx={{
                margin: "5%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputLabel id="">
                <FormattedMessage id="behavior">behavior </FormattedMessage>
              </InputLabel>
              <Select
                fullWidth
                value={behavior}
                label={intl.formatMessage({ id: "behavior" })}
                onChange={handleChangeInputName}
                placeholder={intl.formatMessage({ id: "behavior" })}
                name="behavior"
              >
                <MenuItem value={"NO_CHANGE"}>
                  <FormattedMessage id="no_change" />
                </MenuItem>
                <MenuItem value={"ENABLE_ALERTS"}>
                  <FormattedMessage id="enable_alerts" />
                </MenuItem>
                <MenuItem value={"DISABLE_ALERTS"}>
                  <FormattedMessage id="disable_alerts" />
                </MenuItem>
              </Select>

              <Button
                variant="contained"
                onClick={addCheckpoint}
                disabled={checkName === ""}
                className={styles.addButton}
                sx={{
                  backgroundColor: "#1B1464",
                  textTransform: "none",
                  fontFamily: "var(--font1)",
                  width: "50%",
                  margin: "5%",
                  marginTop: "10%",
                }}
              >
                <FormattedMessage id="add">Add</FormattedMessage>
              </Button>
            </FormControl>
          </div>
        </Box>
      </Modal>
      <AddCheckpointForm
        newChecks={newChecks}
        handleUpdateCheckpoints={handleUpdateCheckpoints}
        handleCancelUpdateCheckpoints={handleCancelUpdateCheckpoints}
        isBlocked={isBlocked}
      />
      <ToastContainer />
    </>
  );
};

export default MyCheckpoints;
