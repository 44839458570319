import React, { useReducer, useEffect } from 'react'
import { company_id } from '../../const';
import { getDeviceLocation, getDeviceLocation2 } from '../../helpers';

import { PlacesContext } from './PlacesContext';
import { placesReducer } from './placesReducer';

const INITIAL_STATE = {
    isLoading: true,
    deviceLocation: undefined
}
const INITIAL_STATE2 = {
    isLoading: true,
    devices: [],
    
}
const PlacesProvider = ({ children }) => {
    // const [state, dispatch] = useReducer(placesReducer, INITIAL_STATE);
    const [state, dispatch] = useReducer(placesReducer, INITIAL_STATE2);


    useEffect(() => {
        //aca hago la query o subscription para saber la posicion de los devices
        // await getDeviceLocation(company_id).then(devices => {
        //     dispatch({ type: 'getDevices', payload: devices })
        // })
        updateDevices()
    }, []);

    const updateDevices = async () => {
        await getDeviceLocation(company_id).then(devices => {
            dispatch({ type: 'getDevices', payload: devices })
        })

    }
     setInterval(updateDevices, 900000);

    return (
        <PlacesContext.Provider value={{
            ...state,
        }}>
            {children}
        </PlacesContext.Provider>
    )
}

export default PlacesProvider