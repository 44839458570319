import { useMutation } from "@apollo/client";
import {
  Select,
  MenuItem,
  Checkbox,
  Button,
  Box,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import React, { useState, useEffect, useCallback } from "react";
import { company_id } from "../../../../const";
import { UPDATE_COMPANY } from "../../../../queries";
import styles from "./EditCheckpointsContainer.module.scss";
import { styled } from "@mui/system";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { getValueOptions } from "@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils";
import { Key } from "@mui/icons-material";

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-cell': {
    fontSize: 'var(--h5)',
    fontFamily: 'var(--font1)',
    color: 'var(--blue)',  
  },
  '& .MuiDataGrid-row': {
    backgroundColor: "#FAFAFA",
    cursor: 'pointer', 
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: "#D9F1F5",
    cursor: 'pointer',
  },
  '& .MuiDataGrid-columnHeader': {
    width: 'auto',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    overflowX: 'hidden',
    display: 'flex',
    height: 'auto',
    width: '18vw',
    fontSize: 'var(--h4)',
    overflowY: 'auto',
    fontFamily: 'var(--font1)',
    alignItems: 'center',
    color: 'var(--cyan)',
  },
});

const generateId = (rows) => {
  return rows.length + 1;
}

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows } = props;

  const handleClick = () => {
    const id = generateId(rows);
    setRows((oldRows) => [...oldRows, { id, name: '', behavior: 'NO_CHANGE', mandatory: false, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };
  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

const MyCheckpoints = ({ customChecks = [], updateNumber }) => {

  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  useEffect(() => {
    // This effect updates rows based on customChecks
    const newRows = customChecks.map((check, index) => ({
      id: index + 1,
      name: check.name,
      behavior: check.behavior,
      mandatory: check.mandatory,
    }));
    setRows(newRows);
    console.log("Rows updated", newRows);
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleUpdateCheckpoints = useCallback(async () => {

    const newChekcs = rows.map((row) => ({
      name: row.name,
      behavior: row.behavior,
      mandatory: row.mandatory,
    }));
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        await updateCompany({
          variables: {
            company_id,
            custom_checks: newChekcs,
          },
        });
        window.alert(
          localStorage.getItem("language") === "es"
            ? "Cambio realizado"
            : "Change was made successfully"
        );
        updateNumber();
      }
    } catch (error) {
      console.error("Error updating checkpoints:", error);
    }
  }, [rows, updateCompany, updateNumber]);

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const translations = {
    NO_CHANGE: {
      en: 'No Change',
      es: 'Sin Cambio',
      // Add more languages as needed
    },
    ENABLE_ALERTS: {
      en: 'Enable Alerts',
      es: 'Activar Alertas',
      // Add more languages as needed
    },
    DISABLE_ALERTS: {
      en: 'Disable Alerts',
      es: 'Desactivar Alertas',
      // Add more languages as needed
    },
  };

  const getLabel = (key) => {
    const currentLanguage = localStorage.getItem("language");
    return translations[key][currentLanguage];
  };


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Description', width: "auto", flex: 1, editable: true },
    {
      field: 'behavior',
      headerName: 'Behavior',
      width: 130,
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 'NO_CHANGE', label: getLabel('NO_CHANGE') },
        { value: 'ENABLE_ALERTS', label: getLabel('ENABLE_ALERTS') },
        { value: 'DISABLE_ALERTS', label: getLabel('DISABLE_ALERTS') },
      ],
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.value,
    },
    {
      field: 'mandatory',
      headerName: 'Mandatory',
      width: 130,
      renderCell: (params) => (
        <Checkbox
          checked={Boolean(params.value)}
          onChange={(event) => {
            const newRows = rows.map(row => row.id === params.id ? { ...row, mandatory: event.target.checked } : row);
            setRows(newRows);
          }}
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, rows },
        }}
      />
    </Box>
  );
};

export default MyCheckpoints;
