import React, { useState, useEffect } from "react";
import styles from "./connected.module.scss";
import { Button, CircularProgress } from "@mui/material";
import QRCode from "react-qr-code";

import axios from "axios";
const UART_SERVICE_UUID = "6e400001-b5a3-f393-e0a9-e50e24dcca9e"; // Replace with actual UART service UUID
const UART_TX_CHARACTERISTIC_UUID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e"; // Replace with actual TX characteristic UUID
const UART_RX_CHARACTERISTIC_UUID = "6e400003-b5a3-f393-e0a9-e50e24dcca9e"; // Replace with actual RX characteristic UUID

const HTRACE_SERVICE_UUID = "f3641400-00b0-4240-ba50-05ca45bf8abc";
const TEMP_CHAR_UUID = "f3641401-00b0-4240-ba50-05ca45bf8abc";
const HALL_CHAR_UUID = "f3641403-00b0-4240-ba50-05ca45bf8abc";
const BATTERY_SERVICE_UUID = "0000180f-0000-1000-8000-00805f9b34fb";
const BATTERY_CHAR_UUID = "00002a19-0000-1000-8000-00805f9b34fb";

const Connected = () => {
  const [devices, setDevices] = useState([]);

  const [device, setDevice] = useState({
    id: "1",
    name: "Device 1",
    connected: true,
    temperature: null,
    batteryLevel: 100,
    qr: "111111111",
  }); //harcodeado para test
  const [server, setServer] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(
    /* 'Disconnected' */ "Connected" //harcodeado para test
  );
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [temperature, setTemperature] = useState(-25); //valor harcodeado para test
  const [batteryLevel, setBatteryLevel] = useState(20); //valor harcodeado para test

  useEffect(
    () => {
      //connect to some endpoint to get the device data with axios and qr code
      //hacer en el useEffect la logica de carga de datos

      const fetchData = async () => {
        //ejemplo, agregar la carga de datos correspondientes
        try {
          const response = await axios.get("https://api.h-trace.com/connect/1");
          console.log(response.data);
          setDevice(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    },
    [
      /* agregar dependencias(variables o funciones q vuelven a disparar el effect) */
    ]
  );

  const scanForDevices = async () => {
    try {
      console.log("Starting device scan...");
      const device = await navigator.bluetooth.requestDevice({
        optionalServices: [
          "device_information",
          UART_SERVICE_UUID,
          HTRACE_SERVICE_UUID,
          BATTERY_SERVICE_UUID,
        ],
        filters: [
          {
            namePrefix: "RCT",
          },
        ],
      });
      console.log("Device found:", device);
      setDevices([device]);
    } catch (error) {
      console.error("Error scanning for devices", error);
    }
  };

  const connectToDevice = async (device) => {
    try {
      console.log("Setting loading to true");
      setLoading(true);
      console.log("Requesting device:", device.name);
      const gattServer = await device.gatt.connect();
      console.log("GATT server connected:", gattServer);

      setDevice(device);
      setServer(gattServer);
      setConnectionStatus(gattServer?.connected ? "Connected" : "Disconnected");
      console.log("Connected to device:", device);

      await logAvailableServices(gattServer); // Log available services
      startMonitoring(gattServer);
    } catch (error) {
      console.error("Error connecting to the device:", error);
      setConnectionStatus("Failed to connect");
    } finally {
      console.log("Setting loading to false");
      setLoading(false);
    }
  };

  const logAvailableServices = async (gattServer) => {
    try {
      const services = await gattServer.getPrimaryServices();
      services.forEach((service) => {
        console.log(`Service: ${service.uuid}`);
        service.getCharacteristics().then((characteristics) => {
          characteristics.forEach((characteristic) => {
            console.log(
              `Characteristic: ${
                characteristic.uuid
              } - Properties: ${JSON.stringify(characteristic.properties)}`
            );
          });
        });
      });
    } catch (error) {
      console.error("Error retrieving services:", error);
    }
  };

  const checkConnectionStatus = () => {
    if (server) {
      console.log("Checking connection status...");
      setConnectionStatus(server.connected ? "Connected" : "Disconnected");
    } else {
      console.log("No server to check.");
      setConnectionStatus("No server");
    }
  };

  const sendUartCommand = async () => {
    if (!server || !device) {
      console.log("No device connected");
      return;
    }
    try {
      console.log("Getting UART service...");
      const service = await server.getPrimaryService(UART_SERVICE_UUID);
      console.log("Getting TX characteristic...");
      const txCharacteristic = await service.getCharacteristic(
        UART_TX_CHARACTERISTIC_UUID
      );

      if (!txCharacteristic.properties.write) {
        console.error("TX characteristic does not support writing");
        return;
      }

      console.log("Getting RX characteristic...");
      const rxCharacteristic = await service.getCharacteristic(
        UART_RX_CHARACTERISTIC_UUID
      );

      if (!rxCharacteristic.properties.notify) {
        console.error("RX characteristic does not support notifications");
        return;
      }

      console.log("Setting up notifications for RX characteristic...");
      await rxCharacteristic.startNotifications();
      rxCharacteristic.addEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );

      console.log('Writing "T" command to TX characteristic...');
      await txCharacteristic.writeValue(new TextEncoder().encode("T"));
    } catch (error) {
      console.error("Error communicating with the UART service:", error);
    }
  };

  const handleNotifications = (event) => {
    const value = event.target.value;
    const responseText = new TextDecoder().decode(value);
    console.log("Notification received:", responseText);
    setResponse(responseText);
  };

  const startMonitoring = async (gattServer) => {
    try {
      const htraceService = await gattServer.getPrimaryService(
        HTRACE_SERVICE_UUID
      );
      const tempCharacteristic = await htraceService.getCharacteristic(
        TEMP_CHAR_UUID
      );
      const batteryService = await gattServer.getPrimaryService(
        BATTERY_SERVICE_UUID
      );
      const batteryCharacteristic = await batteryService.getCharacteristic(
        BATTERY_CHAR_UUID
      );

      if (tempCharacteristic.properties.notify) {
        await tempCharacteristic.startNotifications();
        tempCharacteristic.addEventListener(
          "characteristicvaluechanged",
          handleTempNotifications
        );
      }

      if (batteryCharacteristic.properties.notify) {
        await batteryCharacteristic.startNotifications();
        batteryCharacteristic.addEventListener(
          "characteristicvaluechanged",
          handleBatteryNotifications
        );
      }
    } catch (error) {
      console.error("Error starting monitoring:", error);
    }
  };

  const handleTempNotifications = (event) => {
    const value = event.target.value;
    const temp = new TextDecoder().decode(value);
    console.log("Temperature notification received:", temp);
    setTemperature(temp);
  };

  const handleBatteryNotifications = (event) => {
    const value = event.target.value;
    const battery = value.getUint8(0);
    console.log("Battery notification received:", battery);
    setBatteryLevel(battery);
  };

  return (
    <div className={styles.container}>
      
      {/* <div className={styles.titleAndButtonContainer}>
        <h1 className={styles.title}>Available Bluetooth Devices</h1>
        <Button variant="contained" onClick={scanForDevices}>
          Scan for Devices
        </Button>
      </div>

      <div className={styles.devicesList}>
        {devices.map((device, index) => (
          <div className={styles.devicesListItem} key={index}>
            {`Qr: ${device.qr}` || `Device ${index + 1}`}
            <Button variant="contained" onClick={() => connectToDevice(device)}>
              Connect
            </Button>
          </div>
        ))}
      </div>  */}

   
         {/* <div className={styles.cardContainer}>
           <h2 className={styles.title}>Connection Status</h2>
           <p>Device: {device ? device.name : "No device selected"}</p>
           <p>Status: {connectionStatus}</p>
            {device && (
             <button onClick={checkConnectionStatus}>
               Check Connection Status
             </button>
           )} 
           {connectionStatus === "Connected" && (
             <>
                <button onClick={sendUartCommand}>Send UART Command "T"</button> 
               <p>Response: {response}</p>
               <p>
                 Temperature:{" "}
                 {temperature !== null ? `${temperature} °C` : "N/A"}
               </p>
               <p>
                 Battery Level:{" "}
                 {batteryLevel !== null ? `${batteryLevel} %` : "N/A"}
               </p>
             </>
           )}
         </div> */}

        <div className={styles.cardContainer}>
        <div className={styles.title}>Connection Status</div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontFamily: "DM Sans, sans-serif",
                position: "relative",
                top:"-40px"
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              
              <div className={styles.infoContainer}>
                <div className={styles.qr}>
                  <QRCode
                    value={device.qr.toString()}
                    style={{
                      height: "30%",
                    }}
                  />
                  <span className={styles.qrNumber}>{device.qr}</span>
                </div>

                <div className={styles.tempContainer}>
                  <div className={styles.temp}>
                    {temperature !== null ? `${temperature} °C` : "N/A"}
                  </div>
                  <div className={styles.battery}>
                    {batteryLevel !== null
                      ? `Battery level: ${batteryLevel} %`
                      : "N/A"}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      
    </div>
  );
};

export default Connected;
