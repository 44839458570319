import React from "react";
import styles from "./ViajesEnTransito.module.scss";
import viajesEnCurso from "./viajesEnCurso.png";
import CircularProgress from "@mui/material/CircularProgress";
import { FormattedMessage } from "react-intl";

export default function ViajesEnTransito({ data, loading }) {
  return (
    <>
    {console.log(data)}
    {data!==0 ?
     <div className={styles.card}> 
      {!loading ? (
        <>
          <div className={styles.titleContainer}>
            <img className={styles.icon} src={viajesEnCurso} alt="" />
            <div className={styles.title}>
              <FormattedMessage id="in_transit_ships_title">
                In transit
              </FormattedMessage>{" "}
            </div>
          </div>
          <div className={styles.circle}>
            {
          data && data > 0 ? (
            <div className={styles.circleNumber}>{data}</div>
          ) : (
            <div className={styles.circleNumber}>0</div>
          )
          }
          </div>
        </>
      ) : (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
    </div>
    :
    <div className={styles.noShipmetsInTransit}>
       <FormattedMessage id="no hay envios en transito con ese rango de temperatura">
       no hay envios en transito
        </FormattedMessage>{" "}
    </div>
    }
   
    </>
   
  );
}
