import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { useEffect } from "react";
import { GET_COMPANY_ALERT_PARAMS } from "../../../queries";

export const useGetAlerts = () => {
  const [companyAlertParams, setCompanyAlertParams] = useState({});

  //traer alertParams  GET_COMPANY_ALERT_PARAMS//
  const [
    getCompanyInfo,
    {
      // loading: companyDetailLoading,
      error: companyDetailError,
      data: companyDetailData,
    },
  ] = useLazyQuery(GET_COMPANY_ALERT_PARAMS);
  

  useEffect(() => {
    let result = null;
    const fetch = async () => {
      try {
        result = await getCompanyInfo();
      } catch (error) {
        console.log(error);
      }
      setCompanyAlertParams(result?.data.company.alert_params);
    };
    fetch();
  }, []);

  return { companyAlertParams };
};
