import React, { useState, useEffect, useContext } from "react";
import {
  GET_SHIPMENT_COMMENTS,
  ADD_COMMENT,
  GET_REFRIGERATION_COMMENTS,
  ADD_REFRIGERATION_COMMENT,
  UPDATE_REFRIGERATION_ALERTS,
  GET_REFRIGERATION,
} from "../../queries";
import { useMutation, useLazyQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./Comments.module.scss";

import {
  CircularProgress,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import { language } from "../../const";
import { IoMdSend } from "react-icons/io";
import { AlertsContext } from "../../context/alerts/AlertsProvider";
import { set } from "date-fns";

export default function Comments({
  shipment_id,
  rc_freeze,
  qr,
  refrigeration_id,
  shipment_table,
  alertComments,
  alert,
  freezer,
  refetchAlertComments,
  onActionNewAlertComment,
  refetchComments,
  from,
  to,
}) {
  const intl = useIntl();
  const getTime = (time) => {
    let date = new Date(time);
    const timezoneOffset = date.getTimezoneOffset();
    date.setHours(date.getHours() + timezoneOffset);

    let month = date.getMonth() + 1; // Los meses van de 0 a 11, por lo que hay que sumarle 1
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours <= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // el 0 se convierte en 12
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = month + "/" + day + " " + hours + ":" + minutes + " " + ampm;

    return strTime;
  };
  const { alerts, setAlerts, viewed } = useContext(AlertsContext);
  // ESTADOS LOCALES ---------------------------------------------------------------------
  // const { shipData, setShipData } = useContext(ShipmentsContext);
  //estado para el boton add comment
  const [isAddComment, setIsAddComment] = useState(false);

  //estado para el input del comment
  const [commentAdded, setCommentAdded] = useState({
    text: "",
    timestamp: null,
  });
  const [text, setText] = useState("");
  // QUERIES/MUTATIONS --------------------------------------------------------------------
  //lazy query para updatear cuando se agrega un comentario
  const [
    getComments,
    {
      loading: lazyCommentsLoading,
      error: lazyCommentsError,
      data: lazyCommentsData,
    },
  ] = useLazyQuery(GET_SHIPMENT_COMMENTS, {
    variables: {
      shipment_id,
    },
  });

  const [
    getRefrigerationComments,
    {
      loading: refrigerationCommentsLoading,
      error: refrigerationCommentsError,
      data: refrigerationCommentsData,
    },
  ] = useLazyQuery(GET_REFRIGERATION_COMMENTS, { fetchPolicy: "network-only" });

  //mutation para agregar comentario

  const [
    addComment,
    {
      loading: commentAddedLoading,
      error: commentAddedError,
      data: commentAddedData,
    },
  ] = useMutation(
    ADD_COMMENT,
    {
      variables: {
        shipment_id,
        comment_text: commentAdded.text,
        timestamp: commentAdded.timestamp,
      },
    },
    { fetchPolicy: "network-only" }
  );

  const [
    addRefrigerationComment,
    {
      loading: refCommentLoadding,
      error: refCommentError,
      data: refCommentData,
    },
  ] = useMutation(ADD_REFRIGERATION_COMMENT);
  //static---------------------------------------------------------------------

  const [
    updateAlertText,
    { loading: updateAlertLoading, error: updateAlertError },
  ] = useMutation(UPDATE_REFRIGERATION_ALERTS, {
    refetchQueries: [
      {
        query: GET_REFRIGERATION,
        variables: {
          from: null,
          to: null,
          qr: freezer?.qr,
        },
      },
    ],
  });
  //CUANDO SE INICIA EL COMPONENTE ------------------------------------------------------------------
  useEffect(() => {
    if (rc_freeze) {
      //refrigeration comment
      const fetchRefrigerationComments = async () => {
        let result = await getRefrigerationComments({
          variables: {
            qr: qr,
            from: from,
            to: to,
          },
        });
        //id para borrar del array del context
        let idToDelete = result?.data?.refrigeration?.refrigeration_id;
      

        setAlerts((prev)=>({...prev, static_id: prev.static_id.filter((id) => id !== idToDelete)}))
        if(alerts?.static_id?.length === 0){
          setAlerts({
            icon:false,
            static_id:[]
          })
        }
      };
      fetchRefrigerationComments();
    }
    if (shipment_id) {
      //shipment comments
      const fetchComments = async () => {
        let result = await getComments();
      };
      fetchComments();
    }
  }, [
    /* lazyCommentsData, */ refrigerationCommentsData,
    refetchComments,
    from,
    to,
  ]);

  //HANDLERS ----------------------------------------------------------------------------------
  //handler del input de comentario agregado
  const handleOnChangeComment = (e) => {
    if (rc_freeze) {
      setCommentAdded((prevState) => ({
        ...prevState,
        text: e.target.value,
      }));
    } else {
      //si quiero hacer un comentario gral en el envio
      setCommentAdded((prevState) => ({
        ...prevState,
        text: e.target.value,
      }));
    }
  };

  //handler del confirm del comentario agregado
  const handleConfirmComment = async () => {
    try {
      if (window.confirm(intl.formatMessage({ id: "are_you_sure" }))) {
        await addComment({
          variables: {
            shipment_id,
            comment_text: commentAdded.text,
            timestamp: commentAdded.timestamp,
          },
          refetchQueries: [
            {
              query: GET_SHIPMENT_COMMENTS,
              variables: { shipment_id: shipment_id },
            },
          ],
        });
        setCommentAdded({ text: "", timestamp: null });

        alert(intl.formatMessage({ id: "new_comment_added" }));

        setIsAddComment(false);
        await getComments();
      }
    } catch (error) {
      console.log("hubo un error en el comentario agregado", error);
    }
  };

  const addRefComment = async () => {
    try {
      if (
        window.confirm(
          intl.formatMessage({ id: "are_you_sure_confirmChanges" })
        )
      ) {
        await addRefrigerationComment({
          variables: {
            refrigeration_id: refrigeration_id,
            comment_text: commentAdded.text,
          },
          refetchQueries: [
            { query: GET_REFRIGERATION_COMMENTS, variables: { qr: qr } },
          ],
        });

        setCommentAdded({ text: "", timestamp: null });
        setIsAddComment(false);

        await getRefrigerationComments({
          variables: {
            qr: qr,
          },
        });
      }
    } catch (error) {
      console.log("hubo un error en el comentario agregado", error);
    }
  };

  //agregar comentarios a las alertas del static
  const editTextToAlertComment = (e) => {
    setText(e.target.value);
    onActionNewAlertComment(e, alert);
  };

  //MANEJO DE ERRORES ------------------------------------------------------------------------
  if (lazyCommentsError || refrigerationCommentsError)
    console.log("hubo un error en la carga de comentarios", lazyCommentsError);

  if (commentAddedError || commentAddedError)
    console.log("hubo un error en el comentario agregado", commentAddedError);

  if (alertComments) {
    return (
      <div className={styles.alertCommentText_button_container}>
        <input
          type="text"
          name="text"
          className={styles.alertCommentText}
          placeholder={intl.formatMessage({ id: "new_comment" })}
          value={text || ""}
          onChange={editTextToAlertComment}
        />
      </div>
    );
  }
  if (rc_freeze) {
    return (
      <>
        <div className={styles.fContainer}>
          <h3 className={styles.title}>
            <FormattedMessage id="comments_of">
              Comments of shipment
            </FormattedMessage>{" "}
          </h3>
          <div className={styles.f_commentsAndAddContainer}>
            {/* COMENTARIOS ---------------------------------------------------- */}

            <div className={styles.f_commentsContainer}>
              {refrigerationCommentsLoading || refCommentLoadding ? (
                <div className={styles.f_loading}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {refrigerationCommentsData?.refrigeration?.comments?.length >
                  0 ? (
                    refrigerationCommentsData?.refrigeration?.comments?.map(
                      (c, i) => {
                        return (
                          <div className={styles.eachComment} key={i + 0.5}>
                            <p className={styles.text}>{c.text}</p>

                            <div className={styles.author}>
                              {(c?.author === "0" ? "SUDO" : c?.author) +
                                "  " +
                                getTime(c?.date)}
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className={styles.noComments}>
                      <FormattedMessage id="no_comments">
                        No comments on this shipment
                      </FormattedMessage>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* AGREGAR COMENTARIO -------------------------------------------------------- */}

            <div className={styles.deleteButton_input_saveButtton_container}>
              <div className={styles.addCommentContainer}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  placeholder={intl.formatMessage({ id: "new_comment" })}
                  onChange={handleOnChangeComment}
                  className={rc_freeze ? styles.fInput : styles.input}
                  maxLength="256"
                  value={commentAdded.text}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IoMdSend
                          onClick={addRefComment}
                          className={styles.sendIcon}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    // cuadro de comentarios de TablaViajes en el dashboard(creo q esto ya no se usa!!!!)
    if (shipment_table) {
      return (
        <>
          <div className={styles.table_container}>
            <h3 className={styles.title}>
              <FormattedMessage id="comments_of">
                Comments of shipment
              </FormattedMessage>{" "}
              {/* {shipment_id.split("-")[1]} */}
            </h3>
            <div className={styles.table_commentsAndAddContainer}>
              {/* COMENTARIOS ---------------------------------------------------- */}
              <div className={styles.table_commentsContainer}>
                {lazyCommentsData?.shipment?.comments.length > 0 ? (
                  lazyCommentsData?.shipment?.comments?.map((c, i) => {
                    return (
                      <div className={styles.eachComment} key={i}>
                        <p className={styles.text}>{c.text}</p>

                        <div className={styles.author}>
                          {(c?.author === "0" ? "SUDO" : c?.author) +
                            "  " +
                            getTime(c?.date)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className={styles.noComments}>
                    {rc_freeze ? (
                      "NO freeze comments"
                    ) : (
                      <FormattedMessage id="no_comments">
                        No comments on this shipment
                      </FormattedMessage>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* AGREGAR COMENTARIO -------------------------------------------------------- */}
            <div className={styles.table_addCommentContainer}>
              <input
                type="text"
                placeholder={
                  localStorage.getItem("language") === "en"
                    ? "Write a comment..."
                    : "Escribe un comentario..."
                }
                onChange={handleOnChangeComment}
                className={rc_freeze ? styles.fInput : styles.input}
                maxLength="256"
                value={commentAdded.text}
              />
              <div className={styles.buttonsContainer}>
                <button
                  onClick={handleConfirmComment}
                  className={rc_freeze ? styles.fButton : styles.button}
                >
                  <FormattedMessage id="save_button">Save</FormattedMessage>
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      //cuadro de comentarios de los reportes
      return (
        <>
          <div className={rc_freeze ? styles.fContainer : styles.container}>
            <div className={styles.title}>
              <FormattedMessage id="comments_of">
                Comments of shipment
              </FormattedMessage>{" "}
              {/* {shipment_id.split("-")[1]} */}
            </div>
            <div className={styles.commentsAndAddContainer}>
              {/* COMENTARIOS ---------------------------------------------------- */}
              <div className={styles.commentsContainer}>
                {lazyCommentsData?.shipment?.comments.length > 0 ? (
                  lazyCommentsData?.shipment?.comments?.map((c, i) => {
                    return (
                      <div className={styles.eachComment} key={i}>
                        <p className={styles.text}>{c.text}</p>

                        <div className={styles.author}>
                          {(c?.author === "0" ? "SUDO" : c?.author) +
                            "  " +
                            getTime(c?.date)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className={styles.noComments}>
                    {rc_freeze ? (
                      "NO freeze comments"
                    ) : (
                      <FormattedMessage id="no_comments">
                        No comments on this shipment
                      </FormattedMessage>
                    )}
                  </div>
                )}
              </div>
              {/* AGREGAR COMENTARIO -------------------------------------------------------- */}

              <div className={styles.deleteButton_input_saveButtton_container}>
                <div className={styles.addCommentContainer}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    placeholder={intl.formatMessage({ id: "new_comment" })}
                    onChange={handleOnChangeComment}
                    className={rc_freeze ? styles.fInput : styles.input}
                    maxLength="256"
                    value={commentAdded.text}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IoMdSend
                            onClick={handleConfirmComment}
                            className={styles.sendIcon}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
