import React, { useEffect, useState } from "react";
import { usersType } from "../../../const";
import {
  GET_COMPANY_DETAIL,
  CREATE_USER,
  GET_COMPANIES,
  GET_DISTRIBUTORS,
} from "../../../queries";
import {
  useQuery,
  useMutation,
  operationName,
  useLazyQuery,
} from "@apollo/client";
import { FormattedMessage } from "react-intl";
import styles from "./CreateUser.module.scss";
import Dialog from "@mui/material/Dialog";

export default function CreateUser({ company_id, onAction }) {
 
  // ESTADOS LOCALES -----------------------------------------------------------------------------
  //estados para la seleccion de types
  const [typeSelected, setTypeSelected] = useState("");
  const [dist_id, setDist_id] = useState("");
  const [comp_id, setComp_id] = useState("");
  const [freeOpes, setFreeOpes] = useState([]);
  

  //estado para guardarme la data de los input
  const [inputsData, setInputsData] = useState({
    belong_id: company_id ? company_id : "",
    username: "",
    email: "",
    type: "",
    role: "",
    password: "",
    repeatedPassword: "",
  });

  //estado para el valor default del select assign
  const [selectedDefaultValue, setSelectedDefaultValue] = useState(true);

  //estado para la confirmación del create user
  const [confirmMessage, setConfirmMessage] = useState(false);

  //QUERIES/MUTATIONS ----------------------------------------------------------------------------
  //query para traerme los operadores según tipo

  const {
    // loading: companyDetailLoading,
    // error: companyDetailError,
    data: distributors,
  } = useQuery(GET_DISTRIBUTORS);



  //mutation para crear usuarios
  const [
    createUser,
    { createdUserData, createdUserLoading, createdUserError },
  ] = useMutation(CREATE_USER);

  //query para traer todas las compañias
  const [
    getCompaniesFromDis,
    { data: companiesArray, error: companiesError, loading: companiesLoading },
  ] = useLazyQuery(GET_COMPANIES);

  const [
    getFreeOperators,
    {
      data: freeOperators,
      loading: freeOperatorsLoading,
      // error: freeOperatorsError,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL);


  useEffect(() => {
    const fetchFreeOpes = async () => {
      await getFreeOperators({ variables: { company_id: comp_id } });
    };
    setFreeOpes(freeOperators?.company?.free_operators);
    fetchFreeOpes();
  }, [freeOperators,
     comp_id,
      freeOpes]);

  //USE EFFECT PARA QUE QUEDE ESCUCHANDO LOS CAMBIOS DE TYPE ------------------------------------------------------------
  useEffect(() => {
    setInputsData({
      ...inputsData,
      role: "",
    });
  }, [inputsData.type]);

  //traigo las companias del DIS
  useEffect(() => {
    const fetchCompanies = async () => {
      await getCompaniesFromDis({ variables: { belong_id: dist_id } });
    };
    fetchCompanies();
  }, [dist_id, companiesArray]);

  // HANDLERS ------------------------------------------------------------------------------------
  //handler del select type
  const handleTypeSelect = (e) => {
    setTypeSelected(e.target.value);
    setSelectedDefaultValue(true);
    setInputsData({
      ...inputsData,
      role: "",
    });
    if (e.target.value === "Administrador" || e.target.value === "Admin") {
      setInputsData({
        ...inputsData,
        type: "Admin",
      });
    } else if (e.target.value === "Operario" || e.target.value === "Operator") {
      setInputsData({
        ...inputsData,
        type: "Operator",
      });
    } else if (
      e.target.value === "Calibrador" ||
      e.target.value === "Calibrator"
    ) {
      setInputsData({
        ...inputsData,
        type: "Calibrator",
      });
    } else if (
      e.target.value === "Transportista" ||
      e.target.value === "Shipper"
    ) {
      setInputsData({
        ...inputsData,
        type: "Shipper",
      });
    }
  };

  //handler del onChange de los input
  const handleOnChangeInputs = (e) => {
    setInputsData({
      ...inputsData,
      [e.target.name]: e.target.value,
    });
  };

  //handler del confirm changes
  const handleConfirm = async (e) => {
    e.preventDefault();
    
    try {
      if (inputsData.password !== inputsData.repeatedPassword) {
        return alert("The passwords do not match");
      } /* else if (inputsData.type !== "Admin" && inputsData.assigned === "") {
        return alert("Please assign the user"); */ else {
        if (
          window.confirm(
            localStorage.getItem("language") === "es"
              ? "¿Estás seguro que quieres guardar cambios?"
              : "Are you sure you want to confirm changes?"
          )
        ) {
          await createUser({
            variables: {
              belong_id: inputsData.belong_id,
              username: inputsData.username,
              email: inputsData.email,
              role:
                inputsData.role === ""
                  ? inputsData.type
                  : inputsData.role,
              password: inputsData.password,
            },
          });
          company_id && onAction();
          window.alert("usuario creado con exito!");
          window.location.reload("/");
        }
      }
    } catch (error) {
      console.log("hubo un error en el create user", error);
      if (createdUserError) alert(createdUserError);
      alert(error);
    }
  };

  //handler del select de assign
  const handleAssignChange = (e) => {
    setSelectedDefaultValue(false);
    if (e.target.value === "default select") {
      setInputsData({
        ...inputsData,
        role: "",
      });
    } else if (e.target.value === "" && inputsData.type === "Shipper") {
      setInputsData({ ...inputsData, role: "Shipper" });
    } else if (e.target.value === "" && inputsData.type === "Calibrator") {
      setInputsData({ ...inputsData, role: "Calibrator" });
    } else if (e.target.value === "" && inputsData.type === "Operator") {
      setInputsData({ ...inputsData, role: "Operator" });
    } else {
      setInputsData({
        ...inputsData,
        role: e.target.value,
      });
    }
  };

  //handler del select company asignada (SUDO)
  const handleBelongDistSelect = async (e) => {
    setInputsData({
      ...inputsData,
      belong_id: e.target.value,
    });
    setDist_id(e.target.value);
  };
  const handleBelongCompanySelect = (e) => {
    setInputsData({
      ...inputsData,
      belong_id: e.target.value,
    });
    
    setComp_id(e.target.value);
  };
  return (
    <div className={styles.container}>
      <span
        className={styles.createTitle}
        style={{
          marginLeft: localStorage.getItem("language") === "en" ? "25%" : "20%",
        }}
      >
        {" "}
        <FormattedMessage id="create_user_button">
          Create new user
        </FormattedMessage>
      </span>
      <form style={{ width: "100%", height: "20vw" }}>
        {!company_id && (
          <>
            {/* PERTENECE A -------------------------------------------------------------------- */}
            <span className={styles.eachSection}>
              <label className={styles.eachLabel}>
               Distributor
              </label>

              <select
               
                onChange={handleBelongDistSelect}
                className={styles.select}
              >
               
               
                {distributors?.distributors?.map((dis, i) => {
                  return (
                    <option value={dis?.distributor_id} key={i + 3}>
                      {dis.distributor_id}
                    </option>
                  );
                })}
              </select>
            </span>

            <span className={styles.eachSection}>
              <label className={styles.eachLabel}>
             Company
              </label>

              <select
               
                onChange={handleBelongCompanySelect}
                className={styles.select}
              >
               

                {companiesArray?.companies?.map((c, i) => {
                  return (
                    <option key={i + 4} value={c.company_id}>
                      {c.company_id}
                    </option>
                  );
                })}
                <option value="">{dist_id}</option>
              </select>
            </span>
          </>
        )}
        
        {/* NOMBRE DE USUARIO -------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="username_config">Username</FormattedMessage>{" "}
          </label>
          <input
            type="text"
            name="username"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        {/* EMAIL -------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>Email</label>
          <input
            type="email"
            name="email"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        {/* CONTRASEÑA -------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="password_config">Password</FormattedMessage>
          </label>
          <input
            type="password"
            name="password"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        {/* REPETIR CONTRASEÑA ------------------------------------------------------------------------------ */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="repeat_password_config">
              Repeat password
            </FormattedMessage>
          </label>
          <input
            type="password"
            name="repeatedPassword"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <div className={styles.select_container}>
          {/* TIPO ---------------------------------------------------------------------------------- */}
          <span className={styles.select_section}>
            <label className={styles.eachLabel}>
              <FormattedMessage id="branch_type_config">Type</FormattedMessage>{" "}
            </label>
            <select
              defaultValue="default select"
              onChange={handleTypeSelect}
              className={styles.select}
            >
              <option disabled hidden value="default select">
                {localStorage.getItem("language") === "en"
                  ? "Select type of user"
                  : "Seleccione tipo de usuario"}
              </option>
              {usersType.map((u) => {
                return (
                  <option key={u} value={u}>
                    {u}
                  </option>
                );
              })}
            </select>
          </span>
          {/* ASIGNADO A ------------------------------------------------------------------------------- */}
          <span className={styles.select_section}>
            {inputsData.type !== "" && inputsData.type !== "Admin" && (
              <>
                <label className={styles.eachLabel}>
                  <FormattedMessage id="assigned_to_config">
                    Assigned to:{" "}
                  </FormattedMessage>{" "}
                </label>
                <select
                  // defaultValue="default select"
                  onClick={handleAssignChange}
                  className={styles.select}
                >
                  <option disabled hidden value="default select">
                    {(localStorage.getItem("language") === "en"
                      ? "Select your "
                      : "Seleccione su ") + typeSelected.toLowerCase()}
                  </option>
                  <option value={""}>
                    {localStorage.getItem("language") === "en"
                      ? "All"
                      : "Todos"}
                  </option>

                  {freeOpes?.length !== 0 &&
                    freeOpes
                      ?.filter((o) => o.type === inputsData.type)
                      .map((o) => {
                        return (
                          <option value={o.operator_id} key={o.operator_id}>
                            {o.name}({o.operator_id})
                          </option>
                        );
                      })}
                </select>
                <br />
              </>
            )}
          </span>
        </div>

        {/* GUARDAR ------------------------------------------------------------------------------- */}
        <button
          type="submit"
          onClick={handleConfirm}
          className={styles.createButton}
          disabled={
            !inputsData.username ||
            !inputsData.email ||
            !inputsData.password ||
            !inputsData.repeatedPassword
              ? true
              : false
          }
        >
          <FormattedMessage id="save_button">Save</FormattedMessage>
        </button>
        <Dialog open={confirmMessage}>
          {localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"}
        </Dialog>
      </form>
    </div>
  );
}
