

export const placesReducer = (state, action) => {


  switch (action.type) {
    case 'setDeviceLocation':

      return {
        ...state,
        isLoading: false,
        deviceLocation: action.payload
      }
    case 'getDevices':
      return {
        ...state,
        isLoading: false,
        devices: action.payload
      }


    default:
      return state;
  }
}

