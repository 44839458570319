import { StylesProvider } from "@material-ui/core";
import { Box, Modal } from "@mui/material";
import { useState } from "react";
import styles from "./freezers.module.scss";
import { useMutation } from "@apollo/client";
import {
  CREATE_REFRIGERATION,
  GET_COMPANY_ALERT_PARAMS,
} from "../../../../queries";
import { language } from "../../../../const";
import { useIntl } from "react-intl";

const CreateFreezerModal = ({
  freezerModal,
  closeCreateFreezerModal,
  companyDetailData,
  freeDevices,
}) => {
const intl=useIntl();
  //   //traigo temp range Y FREEDEVICES  GET_COMPANY_ALERT_PARAMS
  const [
    createREfrigeration,
    {
      loading: createRefrigerationLoading,
      error: createRefrigerationLoadingError,
    },
  ] = useMutation(CREATE_REFRIGERATION);

  const [createFreezer, setCreateFreezer] = useState({
    name: "",
    temp_range: "",
    assigned_to: "",
    type: ""
  });

  const handleChange = (e) => {
    setCreateFreezer({ ...createFreezer, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
   
    try {
      if (
        window.confirm(
         intl.formatMessage({ id: "are_you_sure_confirmChanges" })
        )
      ) {
        let newRefrigeration = await createREfrigeration({
          variables: {
            name: createFreezer?.name,
            temperature_range: createFreezer?.temp_range,
            qr: createFreezer?.assigned_to,
            type: createFreezer?.type
          },
        });

        alert(`RC_Static created`);
        window.location.reload();
      }


    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = () => {
    setCreateFreezer({
      name: "",
      temp_range: "",
      assigned_to: "",
      type: ""
    });
    closeCreateFreezerModal();
  };
  return (
    <Modal open={freezerModal} onClose={closeCreateFreezerModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          border: "0.15vw solid #00ABC8",
          borderRadius: "2vw",
          fontFamily: "'Quattrocento Sans', sans-serif",
          boxShadow: 10,
          pt: 2,
          px: 4,
          pb: 3,
          backgroundColor: "white",
          width: "30vw",
          height: "17vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
       
        <form onSubmit={handleSubmit} className={styles.createForm}>
          <div className={styles.inputsContainer}>
            {/* name */}
            <input
              type="text"
              placeholder= {intl.formatMessage({ id: "freezer_name" })}
              name="name"
              className={styles.nameInput}
              onChange={handleChange}
            />
            {/* type */}
            <input
              type="text"
              placeholder={intl.formatMessage({ id: "type" })}
              name="type"
              className={styles.nameInput}
              onChange={handleChange}
            />
            {/* tem. range */}
            <select
              onChange={handleChange}
              name="temp_range"
              className={styles.select_range}
            >
              <option disabled selected>
              {intl.formatMessage({ id: "temp_range" })}
              </option>

              {companyDetailData?.company?.alert_params?.temperature_alerts?.map(
                (range, i) => (
                  <option value={range?.name} key={i + 0.6}>{range?.name}</option>
                )
              )}
            </select>

            {/* assigned_to */}
            <select
              onChange={handleChange}
              name="assigned_to"
              id=""
              className={styles.select_assigned}
            >
              <option disabled selected>
              {intl.formatMessage({ id: "assigned_to" })}
              </option>

              {freeDevices?.map((qr) => (
                <option value={qr?.qr} key={qr.qr + 0.5}>{qr.qr}</option>
              ))}
            </select>
          </div>

          {/* buttons container */}
          <div className={styles.modal_buttons}>
            <button className={styles.createButton}>
            {intl.formatMessage({ id: "create" })}
            </button>
            <button onClick={handleCancel} className={styles.cancelButton}>
            {intl.formatMessage({ id: "cancel" })}
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};
export default CreateFreezerModal;
