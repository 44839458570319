import { useLazyQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GET_SHIPMENT_DETAIL } from "../../../../queries";
import TablaViajes from "../../../TablaViajes/TablaViajes";
import styles from "./checksGraph.module.scss";
import { AiOutlineDownload } from "react-icons/ai";
import { FormattedMessage } from "react-intl";

const ChecksGraph = ({ shipment_id, gmt, checkpointsData, onSelected }) => {
  const [data, setData] = useState({});

  // query para data de graficos
  const [
    getShipment,
    {
      // loading: contentLoading,
      error: contentError,
      data: contentData,
    },
  ] = useLazyQuery(GET_SHIPMENT_DETAIL);

  useEffect(() => {
    const fetchShipments = async () => {
      try {
        await getShipment({ variables: { shipment_id } });
        setData(contentData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchShipments();
  }, [contentData]);

  const totalDuration =
    data?.shipment?.acceleration[data?.shipment?.acceleration?.length - 1]
      ?.counter;

  const convertSeconds = (totalSeconds) => {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    //route map 
    <div className={styles.container}>
      <div className={styles.timeInTransit}>
        <FormattedMessage id={"time_in_transit"}>
          time in transit
        </FormattedMessage>
    
       {`: ${totalDuration ? convertSeconds(totalDuration) : ""}`}
      </div>{" "}

   
        <TablaViajes
          graphOnly={true}
          data={data}
          gmt={gmt}
          checkpointsData={checkpointsData}
          onSelected={onSelected}
        />
  
    </div>
  );
};

export default ChecksGraph;
