import React, { useState, useEffect } from "react";
import styles from "./Login.module.scss";
import TextField from "@mui/material/TextField";
import logoHtrace from "../../H+Trace_imagotipo_HORI.png";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LOGIN, GET_USER, GET_COMPANY_DETAIL } from "../../queries";

import ForgotMyPass from "../ForgotMyPass/ForgotMyPass";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { parseJwt, setLocalStorageData } from "../../helpers.js";
import SelectOperatorToLogin from "../SelectOperatorToLogin/SelectOperatorToLogin";
import { BASE_URL } from "../../const";
import { HiEye } from "react-icons/hi";
import { HiEyeOff } from "react-icons/hi";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
//creo un contexto para seleccion operario al loguearme

export default function Login() {
  if (window.navigator.language.split("-")[0] === "es") {
    localStorage.setItem("language", "es");
  } else localStorage.setItem("language", "en");

  const minWidth = useMediaQuery("(min-width:800px)");

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "white",
    border: "0.15vw solid #00ABC8",
    borderRadius: "2vw",
    fontFamily: "'Quattrocento Sans', sans-serif",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  //QUERIES/MUTATIONS -----------------------------------------------------------------------------
  let userId = "";
  const [login, { data, loading, error }] = useMutation(LOGIN);
  //query para traerme los operadores no asignados

  const [
    getCompanyDetail,
    {
      loading: companyDetailLoading,
      error: companyDetailError,
      data: companyDetailData,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL);

  //para traer la data del usuario

  const [
    getUser,
    {
      data: userDataLazyQuery,
      loading: userDataLazyQueryLoading,
      error: userDataLazyQueryError,
    },
  ] = useLazyQuery(GET_USER, {
    variables: {
      user_id: userId,
    },
  });

  //ESTADOS LOCALES -----------------------------------------------------------------------------------
  //estado para guardar la info de los input
  const [input, setInput] = useState({
    user: "",
    password: "",
  });
  const [user, setUser] = useState({});

  let token = "";
 
  //estado para el modal de recovery code
  const [recoveryModal, setRecoveryModal] = useState(false);
  //const estado para el modal de seleccion de operario
  const [selectOpeModal, setSelectOpeModal] = useState(false);

  //estado para ver si hay un error en el logeo
  const [userError, setUserError] = useState(false);
  const [seePass, setSeePass] = useState(false);
  // HANDLERS ------------------------------------------------------------------------------------------
  //handler del onChange del form
  const handleOnChange = function (e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let access_to;
    setUser(userDataLazyQuery);

    // si es admin se loguea de una
    if (user?.user) {
      if (user.user?.type === "Admin") {
        localStorage.setItem("access", "");
        localStorage.setItem("assigned_to", "");
        window.location.replace("/");
      }

      //si no es admin y es mono se loguea de una, sino se abre modal para elegir operario

      if (user.user?.type !== "Admin") {
        if (user?.user?.mono === false) {
          handleSelectOpeModal();
        } else {
          //busco a que compania pertenece para buscar el operario que tiene asignado
          const fetchCompanyData = async () => {
            await getCompanyDetail({
              variables: {
                company_id: user?.user?.belong_id,
              },
            });
          };
          fetchCompanyData();

          //seteo el objeto access_to del operario que tiene asaignado el usuario y lo guardo en localStorage
          access_to = companyDetailData?.company?.operators?.filter(
            (o) => o.operator_id === user?.user?.assigned_to
          )[0]?.access_to;

          if (access_to) {
            //esto es para setear la data en el context pero no funciona
            //asi que la guardo en localStorage
            // setAccessState(access_to);
            /* 
            el context no carga pq al hacer window.location se refresca la pagina 
            y pierdo los datos... ya pase context x props a home, 
            ahora desde ahi tengo que codear de nuevo las rutas protegidas
            (actualmente redirige desde login, lo cual esta mal pq guardo access en localStorage 
            y es hackeable)
            */
            // //-----------------------------------------------------//

            localStorage.setItem("access", JSON.stringify(access_to));
            localStorage.setItem("assigned_to", user?.user?.assigned_to);

            if (
              access_to.dash_control === false &&
              access_to.dash_intransit === true
            ) {
              window.location.replace(`${BASE_URL}/#in-coming`);
              window.location.reload(); //

              //window.location.replace(`/`);
            } else if (
              access_to.dash_control === false &&
              access_to.dash_report === true
            ) {
              window.location.replace(`${BASE_URL}/#reports`);
              window.location.reload(); //
            } else if (access_to.dash_control === true) {
              window.location.replace(`/`);
            }
          }
        }
      }
    }
  }, [userDataLazyQuery, user, token, companyDetailData]);

  //handler cuando submiteo el form
  let token_decoded = {};

  const handleSubmit = async function (e) {
    e.preventDefault();
    try {
      const result = await login({
        variables: { username: input.user, password: input.password },
      });
      token = result.data.login.token;

      if (parseJwt(token)?.SUDO) {
        setLocalStorageData(token, (Date.now() + 86400000).toString());
        localStorage.setItem("language", "es");
        localStorage.setItem("assigned_to", "null");
        window.location.replace(`/`);
      } else {
        //seteo el userId para traerme la info del usuario
        token_decoded = parseJwt(token);
        userId = token_decoded.user_id;
        console.log(token_decoded)
        getUser();
        setLocalStorageData(token, token_decoded.expires);
      }
    } catch (error) {
      console.log("error al logearse", error);
      setUserError(true);
    }
  };
  //hanler forgotPass

  const handleRecoveryModal = () => {
    setRecoveryModal(true);
  };

  const handleRecoveryModalClose = () => {
    setRecoveryModal(false);
  };

  //handlers de select operator
  const handleSelectOpeModal = () => {
    setSelectOpeModal(true);
  };
  const handleSelectOpeModalClose = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("expiration", "");
    localStorage.setItem("notifications", "");
    localStorage.setItem("notificationsENG", "");
    localStorage.setItem("numberNoti", "");
    localStorage.setItem("access", "");
    localStorage.setItem("assigned_to", null);
    window.location.replace("/");
    setSelectOpeModal(false);
  };
  const handleSeePass = () => {
    setSeePass(!seePass);
  };
  return (
    <div className={styles.loginContainer}>
      <img className={styles.logo} src={logoHtrace} alt="" />
      {/* 
      <div className={styles.logo}>
        este div reemplaza al logo, lo deje para que sea mas rapido el centrado.
         Eliminar y recentrar form 
      </div> */}

      <form onSubmit={handleSubmit} className={styles.container}>
        <div className={styles.inputContainer}>
          
          <TextField
            id="standard-helperText"
            helperText={
              /*  navigator.userLanguage === "es-ES" ||
                 navigator.language === "es-ES" */
              localStorage.getItem("language") === "es" ? "Usuario" : "Username"
            }
            variant="standard"
            fullWidth={true}
            onChange={handleOnChange}
            name="user"
            /* className={styles.input1} */
            FormHelperTextProps={{
              style: {
                color: "rgb(132, 125, 125)",
                fontFamily: "var(--font1)",
                fontSize: "var(--h6)",
               
              },
            }}
            
          />
          <br />
          <div className={styles.inputAndEye}>
            <TextField
              id="standard-helperText"
              helperText={
                localStorage.getItem("language") === "es"
                  ? "Contraseña"
                  : "Password"
              }
              type={seePass ? "text" : "password"}
              variant="standard"
              fullWidth={true}
              onChange={handleOnChange}
              FormHelperTextProps={{
                style: {
                  color: "rgb(132, 125, 125)",
                  fontFamily: "var(--font1)",
                  fontSize: "var(--h6)",
                },
              }}
              name="password"
            />
            <div className={styles.eye}>
              {seePass ? (
              <HiEyeOff onClick={handleSeePass} />
            ) : (
              <HiEye onClick={handleSeePass} />
            )}
            </div>
            
          </div>

          {userError && (
            <div className={styles.errorMessage}>
              {localStorage.getItem("language") === "es"
                ? "Usuario y/o contraseña incorrectos"
                : "User and/or password incorrect"}
            </div>
          )}
          <br />
          <div className={styles.button_and_forgotPass}>
            <a
              href="#forgot_my_pass"
              /* onClick={handleSubmitForgotPass} */ onClick={
                handleRecoveryModal
              }
              className={styles.forgotPass}
            >
              {localStorage.getItem("language") === "es"
                ? "Olvidé mi contraseña"
                : "Forgot my password"}
            </a>
            <button
              className={styles.loginButton}
              type="submit"
              onClick={handleSubmit}
            >
              {localStorage.getItem("language") === "es" ? "Entrar" : "Log in"}
            </button>
          </div>
        </div>
      </form>
      {/* --------------------modal forgot pass------ */}
      <Modal open={recoveryModal} onClose={handleRecoveryModalClose}>
        <Box
          sx={{
            ...modalStyle,
            height: minWidth ? "25vw" : "40vw",
            width: "30vw",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className={styles.modal_title}>
            {localStorage.getItem("language") === "es"
              ? "Cambio de contraseña"
              : "Change password"}
          </div>
          <ForgotMyPass />
        </Box>
      </Modal>

      {/* modal selecccion de operario------------------------------ */}

      <Modal open={selectOpeModal} onClose={handleSelectOpeModalClose}>
        <Box
          sx={{
            ...modalStyle,
            height: /* minWidth ? "15vw" : "15vw", */ "15vw",
            width: "30vw",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className={styles.modal_title}>
            {localStorage.getItem("language") === "es"
              ? "Seleccione un responsable para continuar"
              : "Select a responsible to continue"}
          </div>

          <SelectOperatorToLogin
            user={user}
            handleSelectOpeModalClose={handleSelectOpeModalClose}
            token={token}
          />
        </Box>
      </Modal>
    </div>
  );

}
