import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LateralBtn from "../lateralBtn/LateralBtn";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./rcWay_table.module.css";
import { useGetAlerts } from "./hooks";
import { getColor } from "../../../helpers";
import alertIcon from "./Icono_Alerta.png";

const Rc_wayTable = ({ devices }) => {
  //estilo de la tabla//

  const companyAlertParams = useGetAlerts();
  // let temp = 5; //harcodeada
  const useStyles = makeStyles((theme) => ({
    customAccordionSummary: {
      padding: 0,
      margin: 0,
    },
  }));
  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiTableContainer: {
        styleOverrides: {
          // root: {
          //   marginTop: window.screen.width <= 450 && "50px",
          //   height: window.screen.width <= 450 && "1200px",
          // },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "'Quattrocento Sans', sans-serif",
            border: "none",
            // padding: window.screen.width <= 450 && "2px",
            // heigth: window.screen.width <= 450 && "200px",
          },
        },
      },
    },
  });

  return (
    <div>
      {/* ya tengo los alert params de company, para comparar teperaturas de shipments y ver q color les pongo */}
      <div className={styles.tableTitle}>RC+Way</div>
      {devices.map((dev) => {
        return (
          <Accordion key={dev._id} disableGutters={true}>
            <AccordionSummary
              className={`${classes.customAccordionSummary} MuiAccordionSummary-root MuiAccordionSummary-gutters css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root`}
            >
              <LateralBtn
                timestamp={dev.geolocation.timestamp}
                id={dev.geo_id}
                lng={dev.geolocation.lng}
                lat={dev.geolocation.lat}
              />
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetails}>
              <div className={styles.tableTitleshiId_container}>
                <div>SHI ID</div>
                <div>TEMP.C°</div>
              </div>
              {dev.shipments.map((ship) => {
                
                return (
                  <div className={styles.infoContainer} key={ship._id}>
                    <div
                      className={ship.last_geolocation? styles.ship_id: styles.ship_id_black}
                      style={
                        ship.last_geolocation && {
                          backgroundColor: getColor(
                            companyAlertParams?.companyAlertParams
                              ?.temperature_alerts,
                            ship?.temperature_range,
                            ship.last_geolocation?.temperature,
                            ship.last_geolocation?.intrusion
                          ),
                        }
                      }
                    >
                      {ship.shipment_id.split("-")[1]}
                    </div>
                    <div className={styles.alerts_icon}>
                      {ship.alerts_flags &&
                        (ship.alerts_flags.includes("intrusion") === true ||
                        ship.alerts_flags.includes("temperature") === true ? (
                          <img src={alertIcon} alt="" />
                        ) : null)}
                    </div>
                    <div className={styles.ship_temp}>
                      {ship.last_geolocation &&
                        ship.last_geolocation.temperature}
                    </div>
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default Rc_wayTable;
