import React from "react";
import styles from "./IncomingShipsContent.module.scss";
import { GET_SHIPMENT_DETAIL } from "../../queries";
import { useQuery } from "@apollo/client";
import Barcode from "react-barcode";

export default function IncomingShipsContent({ shipment_id }) {
  //QUERIES/MUTATIONS ---------------------------------------------------------------------------
  //query para traerme el content de los viajes en transito
  const {
    // loading: contentLoading,
    // error: contentError,
    data: contentData,
  } = useQuery(GET_SHIPMENT_DETAIL, {
    variables: {
      shipment_id,
    },
  });

  return (
    <div className={styles.modalContent}>
     
      {contentData?.shipment?.contents?.map((ship) => {
        return (
          <div className={styles.modalData} key={ship.shipment_id}>
            <Barcode
              value={ship.id}
              width={(window.screen.width * 0.1) / 100}
              height={(window.screen.width * 1.04) / 100}
              textAlign="center"
              fontSize={(window.screen.width * 1.04) / 100}
            />
          </div>
        );
      })}
    </div>
  );
}
