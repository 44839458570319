import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import { client } from "./apolloClient";
import { parseJwt } from './helpers';
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"

const mpt = localStorage.getItem('mbt')
mapboxgl.accessToken = mpt

// //chequeo la fecha de expiracion del token
// if (localStorage.getItem('token') && parseInt(localStorage.getItem('expiration')) < Date.now()) {
//   localStorage.setItem('token', '')
// };

const user_id = localStorage.getItem("token")?.length > 0 && parseJwt(localStorage.getItem("token"))?.user_id;

//creo un espacio para guardar las notificaciones
if (user_id && !localStorage.getItem(`notifications`)) localStorage.setItem(`notifications`, '');
if (user_id && !localStorage.getItem(`notificationsENG`)) localStorage.setItem(`notificationsENG`, '');
if (user_id && !localStorage.getItem(`numberNoti`)) localStorage.setItem(`numberNoti`, '0');

//creo un espacio para guardar el idioma
if (user_id && !localStorage.getItem('language')) localStorage.setItem('language', navigator.window.language.split('-')[0]);


ReactDOM.render(
  <ApolloProvider client={client}>
    <App language={localStorage.getItem('language')} />
  </ApolloProvider>,
  document.getElementById('root')
);
