import React, { useReducer, useState } from "react";

import { createContext } from "react";

export const ShipmentsContext = createContext({});

export const ShipmentsProvider = (props) => {
  //exporto un array y una funcion para modificarlo
  const [shipData, setShipData] = useState(null);

  return (
    <ShipmentsContext.Provider
      value={
        { shipData, setShipData }
      }
    >
      {props.children}
    </ShipmentsContext.Provider>
  );
};

export default ShipmentsProvider;