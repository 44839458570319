import React from "react";
import { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  ReferenceArea,
} from "recharts";
import { format } from "date-fns-tz";
import styles from "./shipmentsToCompare.module.scss";
import CircularProgress from "@mui/material/CircularProgress";

import { FormattedMessage } from "react-intl";
import { Bar } from "react-chartjs-2";

import { convertirHoraLocal } from "../../../helpers";
import { formatDuration } from "date-fns";

const ShipmentsToCompare = ({ dataToCompare, gmt, type }) => {
  const [loading, setLoading] = useState(true);
  const [dataState, setDataState] = useState([]);
  const [ticksState, setTicksState] = useState([]);
  const colors = ["#E0271F", "#77E01F", "#1FD8E0", "pink", "orange"];

  //ESTRUCTURA DONDE SE VA A GUARDAR LA DATA QUE VA EN EL GRAFICO ----------------------------------------------------
  let data = [];
  let ticks = [];

  //RECORRO LA DATA CUANDO SE INICIALIZA EL COMPONENTE -------------------------------------------------
  useEffect(() => {
    switch (type) {
      case "shipments":
        dataToCompare?.shipmentsToCompare?.forEach((ship, i) => {
          ship.temperature_readings.forEach((temp) => {
            data.push({
              [`#${ship.shipment_id.split("-")[1]}`]: temp.cv
                ? temp.cv
                : temp.value,
              counter: temp.counter,
              value: temp.cv ? temp.cv : temp.value,
            });
          });
        });
        break;
      case "calibrations":
        dataToCompare?.calibrationsToCompare?.forEach((cal, i) => {
          cal.temperature_readings.forEach((temp) => {
            data.push({
              [`#${cal.calibration_id.split("-")[1]}`]: temp.cv
                ? temp.cv
                : temp.value,
              counter: temp.counter,
              value: temp.cv ? temp.cv : temp.value,
            });
          });
        });
        break;
      default:
        break;
    }

    setDataState([...dataState, data]);

    data.forEach((data) => {
      ticks.push(data.counter);
    });

    ticks.sort((a, b) => a - b);
    setTicksState(
      ticks.filter((item, i) => {
        return ticks.indexOf(item) === i;
      })
    );
  }, [dataToCompare]);



  // LOADING ---------------------------------------------------------------------------
  while (loading) {
    setTimeout(() => setLoading(false), 2000);
    return (
      <>
        <div
          className={styles.loading}
          data-loading-text={
            localStorage.getItem("language") === "es"
              ? "Comparando..."
              : "Comparing..."
          }
        ></div>
      </>
    );
  }
  const convertSeconds = (totalSeconds) => {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      return "0s";
    } else if (days === 0 && hours === 0 && minutes === 0) {
      return `${seconds}s`;
    } else if (days === 0 && hours === 0) {
      return `${minutes}m`;
    } else if (days === 0) {
      return `${hours}h ${minutes}m`;
    } else return `${days}d ${hours}h`;
  };

  return (
    <>
      {type === "shipments" && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={dataState[0]}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="counter"
              /* interval={0} */
              scale="time"
              type="number"
              ticks={ticksState}
              tick={{
                color: "#00ABC8",
                fontSize: "0.7vw",
                fontFamily: "'Quattrocento Sans', sans-serif",
              }}
              tickMargin={6}
              tickLine={true}
              tickFormatter={(tick) => convertSeconds(tick)}
              domain={["auto", "auto"]}
            />

            <YAxis>
              <Label
                value={
                  localStorage.getItem("language") === "en"
                    ? "Temperature(°C)"
                    : "Temperatura(°C)"
                }
                angle={-90}
                position="left"
                fontFamily="'Quattrocento Sans', sans-serif"
                fontSize="1vw"
                fill="#00ABC8"
                fontWeight="lighter"
              />
            </YAxis>

            <Tooltip
              labelFormatter={(label) =>
                `Fecha: ${convertirHoraLocal(label, gmt)}`
              }
              formatter={(value) => `Valor: ${value}`}
            />
            <Legend />
            {dataToCompare?.shipmentsToCompare?.map((ship, i) => {
              return (
                <Line
                  key={ship.shipment_id}
                  type="monotone"
                  dataKey={`#${ship.shipment_id.split("-")[1]}`}
                  stroke={colors[i]}
                  dot={false}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
      {type === "calibrations" && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={dataState[0]}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="counter"
              /* interval={0} */
              scale="time"
              type="number"
              ticks={ticksState}
              tick={{
                color: "#00ABC8",
                fontSize: "0.7vw",
                fontFamily: "'Quattrocento Sans', sans-serif",
              }}
              tickMargin={6}
              tickLine={true}
              tickFormatter={(tick) => convertSeconds(tick)}
              domain={["auto", "auto"]}
            />

            <YAxis>
              <Label
                value={
                  localStorage.getItem("language") === "en"
                    ? "Temperature(°C)"
                    : "Temperatura(°C)"
                }
                angle={-90}
                position="left"
                fontFamily="'Quattrocento Sans', sans-serif"
                fontSize="1vw"
                fill="#00ABC8"
                fontWeight="lighter"
              />
            </YAxis>

            <Tooltip
              labelFormatter={(label) =>
                `Fecha: ${convertirHoraLocal(label, gmt)}`
              }
              formatter={(value) => `Valor: ${value}`}
            />
            <Legend />
            {dataToCompare?.calibrationsToCompare?.map((cal, i) => {
              return (
                <Line
                  key={cal.calibration_id}
                  type="monotone"
                  dataKey={`#${cal.calibration_id.split("-")[1]}`}
                  stroke={colors[i]}
                  dot={false}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ShipmentsToCompare;
