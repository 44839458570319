// import React, { useContext } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom"; // Importa Route y Routes en lugar de Switch
// import Login from "./components/Login/Login";
// import styles from "./App.module.css";
// import Home from "./components/Home/Home";
// import { IntlProvider } from "react-intl";
// import translations from "./translations/translations.js";
// import MyProfile from "./components/MyProfile/MyProfile";

// import { DatesProvider } from "./context/dates/Dates.jsx";
// import {ShipmentsProvider} from "./context/shipments/ContextShipments.jsx";
// import Connected from "./components/Connected/Connected.jsx";

// export default function App() {
//   const language = localStorage.getItem("language");

//   return (
//     <DatesProvider>
//       <ShipmentsProvider>

//       <div className={styles.appContainer}>
//         <IntlProvider locale={language} messages={translations[language]}>
//           <BrowserRouter>
//             <Routes>
//               <Route
//                 path="/"
//                 index
//                 element={
//                   localStorage.getItem("token")?.length > 0 ? (
//                     <Home />
//                   ) : (
//                     <Login />
//                   )
//                 }
//               />

//               <Route
//                 path="/my-profile"
//                 index
//                 element={
//                   localStorage.getItem("token")?.length > 0 ? (
//                     <MyProfile />
//                   ) : (
//                     <Login />
//                   )
//                 }

//               />
//                <Route
//                 path="/connected"
//                 index
//                 element={<Connected/>}
//               />

//             </Routes>
//           </BrowserRouter>
//         </IntlProvider>
//       </div>
//       </ShipmentsProvider>
//     </DatesProvider>
//   );

// }
import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Importa Route y Routes en lugar de Switch
import Login from "./components/Login/Login";
import styles from "./App.module.css";
import Home from "./components/Home/Home";
import { IntlProvider } from "react-intl";
import translations from "./translations/translations.js";
import MyProfile from "./components/MyProfile/MyProfile";

import { DatesProvider } from "./context/dates/Dates.jsx";
import { ShipmentsProvider } from "./context/shipments/ContextShipments.jsx";
import Connected from "./components/Connected/Connected.jsx";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.jsx";
import { AlertsProvider } from "./context/alerts/AlertsProvider.jsx";

export default function App() {
  const language = localStorage.getItem("language");

  return (
    <DatesProvider>
      <AlertsProvider>
        <ShipmentsProvider>
          <div className={styles.appContainer}>
            <IntlProvider locale={language} messages={translations[language]}>
              <BrowserRouter>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Home />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/my-profile" element={<MyProfile />} />
                  <Route path="/connected" element={<Connected />} />

                  {/* Añade más rutas aquí */}
                </Routes>
              </BrowserRouter>
            </IntlProvider>
          </div>
        </ShipmentsProvider>
      </AlertsProvider>
    </DatesProvider>
  );
}
