import React, { useState, useEffect } from "react";
import styles from "./TablaViajes.module.scss";
import Tabs from "@mui/material/Tabs";
import TabUnstyled from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import viajesEnCursoIcon from "../ViajesEnTransito/viajesEnCurso.png";
import viajesHechosIcon from "../ViajesTrazados/viajesHechos.png";
import viajesConformesIcon from "../ViajesConformes/viajesConformes.png";
import viajesParaRevisionIcon from "../ViajesParaRevision/viajesParaRevision.png";
import viajesNoConformesIcon from "../ViajesNoConformes/viajesNoConformes.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import failedIcon from "./failedIcon.png";
import successIcon from "./successIcon.png";
import uncertainIcon from "./uncertainIcon.png";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import {
  GET_COMPANY_DETAIL,
  GET_SHIPMENT_DETAIL,
  GET_SHIPMENTS_PAG,
  SHIPMENTS_UPDATED_SUBSCRIPTION,
  SHIPMENTS_CREATED_SUBSCRIPTION,
  SHIPMENTS_CHECKPOINTS_SUBSCRIPTION,
  GET_SHIPMENTS_PAG_TO_HEADER,
} from "../../queries";
import { useQuery, useLazyQuery, useSubscription } from "@apollo/client";
import Modal from "@mui/material/Modal";
import RegistroTemperaturaIntrusion from "../RegistroTemperaturaIntrusion/RegistroTemperaturaIntrusion";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import { FormattedMessage } from "react-intl";
import { BsCircleFill } from "react-icons/bs";
import Barcode from "react-barcode";
import { language, meses } from "../../const";
import Comments from "../Comments/Comments";
import { convertirHoraLocal } from "../../helpers";
import styled from "styled-components";

// ESTILOS PREDETERMINADOS --------------------------------------------------------
//estilo la tabla
const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          width: "100%",
          justifyContent: "center",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "'Quattrocento Sans', sans-serif",
          fontSize: window.screen.width > 800 ? "1.3vw" : "2.5vw",

          textTransform: "none",
          width: "20%",
          height: "4vw",
          color: "#1B1464 !important",
          marginTop: "0.5vw",
          display: "flex",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          marginTop: "1vw",
          width: "100%",
          height: window.screen.width > 800 ? "29.5vw" : "47vw",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "'Quattrocento Sans', sans-serif",
          border: "none",
          padding: "1%",
          background: "transparent",
        },
      },
    },
  },
});

//estilo el modal de los contents de los viajes en transito
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: window.screen.width > 800 ? "20vw" : "40vw",
  //height: 300,
  bgcolor: "white",
  border: "0.15vw solid #00ABC8",
  borderRadius: "2vw",
  fontFamily: "'Quattrocento Sans', sans-serif",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function TablaViajes({
  company_id,
  initialFilter,
  graphOnly,
  shipment_id,
  data,
  gmt,
  checkpointsData, //para graph only
  customDate,
  onSelected,
}) {
  const StyledTimelineItem = styled.div`
    &::before {
      content: "";
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      padding: 0px;
    }
  `;
  // ESTADOS LOCALES ------------------------------------------------------------------------------------
  //estado para el filtro segun pestaña en la que este parado
  const [selectedFilter, setSelectedFilter] = useState("TRANSIT");
  const [fetchData, setFetchData] = useState([]);
  //estado para paginación
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  //estado para el modal del contenido del envio
  const [content, setContent] = useState(false);

  //estado para el modal del graph de temp-intru
  const [temperatureAlert, setTemperatureAlert] = useState(false);

  //ESTADO PARA MODAL DE COMPARAR ENVIOS
  const [compareModal, setCompareModal] = useState(false);
  //estado para el modal de checkpoints
  const [checkpointsModal, setCheckpointsModal] = useState(false);
  const [last_checkpoint, setLast_checkpoint] = useState({});

  //estado para el modal de comentarios
  const [comments, setComments] = useState(false);

  //estado para guardar el id del shipment para ver sus comentarios

  const [shipToSeeComments, setShipToSeeComments] = useState("");

  //traigo todos los checkpoints filtrados desde el back
  const [checkpoints, setCheckpoints] = useState([]);

  //SETEO DE FECHAS PARA FILTRADO -------------------------------------------------------------------------------
  const date = new Date();
  const firstDayOfMonth = new Date().setDate("01");
  const [initialDay, setInitialDay] = useState(
    new Date(new Date().setHours("00", "00", "00"))
  );
  const [initialMonth, setInitialMonth] = useState(
    new Date(new Date(firstDayOfMonth).setHours("00", "00", "00"))
  );
  const [finalDate, setFinalDate] = useState(new Date(date));
  const [newShipments, setNewShipments] = useState(false);
  //seleccion de intervalo de checkpoints-------------------
  const [selectedCheckpoints, setSelectedCheckpoints] = useState([]);

  // QUERIES Y SUBSCRIPTIONS ------------------------------------------------------------------------------------------

  const {
    loading: createdShipLoading,
    error: createdShipError,
    data: createdShipData,
  } = useSubscription(SHIPMENTS_CREATED_SUBSCRIPTION, {
    onData: (subscriptionData) => {
      console.log(subscriptionData);
      //window.location.reload();
      setNewShipments(!newShipments);
      console.log("newShipments");
    },
  });

  const {
    loading: updatedShipLoading,
    error: updatedShipError,
    data: updatedShipData,
  } = useSubscription(SHIPMENTS_UPDATED_SUBSCRIPTION, {
    onData: (subscriptionData) => {
      console.log(subscriptionData);
      //window.location.reload();
      setNewShipments(!newShipments);
    },
  });

  const { data: updatedCheckpointsData, error: updatedCheckpointsError } =
    useSubscription(SHIPMENTS_CHECKPOINTS_SUBSCRIPTION, {
      onData: (data) => {
        // window.location.reload()

        setLast_checkpoint(data?.data?.data?.shipmentChecked);
      },
    });

  //lazy query para actualizar la data
  const [
    lazyGetShipmentsPag,
    {
      loading: lazyPaginatedDataLoading,
      error: lazyPaginatedDataError,
      data: lazyPaginatedData,
    },
  ] = useLazyQuery(GET_SHIPMENTS_PAG_TO_HEADER, {
    fetchPolicy: "network-only",
  });

  // VARIABLES DONDE GUARDO LA DATA -----------------------------------------------------------------
  // let infoLength = paginatedData?.shipments?.total;
  // let info = paginatedData?.shipments?.selectedItems;
  //query para traerme las branches de la compañia
  const {
    // loading: branchesLoading,
    error: branchesError,
    data: company_detail,
  } = useQuery(GET_COMPANY_DETAIL, {
    variables: {
      company_id,
    },
  });

  //lazy query para traerme el content de los viajes en transito
  const [
    getShipment,
    { loading: contentLoading, error: contentError, data: contentData },
  ] = useLazyQuery(GET_SHIPMENT_DETAIL, { fetchPolicy: "network-only" });

  // USE EFFECT ----------------------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (customDate) {
          await lazyGetShipmentsPag({
            variables: {
              company_id,
              in_transit: selectedFilter === "TRANSIT" ? true : false,
              status: selectedFilter !== "TRANSIT" ? selectedFilter : null,
              from_date: selectedFilter === "TRANSIT" ? null : customDate.from,

              to_date: selectedFilter === "TRANSIT" ? null : customDate.from,
              page: page,
              per_page: rowsPerPage,
            },
          });
          setFetchData(lazyPaginatedData?.shipments?.selectedItems);
        } else {
          await lazyGetShipmentsPag({
            variables: {
              company_id,
              in_transit: selectedFilter === "TRANSIT" ? true : false,
              status: selectedFilter !== "TRANSIT" ? selectedFilter : null,
              from_date:
                selectedFilter !== "TRANSIT"
                  ? initialFilter === "month"
                    ? initialMonth.toISOString()
                    : initialDay.toISOString()
                  : "",
              to_date: finalDate.toISOString(),
              page,
              per_page: rowsPerPage,
            },
          });
          setFetchData(lazyPaginatedData?.shipments?.selectedItems);
        }
        // infoLength = lazyPaginatedData?.shipments?.total;
        //info = lazyPaginatedData?.shipments?.selectedItems;
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [
    updatedShipData,
    createdShipData,
    initialFilter,
    selectedFilter,
    lazyPaginatedData,
    updatedCheckpointsData,
    customDate,
    page,
    newShipments,
  ]);
  useEffect(() => {
    setPage(1);
  }, [initialFilter]);
  // HANDLERS --------------------------------------------------------------------------------------------
  //handler de las solapas
  const handleChange = (e, newValue) => {
    if (newValue === null) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(newValue);
    }
  };

  //handler de la paginacion
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  //handler del modal de contenido
  const handleContent = (shipment_id) => {
    getShipment({ variables: { shipment_id } });
    setContent(true);
  };

  //handler del cuadro de temperatura
  const handleTemperatureContent = (shipment_id) => {
    getShipment({ variables: { shipment_id } });
    setTemperatureAlert(true);
  };

  //handler del modal de checkpoints
  const handleCheckpointsModal = (shipment_id) => {
    const fetchCheckpoints = async () => {
      try {
        await getShipment({ variables: { shipment_id } });
      } catch (error) {
        console.log(error);
      }
    };
    fetchCheckpoints();

    setCheckpointsModal(true);
  };

  //handler del close del modal del contenido
  const handleOnClose = () => {
    setContent(false);
  };

  //handler del close del cuadro de temperatura
  const handleTemperatureClose = () => {
    setTemperatureAlert(false);
  };

  //handler del close del modal de checkpoints
  const handleCheckpointsModalClose = () => {
    setCheckpointsModal(false);
  };

  //handler para que vuelva a la primera pagina cada vez que cambio de tab
  const handleOnChangeTab = () => {
    setPage(1);
  };

  //handler del modal de comments
  const handleCommentsModal = (id) => {
    setComments(true);
    setShipToSeeComments(id);
  };

  //handler del close del modal de comments
  const handleOnCloseComments = () => {
    setComments(false);
  };

  const handleCheckboxChange = (c) => {
    const newSelectedCheckpoints = [...selectedCheckpoints];
    if (newSelectedCheckpoints.includes(c)) {
      const idx = newSelectedCheckpoints.indexOf(c);
      newSelectedCheckpoints.splice(idx, 1);
    } else if (newSelectedCheckpoints.length < 2) {
      newSelectedCheckpoints.push(c);
    } else {
      return;
    }

    setSelectedCheckpoints(newSelectedCheckpoints);
    onSelected(newSelectedCheckpoints);
  };

  //MANEJO DE ERRORES -------------------------------------------------------------------------------------
  if (lazyPaginatedDataError)
    console.log("error en la lazy data paginada", lazyPaginatedDataError);
  // if (paginatedDataError)
  //   console.log("error en la data paginada", paginatedDataError);
  if (createdShipError)
    console.log("error en la subscription de viajes creados", createdShipError);
  if (updatedShipError)
    console.log(
      "error en la subscription de viajes cerrados",
      updatedShipError
    );
  if (updatedCheckpointsError)
    console.log(
      "error en la subscription de checkpoints",
      updatedCheckpointsError
    );
  if (branchesError)
    console.log("error en la query de branches", branchesError);
  if (contentError)
    console.log("error en el contenido de viajes en transito", contentError);

  return (
    <>
      {graphOnly ? (
        data?.shipment?.status !== "TRANSIT" && (
          <div
            className={
              styles.checkpointsContainer
            } /* style={{ paddingTop: "8%", height: "20vw",border:"solid red 1px", overflow:"auto"}}  */
          >
            <StyledTimelineItem align="left">
              {checkpointsData?.checkpoints?.map((c, i) => (
                <TimelineItem key={i} /* style={{border:"solid green 1px"}} */>
                  <TimelineSeparator
                    style={{
                      /*border:"solid green 1px" , */ position: "relative",
                      left: "-3%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "40px",
                        //border: "solid red 1px",
                        position: "relative",
                        left: "-6px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedCheckpoints.includes(c.timestamp)}
                        onChange={() => handleCheckboxChange(c.timestamp)}
                        style={{
                          position: "relative",
                          left: "-6px",
                        }}
                      />
                      <TimelineDot
                        style={{
                          backgroundColor:
                            i === checkpointsData?.checkpoints?.length - 1
                              ? "#00ABC8"
                              : "#bdbdbd",
                          //color: i === data?.shipment?.checkpoints?.length - 1 ? "#FFFFFF" : "#000000" // Puedes ajustar el color del texto según tu diseño
                        }}
                      />
                    </div>

                    {i !== checkpointsData?.checkpoints?.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>

                  <TimelineContent
                    sx={{
                      minWidth: "16vw",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      left: "-2%" /* border:"solid red 1px" */,
                    }}
                  >
                    <div style={{  display: "flex" }}>
                      {/* timestamp -temp */}
                      <Typography variant="h5" fontFamily={"var(--font1)"}>
                        {convertirHoraLocal(
                          c?.timestamp,
                          company_detail?.company?.gmt
                        )}
                      </Typography>
                      <Typography
                        variant="h5"
                        fontFamily={"var(--font1)"}
                        style={{ position: "relative", left: "10%" }}
                      >
                        {c?.temperature
                          ? ` ${c?.temperature.toFixed(2)}°C`
                          : ""}
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="span"
                        fontFamily={"var(--font1)"}
                      >
                        {c.location}
                      </Typography>

                      {c.label && (
                        <Typography
                          variant="h6"
                          fontFamily={"var(--font1)"}
                          style={{
                            position: "relative",
                            left: "2%",
                            color: "#00ABC8",
                           // fontSize: "var(--h5)",
                          }}
                        >
                          {c.label}
                        </Typography>
                      )}
                    </div>

                    <Typography fontFamily={"var(--font1)"} variant="h6">
                      {c?.responsible_name}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </StyledTimelineItem>
          </div>
        )
      ) : (
        <div className={styles.tableContainer}>
          <ThemeProvider theme={theme}>
            <Box display="flex" justifyContent="center" width="100%">
              <Tabs
                value={selectedFilter}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <TabUnstyled
                  value="TRANSIT"
                  onClick={handleOnChangeTab}
                  label={
                    selectedFilter !== "TRANSIT" ? (
                      <img
                        className={styles.icon}
                        src={viajesEnCursoIcon}
                        alt=""
                      />
                    ) : (
                      <FormattedMessage id="in_transit_ships_title">
                        In transit shipments
                      </FormattedMessage>
                    )
                  }
                  className={
                    selectedFilter === "TRANSIT"
                      ? styles.tab
                      : styles.tabNotSelected
                  }
                />
                <TabUnstyled
                  value={null}
                  onClick={handleOnChangeTab}
                  label={
                    selectedFilter !== null ? (
                      <img
                        className={styles.icon}
                        src={viajesHechosIcon}
                        alt=""
                      />
                    ) : (
                      <FormattedMessage id="completed_ships_title">
                        Completed shipments
                      </FormattedMessage>
                    )
                  }
                  className={
                    selectedFilter === "" ? styles.tab : styles.tabNotSelected
                  }
                />
                <TabUnstyled
                  value="SUCCESSFUL"
                  onClick={handleOnChangeTab}
                  label={
                    selectedFilter !== "SUCCESSFUL" ? (
                      <img
                        className={styles.icon}
                        src={viajesConformesIcon}
                        alt=""
                      />
                    ) : (
                      <FormattedMessage id="succ_ships_title">
                        Successful shipments
                      </FormattedMessage>
                    )
                  }
                  className={
                    selectedFilter === "SUCCESSFUL"
                      ? styles.tab
                      : styles.tabNotSelected
                  }
                />
                <TabUnstyled
                  value="UNCERTAIN"
                  onClick={handleOnChangeTab}
                  label={
                    selectedFilter !== "UNCERTAIN" ? (
                      <img
                        className={styles.icon}
                        src={viajesParaRevisionIcon}
                        alt=""
                      />
                    ) : (
                      <FormattedMessage id="uncert_ships_title">
                        Uncertain shipments
                      </FormattedMessage>
                    )
                  }
                  className={
                    selectedFilter === "UNCERTAIN"
                      ? styles.tab
                      : styles.tabNotSelected
                  }
                />
                <TabUnstyled
                  value="FAILED"
                  onClick={handleOnChangeTab}
                  label={
                    selectedFilter !== "FAILED" ? (
                      <img
                        className={styles.icon}
                        src={viajesNoConformesIcon}
                        alt=""
                      />
                    ) : (
                      <FormattedMessage id="fail_ships_title">
                        Failed shipments
                      </FormattedMessage>
                    )
                  }
                  className={
                    selectedFilter === "FAILED"
                      ? styles.tab
                      : styles.tabNotSelected
                  }
                />
              </Tabs>
            </Box>

            {lazyPaginatedDataLoading ? (
              <div className={styles.loading}>
                <CircularProgress />
              </div>
            ) : (
              <TableContainer>
                {lazyPaginatedData?.shipments?.selectedItems?.length > 0 ? (
                  <Table
                    sx={{ minWidth: "100%" }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      {selectedFilter === "TRANSIT" ? (
                        //HEAD VIAJES EN TRANSITO ------------------------------------------------------------------------------------
                        <TableRow
                          sx={{
                            borderBottom: "0.15vw solid lightgrey",
                          }}
                        >
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            ID
                          </TableCell>

                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            QR
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="origin_tab_title">
                              ORIGIN
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="departure_tab_title">
                              DEPARTURE
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="checkpoint_tab_title">
                              LAST CHECKPOINT
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="content_tab_title">
                              CONTENT
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="comments">
                              COMMENTS
                            </FormattedMessage>
                          </TableCell>
                        </TableRow>
                      ) : (
                        // --------------------------------------------------------------------------------------------------------------------

                        //HEAD VIAJES COMPLETOS------------------------------------------------------------------------------------
                        <TableRow
                          sx={{
                            borderBottom: "0.15vw solid lightgrey",
                          }}
                        >
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            ID
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            QR
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="origin_tab_title">
                              ORIGIN
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="sent_tab_title">
                              SENT
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                              width: "5%",
                            }}
                          >
                            <FormattedMessage id="received_tab_title">
                              RECEIVED
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="destination_tab_title">
                              DESTINATION
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="alerts_tab_title">
                              ALERTS
                            </FormattedMessage>
                          </TableCell>
                          <TableCell
                            size="medium"
                            align="center"
                            sx={{
                              color: "#00ABC8",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "2vw",
                              paddingRight: "1vw",
                            }}
                          >
                            <FormattedMessage id="comments">
                              COMMENTS
                            </FormattedMessage>
                          </TableCell>
                        </TableRow>
                        // --------------------------------------------------------------------------------------------------------------------
                      )}
                    </TableHead>
                    <TableBody>
                      {selectedFilter === "TRANSIT" &&
                        //PESTAÑA VIAJES EN TRANSITO --------------------------------------------------------------------------------------
                        lazyPaginatedData?.shipments?.selectedItems?.map(
                          (s) => (
                            <TableRow
                              key={s.shipment_id}
                              style={
                                lazyPaginatedData?.shipments?.selectedItems?.indexOf(
                                  s
                                ) %
                                  2 ===
                                0
                                  ? { background: "#FAFAFA", height: "3.5vw" }
                                  : { background: "#D9F1F5", height: "3.5vw" }
                              }
                            >
                              {/* ID  ----------------------------------------------------------------------------------------------------*/}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {s.shipment_id.split("-")[1]}
                              </TableCell>
                              {/* QR----------------------------------- */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {s.qr}
                              </TableCell>
                              {/* ORIGEN ----------------------------------------------------------------------------------------------------*/}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {company_detail?.company?.branches?.map(
                                  (b) => b.branch_id === s.origin_id && b.name
                                )}
                              </TableCell>
                              {/* SALIDA ---------------------------------------------------------------------------------------------------*/}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {
                                  convertirHoraLocal(
                                    s?.departure,
                                    company_detail?.company?.gmt
                                  )?.split("  ")[0]
                                }{" "}
                                <br />
                                {
                                  convertirHoraLocal(
                                    s?.departure,
                                    company_detail?.company?.gmt
                                  )?.split("  ")[1]
                                }
                              </TableCell>
                              {/* CHECKPOINT --------------------------------------------------------------------------------------------------*/}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {s.shipment_id ===
                                last_checkpoint?.shipment_id ? (
                                  <button
                                    className={styles.checkpointsButton}
                                    onClick={() =>
                                      handleCheckpointsModal(s.shipment_id)
                                    }
                                  >
                                    {
                                      // fecha y hora
                                      convertirHoraLocal(
                                        last_checkpoint?.last_checkpoint
                                          ?.timestamp,
                                        company_detail?.company?.gmt
                                      )
                                    }
                                    <br />

                                    {
                                      //temperatura
                                      last_checkpoint?.last_checkpoint
                                        ?.temperature +
                                        "C°" +
                                        " - " +
                                        //branch
                                        last_checkpoint?.last_checkpoint
                                          ?.location
                                    }
                                  </button>
                                ) : s?.checkpoints?.[
                                    s.checkpoints.length - 1
                                  ] ? (
                                  <button
                                    className={styles.checkpointsButton}
                                    onClick={() =>
                                      handleCheckpointsModal(s.shipment_id)
                                    }
                                  >
                                    {
                                      // fecha y hora
                                      convertirHoraLocal(
                                        s.checkpoints[s.checkpoints.length - 1]
                                          .timestamp,
                                        company_detail?.company?.gmt
                                      )
                                    }
                                    <br />

                                    {
                                      //temperatura
                                      s.checkpoints[s.checkpoints.length - 1]
                                        ?.temperature +
                                        "C°" +
                                        " - " +
                                        //branch
                                        s.checkpoints[s.checkpoints.length - 1]
                                          ?.location
                                    }
                                  </button>
                                ) : (
                                  <FormattedMessage id="no_checkpoints">
                                    No checkpoints
                                  </FormattedMessage>
                                )}
                              </TableCell>
                              {/* CONTENIDO  -------------------------------------------------------------------------------------------*/}
                              <TableCell align="center" size="small">
                                <button
                                  className={styles.contentButton}
                                  onClick={() => handleContent(s.shipment_id)}
                                >
                                  <FormattedMessage id="content_button">
                                    OPEN
                                  </FormattedMessage>
                                </button>
                              </TableCell>
                              {/* COMENTARIOS  -------------------------------------------------------------------------------------------*/}
                              <TableCell align="center" size="small">
                                <button
                                  className={styles.contentButton}
                                  onClick={() =>
                                    handleCommentsModal(s.shipment_id)
                                  }
                                >
                                  <FormattedMessage id="content_button">
                                    OPEN
                                  </FormattedMessage>
                                </button>
                              </TableCell>
                            </TableRow>
                          )
                          // ---------------------------------------------------------------------------------------------------------------
                        )}
                      {selectedFilter !== "TRANSIT" &&
                        lazyPaginatedData?.shipments?.selectedItems?.length !==
                          0 &&
                        !lazyPaginatedDataLoading &&
                        //PESTAÑA VIAJES COMPLETOS --------------------------------------------------------------------------------------

                        lazyPaginatedData?.shipments?.selectedItems?.map(
                          (s) => (
                            <TableRow
                              key={s.shipment_id}
                              style={
                                lazyPaginatedData?.shipments?.selectedItems?.indexOf(
                                  s
                                ) %
                                  2 ===
                                0
                                  ? { background: "#FAFAFA" }
                                  : { background: "#D9F1F5" }
                              }
                            >
                              {/* ID */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {s.shipment_id.split("-")[1]}
                              </TableCell>
                              {/* QR */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {s.qr}
                              </TableCell>
                              {/* ORIGEN */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {company_detail?.company?.branches?.map(
                                  (b) => b.branch_id === s.origin_id && b.name
                                )}
                              </TableCell>
                              {/* ENVIADO */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {
                                  convertirHoraLocal(
                                    s.departure,
                                    company_detail?.company?.gmt
                                  ).split("  ")[0]
                                }{" "}
                                <br />
                                {
                                  convertirHoraLocal(
                                    s.departure,
                                    company_detail?.company?.gmt
                                  ).split("  ")[1]
                                }
                              </TableCell>
                              {/* RECIBIDO */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {
                                  convertirHoraLocal(
                                    s?.arrival,
                                    company_detail.company.gmt
                                  ).split("  ")[0]
                                }{" "}
                                <br />
                                {
                                  convertirHoraLocal(
                                    s?.arrival,
                                    company_detail.company.gmt
                                  ).split("  ")[1]
                                }
                              </TableCell>
                              {/* DESTINO */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {company_detail?.company?.branches?.map(
                                  (b) =>
                                    b.branch_id === s.destination_id && b.name
                                )}
                              </TableCell>
                              {/* ALERTAS */}
                              <TableCell
                                align="center"
                                size="small"
                                sx={{
                                  color: "#1B1464",
                                  fontSize:
                                    window.screen.width > 800 ? "1vw" : "2vw",
                                }}
                              >
                                {s.status === "SUCCESSFUL" && (
                                  <button
                                    onClick={() =>
                                      handleTemperatureContent(s.shipment_id)
                                    }
                                    className={styles.alertButton}
                                  >
                                    <img
                                      className={styles.icon}
                                      src={successIcon}
                                      alt=""
                                    />
                                  </button>
                                )}
                                {s.status === "UNCERTAIN" && (
                                  <button
                                    onClick={() =>
                                      handleTemperatureContent(s.shipment_id)
                                    }
                                    className={styles.alertButton}
                                  >
                                    <img
                                      className={styles.icon}
                                      src={uncertainIcon}
                                      alt=""
                                    />
                                  </button>
                                )}
                                {s.status === "FAILED" && (
                                  <button
                                    onClick={() =>
                                      handleTemperatureContent(s.shipment_id)
                                    }
                                    className={styles.alertButton}
                                  >
                                    <img
                                      className={styles.icon}
                                      src={failedIcon}
                                      alt=""
                                    />
                                  </button>
                                )}
                              </TableCell>
                              {/* COMENTARIOS  -------------------------------------------------------------------------------------------*/}
                              <TableCell align="center" size="small">
                                <button
                                  className={styles.contentButton}
                                  onClick={() =>
                                    handleCommentsModal(s.shipment_id)
                                  }
                                >
                                  <FormattedMessage id="content_button">
                                    OPEN
                                  </FormattedMessage>
                                </button>
                              </TableCell>
                            </TableRow>
                          )
                          // ---------------------------------------------------------------------------------------------------------------
                        )}
                    </TableBody>
                  </Table>
                ) : (
                  <div className={styles.noData}>
                    <FormattedMessage id="no_ships">
                      No shipments to show
                    </FormattedMessage>
                  </div>
                )}
              </TableContainer>
            )}
            {lazyPaginatedData?.shipments?.total > 7 &&
            !lazyPaginatedDataLoading ? (
              <div className={styles.pagination}>
                <Pagination
                  count={Math.ceil(
                    lazyPaginatedData?.shipments?.total / rowsPerPage
                  )}
                  page={page}
                  onChange={handleChangePage}
                  size={window.screen.width <= 1000 ? "small" : "large"}
                />
              </div>
            ) : (
              ""
            )}
          </ThemeProvider>
          {/* MODAL CHECKPOINTS --------------------------------------------------------------------------- */}
          <Modal open={checkpointsModal} onClose={handleCheckpointsModalClose}>
            <Box
              sx={{
                ...modalStyle,
                height: window.screen.width > 800 ? "40vw" : "40vw",
                width: "30vw",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {/* <div className={styles.checkpointsBox}>
                <div className={styles.modalTitleContainer}>Checkpoints</div>

                <div className={styles.checksYDatas}>
                  <div className={styles.checks}>
                    {contentData?.shipment?.checkpoints?.map((c, i) => {
                      return (
                        <div className={styles.eachCheckpoint} key={i + 56}>
                          <div className={styles.iconAndLine}>
                            <BsCircleFill
                              className={
                                // contentData?.shipment?.checkpoints?.indexOf(c) ===
                                //   contentData?.shipment?.checkpoints?.length - 1 ||
                                contentData?.shipment?.checkpoints?.indexOf(
                                  c
                                ) === 0
                                  ? styles.checkpointsIcon2
                                  : styles.checkpointsIcon
                              }
                            />
                            {contentData?.shipment?.checkpoints?.indexOf(c) !==
                              contentData?.shipment?.checkpoints?.length -
                                1 && (
                              <div className={styles.intermediateLine}></div>
                            )}
                          </div>
                          <div className={styles.labName}>{c?.location}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.datas}>
                    {contentData?.shipment?.checkpoints?.map((c, i) => {
                      return (
                        <div className={styles.checkDate} key={i + 65}>
                          {convertirHoraLocal(
                            c?.timestamp,
                            company_detail.company.gmt
                          )}{" "}
                          - {c.temperature ? ` (${c?.temperature}°C) ` : ""}
                          <div className={styles.checkpointLabel}>{c?.label}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div> */}
              <div className={styles.modalTitleContainer}>
                {language === "es" ? "Mapa de ruta" : "Route map"}
              </div>
              <div
                style={{
                  width: "30vw",
                  height: "35vw",
                  overflowY: "auto",
                  color: "#1B1464",
                }}
              >
                <Timeline>
                  {contentLoading ? (
                    <div className={styles.loading}>
                      {" "}
                      <CircularProgress />{" "}
                    </div>
                  ) : (
                    contentData?.shipment?.checkpoints?.map((c, i) => (
                      <TimelineItem key={i}>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          // variant="body2"
                          color="text.primary"
                          variant="h6"
                        >
                          <div style={{ color: "#1B1464" }}>
                            {
                              convertirHoraLocal(
                                c?.timestamp,
                                company_detail.company.gmt
                              ).split("  ")[0]
                            }{" "}
                            -{" "}
                            {
                              convertirHoraLocal(
                                c?.timestamp,
                                company_detail.company.gmt
                              ).split("  ")[1]
                            }{" "}
                            <br />
                            <strong>
                              {c?.temperature ? ` ${c?.temperature}°C` : ""}
                            </strong>
                          </div>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector
                            style={{
                              backgroundColor: i === 0 && "transparent",
                            }}
                          />
                          <TimelineDot
                            style={{
                              backgroundColor:
                                i ===
                                contentData?.shipment?.checkpoints?.length - 1
                                  ? "#00ABC8"
                                  : "#bdbdbd",
                              //color: i === data?.shipment?.checkpoints?.length - 1 ? "#FFFFFF" : "#000000" // Puedes ajustar el color del texto según tu diseño
                            }}
                          />

                          <TimelineConnector
                            style={{
                              backgroundColor:
                                i ===
                                  contentData?.shipment?.checkpoints?.length -
                                    1 && "transparent",
                            }}
                          />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{ py: "12px", px: 2 }}
                          className={
                            i === 0 ||
                            i === contentData?.shipment?.checkpoints?.length - 1
                              ? "special"
                              : ""
                          }
                        >
                          <Typography variant="h6" component="span">
                            <div style={{ color: "#00AAC8" }}>
                              {c.label
                                ? `${c.label}: ${c?.location}`
                                : c.location}
                            </div>
                          </Typography>

                          <Typography variant="h6">
                            {c?.responsible_name}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))
                  )}
                </Timeline>
              </div>
            </Box>
          </Modal>
          {/* MODAL CONTENIDO VIAJES EN TRANSITO ----------------------------------------------------------------- */}
          <Modal open={content} onClose={handleOnClose}>
            <Box
              sx={{
                ...modalStyle,
                height: window.screen.width > 800 ? "25vw" : "50vw",
                width: window.screen.width > 800 ? "45%" : "65%",
              }}
            >
              <h2 className={styles.modalTitle}>
                <FormattedMessage id="content_title">
                  Shipment content
                </FormattedMessage>
              </h2>
              {contentData?.shipment?.contents ? (
                <div className={styles.modalContent}>
                  {contentData?.shipment?.contents?.map((ship) => (
                    <div className={styles.modalData} key={ship.id}>
                      {/* {generateBarcode(ship.id)} */}
                      <Barcode
                        value={ship.id}
                        width={(window.screen.width * 0.18) / 100}
                        height={(window.screen.width * 2) / 100}
                        fontSize={(window.screen.width * 1.02) / 100}
                        textAlign="center"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.modalLoading}>
                  <CircularProgress />
                </div>
              )}
            </Box>
          </Modal>

          {/* MODAL COMENTARIOS ---------------------------------------------------------------- */}
          <Modal open={comments} onClose={handleOnCloseComments}>
            <Box
              sx={{
                ...modalStyle,
                height: window.screen.width > 800 ? "25vw" : "40vw",
                width: window.screen.width > 800 ? "20%" : "50%",
                overflowY: "auto",
              }}
            >
              <Comments shipment_id={shipToSeeComments} shipment_table={true} />
            </Box>
          </Modal>
          {/* MODAL GRAFICO TEMPERATURA-INTRUSION ---------------------------------------------------------- */}
          <Modal open={temperatureAlert} onClose={handleTemperatureClose}>
            <Box
              sx={{
                ...modalStyle,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                border: "0.15vw solid #00ABC8",
                borderRadius: 10,
                fontFamily: "'Quattrocento Sans', sans-serif",
                boxShadow: 10,
                pt: 2,
                px: 4,
                pb: 3,
                width: window.screen.width > 800 ? "60%" : "70%",
                height: window.screen.width > 800 ? "40vw" : "60vw",
              }}
            >
              <RegistroTemperaturaIntrusion
                shipment_id={contentData?.shipment?.shipment_id}
                branch_departure={company_detail?.company?.branches?.map(
                  (b) =>
                    b.branch_id === contentData?.shipment?.origin_id && b.name
                )}
                /* 
                --------------------------------------------------------------------------------------------------- */
                departure={convertirHoraLocal(
                  contentData?.shipment?.departure,
                  company_detail?.company?.gmt
                )}
                /* --------------------------------------------------------------------------------------------- */
                branch_arrival={company_detail?.company?.branches?.map(
                  (b) =>
                    b.branch_id === contentData?.shipment?.destination_id &&
                    b.name
                )}
                status={contentData?.shipment?.status}
                temperature_readings={
                  contentData?.shipment?.temperature_readings
                }
                intrusions={contentData?.shipment?.intrusions}
                alerts={contentData?.shipment?.alerts}
                gmt={company_detail?.company?.gmt}
                ship_temp_range={contentData?.shipment?.temperature_range}
                checkpoints={contentData?.shipment?.checkpoints}
                last_checkpoint={contentData?.shipment?.last_checkpoint}
                loading={contentLoading}
              />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
}
