import axios from "axios";
import {
  GET_CALIBRATION_DEVICE_PDF,
  GET_REPORT_PDF,
  GET_STATIC_PDF,
  SERVER_URL,
  language,
} from "./const";
import { UPDATE_COMPANY } from "./queries";
import {
  format,
  formatInTimeZone,
  utcToZonedTime,
  zonedTimeToUtc,
} from "date-fns-tz";

import { es, enUS } from "date-fns/locale"; // Cambia el idioma de los meses y días a español si es necesario
import styled, { keyframes } from "styled-components";
import { wsLink } from "./apolloClient";
import { useContext } from "react";
import { AlertsContext } from "./context/alerts/AlertsProvider";

//funcion para sacar diferencial porcentual con el dia/mes anterior
export function getPercentageChange(oldNumber, newNumber) {
  var decreaseValue = oldNumber - newNumber;

  if (oldNumber === 0 || newNumber === 0 || oldNumber === newNumber)
    return null;

  return ((decreaseValue / oldNumber) * 100).toFixed(1) + "%";
}

//funcion para decodear el token y sacar la info
export function parseJwt(token) {
  if(!token) {
    return
  }else{
    var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  localStorage.setItem("mbt", JSON.parse(jsonPayload).mb);

  return JSON.parse(jsonPayload);
  };
}

//funcion para descargar reporte en pdf
export async function fetchReportFile(_id,checkpointsInterval,shipment_id) {
  const token = localStorage.getItem("token");
  console.log(shipment_id)
  const downloadReport = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: { authorization: `Bearer ${token}` },
        responseType: "blob",
      });
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `report-${shipment_id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      alert("Error intentando descargar el reporte");
    }
  };

  const url = checkpointsInterval.length === 2
    ? `${GET_REPORT_PDF}${_id}/${checkpointsInterval[0]}/${checkpointsInterval[1]}`
    : `${GET_REPORT_PDF}${_id}`;

  await downloadReport(url);
}


export async function fetchCalibrationReport(device) {
  const token = localStorage.getItem("token");
  
  try {
    const response = await axios.get(GET_CALIBRATION_DEVICE_PDF + device._id, {
      headers: { authorization: `Bearer ${token}` },
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `report:#${device.qr}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch {
    alert("Error intentando descargar el reporte");
  }
}

export async function fetchRCStaticPdf(e, _id,id, from, to) {
  const token = localStorage.getItem("token");
  
  try {
    const response = await axios
      .get(`${GET_STATIC_PDF}/${_id}/${from}/${to}`, {
        headers: { authorization: `Bearer ${token}` },
        responseType: "blob",
      })
    

      console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `report-${id}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch {
    alert("Error intentando descargar el reporte");
  }
}

//funcion para formatear la fecha de las notificaciones
export function formatNotiDate(date, language) {
  Number.prototype.padLeft = function (base, chr) {
    var len = String(base || 10).length - String(this).length + 1;
    return len > 0 ? new Array(len).join(chr || "0") + this : this;
  };

  if (language === "es") {
    return (
      [
        date.getDate().padLeft(),
        (date.getMonth() + 1).padLeft(),
        date.getFullYear(),
      ].join("/") +
      " " +
      [date.getHours().padLeft(), date.getMinutes().padLeft()].join(":")
    );
  } else {
    return (
      [
        (date.getMonth() + 1).padLeft(),
        date.getDate().padLeft(),
        date.getFullYear(),
      ].join("/") +
      " " +
      [date.getHours().padLeft(), date.getMinutes().padLeft()].join(":")
    );
  }
}

//seteo data general en local storage
export const setLocalStorageData = (token, date) => {
  console.log(new Date(date).getTime());
  localStorage.setItem("token", token);
  localStorage.setItem("expiration", new Date(date).getTime());
  localStorage.setItem("notifications", "");
  localStorage.setItem("notificationsENG", "");
  localStorage.setItem("numberNoti", 0);
};
// funcion para sacar al usuario q se quiere meter x la fuerza donde no tiene acceso
export const logOut = () => {
  console.log("out!!!");
  // localStorage.setItem("token", "");
  // localStorage.setItem("expiration", "");
  // localStorage.setItem("notifications", "");
  // localStorage.setItem("notificationsENG", "");
  // localStorage.setItem("numberNoti", "");
  // localStorage.setItem("access", "");
  // localStorage.setItem("assigned_to", "");
  // localStorage.setItem("tempAlert", false);
  // localStorage.setItem("intrusion", false);
  localStorage.clear();
    // Cerrar la conexión WebSocket
    if (wsLink && wsLink.subscriptionClient) {
      wsLink.subscriptionClient.close();
    }
  
  window.location.replace("/");
};

export const getDeviceLocation = async (company_id) => {
  const token = localStorage.getItem("token");
  const result = await axios.get(`${SERVER_URL}/getgeo/${company_id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return result.data;
};

export const calcBoundaries = (devices) => {
  const boundaries = {
    min_coords: {
      lng: null,
      lat: null,
    },
    max_coords: {
      lng: null,
      lat: null,
    },
  };
  let lat = devices.map(function (p) {
    return p.lat;
  });
  let lng = devices.map(function (p) {
    return p.lng;
  });

  boundaries.min_coords.lng = Math.min.apply(null, lng);
  boundaries.min_coords.lat = Math.min.apply(null, lat);
  boundaries.max_coords.lng = Math.max.apply(null, lng);
  boundaries.max_coords.lat = Math.max.apply(null, lat);

  return boundaries;
};
export const getColor = (companyAlertParams, range, temp, intrusion) => {
  let backColor = "";
  //filtro el rango c nombre (REF, CON,AMB)
  let rangesToCompare = companyAlertParams?.filter(
    (temp) => temp?.name === range
  )[0];

  let tempAlert = false;

  if (intrusion) localStorage.setItem("intrusion", true);

  if (temp < rangesToCompare?.min || temp > rangesToCompare?.max) {
    tempAlert = true;
    backColor = "red";

    // localStorage.setItem('tempAlert',true);
  }
  //SI TENGO INTRUSION Y NO ALERTA DE TEMP.
  else if (intrusion && !tempAlert) backColor = "#F0EA3F"; //yellow
  // SI TENGO INTRUSION Y ALERTA DE TEMP. GANA TEMP!
  else if (intrusion && tempAlert) backColor = "#D60707";
  // SI ESTA TODO BIEN VERDE
  else backColor = "#33B27F";

  return backColor;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertirHoraLocal = (fechaISO, zonaHoraria) => {
  if (fechaISO && zonaHoraria) {
    const fechaUtc = formatInTimeZone(fechaISO, zonaHoraria, `MMM dd  h:mm a`, {
      locale: language === "es" ? es : enUS,
    });

    // const formatoFechaHora = `MMM dd  h:mm a`;

    // const fechaFormateada = format(
    //   fechaUtc,
    //   formatoFechaHora,
    //   localStorage.getItem("language") === "es"
    //     ? { locale: es }
    //     : { locale: enUS }
    // );

    // return fechaFormateada.toLowerCase();
    return fechaUtc;
  }

  return "--";
};

export const getTempStyle = (item, updatedShipmentId, tolerance) => {
  console.log("desde helpers", tolerance);

  const temperature = item?.last_checkpoint?.temperature;
  const minRange = item?.temperature_range?.min;
  const maxRange = item?.temperature_range?.max;

  const isAnimationActive = item.shipment_id === updatedShipmentId;

  const styles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: "3vw",
    width: "90%",
    height: "30%",
   // fontFamily: "Quattrocento Sans, sans-serif",
    color: getTempColor(item, tolerance),
    animationName: isAnimationActive ? "checkpointAnimationTemp" : "",
    animationDuration: "2s",
    opacity: isAnimationActive ? 1 : 0,
    transition: "opacity 0.5s ease-in-out, color 0.5s ease-in-out",
  };

  return styles;
};

export const getTempColor = (item, tolerance) => {

  const temperature = item?.last_checkpoint?.temperature;
  const minRange = item?.temperature_range?.min;
  const maxRange = item?.temperature_range?.max;

  if (temperature >= minRange && temperature <= maxRange) {
    return "green"; // Temperatura dentro del rango
  } else if (
    temperature >= minRange - tolerance.temp &&
    temperature <= maxRange + tolerance
  ) {
    return "orange"; // Temperatura dentro de la tolerancia
  } else if (temperature > maxRange + tolerance.temp) {

    return "red"; // Temperatura por encima del máximo + tolerancia
  } else if (temperature < minRange - tolerance.temp) {
    return "blue"; // Temperatura por debajo del mínimo - tolerancia
  }
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript empiezan desde 0
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};



export const closeWebSocketConnection = () => {
  if (wsLink) {
    console.log('cerrando ando')
    wsLink.subscriptionClient.close();
  }
};

export const isTokenExpired = () => {
  console.log('isTokenExpired')
  const expiration = localStorage.getItem("expiration");
  if (!expiration) return true;
  const now = new Date().getTime();
  console.log(now > expiration)
  return now > expiration;
};
export const expiredAction = () => {
  if (isTokenExpired()) {
    closeWebSocketConnection();
    logOut();
  }else {
    return
  }
}

 export const getTimestamp = (tick) => {
  let date = new Date(tick);
  const timezoneOffset = date.getTimezoneOffset();
  date.setHours(date.getHours() /*  + (timezoneOffset / 60) */);

  var month = date.getMonth() + 1; // Los meses van de 0 a 11, por lo que hay que sumarle 1
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // el 0 se convierte en 12
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = month + "/" + day + " " + hours + ":" + minutes + " " + ampm;
  return strTime;
};


