import React, { createContext, useEffect, useState } from "react";
import {  GET_REFRIGERATIONS_TO_SET_NOTIFICATIONS } from "../../queries";
import { useLazyQuery } from "@apollo/client";
import { company_id } from "../../const";

// Crea el contexto
export const AlertsContext = createContext();

// Crea el proveedor del contexto
export const AlertsProvider = ({ children }) => {
  //rc static Context ------------------------------------------------------------------------------

  const [alerts, setAlerts] = useState({
    icon: false,
    static_id: [],
  });

const viewed = (id)=>{
  console.log(id)
}
  const [
    getRefrigerations,
    {
      loading: refrigerationsLoading,
      error: refrigerationsError,
      data: refrigerationsResult,
    },
  ] = useLazyQuery(GET_REFRIGERATIONS_TO_SET_NOTIFICATIONS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
   
    const fetchRefrigerations = async () => {
      const result = await getRefrigerations({
        variables: {
          company_id: company_id,
          page: 1,
          per_page: 1000,
          only_new: true,
        },
      });
      if (result?.data?.refrigerations?.length > 0) {
        setAlerts({
          icon: true,
          static_id: result?.data?.refrigerations?.map((refrigeration) => refrigeration.refrigeration_id),
        });
      }else{
        setAlerts({
          icon: false,
          static_id: [],
        });
      }

    
    };
  
  
    fetchRefrigerations();
    const intervalId = setInterval(fetchRefrigerations, 1800000); // 1800000 ms = 30 minutos
    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);

// in transit context -----------------------------------------------------------------------------
const [inTransitAlerts, setInTransitAlerts] = useState(false)

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        setAlerts,
        viewed, 
        inTransitAlerts,
        setInTransitAlerts
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};
