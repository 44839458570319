import { useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import { GET_CALIBRATION } from "../../../queries";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Bar,
  ReferenceLine,
} from "recharts";
import styles from "./charts.module.scss";
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
const CalTempChart = ({ calibration_id, expanded, index }) => {
  const [loading, setLoading] = useState(true);
  const [tempData, setTempData] = useState([]);
  const [intrusions, setIntrusions] = useState([]);
  const [dataState, setDataState] = useState([]);
  const [
    chartsData,
    {
      loading: chartsDataLoading,
      error: chartsDataError,
      data: chartsDataResult,
    },
  ] = useLazyQuery(GET_CALIBRATION);

  //funcion para convertir los segundos en formado dd-hh-mm-ss
  const convertSeconds = (totalSeconds) => {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const getTimestamp = (tick) => {
    let date = new Date(tick);
    const timezoneOffset = date.getTimezoneOffset();
    date.setHours(date.getHours() /* + (timezoneOffset / 60) */);

    var month = date.getMonth() + 1; // Los meses van de 0 a 11, por lo que hay que sumarle 1
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // el 0 se convierte en 12
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = month + "/" + day + " " + hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  useEffect(() => {
    const fetchChartsData = async () => {
      let result = await chartsData({
        variables: {
          calibration_id,
        },
      });
      setTempData(result?.data?.calibration?.temperature_readings);
      setIntrusions(result?.data?.calibration?.intrusions);
      // console.log('tempData')
      // console.log(chartsDataResult?.calibration)
    };
    fetchChartsData();
  }, [calibration_id, expanded, index, chartsDataResult]);

  let data = [
    {
      value:chartsDataResult?.calibration?.temperature_readings?.[0]?.value,
      counter: 0,
      formattedCounter: "0d 0h 0m 0s",
      intrusion: 0,
      timestamp:
        chartsDataResult?.calibration?.temperature_readings?.[0]?.timestamp,
      millisec: Date.parse(
        chartsDataResult?.calibration?.temperature_readings?.[0]?.timestamp
      ),
      // max: ship_temp_range?.max,
      // min:ship_temp_range?.min
    },
  ];

  //RECORRO LA DATA CUANDO SE INICIALIZA EL COMPONENTE -------------------------------------------------
  useEffect(() => {
    for (
      let i = 0;
      i < chartsDataResult?.calibration?.temperature_readings?.length;
      i++
    ) {
      let hasIntrusion = false;

      for (
        let j = 0;
        j < chartsDataResult?.calibration?.intrusions?.length;
        j++
      ) {
        if (
          chartsDataResult?.calibration?.temperature_readings?.[i]?.counter ===
          chartsDataResult?.calibration?.intrusions[j].init_counter
        ) {
          hasIntrusion = true;
          break;
        } else if (
          chartsDataResult?.calibration?.temperature_readings?.[i]?.counter ===
          chartsDataResult?.calibration?.intrusions[j].final_counter
        ) {
          hasIntrusion = false;
        }
      }
      data.push({
        value:chartsDataResult?.calibration?.temperature_readings?.[i]?.value,
        counter:
          chartsDataResult?.calibration?.temperature_readings[i]?.counter,
        formattedCounter: convertSeconds(
          chartsDataResult?.calibration?.temperature_readings[i]?.counter
        ),
        intrusion: hasIntrusion ? 1 : 0,
        timestamp:
          chartsDataResult?.calibration?.temperature_readings[
            i
          ]?.timestamp.toLocaleString(),
        millisec: Date.parse(
          chartsDataResult?.calibration?.temperature_readings[i]?.timestamp
        ),
      });
     
    }

    setDataState(data);
  }, [chartsDataResult?.calibration?.temperature_readings]);

  while (loading) {
    setTimeout(() => setLoading(false), 2000);
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload?.length) {
      const tooltipStyle = {
        background: "#ffffff",
        padding: "10px",
        border: "1px solid #ccc",
      };

      return (
        <div style={tooltipStyle}>
          <p>{`Fecha: ${getTimestamp(label)}`}</p>
          <p style={{ color: "#00ABC8" }}>Temp: {payload[0]?.value}°C</p>
          <p
            style={{ color: "#FF001F" }}
          >{`Intrusion: ${payload[1]?.value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <>
      { dataState?.[1] ?(
        <div className={styles.chartContainer}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width="100%"
              height="100%"
              data={dataState}
              margin={{
                top: window.screen.width < 1050 ? 2 : 6,
                right: window.screen.width < 1050 ? 10 : 30,
                left: 18,
                bottom: 25,
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />

              <XAxis
                dataKey="millisec"
                type="number"
                ticks={dataState?.millisec}
                tick={{
                  color: "#00ABC8",
                  fontSize: "0.7vw",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
                tickMargin={6}
                tickLine={true}
                tickCount={20}
               
                tickFormatter={getTimestamp}
                domain={["dataMin", "dataMax"]}
              >
                {/* <Label
                  value={
                    localStorage.getItem("language") === "en"
                      ? "Time (days-hours-minutes-seconds)"
                      : "Tiempo (días-horas-minutos-segundos)"
                  }
                  offset={-20}
                  position="insideBottom"
                  fontFamily="'Quattrocento Sans', sans-serif"
                  fontSize="0.8vw"
                  stroke="#1B1464"
                  strokeWidth={0.3}
                /> */}
              </XAxis>
              <YAxis
                yAxisId="left"
                dataKey="value"
                type="number"
                tick={{
                  color: "#00ABC8",
                  fontSize: "0.7vw",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
                tickCount={10}
                tickLine={true}
                padding={{ top: (window.screen.width * 6.25) / 100 }}
                domain={[0, "dataMax"]}
              >
                <Label
                  value={
                    localStorage.getItem("language") === "en"
                      ? "Temperature(°C)"
                      : "Temperatura(°C)"
                  }
                  angle={-90}
                  position="left"
                  fontFamily="'Quattrocento Sans', sans-serif"
                  fontSize="0.8vw"
                  fill="#00ABC8"
                  fontWeight="lighter"
                />
              </YAxis>
              <YAxis
                yAxisId="right"
                dataKey="intrusion"
                type="category"
                tick={{
                  color: "#00ABC8",
                  fontSize: "0.7vw",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                }}
                tickMargin={6}
                tickCount={10}
                tickLine={false}
                orientation="right"
                padding={{ bottom: (window.screen.width * 20.8) / 100 }}
                domain={["0", "1"]}
              >
                <Label
                  value={
                    localStorage.getItem("language") === "en"
                      ? "Intrusions"
                      : "Intrusiones"
                  }
                  angle={-90}
                  position="insideRight"
                  fontFamily="'Quattrocento Sans', sans-serif"
                  fontSize="0.8vw"
                  fill="#FF001F"
                  fontWeight="lighter"
                />
              </YAxis>

              <Tooltip content={<CustomTooltip />} />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="value"
                stroke="#00ABC8"
                dot={false}
              />
              <Line
                yAxisId="right"
                type="step"
                dataKey="intrusion"
                stroke="#FF001F"
                dot={false}
              />
          
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) :  (
        <div className={styles.noData}>
          <FormattedMessage id="no_data_register">No data</FormattedMessage>
        </div>
      )}
    </>
  );
};

export default CalTempChart;
