import React, { useState, useEffect } from "react";
import styles from "./CreateDevice.module.scss";
import { FormattedMessage } from "react-intl";
import { Dialog } from "@mui/material";
import {
  devicesList,
  devicesSensors,
  devicesTempRanges,
} from "../../../const";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_DEVICE, GET_COMPANIES, GET_COMPANY_DETAIL } from "../../../queries";

export default function CreateDevice() {
  // ESTADOS LOCALES ------------------------------------------------------------------------------------
  //estado para la confirmación del create device
  const [confirmMessage, setConfirmMessage] = useState(false);

  //estado para guardarme la data que va ingresando a los input
  const [comp_id, setComp_id] = useState('');
  const [alertParams, setAlertParams] = useState([])
  const [inputsData, setInputsData] = useState({
    address: "",
    qr: "",
    version: "",
    assigned_to:"",
    device_format: "",
    sensors: [],

  });

  //QUERIES/MUTATIONS -----------------------------------------------------------------------------------
  //mutation para crear dispositivo
  const [
    createDevice,
    {
      // data: createdDevice,
      // loading: createdDeviceLoading,
      error: createdDeviceError,
    },
  ] = useMutation(CREATE_DEVICE);

  //lazy query para traer todas las compañias
  const [
    getAllCompanies,
    {
      data: allCompaniesData,
      // loading: allCompaniesLoading,
      // error: allCompaniesError,
    },
  ] = useLazyQuery(GET_COMPANIES);
  const [
    getCompany, {
      data: companyData,

    }

  ] = useLazyQuery(GET_COMPANY_DETAIL)

  //CUANDO SE INICIA EL COMPONENTE SE OBTIENE DATO DE TODAS LAS COMPAÑIAS (SUDO) -----------------------------------------------------------
  useEffect(() => {
    getAllCompanies();
  }, []);

  //traigos alertparams de la company q elijo
  useEffect(() => {
    setAlertParams([])
    const fetchCompany = async () => {
      await getCompany({
        variables: {
          company_id: comp_id
        }
      })

      //setAlertParams(companyData?.company?.alert_params?.temperature_alerts)
    }
    fetchCompany()
  }, [comp_id, companyData])

  // HANDLERS -----------------------------------------------------------------------------------------------
  //handler del onChange de los input
  const handleOnChangeInputs = (e) => {
    if (e.target.name === "sensors") {
      setInputsData({
        ...inputsData,
        sensors: !e.target.checked
          ? inputsData.sensors.filter((s) => s !== e.target.value)
          : [...inputsData.sensors, e.target.value],
      });
    } else {
      setInputsData({
        ...inputsData,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "assigned_to") {
      setInputsData({
        ...inputsData,
        [e.target.name]: e.target.value,
      });
      setComp_id(e.target.value)
    }
  };

  //handler del confirm changes
  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      if (
        !inputsData.address ||
        !inputsData.qr ||
        !inputsData.version ||
        
        !inputsData.device_format ||
        !inputsData.sensors[0]

      ) {
        return alert(
          localStorage.getItem("language") === "en"
            ? "Please complete all the data spaces"
            : "Por favor, completa todos los campos"
        );
      }
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        // aca va la llamada al create device
        let response = await createDevice({
          variables: inputsData
        });
       
        alert("Device created");
        // setModalCreateUser(false);
        window.location.reload();
      }
    } catch (error) {
      console.log("hubo un error en el create device", error);

    }
  };

  //   MANEJO DE ERRORES ------------------------------------------------------------------------------
  if (createdDeviceError)
    console.log("error en el device creado", createdDeviceError);

  return (
    <div className={styles.container}>
      <h2
        className={styles.createTitle}
      >
        {" "}
        <FormattedMessage id="create_device">
          Create new device
        </FormattedMessage>
      </h2>
      <form className={styles.formContainer}>
        {/* MAC ADDRESS ------------------------------------------------------------------------ */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="mac_address">MAC Address</FormattedMessage>{" "}
          </label>
          <input
            type="text"
            name="address"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* QR --------------------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>QR</label>
          <input
            type="number"
            name="qr"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* ASIGNADO A --------------------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}><FormattedMessage id='assigned_to_config'>Assigned to</FormattedMessage></label>
          <select
            onChange={handleOnChangeInputs}
            defaultValue="default select"
            name="assigned_to"
            className={styles.select}
          >
            <>
              <option disabled hidden value="default select">
                Select a company
              </option>
              {allCompaniesData?.companies?.map((c) => {
                return (
                  <option value={c.company_id} key={c.company_id}>
                    {c.company_id}
                  </option>
                );
              })}

            </>
          </select>
        </span>
        <br />
        {/* VERSION --------------------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="version">Version</FormattedMessage>
          </label>
          <input
            type="text"
            name="version"
            onChange={handleOnChangeInputs}
            className={styles.eachInput}
          ></input>
        </span>
        <br />
        {/* TIPO/TEMP RANGE -------------------------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="branch_type_config">Type</FormattedMessage>
          </label>
          <select
            onChange={handleOnChangeInputs}
            name="temperature_range"
            defaultValue="default select"
            className={styles.select}
          >
            <option disabled hidden value="default select">
              {localStorage.getItem("language") === "en"
                ? "Select a temperature range"
                : "Seleccione un tipo"}
            </option>
            {companyData?.company?.alert_params?.temperature_alerts?.map((a) => {
              return (
                <option key={a.name} value={a.name}>
                  {a.name}
                </option>
              );
            })}

          </select>
        </span>
        <br />
        {/* FORMATO ---------------------------------------------------------------------------------------------- */}
        <span className={styles.eachSection}>
          <label className={styles.eachLabel}>
            <FormattedMessage id="format">Format</FormattedMessage>
          </label>
          <select
            onChange={handleOnChangeInputs}
            name="device_format"
            defaultValue="default select"
            className={styles.select}
          >
            <option disabled hidden value="default select">
              {localStorage.getItem("language") === "en"
                ? "Select a format"
                : "Seleccione un formato"}
            </option>
            {devicesList?.map((d) => {
              return (
                <option key={d} value={d}>
                  {d}
                </option>
              );
            })}
          </select>
        </span>
        <br />
        {/* SENSORES -------------------------------------------------------------------------------------------- */}
        <div className={styles.eachSection}>
          <div>
            <label className={styles.eachLabel}>
              <FormattedMessage id="sensors">Sensors</FormattedMessage>
            </label>
            <div className={styles.sensorsInputContainer}>
              {devicesSensors?.map((d) => {
                return (
                  <div key={d}>
                    <span>
                      <label className={styles.eachSensor}>
                        <input
                          type="checkbox"
                          name="sensors"
                          value={d}
                          onChange={handleOnChangeInputs}
                        />
                        {d}
                      </label>
                    </span>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* GUARDAR ---------------------------------------------------------------------------------------------- */}
        <button
          type="submit"
          onClick={handleConfirm}
          className={styles.createButton}
        // disabled={
        //   !inputsData.username ||
        //   !inputsData.email ||
        //   !inputsData.password ||
        //   !inputsData.repeatedPassword
        //     ? true
        //     : false
        // }
        >
          <FormattedMessage id="save_button">Save</FormattedMessage>
        </button>
        <Dialog open={confirmMessage}>
          {localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"}
        </Dialog>
      </form>
    </div>
  );
}
