import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "../EditCheckpointsContainer.module.scss";
import { useIntl } from "react-intl";
const AddCheckpointForm = ({
  newChecks,
  /*   checkName,
  addCheckpoint, */
  handleUpdateCheckpoints,
  handleCancelUpdateCheckpoints,
 /*  handleChangeInputName, */
  isBlocked,
}) => {
  const intl = useIntl();
  return (
    <>
     
      <div className={styles.update_cancel_container}>
        <button
          onClick={() => handleUpdateCheckpoints(newChecks)}
          className={styles.updateButton}
          disabled={isBlocked}
        >
         
          <FormattedMessage id="update" />
        </button>
        <button
          onClick={handleCancelUpdateCheckpoints}
          className={styles.cancelButton}
          disabled={isBlocked}
        >
        
          <FormattedMessage id="cancel" />
        </button>
      </div>
    </>
  );
};

export default AddCheckpointForm;
