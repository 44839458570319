import React, { useState, useContext, useEffect } from "react";
import Dashboard from "../Dashboard/Dashboard.jsx";
import Dashboard2 from "../Dashboard/Dashboard2.jsx";
import UserBar from "../UserBar/UserBar.jsx";
import configureIcon from "./configureIcon.png";
import dashboardIcon from "./dashboardIcon.png";

import dashboardWhiteIcon from "./dashboardWhiteIcon.png";

import configurationWhiteIcon from "./configurationWhiteIcon.png";
import aigrey from "./Icono_AI_Gris.png";
import inComingIcon from "./inComingIcon.png";
import calibrateBlueIcon from "./Icono_Calibracion_Azul.png";
import calibrateWhiteIcon from "./Icono_Calibracion_Blanco.png";
import styles from "./Home.module.scss";
import { TiThMenu } from "react-icons/ti";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FormattedMessage } from "react-intl";
import Configuracion from "../Configuración/Configuracion.jsx";
import { IoIosAlert } from "react-icons/io";
import {
  closeWebSocketConnection,
  isTokenExpired,
  parseJwt,
} from "../../helpers.js";
import { BASE_URL } from "../../const";
import InComing from "../InComing/InComing.jsx";
import inComingWhiteIcon from "./inComingWhiteIcon.png";
import RcWay from "../Gps/RcWay.jsx";

import { logOut } from "../../helpers.js";

import freez_Azul from "./freezer_icons/Freez_Azul.png";
import freez_Blanco from "./freezer_icons/Freez_Blanco.png";

import Rc_Freezer from "../RcFreezer/Rc_Freezer.jsx";
import { GET_COMPANY_DETAIL } from "../../queries.js";
import { useQuery } from "@apollo/client";
import Calibrations from "../calibrations/Calibrations.jsx";
import PrivateRoute from "../PrivateRoute/PrivateRoute.jsx";
import { GoDotFill } from "react-icons/go";
import { AlertsContext } from "../../context/alerts/AlertsProvider.jsx";
export default function Home({ operator_access }) {
  //const {operator_access} = useContext(UserAccessContext);
  const { alerts, setAlerts } = useContext(AlertsContext);
  const [alertIcon, setAlertIcon] = useState(false);
  //ESTADOS LOCALES ------------------------------------------------
  //estado que guarda el filtro de vista inicial
  const [view, setView] = useState("dashboard");

  //estado y variable para el sidebar
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    loading: companyDetailLoading,
    error: companyDetailError,
    data: companyDetailData,
  } = useQuery(GET_COMPANY_DETAIL);

  useEffect(() => {
    const checkAlerts = () => {
      
      if(alerts.static_id.length > 0){
        setAlertIcon(true);
      }else{
        setAlertIcon(false);
      }
    };
    checkAlerts();
  }, [alerts]);

  //HANDLERS ---------------------------------------------------------------------
  //handlers del sidebar
  const handleView = (word) => {
    setView(word);
    window.location.replace(`/${word}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  //handler sidebar responsive
  const handleResponsiveMenu = (value_view) => {
    setView(value_view);
    window.location.replace(`/#${value_view}`);
  };

  //LEVEL DEL USUARIO LOGUEADO
  const level = parseJwt(localStorage.getItem("token"))?.lvl;
  const access = localStorage.getItem("access");
  let access_parced = "";
  const assigned = localStorage.getItem("assigned_to");

  if (!parseJwt(localStorage.getItem("token"))?.SUDO) {
    if (assigned === "null") logOut();
    if (access !== "") {
      access_parced = JSON.parse(access);
    }
  } else window.location.replace(`/#configuration`);
  
  return (
    <div className={styles.allContainer}>
      <PrivateRoute>
        <UserBar gmt={companyDetailData?.company?.gmt} />

        {/* <button onClick={createAlert}>create</button>
        <button onClick={removeAlert}>remove</button> */}

        {/* LEVEL 2 ----------------------------------------------------------------------------------------------- */}
        {/*  {parseJwt(localStorage.getItem("token")).SUDO ||
        level === 1 ||
        level === 2  */}
        <div className={styles.container}>
          {/* MENU RESPONSIVE --------------------------------------------------------------- */}
          <div className={styles.respMenuContainer}>
            <button className={styles.respMenu} onClick={handleClick}>
              <TiThMenu size={25} />
            </button>
            <Menu
              disableScrollLock={true}
              id="demo-positioned-menu"
              aria-label="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{ borderRadius: "2vw", width: "auto", height: "auto" }}
            >
              {(window.location.href !== `${BASE_URL}/#dashboard` ||
                window.location.href !== `${BASE_URL}/`) && (
                <MenuItem
                  onClick={() => handleResponsiveMenu("dashboard")}
                  sx={{
                    color: "#1B1464",
                    fontFamily: "'Quattrocento Sans', sans-serif",
                    fontSize: "3vw",
                  }}
                >
                  <a href="#dashboard" style={{ textDecoration: "none" }}>
                    Dashboard
                  </a>
                </MenuItem>
              )}
              {window.location.href !== `${BASE_URL}/#reports` && (
                <MenuItem
                  onClick={() => handleResponsiveMenu("reports")}
                  sx={{
                    color: "#1B1464",
                    fontFamily: "'Quattrocento Sans', sans-serif",
                    fontSize: "3vw",
                  }}
                >
                  <FormattedMessage id="reports_title">
                    <a href="#reports" style={{ textDecoration: "none" }}>
                      Reports
                    </a>
                  </FormattedMessage>
                </MenuItem>
              )}
              {window.location.href !== `${BASE_URL}/#configuration` && (
                <MenuItem
                  onClick={() => handleResponsiveMenu("configuration")}
                  sx={{
                    color: "#1B1464",
                    fontFamily: "'Quattrocento Sans', sans-serif",
                    fontSize: "3vw",
                  }}
                >
                  <FormattedMessage id="config_title">
                    <a href="#configuration" style={{ textDecoration: "none" }}>
                      Settings
                    </a>
                  </FormattedMessage>
                </MenuItem>
              )}
              {window.location.href !== `${BASE_URL}/#in-coming` && (
                <MenuItem
                  onClick={() => handleResponsiveMenu("in-coming")}
                  sx={{
                    color: "#1B1464",
                    fontFamily: "'Quattrocento Sans', sans-serif",
                    fontSize: "3vw",
                  }}
                >
                  <FormattedMessage id="in_coming_title">
                    <a href="#reports" style={{ textDecoration: "none" }}>
                      In coming
                    </a>
                  </FormattedMessage>
                </MenuItem>
              )}

              {window.location.href !== `${BASE_URL}/#rcfreezer` && (
                <MenuItem
                  onClick={() => handleResponsiveMenu("rcfreezer")}
                  sx={{
                    color: "#1B1464",
                    fontFamily: "'Quattrocento Sans', sans-serif",
                    fontSize: "3vw",
                  }}
                >
                  <FormattedMessage id="rcfreezer_title">
                    <a href="#rcfreezer" style={{ textDecoration: "none" }}>
                      rcfreezer
                    </a>
                  </FormattedMessage>
                </MenuItem>
              )}

              {window.location.href !== `${BASE_URL}/#calibrations` && (
                <MenuItem
                  onClick={() => handleResponsiveMenu("calibrations")}
                  sx={{
                    color: "#1B1464",
                    fontFamily: "'Quattrocento Sans', sans-serif",
                    fontSize: "3vw",
                  }}
                >
                  <FormattedMessage id="calibrations_title">
                    <a href="#calibrations" style={{ textDecoration: "none" }}>
                      calibrations
                    </a>
                  </FormattedMessage>
                </MenuItem>
              )}
            </Menu>
          </div>
          {/* SIDEBAR ---------------------------------------------------------------------- */}
          <div className={styles.sideBarContainer}>
            {/* SIDEBAR => BOTON DASHBOARD  */}
            {(level === 1 ||
              level === 2 ||
              (level === 3 && access_parced.dash_control === true)) && (
              <div className={styles.buttonContainer}>
                <button
                  className={
                    window.location.href === `${BASE_URL}/#dashboard` ||
                    window.location.href === `${BASE_URL}/`
                      ? styles.buttonActive
                      : styles.button
                  }
                  onClick={() => handleView('#dashboard')}// handleView("") estaba asi y esto reinicia la pagina
                >
                  <a href="#dashboard" style={{ textDecoration: "none" }}>
                    <img
                      className={styles.icon}
                      src={
                        window.location.href === `${BASE_URL}/#dashboard` ||
                        window.location.href === `${BASE_URL}/`
                          ? dashboardWhiteIcon
                          : dashboardIcon
                      }
                      alt=""
                    />
                    {(window.location.href === `${BASE_URL}/#dashboard` ||
                      window.location.href === `${BASE_URL}/`) && (
                      <div className={styles.buttonTitle}>Dashboard</div>
                    )}
                  </a>
                </button>
              </div>
            )}

            {/* SIDEBAR => BOTON IN COMING  */}
            {(level === 1 ||
              level === 2 ||
              (level === 3 && access_parced.dash_intransit === true)) && (
              <div className={styles.buttonContainer}>
                <button
                  className={
                    window.location.href === `${BASE_URL}/#in-coming`
                      ? styles.buttonActive
                      : styles.button
                  }
                  onClick={() => handleView("#in-coming")}
                >
                  <a href="#in-coming" style={{ textDecoration: "none" }}>
                    <img
                      className={styles.icon}
                      src={
                        window.location.href === `${BASE_URL}/#in-coming`
                          ? inComingWhiteIcon
                          : inComingIcon
                      }
                      alt=""
                    />

                    {window.location.href === `${BASE_URL}/#in-coming` && (
                      <div className={styles.buttonTitle}>
                        {" "}
                        <FormattedMessage id="in_coming_title">
                          In coming
                        </FormattedMessage>
                      </div>
                    )}
                  </a>
                </button>

                {/* {alerts.icon && (
                  <GoDotFill
                    className={
                      window.location.href === `${BASE_URL}/#in-coming`
                        ? styles.alertIconActive
                        : styles.alertIcon
                    }
                  />
                )} */}
              </div>
            )}

            {/*RC_FREEZER BUTTON  */}
            {companyDetailData?.company?.hired_services?.includes(
              "RC_STATIC"
            ) &&
              (level === 1 || level === 2 || level === 3) && (
                <div className={styles.buttonContainer}>
                  <button
                    className={
                      window.location.href === `${BASE_URL}/#rcstatic`
                        ? styles.buttonActive
                        : styles.button
                    }
                    onClick={() => handleView("#rcstatic")}
                  >
                    <a href="#rcstatic" style={{ textDecoration: "none" }}>
                      <img
                        style={{ height: "1.7vw" }}
                        src={
                          window.location.href === `${BASE_URL}/#rcstatic`
                            ? freez_Blanco
                            : freez_Azul
                        }
                        alt=""
                      />
                      {window.location.href === `${BASE_URL}/#rcstatic` && (
                        <div className={styles.buttonTitle}>RC+Static</div>
                      )}
                    </a>
                  </button>
                  {alertIcon && (
                    <GoDotFill
                      className={
                        window.location.href === `${BASE_URL}/#rcstatic`
                          ? styles.alertIconActive
                          : styles.alertIcon
                      }
                    />
                  )}
                </div>
              )}

            {/* SIDEBAR => BOTON CALIBRATIONS   */}
            {(parseJwt(localStorage.getItem("token"))?.SUDO ||
              level === 1 ||
              level === 2 ||
              (level === 3 && access_parced.calibrate === true)) && (
              <div className={styles.buttonContainer}>
                <button
                  className={
                    window.location.href === `${BASE_URL}/#calibrations`
                      ? styles.buttonActive
                      : styles.button
                  }
                  onClick={() => handleView("#calibrations")}
                >
                  <a href="#calibrations" style={{ textDecoration: "none" }}>
                    <img
                      //className={styles.icon}
                      style={{ height: "1.8vw", marginLeft: "-0.1vw" }}
                      src={
                        window.location.href === `${BASE_URL}/#calibrations`
                          ? calibrateWhiteIcon
                          : calibrateBlueIcon
                      }
                      alt=""
                    />

                    {window.location.href === `${BASE_URL}/#calibrations` && (
                      <div className={styles.buttonTitle}>
                        {" "}
                        <FormattedMessage id="calibrations_title">
                          calibrations
                        </FormattedMessage>
                      </div>
                    )}
                  </a>
                </button>
              </div>
            )}
            {/* SIDEBAR => IA */}

            {(parseJwt(localStorage.getItem("token"))?.SUDO ||
              level === 1 ||
              level === 2 ||
              (level === 3 && access_parced.calibrate === true)) && (
              <div className={styles.buttonContainer}>
                <button
                  className={styles.ai}
                  disabled={true}
                  // onClick={() => handleView("#ai")}
                >
                  {/* <a href="#ai" style={{ textDecoration: "none", cursor:"disabled"}}> */}
                  <img
                    className={styles.icon}
                    src={
                      window.location.href === `${BASE_URL}/#ai`
                        ? aigrey
                        : aigrey
                    }
                    alt=""
                  />
                  {/* {window.location.href === `${BASE_URL}/#ai` && (
                    <div className={styles.buttonTitle}> Ai</div>
                  )}
                </a> */}
                </button>
              </div>
            )}
            {/* SIDEBAR => BOTON CONFIG  */}
            {(parseJwt(localStorage.getItem("token"))?.SUDO ||
              level === 1 ||
              level === 2 ||
              (level === 3 && access_parced.calibrate === true)) && (
              <div className={styles.buttonContainer}>
                <button
                  className={
                    window.location.href === `${BASE_URL}/#configuration`
                      ? styles.buttonActive
                      : styles.button
                  }
                  onClick={() => handleView("#configuration")}
                >
                  <a href="#configuration" style={{ textDecoration: "none" }}>
                    <img
                      className={styles.icon}
                      src={
                        window.location.href === `${BASE_URL}/#configuration`
                          ? configurationWhiteIcon
                          : configureIcon
                      }
                      alt=""
                    />
                    {window.location.href === `${BASE_URL}/#configuration` && (
                      <div className={styles.buttonTitle}>
                        {" "}
                        <FormattedMessage id="config_title">
                          Configuration
                        </FormattedMessage>
                      </div>
                    )}
                  </a>
                </button>
              </div>
            )}
          </div>

          {/* COMPONENTES RENDERIZADOS  */}
          {(window.location.href === `${BASE_URL}/#dashboard` ||
            window.location.href === `${BASE_URL}/`) && (
            <Dashboard
              id="dashboard"
              access_parced={access_parced}
              level={level}
              operator_access={operator_access}
            />
            //   <Dashboard2
            //   id="dashboard"
            //   access_parced={access_parced}
            //   level={level}
            //   operator_access={operator_access}
            // />
          )}

          {/* {window.location.href === `${BASE_URL}/#reports` && (
         no va // <Reports id="reports" access_parced={access_parced} level={level} />
          este es el posta <Reports2 id="reports" access_parced={access_parced} />
        )} */}

          {window.location.href === `${BASE_URL}/#configuration` && (
            <Configuracion
              id="configuration"
              access_parced={access_parced}
              level={level}
            />
          )}
          {window.location.href === `${BASE_URL}/#in-coming` && (
            <InComing
              id="in-coming"
              access_parced={access_parced}
              level={level}
            />
          )}
          {window.location.href === `${BASE_URL}/#gps` && <RcWay id="rc_way" />}
          {window.location.href === `${BASE_URL}/#rcstatic` && (
            <Rc_Freezer id="rcstatic" gmt={companyDetailData?.company?.gmt} />
          )}
          {window.location.href === `${BASE_URL}/#calibrations` && (
            <Calibrations id="calibrations" />
          )}
        </div>
      </PrivateRoute>
    </div>
  );
}
