export const mapReducer = (state, action) => {
    switch (action.type) {
        case 'setMap':

            return {
                isMapReady: true,
                map: action.payload
            }
        default:
            return state
    }
}