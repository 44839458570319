import React, { useState, useEffect, useMemo } from "react";
import {
  GET_COMPANY_DETAIL,
  UPDATE_COMPANY,
  DELETE_OPERATOR,
  DELETE_CALIBRATOR,
  DELETE_SHIPPER,
  UPDATE_OPERATOR,
} from "../../../queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import Pagination from "@mui/material/Pagination";
import styles from "./MyOperators.module.scss";
import { CircularProgress } from "@mui/material";
import { BiCaretDown } from "react-icons/bi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { LabelList } from "recharts";
import { BsSearch } from "react-icons/bs";
import CustomButton from "../../Buttons/CustomButton";

export default function MyOperators({ company_id, type, opesNumber }) {
  // ESTADOS LOCALES ----------------------------------------------

  const access = {
    send: true,
    receive: true,
    checkpoint: true,
    calibrate: true,
    dash_report: true,
    dash_intransit: true,
    dash_control: true,
    recursive: true,
    geolocation: true,
  };
  const [showAllOps, setShowAllOps] = useState(false)
  //estados para paginación
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentElements, setCurrentelements] = useState([]);

  //estado para el edit operator
  const [editOperator, setEditOperator] = useState("");

  //estado para lo que se esta editando
  const [editInfo, setEditInfo] = useState({});

  //estado para guardarme el filtro de status
  const [statusFilter, setStatusFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  //estado y variable para el dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl_type, setAnchorEl_type] = useState(null);
  const [anchorAccess, setAnchorAccess] = useState(null);
  const open = Boolean(anchorEl);
  const open_type = Boolean(anchorEl_type);
  const open_access = Boolean(anchorAccess);

  //operador para updatear
  const [operator_access, setOperator_access] = useState({});
  const [admin, setAdmin] = useState(false);
  const [info2, setInfo2] = useState([]);
  const [access_menu, setAccess_menu] = useState({});

  //operador segun busqueda
  const [search, setSearch] = useState("");
 const [refetch, setRefetch] = useState(false)
  const [
    getCompanyDetail,
    {
      loading: companyDetailLoading,
      error: companyDetailError,
      data: companyDetailData,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL, {
    variables: {
      company_id,
    },

    fetchPolicy: "network-only",
  });

  //guardo los operadores en un estado local info2
  useEffect(() => {
   
    const fetch = async () => {
    const result=  await getCompanyDetail();
      setEditInfo({});  
      setInfo2(result?.data?.company?.operators?.filter((o) => (showAllOps ? o : o.status === "ACTIVE")));
      opesNumber( result?.data?.company?.operators?.filter((o) => (showAllOps ? o : o.status === "ACTIVE"))?.length)
   
    };
    fetch();
   
  }, [/* companyDetailData, *//* showAllOps */refetch]);

  //mutation para updatear company

  //mutation para eliminar operadores
  const [
    deleteOperator,
    {
      // data: deletedOperatorData,
      loading: deletedOperatorLoading,
      error: deletedOperatorError,
    },
  ] = useMutation(DELETE_OPERATOR);

//update operator
const [
  updateOperator,
  {
   
    loading: updateOperatorLoading,
    error: updateOperatorError,
  },
] = useMutation(UPDATE_OPERATOR, {
  refetchQueries: [{ query: GET_COMPANY_DETAIL, variables: company_id }],
});

  //eliminar este codigo!!!!!!!!!!!*******************************
  //mutation para eliminar transportistas
  const [
    deleteShipper,
    {
      // data: deletedShipperData,
      loading: deletedShipperLoading,
      error: deletedShipperError,
    },
  ] = useMutation(DELETE_SHIPPER);

  //mutation para eliminar calibradores
  const [
    deleteCalibrator,
    {
      // data: deletedCalibratorData,
      loading: deletedCalibratorLoading,
      error: deletedCalibratorError,
    },
  ] = useMutation(DELETE_CALIBRATOR);
//eliminar este codigo!!!!!!!!!!!***********************************

  //HANDLERS -----------------------------------------------------------------------
  //handler de busqueda
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  //handler de paginacion
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  let accessObject;
  let true_access = [];

  //handler del edit operator
  const handleEditOperator = (id) => {
    accessObject = currentElements?.filter((e) => e.operator_id === id)[0]
      ?.access_to;
    true_access = Object.keys(accessObject).filter((k) => accessObject[k]);
    setOperator_access(accessObject);

    setEditOperator(id);
    setEditInfo({
      ...editInfo,
      [type + "_id"]: id,
    });
  };

  //handler del cancel edit operator
  const handleCancelEditOperator = () => {
    setEditOperator("");
    setEditInfo({});
  };

  //handler del onchange de los input
  const handleOnChangeEditOperator = (e) => {
    if (
      e.target.value === "Calibrator" ||
      e.target.value === "Shipper" ||
      e.target.value === "Operator"
    ) {
      setAdmin(false);
    }

    setEditInfo({
      ...editInfo,
      access_to: operator_access,
    });
    if (e.target.name === "access_to") {
      setOperator_access({
        ...operator_access,
        [e.target.value]: e.target.checked,
      });
      setEditInfo({
        ...editInfo,
        access_to: {
          ...operator_access,
          [e.target.value]: e.target.checked,
        },
      });
    } else {
      setEditInfo({
        ...editInfo,
        [e.target.name]: e.target.value,
      });
    }
    console.log(e.target.name, e.target.value);
  };

  //handler del delete operatortype
  const handleDeleteOperator = async (id) => {
    setEditInfo({
      company_id,
    });
    try {
      await deleteOperator({
        variables: { operator_id: id },
      });
      await getCompanyDetail();
    } catch (error) {
      console.log(error);
    }
  };

  //handler del save edit operator
  const handleSaveData = async () => {
    setEditOperator("");
    
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        setEditInfo({
          company_id,
        });

        await updateOperator({
          variables: {
            operator: editInfo,
          },
        });
        setAdmin(false);
        setRefetch(!refetch)
        //await getCompanyDetail();
      }
    } catch (error) {
      console.log("error en la mutation de updateOperator", error);
    }
  };

  //handler del dropdown de status
  const handleDropdown = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleDropdown_type = (e) => {
    setAnchorEl_type(e.currentTarget);
  };
  // const handleDropdown_access = (e) => {
  //   setAnchorAccess(e.currentTarget);
  // };
  //handler del close del dropdown
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleCloseDropdown_type = () => {
    setAnchorEl_type(null);
  };
  // const handleCloseDropdown_access = () => {
  //   setAnchorAccess(null);
  // };
  //handler del status filter
  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };
  const handleTypeFilter = (value) => {
    setTypeFilter(value);
  };

  // VARIABLE DE LA INFO -------------------------------------------------

  // ME GUARDO LA INFO EN UNA VARIABLE COPIA -------------------------------------------------------
  /*   if (type === "operator") {
     info = companyDetailData?.company?.operators;
   } else if (type === "shipper") {
     info = companyDetailData?.company?.shippers;
   } else if (type === "calibrator") {
     info = companyDetailData?.company?.calibrators;
   }  */

  //PARA PAGINAR -------------------------------------------------------------------------
  const indexOfLastElement = page * rowsPerPage;
  const indexOfFirstElement = indexOfLastElement - rowsPerPage;

  //manejo del estado de current elements (filtro por type y/o status )---------------------------------------------

  useEffect(() => {
    let elements;

    if (info2) {
      if (typeFilter !== "") {
        elements = info2
          .filter((o) => o?.type === typeFilter)
          .slice(indexOfFirstElement, indexOfLastElement);

        statusFilter !== ""
          ? setCurrentelements(
              elements.filter((o) => o?.status === statusFilter)
            )
          : setCurrentelements(elements);
      } else
        setCurrentelements(
          info2?.slice(indexOfFirstElement, indexOfLastElement)
        );

      if (typeFilter === "" && statusFilter !== "") {
        elements = info2
          .filter((o) => o?.status === statusFilter)
          .slice(indexOfFirstElement, indexOfLastElement);
        setCurrentelements(elements);
      }
      if(search !== ""){
        setCurrentelements(info2.filter((o) => o.name.toLowerCase().includes(search.toLowerCase())).slice(indexOfFirstElement, indexOfLastElement))
        setPage(1)
      }
    }
  }, [
    info2,
    statusFilter,
    typeFilter,
    indexOfLastElement,
    indexOfFirstElement,
    companyDetailData,
    search,
  ]);

  // MANEJO DE ERRORES -----------------------------------------------------------------
  if (companyDetailError)
    console.log("error en la data de la compañia", companyDetailError);
  if (updateOperatorError)
    console.log("error al updatear el operador", updateOperatorError);
  if (deletedOperatorError)
    console.log("error en la eliminacion del operador", deletedOperatorError);
  if (deletedShipperError)
    console.log(
      "error en la eliminacion del transportista",
      deletedShipperError
    );
  if (deletedCalibratorError)
    console.log(
      "error en la eliminacion del calibrador",
      deletedCalibratorError
    );

  //LOADINGS --------------------------------------------------------------------
  if(search !== ""){
  console.log("search", search)//no borrar este console
  }else{
     if (
    info2 === undefined ||
    updateOperatorLoading ||
    deletedOperatorLoading ||
    deletedShipperLoading ||
    deletedCalibratorLoading ||
    companyDetailLoading ||
    currentElements.length === 0 || //===[] estaba asi
    currentElements === undefined
  ) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  } 
  }
 const handleShowAllOps = () => {
   setShowAllOps(!showAllOps)
   setPage(1)
   if(showAllOps){
     setInfo2(companyDetailData?.company?.operators?.filter((o) => (o.status === "ACTIVE")));
     opesNumber( companyDetailData?.company?.operators?.filter((o) => (o.status === "ACTIVE"))?.length)
    }else{
      setInfo2(companyDetailData?.company?.operators?.filter((o) => (o)));
      opesNumber( companyDetailData?.company?.operators?.filter((o) => (o))?.length)
    }
  //  setInfo2(companyDetailData?.company?.operators?.filter((o) => (showAllOps ? o : o.status === "ACTIVE")));
  //  opesNumber( companyDetailData?.company?.operators?.filter((o) => (showAllOps ? o : o.status === "ACTIVE"))?.length)

  }

  return (
    <div className={styles.myOperatorsContainer}>
          <CustomButton
        type={"operatorFilter"}
        action={handleShowAllOps}
        text={
          showAllOps
            ? localStorage.getItem("language") === "en"
              ? "Show active operators"
              : "Mostrar operadores activos"
            : localStorage.getItem("language") === "en"
            ? "Show all operators"
            : "Mostrar todos los operadores"
        }
        togle={showAllOps}
      />
      {info2?.[0] ? (
        <>
          {/* BUSQUEDA POR NOMBRE DE OPEREATOR---------------------------------------------------------------- */}
          <div className={styles.inputContainer}>
            <label className={styles.searchQrLabel}>
              <BsSearch size={window.screen.width > 800 ? "1vw" : "3vw"} />
            </label>
            <input
              type="text"
              onChange={handleSearch}
              className={styles.searchQrInput}
              placeholder={
                localStorage.getItem("language") === "en"
                  ? "OPERATOR NAME:"
                  : "NOMBRE DE OPERADOR:"
              }
            />
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* HEAD ID ------------------------------------------------------------ */}
                  <TableCell
                    size="medium"
                    // align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                      
                      color: "#00ABC8",
  
                      paddingLeft: "1vw",
                      width: "6.25%",
                    }}
                  >
                    ID
                  </TableCell>
                  {/* HEAD NAME -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                      
                      color: "#00ABC8",
  

                      width: "12.5%",
                      // paddingLeft: "3vw",
                    }}
                  >
                    <FormattedMessage id="name_config_company">
                      NAME
                    </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD CODE -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                      
                      color: "#00ABC8",
  
                      // paddingLeft: "3vw",
                      width: "12.5%",
                    }}
                  >
                    <FormattedMessage id="code_may">CODE</FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD EMAIL -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                      
                      color: "#00ABC8",
  
                      // paddingLeft: "3vw",
                      // textAlign: "left",
                      width: "12.5%",
                    }}
                  >
                    <FormattedMessage id="email">MAIL</FormattedMessage>{" "}
                  </TableCell>
                  {/*  HEAD TYPE -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                      
                      color: "#00ABC8",
  
                      // paddingLeft: "3vw",
                      // textAlign: "left",
                      width: "12.5%",
                    }}
                  >
                    <div>
                      <FormattedMessage id="operator_type">
                        TYPE
                      </FormattedMessage>{" "}
                      <button
                        className={styles.dropdownButton}
                        onClick={handleDropdown_type}
                      >
                        <BiCaretDown />
                      </button>
                    </div>
                    <Menu
                      id="demo-positioned-menu"
                      disableScrollLock={true}
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl_type}
                      open={open_type}
                      onClose={handleCloseDropdown_type}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{
                        borderRadius: "2vw",
                        width: window.screen.width > 800 ? "10vw" : "20vw",
                      }}
                    >
                      {typeFilter !== "admin" && (
                        <MenuItem
                          onClick={() => handleTypeFilter("Admin")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            paddingLeft: "5%",
                          }}
                        >
                          <FormattedMessage id="admin">Admin</FormattedMessage>
                        </MenuItem>
                      )}
                      {typeFilter !== "operator" && (
                        <MenuItem
                          onClick={() => handleTypeFilter("Operator")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            paddingLeft: "5%",
                          }}
                        >
                          <FormattedMessage id="operator">
                            Operator
                          </FormattedMessage>
                        </MenuItem>
                      )}

                      {typeFilter !== "shipper" && (
                        <MenuItem
                          onClick={() => handleTypeFilter("Shipper")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            paddingLeft: "5%",
                          }}
                        >
                          <FormattedMessage id="shipper">
                            Shipper
                          </FormattedMessage>
                        </MenuItem>
                      )}

                      {typeFilter !== "calibrator" && (
                        <MenuItem
                          onClick={() => handleTypeFilter("Calibrator")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            paddingLeft: "5%",
                          }}
                        >
                          <FormattedMessage id="calibrator">
                            Calibrator
                          </FormattedMessage>
                        </MenuItem>
                      )}
                      {typeFilter !== "" && (
                        <MenuItem
                          onClick={() => handleTypeFilter("")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                          }}
                        >
                          <FormattedMessage id="all_select">
                            All
                          </FormattedMessage>
                        </MenuItem>
                      )}
                    </Menu>
                  </TableCell>

                  {/* ACCESS_TO --------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                      
                      color: "#00ABC8",
  
                      // paddingLeft: "3vw",
                      // textAlign: "left",
                      width: "12.5%",
                    }}
                  >
                    <FormattedMessage id="access_to">
                      ACCESS TO
                    </FormattedMessage>{" "}
                  </TableCell>

                  {/* HEAD STATUS -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                      
                      color: "#00ABC8",
  

                      width: "12.5%",
                      // paddingLeft: "2vw",
                    }}
                  >
                    <div className={styles.statusContainer}>
                      <FormattedMessage id="status_tab_title">
                        STATUS
                      </FormattedMessage>{" "}
                      {/* <button
                      className={styles.dropdownButton}
                      onClick={handleDropdown}
                    >
                      <BiCaretDown />
                    </button> */}
                    </div>
                    <Menu
                      id="demo-positioned-menu"
                      disableScrollLock={true}
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseDropdown}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{
                        borderRadius: "2vw",
                        width: window.screen.width > 800 ? "10vw" : "20vw",
                      }}
                    >
                      {statusFilter !== "active" && (
                        <MenuItem
                          onClick={() => handleStatusFilter("active")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            paddingLeft: "3vw",
                          }}
                        >
                          <FormattedMessage id="active">
                            Active
                          </FormattedMessage>
                        </MenuItem>
                      )}
                      {statusFilter !== "inactive" && (
                        <MenuItem
                          onClick={() => handleStatusFilter("inactive")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                            paddingLeft: "5%",
                          }}
                        >
                          <FormattedMessage id="inactive">
                            Inactive
                          </FormattedMessage>
                        </MenuItem>
                      )}
                      {statusFilter !== "" && (
                        <MenuItem
                          onClick={() => handleStatusFilter("")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize:
                              window.screen.width > 800 ? "1.1vw" : "1.5vw",
                          }}
                        >
                          <FormattedMessage id="all_select">
                            All
                          </FormattedMessage>
                        </MenuItem>
                      )}
                    </Menu>
                  </TableCell>
                  {/* columna vacia para los botones------------------ */}
                  <TableCell
                    size="medium"
                    /*  align="center" */
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",
                      
                      color: "#00ABC8",
  
                      width: "6.25%",
                    }}
                  ></TableCell>
                  {/* columna vacia para los botones------------------ */}
                </TableRow>
              </TableHead>

              <TableBody>
                {currentElements?.map((o, i) => {
                  Object.keys(o?.access_to).filter((key) => o?.access_to[key]);
                  return (
                    <TableRow key={o.operator_id}>
                      {/* ID ------------------------------------------------------------------------- */}

                      <TableCell
                        /* align="center" */
                        size="small"
                        sx={{
                          border: "none",
                          padding: "0.5%",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(o) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          // 
      
                          color: "#1B1464",
                          paddingLeft: "1vw",
                        }}
                      >
                        {o[type + "_id"].split("-")[1]}
                      </TableCell>
                      {/* NOMBRE ---------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          padding: "0.5%",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(o) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          // 
      
                          color: "#1B1464",
                          // paddingLeft: "3vw",
                        }}
                      >
                        {editOperator === o[type + "_id"] ? (
                          <input
                            type="text"
                            defaultValue={o.name}
                            name="name"
                            onChange={handleOnChangeEditOperator}
                            style={{
                              border: "none",
                              borderBottom: "0.15vw solid grey",
                              width: "7vw",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",
          
                              color: "#1B1464",
                            }}
                          />
                        ) : (
                          o?.name
                        )}
                      </TableCell>
                      {/* CODIGO ---------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          padding: "0.5%",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(o) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          // 
      
                          color: "#1B1464",
                          // paddingLeft: "3vw",
                        }}
                      >
                        {editOperator === o[type + "_id"] ? (
                          <input
                            type="text"
                            defaultValue={o.code}
                            name="code"
                            onChange={handleOnChangeEditOperator}
                            style={{
                              border: "none",
                              borderBottom: "0.15vw solid grey",
                              width: "7vw",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",
          
                              color: "#1B1464",
                            }}
                          />
                        ) : (
                          o?.code
                        )}
                      </TableCell>
                      {/* MAIL ---------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          padding: "0.5%",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(o) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          // 
      
                          color: "#1B1464",
                          // paddingLeft: "3vw",
                        }}
                      >
                        {editOperator === o[type + "_id"] ? (
                          <input
                            type="text"
                            defaultValue={o?.mail}
                            name="mail"
                            onChange={handleOnChangeEditOperator}
                            style={{
                              border: "none",
                              borderBottom: "0.15vw solid grey",
                              width: "12vw",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              fontSize:
                                window.screen.width > 800 ? "1vw" : "1.5vw",
          
                              color: "#1B1464",
                            }}
                          />
                        ) : (
                          o?.mail
                        )}
                      </TableCell>

                      {/* TYPE  ----------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          padding: "0.5%",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(o) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          // 
      
                          color: "#1B1464",
                          // paddingLeft: "3vw",
                        }}
                      >
                        {editOperator === o[type + "_id"] ? (
                          <div className={styles.type}>
                            <span className={styles.eachSection}>
                              <select
                                name="type"
                                defaultValue={o.type}
                                onChange={handleOnChangeEditOperator}
                                className={styles.select}
                              >
                                <option value="Calibrator">
                                  {localStorage.getItem("language") === "en"
                                    ? "Calibrator"
                                    : "Calibrador"}
                                </option>
                                {/*   <option value="Admin">Admin</option> */}
                                <option value="Operator">
                                  {localStorage.getItem("language") === "en"
                                    ? "Operator"
                                    : "Operador"}
                                </option>
                                <option value="Shipper">
                                  {localStorage.getItem("language") === "en"
                                    ? "Shipper"
                                    : "Transportista"}
                                </option>
                              </select>
                            </span>
                          </div>
                        ) : (
                          <div>
                            {o?.type === "Operator" && (
                              <FormattedMessage id="operator">
                                Operator
                              </FormattedMessage>
                            )}
                            {/*  {o?.type === "Admin" && (
                            <FormattedMessage id="admin">
                              Admin
                            </FormattedMessage>
                          )} */}

                            {o?.type === "Calibrator" && (
                              <FormattedMessage id="calibrator">
                                Calibrator
                              </FormattedMessage>
                            )}

                            {o?.type === "Shipper" && (
                              <FormattedMessage id="shipper">
                                Shipper
                              </FormattedMessage>
                            )}
                          </div>
                        )}
                      </TableCell>

                      {/* ACCESS_TO ------------------------------------------------------------------------- */}

                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          padding: "0.5%",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(o) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          // 
      
                          color: "#1B1464",
                          // paddingLeft: "3vw",
                        }}
                      >
                        {editOperator === o[type + "_id"] ? (
                          <span className={styles.access_to_edit}>
                            {/*  chequed true read only-------------------------------------------- */}
                            {admin === true &&
                              Object.keys(access).map((a, i) => {
                                return (
                                  <span key={i}>
                                    <input
                                      type="checkbox"
                                      name="access_to"
                                      value={a}
                                      defaultChecked={true}
                                      onChange={handleOnChangeEditOperator}
                                      disabled
                                    />
                                    {a === "send" &&
                                      (localStorage.getItem("language") === "es"
                                        ? "Enviar"
                                        : "Send")}
                                    {a === "receive" &&
                                      (localStorage.getItem("language") === "es"
                                        ? "Recibir"
                                        : "Receive")}
                                    {a === "checkpoint" && "Checkpoints"}

                                    {a === "calibrate" &&
                                      (localStorage.getItem("language") === "es"
                                        ? "Calibrar"
                                        : "Calibrate")}
                                    {a === "dash_report" &&
                                      (localStorage.getItem("language") === "es"
                                        ? "Reportes"
                                        : "Reports")}
                                    {a === "dash_intransit" &&
                                      (localStorage.getItem("language") === "es"
                                        ? "En tránsito"
                                        : "In transit")}
                                    {a === "dash_control" &&
                                      (localStorage.getItem("language") === "es"
                                        ? "Panel de control"
                                        : "Dashboard")}
                                    {a === "recursive" &&
                                      (localStorage.getItem("language") === "es"
                                        ? "Recursivo"
                                        : "Recursive")}

                                    {a === "geolocation" &&
                                      (localStorage.getItem("language") === "es"
                                        ? "Geolocalización"
                                        : "Geolocation")}
                                  </span>
                                );
                              })}

                            {/*  chequed true by default--------------------- */}
                            {admin === false &&
                              Object.keys(access)
                                .filter((key) => o?.access_to[key])
                                .map((a, i) => {
                                  return (
                                    <span
                                      key={i + 100}
                                      className={
                                        styles.span_checkedTrueByDefault
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        name="access_to"
                                        value={a}
                                        defaultChecked={true}
                                        onChange={handleOnChangeEditOperator}
                                      />
                                      {a === "send" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Enviar"
                                          : "Send")}
                                      {a === "receive" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Recibir"
                                          : "Receive")}
                                      {a === "checkpoint" && "Checkpoints"}
                                      {a === "recursive" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Recursivo"
                                          : "Recursive")}
                                      {a === "calibrate" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Calibrar"
                                          : "Calibrate")}
                                      {a === "dash_report" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Reportes"
                                          : "Reports")}
                                      {a === "dash_intransit" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "En tránsito"
                                          : "In transit")}
                                      {a === "dash_control" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Panel de control"
                                          : "Dashboard")}

                                      {a === "geolocation" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Geolocalización"
                                          : "Geolocation")}
                                    </span>
                                  );
                                })}
                            {/* checked false by default------------------------------- */}
                            {admin === false &&
                              Object.keys(access)
                                .filter((key) => !o?.access_to[key])
                                .map((a, i) => {
                                  return (
                                    <span
                                      key={i + 101}
                                      className={
                                        styles.span_checkedFalseByDefaut
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        name="access_to"
                                        value={a}
                                        onChange={handleOnChangeEditOperator}
                                      />
                                      {a === "send" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Enviar"
                                          : "Send")}
                                      {a === "receive" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Recibir"
                                          : "Receive")}
                                      {a === "checkpoint" && "Checkpoints"}
                                      {a === "recursive" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Recursivo"
                                          : "Recursive")}
                                      {a === "calibrate" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Calibrar"
                                          : "Calibrate")}
                                      {a === "dash_report" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Reportes"
                                          : "Reports")}
                                      {a === "dash_intransit" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "En tránsito"
                                          : "In transit")}
                                      {a === "dash_control" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Panel de Control"
                                          : "Dashboard")}
                                      {a === "geolocation" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Geolocalización"
                                          : "Geolocation")}
                                    </span>
                                  );
                                })}
                          </span>
                        ) : (
                          ///////////  menu desplegable////////

                          // el menu esta hecho con un <select> pero se puede hacer con un <ul> tipo navBar para estilizarlo mas
                          // con <Menu> y <MenuItem> hace lo que se le canta
                          // resolverlo algun dia
                          <div className={styles.access_container}>
                            <span className={styles.span_access_to}>
                              {localStorage.getItem("language") === "es"
                                ? "Ver"
                                : "Show"}
                            </span>
                            <div className={styles.select_container_access_to}>
                              <select
                                className={
                                  currentElements?.indexOf(o) % 2 === 0
                                    ? styles.select_access_to
                                    : styles.select2_access_to
                                }
                              >
                                {Object.keys(o?.access_to)
                                  .filter((key) => o?.access_to[key])
                                  .map((item, i) => (
                                    <option
                                      className={styles.option}
                                      disabled
                                      key={i + 78}
                                    >
                                      {item === "send" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Enviar"
                                          : "Send")}
                                      {item === "receive" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Recibir"
                                          : "Recieve")}
                                      {item === "checkpoint" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Checkpoints"
                                          : "Checkpoints")}
                                      {item === "calibrate" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Calibrar"
                                          : "Calibrate")}
                                      {item === "reports" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Reportes"
                                          : "Reports")}
                                      {item === "recursive" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Recursivo"
                                          : "Recursive")}
                                      {item === "dash_report" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Reportes"
                                          : "Reports")}
                                      {item === "dash_intransit" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "En tránsito"
                                          : "In transit")}
                                      {item === "dash_control" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Panel de control"
                                          : "Dashboard")}
                                      {item === "geolocation" &&
                                        (localStorage.getItem("language") ===
                                        "es"
                                          ? "Geolocalización"
                                          : "Geolocation")}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        )}
                      </TableCell>
                      {/* STATUS ---------------------------------------------------------------------------- */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          padding: "0.5%",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(o) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          // 
      
                          color: "#1B1464",
                          // paddingLeft: "3vw",
                        }}
                      >
                        {editOperator === o[type + "_id"] ? (
                          <select
                            name="status"
                            defaultValue={o?.status}
                            onChange={handleOnChangeEditOperator}
                            className={styles.select}
                          >
                            <option value="ACTIVE">
                              {localStorage.getItem("language") === "en"
                                ? "Active"
                                : "Activo"}
                            </option>
                            <option value="INACTIVE">
                              {localStorage.getItem("language") === "en"
                                ? "Inactive"
                                : "Inactivo"}
                            </option>
                          </select>
                        ) : localStorage.getItem("language") === "en" ? (
                          o?.status.toUpperCase()
                        ) : o?.status === "ACTIVE" ? (
                          "ACTIVO"
                        ) : (
                          "INACTIVO"
                        )}
                      </TableCell>
                      <TableCell
                        /*  align="center" */
                        size="small"
                        sx={{
                          border: "none",
                          padding: "0.5%",
                          fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                          backgroundColor:
                            currentElements?.indexOf(o) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          // 
      
                          color: "#1B1464",
                          /*  paddingLeft: "3vw", */
                        }}
                      >
                        {editOperator !== o[type + "_id"] ? (
                          <div className={styles.buttonsContainer2}>
                            <button
                              onClick={() =>
                                handleEditOperator(o[type + "_id"])
                              }
                              className={styles.editOperatorButton}
                            >
                              <FormattedMessage id="edit_device_button">
                                Edit
                              </FormattedMessage>
                            </button>
                            <br />
                            <button
                              onClick={() =>
                                handleDeleteOperator(o[type + "_id"])
                              }
                              className={styles.deleteOperatorButton}
                            >
                              <FormattedMessage id="delete_device_button">
                                Delete
                              </FormattedMessage>
                            </button>
                          </div>
                        ) : (
                          <div className={styles.buttonsContainer2}>
                            <button
                              onClick={handleSaveData}
                              className={styles.saveButton}
                            >
                              <FormattedMessage id="save_button">
                                Save
                              </FormattedMessage>
                            </button>
                            <br />
                            <button
                              onClick={handleCancelEditOperator}
                              className={styles.cancelButton}
                            >
                              <FormattedMessage id="cancel_button">
                                Cancel
                              </FormattedMessage>
                            </button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <div className={styles.noData}>
          {localStorage.getItem("language") === "en"
            ? `No ${type}s`
            : "No hay operarios de este tipo"}
        </div>
      )}
      {/* PAGINACION ------------------------------------------------------------------------------------- */}
      {currentElements?.length === 0 && (
        <div className={styles.not_found_msj}>
          {" "}
          <FormattedMessage id="not_found">Not found!</FormattedMessage>
        </div>
      )}
      {info2?.length > 10 && search==='' ? (
        <div className={styles.pagination}>
          <Pagination
            count={Math.ceil(info2?.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            size={window.screen.width <= 1000 ? "small" : "large"}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
