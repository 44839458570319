import React, { useState } from "react";
import styles from "./dropdownCalibrations.module.scss";
import { useEffect } from "react";
import { GET_CALIBRATION } from "../../../queries";
import { useLazyQuery } from "@apollo/client";
import CalTempChart from "../charts/CalTempChart";
import CalAccChart from "../charts/CalAccChart";
import { WidthFull } from "@mui/icons-material";
import CalibrationComments from "../calibrationComments/CalibrationComments";
const DropdownCalibrations = ({ calibration_id, expanded, index, gmt }) => {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.chartsContainer}>
        {/* grafico de temperatura e intrusiones */}
        <div style={{ width: "50%"}}>
          {expanded[index] && (
            <CalTempChart
              calibration_id={calibration_id}
              expanded={expanded}
              index={index}
            />
          )}
        </div>
        <div style={{ width: "50%"}}>
          {/* grafico de aceleracion */}
          {expanded[index] && (
            <CalAccChart
              calibration_id={calibration_id}
              expanded={expanded}
              index={index}
            />
          )}
        </div>
      </div>

      {/* <div
        style={{
          height: "40%",
          width: "100%",
          border: "solid red 1px",
          marginTop: "5%",
        }}
      >
      
        {
          expanded[index] && (
          <CalibrationComments 
          calibration_id={calibration_id}
          expanded={expanded}
          index={index}
          />
        )}
       
      </div> */}
    </div>
  );
};

export default DropdownCalibrations;
