import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_DISTRIBUTORS,
  CREATE_COMPANY,
  GET_BARCODES,
} from "../../../queries";
import { temp_alerts, acc_alerts, company_id } from "../../../const";
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import styles from "./CreateCompany.module.scss";
import TimezoneSelect from "react-timezone-select";
import { lightGreen } from "@mui/material/colors";
import CreateOperator from "../CreateOperator/CreateOperator";

const CreateCompnay2 = () => {
  const type = null;
  const [barcodes, setBarcodes] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const barcodesArray = [];
  const [branchToBeAdded, setBranchToBeAdded] = useState({
    owned: false,
  });
  const [operatorsArray, setOperatorsArray] = useState([]);
  const [name, setName] = useState("");
  const [billing, setBilling] = useState("");
  const [location, setLocation] = useState("");
  const [distributor_id, setDistributor_id] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [newCompany, setNewCompany] = useState({
    name: "",
    billing: "",
    location: "",
    branches: [],
    distributor_id: "",
    barcode: [],
    operators: [],
    alert_params: {
      temperature_alerts: [
        {
          name: "AMB",
          max: 0,
          min: 0,
        },
        {
          name: "REF",
          max: 0,
          min: 0,
        },
        {
          name: "CON",
          max: 0,
          min: -500,
        },
      ],
      acceleration_alerts: [
        {
          name: "Blood",
          value: 0,
        },
      ],
    },
  });

  const createOperatorAction = (operatorToCreate) => {
    setOperatorsArray([...operatorsArray, operatorToCreate]);
  };

  //TRAIGO DISTRIBUTORS//
  const {
    data: allDistributorsData,
    error: allDistributorsError,
    // loading: allDistributorsLoading,
  } = useQuery(GET_DISTRIBUTORS);

  const [
    getBarcodes,
    { data: barcodesList, error: barcodesError, loading: barcodesLoading },
  ] = useLazyQuery(GET_BARCODES);
  const [
    createCompany,
    {
      // data: createdCompanyData,
      error: createdCompanyError,
      loading: createdCompanyLoading,
    },
  ] = useMutation(CREATE_COMPANY);

  //TRAIGO BARCODES//

  useEffect(() => {
    let response = {};
    const fetchBarcodes = async () => {
      response = await getBarcodes();
      response?.data?.__type?.enumValues?.forEach((barcode) => {
        barcodesArray.push(Object.values(barcode)[0]);
        setBarcodes([barcodesArray]);
      });
    };
    fetchBarcodes();
    setNewCompany({ ...newCompany, barcode: barcodesArray });
  }, [barcodesList]);
  /* 
  este useefect es para compensar la carga del estate de operadores que
  siempre va uno atras pq los creo desde un componente hijo
  */
  useEffect(() => {
    setNewCompany({ ...newCompany, operators: operatorsArray });
    
  }, [operatorsArray]);

  //HANDLERS-----------------------------------------------
  //
  const handleName = (e) => {
    // setName(e.target.value)
    setNewCompany({ ...newCompany, name: e.target.value });
  };
  const handlebilling = (e) => {
    // setBilling(e.target.value)
    setNewCompany({ ...newCompany, billing: e.target.value });
  };
  const handleLocation = (e) => {
    // setLocation(e.target.value);
    setNewCompany({ ...newCompany, location: e.target.value });
  };
  const handleContactInfo = (e) => {
    // setContactInfo(e.target.value)
    setNewCompany({ ...newCompany, contact_info: e.target.value });
  };

  const handleOnChangeDis = (e) => {
    // setDistributor_id(e.target.value)
    setNewCompany({ ...newCompany, distributor_id: e.target.value });
  };
  const handleChangeBarcodes = (e, index) => {
    if (newCompany?.barcode?.lentgh !== 0) {
      setNewCompany({
        ...newCompany,
        barcode: newCompany.barcode.includes(e.target.value)
          ? newCompany.barcode.filter((b) => b !== e.target.value)
          : [...newCompany.barcode, e.target.value],
      });
    } else {
      setNewCompany({
        ...newCompany,
        barcode: [e.target.value],
      });
    }
  };
  const handleOnChangeBranch = (e) => {
    if (e.target.name === "owned") {
      setBranchToBeAdded({
        ...branchToBeAdded,
        owned: e.target.checked,
      });
    } else {
      setBranchToBeAdded({
        ...branchToBeAdded,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleConfirmAddBranch = (e) => {
    setNewCompany({
      ...newCompany,
      branches: [
        ...newCompany.branches,
        {
          name: branchToBeAdded.name,
          address: branchToBeAdded.address,
          type: branchToBeAdded.type,
          owned: branchToBeAdded.owned,
        },
      ],
    });
  };

  //handler del delete branch
  const handleDeleteBranch = (position) => {
    let stateCopy = newCompany.branches;
    stateCopy.splice(position, 1);
    setNewCompany({
      ...newCompany,
      branches: stateCopy,
    });
  };

  //handler del delete operator
  const handleDeleteOperator = (index) => {
    // modifica el array de operadores q se guarda en la base
    let copy = newCompany?.operators?.filter(
      (el) => newCompany?.operators?.indexOf(el) !== index
    );
    setNewCompany({
      ...newCompany,
      operators: copy,
    });
    //modifico el array de operadores q se muestra en pantalla
    setOperatorsArray(copy);
  };

  //handler para setear las alertas de temperatura y aceleracion de la compañia
  const handleEditDataChangeAlerts = (e) => {
    let type = e.target.name.split("_")[0];
    let index = parseInt(e.target.name.split("_")[1]);

    if (type === "temp") {
      let property = e.target.name.split("_")[2];

      let temp_copy = [...newCompany?.alert_params?.temperature_alerts];
      let oldParam = temp_copy[index];
      let newParam = {
        name: oldParam.name,
        max: property === "max" ? parseInt(e?.target?.value) : oldParam.max,
        min: property === "min" ? parseInt(e?.target?.value) : oldParam.min,
      };
      temp_copy[index] = newParam;
      setNewCompany({
        ...newCompany,
        alert_params: {
          ...newCompany.alert_params,
          temperature_alerts: temp_copy,
        },
      });
    }

    if (type === "acc") {
      let acc_copy = [...newCompany?.alert_params?.acceleration_alerts];
      let oldParam = acc_copy[index];
      let newParam = {
        name: oldParam.name,
        value: parseInt(e?.target?.value),
      };
      acc_copy[index] = newParam;
      setNewCompany({
        ...newCompany,
        alert_params: {
          ...newCompany.alert_params,
          acceleration_alerts: acc_copy,
        },
      });
    }
  };
  const handleOnChangeLanguage = (e) => {
    setNewCompany({ ...newCompany, language: e.target.value });
  };
  //handler para el create company
  const handleCreateCompany = async () => {
    newCompany.gmt = selectedTimezone.value;

    try {
      //descomentar todo esto para crear company!!!!!
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        await createCompany({
          variables: newCompany,
        });
        
        alert(
          localStorage.getItem("language") === "en"
            ? "Company created"
            : "Compañía creada"
        );
        window.location.reload("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handler del cancel
  const handleCancelCreateCompany = () => {
    window.location.reload("/#configuration");
  };

  //MANEJO DE ERROES ------------------------------------------------------------------
  if (allDistributorsError)
    console.log("error al traerme a los distribuidores", allDistributorsError);
  if (createdCompanyError)
    console.error("error al crear la compañia", createdCompanyError);

  // //LOADINGS ----------------------------------------------------------------------------
  if (createdCompanyLoading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>CreateCompnay2</div>
      name
      <input type="text" name="name" onChange={handleName} />
      billing
      <input type="text" name="billing" onChange={handlebilling} />
      location
      <input type="text" name="location" onChange={handleLocation} />
      contact info
      <input type="text" name="contact_info" onChange={handleContactInfo} />
      {/* DISTRIBUTOR-------------------------------------------------- */}
      <div className={styles.eachSection}>
        <label className={styles.infoTitle}>
          <FormattedMessage id="belongs_to_may">BELONGS TO</FormattedMessage>
        </label>
        <select
          defaultValue="default"
          name="distributor_id"
          onChange={handleOnChangeDis}
          className={styles.selects}
        >
          <option value="default" disabled hidden>
            {localStorage.getItem("en")
              ? "Select a distributor"
              : "Seleccione un distribuidor"}
          </option>
          {allDistributorsData?.distributors?.map((d) => {
            return (
              <option value={d.distributor_id} key={d.distributor_id}>
                {`${d.name} (${d.distributor_id})`}
              </option>
            );
          })}
        </select>
      </div>
      {/* BARCODES----------------------------------------------------- */}
      <div className={styles.eachSection}>
        <label className={styles.infoTitle}>
          <FormattedMessage id="barcode_config_company">
            BARCODE/S
          </FormattedMessage>
        </label>
        <br />
        <br />
        {barcodes[0]?.map((b, i) => {
          return (
            <span key={b} className={styles.eachBarcode}>
              <label>{b}</label>
              <input
                type="checkbox"
                name="barcode"
                value={b}
                onChange={(e) => handleChangeBarcodes(e, i)}
                defaultChecked={true}
              />
            </span>
          );
        })}
      </div>
      {/* BRANCHES ----------------------------------------------------------------------------- */}
      <>
        <label className={styles.branchesTitle}>
          <FormattedMessage id="branches_may">BRANCHES</FormattedMessage>
        </label>
        <br />
        <br />
        <div className={styles.branchesContainer}>
          {newCompany?.branches?.[0] &&
            newCompany?.branches?.map((b, i) => {
              return (
                <div className={styles.eachBranch} key={i + 2}>
                  <span className={styles.eachBranchData}>
                    <span>
                      <FormattedMessage id="name_config_may">
                        NAME
                      </FormattedMessage>
                    </span>
                    <span>{b.name}</span>
                  </span>
                  <span className={styles.eachBranchData}>
                    <span>
                      <FormattedMessage id="address_config_may">
                        ADDRESS
                      </FormattedMessage>
                    </span>
                    <span>{b.address}</span>
                  </span>
                  <span className={styles.eachBranchData}>
                    <span>
                      <FormattedMessage id="type_config_may">
                        TYPE
                      </FormattedMessage>
                    </span>
                    <span>{b.type}</span>
                  </span>
                  <span className={styles.eachBranchData}>
                    <span>
                      <FormattedMessage id="owned_may">
                        THE COMPANY OWNS THE BRANCH
                      </FormattedMessage>
                    </span>
                    <span>
                      {b.owned
                        ? localStorage.getItem("language") === "en"
                          ? "Yes"
                          : "Sí"
                        : "No"}
                    </span>
                  </span>
                  <button
                    className={styles.deleteOperatorButton}
                    onClick={() =>
                      handleDeleteBranch(newCompany?.branches?.indexOf(b))
                    }
                  >
                    <FormattedMessage id="delete_device_button">
                      Delete
                    </FormattedMessage>
                  </button>
                </div>
              );
            })}
        </div>
        {/* AGREGAR BRANCH  ------------------------------------------------------------------ */}
        <div className={styles.addBranchContainer}>
          <div className={styles.eachAddBranchData}>
            <span>
              <FormattedMessage id="name_config">Name</FormattedMessage>
            </span>
            <input type="text" name="name" onChange={handleOnChangeBranch} />
          </div>
          <div className={styles.eachAddBranchData}>
            <span>
              <FormattedMessage id="branch_address_config">
                Address
              </FormattedMessage>
            </span>
            <input type="text" name="address" onChange={handleOnChangeBranch} />
          </div>
          <div className={styles.eachAddBranchData}>
            <span>
              <FormattedMessage id="branch_type_config">Type</FormattedMessage>
            </span>
            <input type="text" name="type" onChange={handleOnChangeBranch} />
          </div>
          <div className={styles.checkOwned}>
            <span>
              <FormattedMessage id="owned_config">
                The company owns the branch
              </FormattedMessage>
            </span>
            <input
              type="checkbox"
              value={false}
              name="owned"
              onChange={handleOnChangeBranch}
            />
          </div>
          <button
            onClick={handleConfirmAddBranch}
            className={styles.addBranchButton}
          >
            <FormattedMessage id="save_button">Save</FormattedMessage>
          </button>
        </div>
      </>
      {/* OPERADORES ----------------------------------------------------------------------------- */}
      <div className={styles.eachTypeOfOperator}>
        <label className={styles.infoTitle}>
          <FormattedMessage id="operators_may">OPERATORS</FormattedMessage>
        </label>
        {operatorsArray?.[0] ? (
          operatorsArray?.map((o, i) => {
            return (
              <div className={styles.operatorsDataContainer} key={i + 1000}>
                <span className={styles.eachOperatorDataContainer}>
                  <span>
                    <FormattedMessage id="name_config_may">
                      NAME
                    </FormattedMessage>
                  </span>
                  <span>{o.name}</span>
                </span>
                <span className={styles.eachOperatorDataContainer}>
                  <span>
                    <FormattedMessage id="code_may">CODE</FormattedMessage>
                  </span>
                  <span>{o.code}</span>
                </span>
                <span className={styles.eachOperatorDataContainer}>
                  <span>EMAIL</span>
                  <span>{o.mail}</span>
                </span>

                <button
                  className={styles.deleteOperatorButton}
                  onClick={() =>
                    handleDeleteOperator(operatorsArray.indexOf(o), "operators")
                  }
                >
                  <FormattedMessage id="delete_device_button">
                    Delete
                  </FormattedMessage>
                </button>
              </div>
            );
          })
        ) : (
          <div className={styles.noOperator}>-</div>
        )}
      </div>
      {/* AGREGAR OPERADOR/SHIPPER/CALIBRADOR --------------------------------------------------------------- */}
      <div>
        <CreateOperator
          createOperatorAction={createOperatorAction}
          company_id={company_id}
          type={type}
        />
        <div className={styles.addOperatorButtonsContainer}>
          {/* <button
              onClick={handleConfirmAddOperator}
              className={styles.addBranchButton}
             >
               <FormattedMessage id="save_button">Save</FormattedMessage>
             </button> */}
        </div>
      </div>
      {/* ALERT PARAMS ----------------------------------------------------------------------------- */}
      <div className={styles.eachSection}>
        <label className={styles.infoTitle}>
          <FormattedMessage id="alerts_tab_title">ALERTS</FormattedMessage>
        </label>
        <div className={styles.all_alerts_container}>
          {/* TABLA DE ALERTAS DE TEMPERATURA ------------------------------------------------------------------------------- */}
          <div className={styles.temp_alerts_container}>
            <div className={styles.type_alert_title}>
              <FormattedMessage id="temperature_alerts_config_company">
                TEMPERATURE ALERTS{" "}
              </FormattedMessage>{" "}
            </div>
            <div className={styles.editDataTempContainer}>
              {temp_alerts?.map((t) => {
                return (
                  <div className={styles.editDataTemp} key={t}>
                    <div className={styles.tempTitle}>
                      {t === "AMB" && "RT"}
                      {t === "REF" && (
                        <FormattedMessage id="refrigeration_config">
                          Refrigeration{" "}
                        </FormattedMessage>
                      )}
                      {t === "CON" && (
                        <FormattedMessage id="freezing_config">
                          Freezing{" "}
                        </FormattedMessage>
                      )}
                    </div>
                    <div className={styles.dataTempContainer}>
                      <span className={styles.eachTempDataContainer}>
                        <span className={styles.eachDataTempTitle}>
                          M
                          {localStorage.getItem("language") === "en"
                            ? "a"
                            : "á"}
                          x{" "}
                        </span>
                        <input
                          type="number"
                          defaultValue={0}
                          name={`temp_${temp_alerts?.indexOf(t)}_max`}
                          onChange={handleEditDataChangeAlerts}
                          style={{
                            border: "none",
                            borderBottom: "0.15vw solid grey",
                            width: "2.3vw",
                            textAlign: "left",
                            fontSize: "1vw",
                            
        
                          }}
                        />
                      </span>
                      <span className={styles.eachTempDataContainer}>
                        <span className={styles.eachDataTempTitle}>
                          M
                          {localStorage.getItem("language") === "en"
                            ? "i"
                            : "í"}
                          n{" "}
                        </span>
                        {t !== "CON" ? (
                          <input
                            type="number"
                            defaultValue={0}
                            name={`temp_${temp_alerts?.indexOf(t)}_min`}
                            onChange={handleEditDataChangeAlerts}
                            style={{
                              border: "none",
                              borderBottom: "0.15vw solid grey",
                              width: "2.3vw",
                              textAlign: "left",
                              fontSize: "1vw",
                              
          
                            }}
                          />
                        ) : (
                          <span className={styles.dataNumber}>-500</span>
                        )}
                      </span>
                    </div>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
          {/* ACCELERATION ALERTS ------------------------------------------------------------------------------------------ */}
          <div className={styles.acc_alerts_container}>
            <div className={styles.type_alert_title}>
              <FormattedMessage id="acceleration_alerts_config_company">
                ACCELERATION ALERTS:{" "}
              </FormattedMessage>
            </div>
            <div className={styles.editDataTempContainer}>
              {acc_alerts?.map((a) => {
                return (
                  <span className={styles.editDataTemp} key={a}>
                    <div className={styles.tempTitle}>
                      {localStorage.getItem("language") === "en"
                        ? a
                        : a === "Blood" && "Sangre"}
                    </div>
                    <div className={styles.eachAccDataContainer}>
                      <span className={styles.eachDataTempTitle}>
                        <FormattedMessage id="acceleration_value_config">
                          Value{" "}
                        </FormattedMessage>{" "}
                      </span>
                      <input
                        type="number"
                        defaultValue={0}
                        min={0}
                        name={`acc_${acc_alerts?.indexOf(a)}`}
                        onChange={handleEditDataChangeAlerts}
                        style={{
                          border: "none",
                          borderBottom: "0.15vw solid grey",
                          width: "1.6vw",
                          textAlign: "left",
                          fontSize: "1.1vw",
                          
      
                        }}
                      />
                    </div>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* LENGUAJE ----------------------------------------------------------------------------- */}
      <div className={styles.eachSection}>
        <label className={styles.infoTitle}>
          <FormattedMessage id="language_may">LANGUAGE</FormattedMessage>
        </label>
        <select
          defaultValue="default"
          name="language"
          onChange={handleOnChangeLanguage}
          className={styles.selects}
        >
          <option value="default" disabled hidden>
            {localStorage.getItem("language") === "en"
              ? "Select a language"
              : "Seleccione un idioma"}
          </option>
          <option value="ENGLISH">
            {localStorage.getItem("language") === "en" ? "English" : "Inglés"}
          </option>
          <option value="SPANISH">
            {localStorage.getItem("language") === "en" ? "Spanish" : "Español"}
          </option>
        </select>
      </div>
      {/* HUSO HORARIO ----------------------------------------------------------------------------- */}
      <div className={styles.eachSection}>
        <label className={styles.infoTitle}>TIME ZONE</label>
        <TimezoneSelect
          name="gmt"
          value={selectedTimezone}
          onChange={setSelectedTimezone}
        />
      </div>
      {/* CREAR COMPAÑIA --------------------------------------------------------------------- */}
      <button
        onClick={handleCreateCompany}
        className={styles.createCompanyButton}
      >
        <FormattedMessage id="create_company">Create company</FormattedMessage>
      </button>
      <button
        onClick={handleCancelCreateCompany}
        className={styles.cancelButton}
      >
        <FormattedMessage id="cancel_button">Cancel</FormattedMessage>
      </button>
    </div>
  );
};

export default CreateCompnay2;
