import { useMutation } from "@apollo/client";
import { Modal, Box, Input, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UPDATE_COMPANY, UPDATE_TEMP_ALERT_PARAMS } from "../../../../queries";
import AddAlertModal from "./addAlertModal/AddAlertModal";
import styles from "./customTempAlerts.module.scss";
import { set } from "date-fns";
const CustomTempAlerts = ({ company_id, companyAlerts, tolerance }) => {
  const intl = useIntl();
  const [alertParams, setAlertParams] = useState();
  const [edit, setEdit] = useState(false);
  const [modalAddAlert, setModalAddAlert] = useState(false);
  const [deviceNameError, setDeviceNameError] = useState("");
  const [toleranceState, setToleranceState] = useState({
    time: tolerance?.time,
    temp: tolerance?.temp,
  });

  const [
    updateCompany,
    {
      data: updatedCompanyData,
      loading: updatedCompanyLoading,
      error: updatedCompanyError,
    },
  ] = useMutation(UPDATE_TEMP_ALERT_PARAMS);
  const [
    updateCompanyTolerance,
    {
      data: updatedCompanyToleranceData,
      loading: updatedCompanyToleranceLoading,
      error: updatedCompanyToleranceError,
    },
  ] = useMutation(UPDATE_COMPANY);

  useEffect(() => {
    setAlertParams(companyAlerts);
    console.log(tolerance);
    setToleranceState({
      time: tolerance?.time ? tolerance.time / 60000 : 0, // Convertir milisegundos a minutos
      temp: tolerance?.temp,
    });
  }, [companyAlerts]);

  const handleEditAlerts = (e, index) => {
    const copy = [...alertParams?.temperature_alerts];
    const oldParam = copy[index];
    let field = e.target.name.split("-")[0];

    const newParam = {
      name: field === "name" ? e.target.value : oldParam.name,
      max: field === "max" ? parseInt(e?.target?.value) : oldParam.max,
      min: field === "min" ? parseInt(e?.target?.value) : oldParam.min,
    };

    copy[index] = newParam;
    setAlertParams({
      ...alertParams,
      temperature_alerts: copy,
      acceleration_alerts: null,
    });
  };

  const handleAddAlertModal = () => {
    setModalAddAlert(true);
  };

  const handleModalAddAlertClose = () => {
    setModalAddAlert(false);
  };

  const editParams = () => {
    setEdit(true);
  };
  const cancelEdit = () => {
    setAlertParams(companyAlerts);
    setEdit(false);
  };
  const remove_alert = (e, index, name) => {
    const nuevoArray = [...alertParams.temperature_alerts];
    nuevoArray.splice(index, 1);
    setAlertParams({
      temperature_alerts: nuevoArray,
      acceleration_alerts: null,
    });
  };

  const handleSubmitCustomAlerts = async () => {
    try {
      if (
        window.confirm(
          intl.formatMessage({ id: "are_you_sure_confirmChanges" })
        )
      ) {
        await updateCompany({
          variables: {
            company_id,
            alert_params: alertParams,
          },
        });
        setEdit(false);
        // window.location.reload();
      }
    } catch (e) {
      console.log(e);
      setAlertParams(companyAlerts);

      toast.error(intl.formatMessage({ id: "device_using_temp" }));
    }
  };
  const handleChangeTolerance = (e) => {
    const { name, value } = e.target;
    const floatValue = parseFloat(value); // Convertir el valor a flotante
    if (floatValue < 0) {
      return;
    }

    setToleranceState({ ...toleranceState, [name]: floatValue });
  };

  const handleSubmitTolerance = async () => {
    console.log(toleranceState);

    try {
      // Convertir el tiempo de minutos a milisegundos antes de enviar
      const toleranceToSend = {
        ...toleranceState,
        time: toleranceState.time * 60000,
      };

      if (
        window.confirm(
          intl.formatMessage({ id: "are_you_sure_confirmChanges" })
        )
      ) {
        await updateCompanyTolerance({
          variables: {
            company_id,
            tolerance: toleranceToSend,
          },
        });
      }

      // refetch

      toast.success(
        <FormattedMessage id="tolerance_updated">
          Tolerance updated
        </FormattedMessage>
      );
    } catch (e) {
      console.log(e);
      toast.error(
        <FormattedMessage id="error_updating_tolerance">
          Error updating tolerance
        </FormattedMessage>
      );
    }
  };

  return (
    <div className={styles.temp_alerts_container}>
      <div className={styles.type_alert_title}>
        <FormattedMessage id="temperature_alerts_config_company">
          TEMPERATURE ALERTS{" "}
        </FormattedMessage>{" "}
      </div>
      <div className={styles.editDataTempContainer}>
        {alertParams?.temperature_alerts?.map((alert, index) => {
          return (
            <div className={styles.editDataTemp} key={alert?.name}>
              <div className={styles.tempTitle}>{alert.name}</div>

              <div className={styles.dataTempContainer}>
                <span className={styles.eachTempDataContainer}>
                  <span className={styles.eachDataTempTitle}>Max</span>
                  {edit ? (
                    <input
                      type="number"
                      defaultValue={alert.max}
                      name={`max-${index}`}
                      onChange={(e) => handleEditAlerts(e, index)}
                      style={{
                        border: "none",
                        borderBottom: "0.15vw solid grey",
                        width: "80%",
                        textAlign: "center",
                        fontSize: window.screen.width > 800 ? "1.2vw" : "2vw",

                        color: "#1B1464",
                      }}
                    />
                  ) : (
                    <span className={styles.dataNumber}>{alert.max}</span>
                  )}
                </span>

                <span className={styles.eachTempDataContainer}>
                  <span className={styles.eachDataTempTitle}>Min</span>
                  {edit ? (
                    <input
                      type="number"
                      defaultValue={alert.min}
                      name={`min-${index}`}
                      onChange={(e) => handleEditAlerts(e, index)}
                      style={{
                        border: "none",
                        borderBottom: "0.15vw solid grey",
                        width: "80%",
                        textAlign: "center",
                        fontSize: window.screen.width > 800 ? "1.2vw" : "2vw",

                        color: "#1B1464",
                      }}
                    />
                  ) : (
                    <span className={styles.dataNumber}>{alert.min}</span>
                  )}
                </span>
              </div>
              {edit && (
                <button
                  className={styles.remove_alert_button}
                  onClick={(e) => remove_alert(e, index, alert.name)}
                >
                  {localStorage.getItem("language") === "en"
                    ? "Delete"
                    : "Borrar"}
                </button>
              )}
            </div>
          );
        })}
      </div>

      {!edit ? (
        <div className={styles.buttons_container}>
          <button onClick={editParams} className={styles.editButton}>
            {localStorage.getItem("language") === "en" ? "Edit" : "Editar"}
          </button>
          <button onClick={handleAddAlertModal} className={styles.editButton}>
            {localStorage.getItem("language") === "en" ? "Add" : "Agregar"}
          </button>
        </div>
      ) : (
        <div className={styles.buttons_container}>
          <button
            onClick={handleSubmitCustomAlerts}
            className={styles.saveButton}
          >
            {localStorage.getItem("language") === "en" ? "Save" : "Guardar"}
          </button>
          <button onClick={cancelEdit} className={styles.cancelButton}>
            {localStorage.getItem("language") === "en" ? "Cancel" : "Cancelar"}
          </button>
        </div>
      )}

      <AddAlertModal
        modalAddAlert={modalAddAlert}
        handleModalAddAlertClose={handleModalAddAlertClose}
        companyAlerts={companyAlerts?.temperature_alerts}
        company_id={company_id}
        type="temperature"
      />
      <ToastContainer />
      <div className={styles.toleranceContainer}>
        <div className={styles.toleranceTitle}>
          <FormattedMessage id="tolerance">tolerance</FormattedMessage>
        </div>
        <div className={styles.toleranceInputsContainer}>
          <div className={styles.inputsAndSpanContainer}>
            <span>
              {" "}
              <FormattedMessage id="time">time</FormattedMessage> (min){" "}
            </span>
            <Input
              type="number"
              name="time"
              onChange={handleChangeTolerance}
              value={toleranceState?.time}
              min="0"
              className={styles.toleranceInputs}
            />
          </div>

          <div className={styles.inputsAndSpanContainer}>
            <span>
              <FormattedMessage id="temperature">temperature</FormattedMessage>{" "}
              (°C)
            </span>
            <Input
              type="number"
              name="temp"
              onChange={handleChangeTolerance}
              value={toleranceState?.temp}
              min="0"
              step="0.1"
              className={styles.toleranceInputs}
            />
          </div>
        </div>
        <Button
          className={styles.setTolerance}
          onClick={handleSubmitTolerance}
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: "var(--cyan)",
            fontSize: "var(--h6)",
          }}
        >
          <FormattedMessage id="set_tolerance">SetTolerance</FormattedMessage>
        </Button>
      </div>
    </div>
  );
};

export default CustomTempAlerts;
