import { padding } from "@mui/system";
import React from "react";
import { useContext } from "react";
import { MapContext } from "../../context/map/MapContext";
import styleBtn from "./styleBtnLocation.module.css";

const BtnMyLocation = ({ boundaries }) => {

  const { map, isMapReady } = useContext(MapContext);

  const onClick = () => {

    if (!isMapReady) throw new Error("Mapa no esta listo");
    //buscar como se agrega el padding// x ahora esta sumando y restamdo 0.5 en lat y long
    map?.fitBounds([
      [boundaries.min_coords?.lng , boundaries.min_coords?.lat ],
      [boundaries.max_coords?.lng , boundaries.max_coords?.lat ],
    ],{padding:{top:200,bottom:200,left:200,right:200}});
  };

  return (
    <button className={styleBtn.button} onClick={onClick}>
      {localStorage.getItem("language") === "es" ? "Centrar" : "Center"}
    </button>
  );
};

export default BtnMyLocation;
