import React, { useState, useEffect,useContext } from "react";
import styles from "./inComing2.module.scss";
import { FormattedMessage } from "react-intl";

import Box from "@mui/material/Box";
import {
  GET_SHIPMENTS_PAG,
  GET_COMPANY_DETAIL,
  GET_SHIPMENT_DETAIL,
  SHIPMENTS_CHECKPOINTS_SUBSCRIPTION,
  SHIPMENTS_CREATED_SUBSCRIPTION,
  SHIPMENTS_UPDATED_SUBSCRIPTION,
} from "../../queries";
import {
  useQuery,
  useLazyQuery,
  useSubscription,
  useApolloClient,
} from "@apollo/client";
import { company_id, language } from "../../const";
import Modal from "@mui/material/Modal";
import IncomingShipsContent from "../IncomingShipsContent/IncomingShipsContent";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import QRCode from "react-qr-code";
import {
  closeWebSocketConnection,
  convertirHoraLocal,
  expiredAction,
  getTempColor,
  isTokenExpired,
  logOut,
  parseJwt,
} from "../../helpers.js";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import ViajesEnTransito from "../ViajesEnTransito/ViajesEnTransito.jsx";
import { RiArrowDropDownLine } from "react-icons/ri";
import { withStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { AlertsContext } from "../../context/alerts/AlertsProvider.jsx";
// ESTILOS PREDETERMINADOS ---------------------------------------------------------------------------------
//estilo el modal de los contents de los viajes en transito
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 300,
  bgcolor: "white",
  border: "2px solid #00ABC8",
  borderRadius: 10,
  // fontFamily: "'Quattrocento Sans', sans-serif",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function InComing({ access_parced, level }) {
  if (!parseJwt(localStorage.getItem("token")).SUDO) {
    if (access_parced.dash_intransit === false) logOut();
  }
  const { alerts, setAlerts } = useContext(AlertsContext);
  const navigate = useNavigate();
  const client = useApolloClient();
  
  const handleTokenExpiration = () => {
    if (isTokenExpired()) {
      closeWebSocketConnection();
      navigate("/login");
      return true;
    }
    return false;
  };
 

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: "18px", // Tamaño de fuente deseado
    },
  }))(Tooltip);
  //ESTADOS LOCALES -------------------------------------------------------------------------------------------
  //estados para paginación
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [onSearch, setOnSearch] = useState(false);

  //estado para el modal de los checkpoints
  const [checkpointsModal, setCheckpointsModal] = useState(false);
  //estado para guardarme el filtro de tipo de envio
  const [typeFilter, setTypeFilter] = useState("");
  //estado para la animacion de los checkpoints
  const [showLastCheckpoint, setShowLastCheckpoint] = useState(false);
  //estado para el envio actualizado
  const [updatedShipmentId, setUpdatedShipmentId] = useState(null);
  //estado y variable para el dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // QUERIES/MUTATIONS ----------------------------------------------------------------------------------------
  //query para traerme la data paginada
  const {
    loading: paginatedDataLoading,
    error: paginatedDataError,
    data: paginatedData,
  } = useQuery(GET_SHIPMENTS_PAG, {
    variables: {
      company_id,
      in_transit: true,
      status: "TRANSIT",
      page,
      per_page: rowsPerPage,
      temperature_range: typeFilter,
    },
  });

  //lazy query para actualizar la data
  const [
    lazyGetShipmentsPag,
    {
      loading: lazyPaginatedDataLoading,
      error: lazyPaginatedDataError,
      data: lazyPaginatedData,
    },
  ] = useLazyQuery(GET_SHIPMENTS_PAG, { fetchPolicy: "network-only" });

  //query para traerme las branches de la compañia
  const {
    // loading: branchesLoading,
    error: branchesError,
    data: company_detail,
  } = useQuery(GET_COMPANY_DETAIL, {
    variables: {
      company_id,
    },
  });

  useEffect(() => {
  expiredAction();
    setTolerance(company_detail?.company?.tolerance);
    console.log(paginatedData)
  }, [company_detail]);

  const [tolerance, setTolerance] = useState(0);

  // Estado para almacenar la data modificada por las suscripciones
  const [updatedData, setUpdatedData] = useState(
    paginatedData?.shipments?.selectedItems
  );
  const [filteredData, setFilteredData] = useState(
    paginatedData?.shipments?.selectedItems
  );
  const [activeFilter, setActiveFilter] = useState("");

const activateAlert=(data)=>{
console.log(data)
// const temperature = item?.last_checkpoint?.temperature;
// const minRange = item?.temperature_range?.min;
// const maxRange = item?.temperature_range?.max;

// if (temperature >= minRange && temperature <= maxRange) {
//   return "green"; // Temperatura dentro del rango
// } else if (
//   temperature >= minRange - tolerance.temp &&
//   temperature <= maxRange + tolerance
// ) {
//   return "orange"; // Temperatura dentro de la tolerancia
// } else if (temperature > maxRange + tolerance.temp) {

//   return "red"; // Temperatura por encima del máximo + tolerancia
// } else if (temperature < minRange - tolerance.temp) {
//   return "blue"; // Temperatura por debajo del mínimo - tolerancia
// }
}
  // Suscripción para checkpoints
  const { data: updatedCheckpointsData } = useSubscription(
    SHIPMENTS_CHECKPOINTS_SUBSCRIPTION,
    {
      onData: ({ data }) => {
        expiredAction();

        try {
          const newCheckpoint = data?.data?.shipmentChecked;
          setUpdatedShipmentId(newCheckpoint?.shipment_id);
          setUpdatedData((prevData) => {
            const newData = [...prevData];
            const index = newData.findIndex(
              (shipment) => shipment?.shipment_id === newCheckpoint?.shipment_id
            );

            if (index !== -1) {
              newData[index] = {
                ...newData[index],
                last_checkpoint: newCheckpoint.last_checkpoint,
              };
            }
            setShowLastCheckpoint(true);
            setTimeout(() => {
              setShowLastCheckpoint(false);
            }, 3000); // Cambia el valor 3000 a la duración de tu animación en milisegundos
            setTimeout(() => {
              setUpdatedShipmentId(null);
            }, 3000); // Cambia el valor 3000 a la duración de tu animación en milisegundos
            return newData;
          });

          setFilteredData((prevData) => {
            const newData = [...prevData];
            const index = newData.findIndex(
              (shipment) => shipment?.shipment_id === newCheckpoint?.shipment_id
            );

            if (index !== -1) {
              newData[index] = {
                ...newData[index],
                last_checkpoint: newCheckpoint.last_checkpoint,
              };
            }
            setShowLastCheckpoint(true);
            setTimeout(() => {
              setShowLastCheckpoint(false);
            }, 3000); // Cambia el valor 3000 a la duración de tu animación en milisegundos
            setTimeout(() => {
              setUpdatedShipmentId(null);
            }, 3000); // Cambia el valor 3000 a la duración de tu animación en milisegundos
            return newData;
          });

          //setear alerta para icono
          activateAlert(newCheckpoint)
        
        } catch (error) {
          console.error("Error handling checkpoints subscription:", error);
        }
      },
    }
  );
  // Suscripción para nuevos viajes creados
  const {
    loading: newShipmentLoading,
    error: newShipmentError,
    data: newShipmentData,
  } = useSubscription(SHIPMENTS_CREATED_SUBSCRIPTION, {
    onData: ({ data }) => {
      expiredAction();
      try {
        const newShipment = data?.data?.shipmentAdded;
        setUpdatedData((prevData) => [...prevData, newShipment]);
        setFilteredData((prevData) => [...prevData, newShipment]);
      } catch (error) {
        console.error("Error handling new shipments subscription:", error);
      }
    },
  });

  // Suscripción para viajes actualizados/cerrados
  const {
    loading: updatedShipmentLoading,
    error: updatedShipmentError,
    data: updatedShipmentData,
  } = useSubscription(SHIPMENTS_UPDATED_SUBSCRIPTION, {
    onData: ({ data }) => {
      expiredAction();
      try {
        const dataToRemove = data?.data?.shipmentUpdated;
        //setUpdatedData(dataToRemove)
        setUpdatedData((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex(
            (shipment) => shipment.shipment_id === dataToRemove.shipment_id
          );

          if (index !== -1) {
            newData.splice(index, 1);
          }
          return newData;
        });

        setFilteredData((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex(
            (shipment) => shipment.shipment_id === dataToRemove.shipment_id
          );

          if (index !== -1) {
            newData.splice(index, 1);
          }
          return newData;
        });
      } catch (error) {
        console.error("Error handling updated shipments subscription:", error);
      }
    },
  });
  // USE EFFECT ----------------------------------------------------------------------------------------------------

  // Lazy query para obtener detalles de un envío
  const [
    getShipment,
    {
      loading: contentLoading,
      error: contentError,
      data: contentData,
      refetch: refetchShipment,
    },
  ] = useLazyQuery(GET_SHIPMENT_DETAIL);
  // Actualizar el estado cuando la data paginada cambia
  useEffect(() => {
    setUpdatedData(paginatedData?.shipments?.selectedItems);
    setFilteredData(paginatedData?.shipments?.selectedItems);
  }, [paginatedData]);

  // HANDLERS -----------------------------------------------------------------------------------------
  //handler del modal de checkpoints
  const handleCheckpointsModal = async (shipment_id) => {
    expiredAction();
    const fetchData = async () => {
      try {
        await getShipment({ variables: { shipment_id } });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    setCheckpointsModal(true);
    refetchShipment();
  };

  //handler del close del modal de checkpoints
  const handleCheckpointsModalClose = () => {
    setCheckpointsModal(false);
  };

 

  //handler del dropdown de type
  const handleDropdown = (e) => {
    setAnchorEl(e.currentTarget);
  };


  //handler del type filter
  const handleTypeFilter = (value) => {
    // setTypeFilter(value);
    setActiveFilter(value);
    const withFilter = updatedData?.filter((item) => {
      return item.temperature_range.name === value;
    });
    setFilteredData(withFilter);

    if (value === "") {
      setOnSearch(false);
      setFilteredData(updatedData);
    } else {
      setOnSearch(true);
    }
  };

  // MANEJO DE ERRORES -----------------------------------------------------------------------
  if (lazyPaginatedDataError)
    console.log("hubo un error en la carga de data", lazyPaginatedDataError);
  if (branchesError)
    console.log("hubo un error en la carga de branches", branchesError);
  if (contentError)
    console.log("hubo un error en el contenido de los shipments", contentError);

  //LOADINGS -------------------------------------------------------------------------
  if (paginatedDataLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          left: "-3vw",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const cardStyle = {
    height: "23vh",
    display: "flex",
    flexDirection: "column",
    padding: "5%",
    // border: "solid #00ABC8 2px",
    borderRadius: "10px",
    backgroundColor: "var(--white)",
  };
  // Tarjeta vacía
  const emptyCard = {
    id: null,
  };
  // Combina la tarjeta vacía con los datos existentes
  const dataWithEmptyCard =
    filteredData && filteredData.length > 0
      ? [emptyCard, ...filteredData]
      : [emptyCard];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {filteredData?.length !== 0 ? (
        <>
          <div
            style={{
              display: "flex",
              //border: "solid red 1px",
              width: "35%",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            <div className={styles.dropdown_type_div2} onClick={handleDropdown}>
              {language === "es" ? "Rango térmico" : "Temperature range"}
            </div>
            {/* filter buttons */}
            <div className={styles.filterButtons}>
              {/* <button onClick={closeWebSocketConnection}>disconnect</button> */}
              <Button
                onClick={() => handleTypeFilter("")}
                variant={"" === activeFilter ? "contained" : "outlined"}
              >
                {language === "es" ? "Todos" : "All"}
              </Button>

              {company_detail?.company?.alert_params?.temperature_alerts?.map(
                (t, index) => (
                  <Button
                    key={index + 8.9}
                    onClick={() => handleTypeFilter(t?.name)}
                    variant={
                      t?.name === activeFilter ? "contained" : "outlined"
                    }
                  >
                    {t?.name}
                  </Button>
                )
              )}
            </div>
          </div>

          <Grid
            container
            spacing={2}
            style={{
              // border: "solid red 1px",
              height: "100vh",
              overflowY: "auto",
              padding: "1%",
              marginTop: "2%",
            }}
          >
            {/* <ViajesEnTransito data={filteredData?.length} /> */}

            {dataWithEmptyCard?.map((item) => (
              <Grid key={item.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                {item.id !== null ? (
                  <Card style={cardStyle} key={item.qr}>
                    <div className={styles.cardContent} key={item.qr}>
                      {/* header */}
                      <div className={styles.cardHeader}>
                        <div className={styles.cardTitle}>
                          {language === "es" ? "Envío #" : "Shipment #"}
                          {item.shipment_id.split("-")[1]}
                        </div>
                        <div className={styles.range}>
                          {item?.temperature_range?.name}
                        </div>
                      </div>

                      <div className={styles.cardSubcontainer}>
                        <div className={styles.cardQrContainer}>
                          <QRCode
                            value={item.qr.toString()}
                            style={{
                              height: "30%",
                              position: "relative",
                              top: "-20%",
                            }}
                          />
                          <span className={styles.qrNumber}>{item.qr}</span>
                        </div>

                        <div className={styles.cardInfoContainer}>
                          <span
                            className={
                              item.shipment_id === updatedShipmentId
                                ? styles.checkpoint_animation_temp
                                : styles.temp
                            }
                            style={{ color: getTempColor(item, tolerance) }}
                          >
                       
                            {item?.last_checkpoint?.temperature !== null
                              ? item?.last_checkpoint?.temperature
                              : ""}
                            °C
                          </span>

                          <span
                            className={
                              item.shipment_id === updatedShipmentId
                                ? styles.checkpoint_animation
                                : styles.lastCheck
                            }
                          >
                            <div className={styles.time}>
                              {item?.last_checkpoint?.timestamp
                                ? convertirHoraLocal(
                                    item?.last_checkpoint?.timestamp,
                                    company_detail?.company?.gmt
                                  )
                                : ""}
                            </div>

                            <div className={styles.branch}>
                              {item?.last_checkpoint
                                ? item?.last_checkpoint?.location
                                : ""}
                            </div>
                          </span>

                          <div className={styles.buttonContainer}>
                            <CustomTooltip
                              title={<FormattedMessage id="routemap" />}
                            >
                              <button
                                className={styles.buttonChecksAndcontent}
                                onClick={() =>
                                  handleCheckpointsModal(item.shipment_id)
                                }
                              >
                                <RiArrowDropDownLine size="2vw" />
                              </button>
                            </CustomTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                ) : (
                  //card vacia--------------------------
                  <Card style={cardStyle}>
                    <ViajesEnTransito data={filteredData?.length} />
                  </Card>
                )}
              </Grid>
            ))}
          </Grid>
          {/* MODAL CHECKPOINTS --------------------------------------------------------------------------------------------------- */}
          <Modal open={checkpointsModal} onClose={handleCheckpointsModalClose}>
            <Box
              sx={{
                ...modalStyle,
                height: "40vw",
                width: "30vw",
              }}
            >
              <div className={styles.modalTitleContainer}>
                {language === "es" ? "Mapa de ruta" : "Route map"}
              </div>
              <div
                style={{
                  width: "30vw",
                  height: "60%",
                  overflowY: "auto",
                  color: "#1B1464",
                  borderBottom: "solid grey 1px",
                }}
              >
                {contentLoading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontFamily: "DM Sans, sans-serif",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                <Timeline className={styles.timeline}>
                  {contentData?.shipment?.checkpoints?.map((c, i) => (
                    <TimelineItem key={i}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0" }}
                        align="right"
                        // variant="body2"
                        color="text.primary"
                        variant="h6"
                        fontFamily={"DM Sans, sans-serif"}
                      >
                        <div style={{ color: "#1B1464" }}>
                          {
                            convertirHoraLocal(
                              c?.timestamp,
                              company_detail.company.gmt
                            ).split("  ")[0]
                          }{" "}
                          -{" "}
                          {
                            convertirHoraLocal(
                              c?.timestamp,
                              company_detail.company.gmt
                            ).split("  ")[1]
                          }{" "}
                          <br />
                          <strong>
                            {c?.temperature !== null
                              ? ` ${c?.temperature}°C`
                              : ""}
                          </strong>
                        </div>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          style={{ backgroundColor: i === 0 && "transparent" }}
                        />
                        <TimelineDot
                          style={{
                            backgroundColor:
                              i ===
                              contentData?.shipment?.checkpoints?.length - 1
                                ? "#00ABC8"
                                : "#bdbdbd",
                            //color: i === data?.shipment?.checkpoints?.length - 1 ? "#FFFFFF" : "#000000" // Puedes ajustar el color del texto según tu diseño
                          }}
                        />

                        <TimelineConnector
                          style={{
                            backgroundColor:
                              i ===
                                contentData?.shipment?.checkpoints?.length -
                                  1 && "transparent",
                          }}
                        />
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{ py: "12px", px: 2 }}
                        className={
                          i === 0 ||
                          i === contentData?.shipment?.checkpoints?.length
                            ? "special"
                            : ""
                        }
                      >
                        <Typography
                          variant="h6"
                          component="span"
                          fontFamily={"DM Sans, sans-serif"}
                        >
                          <div style={{ color: "#00AAC8" }}>
                            {c.label
                              ? `${c.label}: ${c?.location}`
                              : c.location}
                          </div>
                        </Typography>

                        <Typography
                          variant="h6"
                          fontFamily={"DM Sans, sans-serif"}
                        >
                          {c?.responsible_name}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </div>
              <div
                className={styles.modalTitleContainer}
                style={{ marginTop: "1%", marginBottom: "-5%" }}
              >
                {language === "es" ? "Contenido" : "Content"}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30%",

                  overflowY: "auto",
                }}
              >
                <IncomingShipsContent
                  shipment_id={contentData?.shipment?.shipment_id}
                />
              </div>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          {/* si no ha envios que coincidan con la busqueda renderizo el menu para realizar otra */}

          {onSearch && (
            <div
              style={{
                display: "flex",
                // border: "solid red 1px",
                width: "35%",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              <div
                className={styles.dropdown_type_div2}
                onClick={handleDropdown}
              >
                {language === "es" ? "Rango térmico" : "Temperature range"}
              </div>
              {/* filter buttons */}
              <div className={styles.filterButtons}>
                <Button
                  onClick={() => handleTypeFilter("")}
                  variant={"" === activeFilter ? "contained" : "outlined"}
                >
                  {language === "es" ? "Todos" : "All"}
                </Button>

                {company_detail?.company?.alert_params?.temperature_alerts?.map(
                  (t) => (
                    <Button
                      key={t?.name}
                      onClick={() => handleTypeFilter(t?.name)}
                      variant={
                        t?.name === activeFilter ? "contained" : "outlined"
                      }
                    >
                      {t?.name}
                    </Button>
                  )
                )}
              </div>
            </div>
          )}

          <Grid
            container
            spacing={2}
            style={{
              // border: "solid red 1px",
              height: "100vh",
              overflowY: "auto",
              padding: "1%",
              marginTop: "2%",
            }}
          >
            <ViajesEnTransito data={filteredData?.length} />
          </Grid>

          <div
            style={{
              width: "100%",
              height: "5vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // fontFamily: "'Quattrocento Sans', sans-serif",
              fontSize: "1.5vw",
              color: "#1B1464",
            }}
          >
            <FormattedMessage id="no_ships_in_transit">
              {" "}
              No shipments In transit{" "}
            </FormattedMessage>
          </div>
        </>
      )}
    </div>
  );
}
