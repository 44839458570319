import React, { useContext, useEffect, useState } from "react";
import ViajesTrazadosGraph from "../ViajesTrazadosGraph/ViajesTrazadosGraph";
import ViajesNoConformesArea from "../ViajesNoConformesArea/ViajesNoConformesArea";
import styles from "./Dashboard.module.scss";
import { useSubscription } from "@apollo/client";
import {
  SHIPMENTS_CREATED_SUBSCRIPTION,
  SHIPMENTS_UPDATED_SUBSCRIPTION,
} from "../../queries";
import axios from "axios";

import { company_id, SERVER_URL } from "../../const";
import { logOut, parseJwt } from "../../helpers.js";

import Reports2 from "../Reports/Reports2.jsx";
import DatePickerFromTo from "./dateFromTo/DatePickerFromTo.jsx";
import { DatesContext } from "../../context/dates/Dates.jsx";
import { Container, Grid, Card } from "@material-ui/core";

const Dashboard2 = ({ access_parced }) => {
  const token = localStorage.getItem("token");
  if (!parseJwt(localStorage.getItem("token")).SUDO) {
    if (access_parced.dash_control === false) logOut();
  }
  //importa datos de context
  const { from, to } = useContext(DatesContext);
  // ESTADOS LOCALES ------------------------------------------------------------------------
  //estados donde me guardo la info traida de la query
  const [inTransitShipsState, setInTransitShipsState] = useState(0);
  const [allData, setAllData] = useState({
    completedShipsState: 0,
    succShipsState: 0,
    uncertShipsState: 0,
    failShipsState: 0,
    branchesWithMoreAlerts: [],
    causes: [],
  });
  const [buttonError, setButtonError] = useState(false);
  //estado para los loading
  const [loading, setLoading] = useState(true);

  //estados y variables para el seteo de fechas y filtros mes/dia
  const date = new Date();
  const firstDayOfMonth = new Date().setDate("01");
  const [initialDay, setInitialDay] = useState(
    new Date(new Date().setHours("00", "00", "00"))
  );

  const [initialMonth, setInitialMonth] = useState(
    new Date(new Date(firstDayOfMonth).setHours("00", "00", "00"))
  );
  const [yesterday, setYesterday] = useState(
    new Date(new Date() - 86400000).toISOString().split("T")[0]
  );

  //TRAIGO LA DATA DESDE EL SERVIDOR SEGUN FILTRO MES/DIA ------------------------------------------------------------------
  async function fetchData(company_id) {
    if (from && to) {
      const result = await axios.get(
        `${SERVER_URL}/statistics/${company_id}/${from}/${to}`,
        { headers: { authorization: `Bearer ${token}` } }
      );

      try {
        let successfulCount = 0;
        let failedCount = 0;
        let transitCount = 0;
        let uncertainCount = 0;
        let branches = result?.data?.branches?.sort(
          (a, b) => b.count - a.count
        );

        result.data.traced.forEach((item) => {
          if (item._id === "SUCCESSFUL") {
            successfulCount = item.count;
          }
          if (item._id === "FAILED") {
            failedCount = item.count;
          }
          if (item._id === "TRANSIT") {
            transitCount = item.count;
          }
          if (item._id === "UNCERTAIN") {
            uncertainCount = item.count;
          }
        });
        setInTransitShipsState(transitCount);

        setAllData({
          ...allData,
          completedShipsState: failedCount + successfulCount + uncertainCount,
          succShipsState: successfulCount,
          uncertShipsState: uncertainCount,
          failShipsState: failedCount,

          branchesWithMoreAlerts: branches.slice(0, 4),

          causes: result?.data?.causes,
        });

        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        console.log(error);
        setLoading(false);
      }
    } else {
      setButtonError(true);
      alert("Por favor seleccione un rango de fechas válido");
    }
  }

  // QUERIES / SUBSCRIPTIONS / MUTATIONS --------------------------------------------------------------------------
  //subscription para viajes iniciados
  const {
    data: createdShipData,
    error: createdShipError,
    // loading: createdShipLoading,
  } = useSubscription(SHIPMENTS_CREATED_SUBSCRIPTION);

  //subscription para viajes cerrados
  const {
    // loading: updatedShipLoading,
    error: updatedShipError,
    data: updatedShipData,
  } = useSubscription(SHIPMENTS_UPDATED_SUBSCRIPTION);

  // CUANDO SE INICIA EL COMPONENTE ---------------------------------------------------------------------------------
  //  TRAER DATA DE LOS ULTIMOS 15 DIAS!!!!!

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      if (company_id) {
        await fetchData(company_id);
      }
      setLoading(false);
    };

    fetchDataAsync();
  }, [company_id, updatedShipData, createdShipData, from, to]);

  //MANEJO DE ERRORES ------------------------------------------------------------------------------------------------
  if (createdShipError)
    console.log(
      "Hubo un error en la subscription de viajes iniciados",
      createdShipError
    );
  if (updatedShipError)
    console.log(
      "Hubo un error en la subscription de viajes cerrados",
      updatedShipError
    );
  //card styles---------------------------------------------------------
  const containerStyle = {
    marginTop: "40px",
    width: "100%",
  };
  const cardStyle = {
    height: "15vw",
    backgroundColor: "#FAFAFA",
    boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
    overFlow: "auto",
  };

  const cardStyleFailed = {
    height: "15vw",
    backgroundColor: "#FAFAFA",
    boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
    overFlow: "auto",
    //border:"solid red 1px",
    //width: "95%",
   // marginLeft: "5%",
  };
  const cardReports = {
    // height: "100%",
    backgroundColor: "#FAFAFA",
    boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
    overFlow: "auto",
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <DatePickerFromTo
        getData={fetchData}
        error={buttonError}
        setError={setButtonError}
      />

      <Container style={containerStyle} maxWidth={false}>
        <Grid container spacing={5}>
          {/* completed */}
          <Grid item xs={5}>
            <Card style={cardStyle}>
              <div className={styles.cardTitle}> Completed</div>
              <div className={styles.cardsContainer2}>
                <ViajesTrazadosGraph
                  data={{
                    completedShips: allData?.completedShipsState,
                    succShips: allData?.succShipsState,
                    uncertShips: allData?.uncertShipsState,
                    failShips: allData?.failShipsState,
                  }}
                  loading={loading}
                  // initialFilter={initialFilter}
                  company_id={company_id}
                  succeeded={allData?.succShipsState}
                  uncertain={allData?.uncertShipsState}
                  failed={allData?.failShipsState}
                />
              </div>
            </Card>
          </Grid>

          {/* no conformes */}
          <Grid item xs={7}>
            <Card style={cardStyleFailed}>
              <div className={styles.cardsContainer}>
                <ViajesNoConformesArea
                  data={{
                    uncertShips: allData?.uncertShipsState,
                    failShips: allData?.failShipsState,
                  }}
                  loading={loading}
                  branchesData={allData?.branchesWithMoreAlerts}
                  company_id={company_id}
                  causes={allData?.causes}
                  allData={allData}
                />
              </div>
            </Card>
          </Grid>
            
            {/* reports */}
          <Grid item xs={12}>
            <Card style={cardReports}>
              <div className={styles.cardTitle}>Reports</div>
              <Reports2 />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Dashboard2;
