import React, { useState } from "react";
import styles from "./UserBar.module.scss";
import { useApolloClient, useQuery, useSubscription } from "@apollo/client";
import {
  GET_USERNAME,
  SHIPMENTS_CREATED_SUBSCRIPTION,
  SHIPMENTS_UPDATED_SUBSCRIPTION,
  SHIPMENTS_CHECKPOINTS_SUBSCRIPTION,
} from "../../queries";
import notificationIcon from "./notification.png";
import { BiChevronDown } from "react-icons/bi";
import { parseJwt, formatNotiDate, convertirHoraLocal, logOut,  closeWebSocketConnection } from "../../helpers";
import logoHtrace from "../../H+Trace_imagotipo_HORI.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import spainFlag from "./sp_flag.png";
import ukFlag from "./uk_flag.png";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../PrivateRoute/PrivateRoute";
// import { BsWindowSidebar } from "react-icons/bs";
// import { FormattedMessage } from "react-intl";

export default function UserBar({ gmt }) {
  const navigate = useNavigate();
  const client = useApolloClient();
  
  const handleTokenExpiration = () => {
    if (isTokenExpired()) {
      closeWebSocketConnection();
      navigate("/login");
      return true;
    }
    return false;
  };
  //ESTADOS LOCALES --------------------------------------------------------------------------------------------
  //estados para los dropdowns
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const open3 = Boolean(anchorEl3);
  const [lastIndexNoti, setLastIndexNoti] = useState(5);
  const [logOutMenu, setLogOutMenu] = useState(false);

  //estado para el lenguaje
  const [language, setLanguage] = useState(localStorage.getItem("language"));

  //estado para numero de notificaciones
  const [noti, setNoti] = useState(
    localStorage.getItem(`numberNoti`) > "0"
      ? parseInt(localStorage.getItem(`numberNoti`))
      : ""
  );
  //USER ID -----------------------------------------------------------------------------------------------
  const user_id = parseJwt(localStorage.getItem("token"))?.user_id;

  //VARIABLES COPIAS DE NOTIFICACIONES ------------------------------------------------------------------------
  var notiString = "";
  var notiStringENG = "";

  //QUERIES/MUTATIONS/SUBSCRIPTIONS ---------------------------------------------------------------------------------
  //subscription a los viajes creados
  const {
    loading: createdShipLoading,
    error: createdShipError,
    data: createdShipData,
  } = useSubscription(SHIPMENTS_CREATED_SUBSCRIPTION, {
    onData: (incomingData) => {
      if (handleTokenExpiration()) logOut();
      // Almacenar el objeto serializado en localStorage
      localStorage.setItem(
        "ship_created",
        JSON.stringify(incomingData?.data?.data?.shipmentAdded)
      );
      const shipmentId = incomingData?.data?.data?.shipmentAdded?.shipment_id;
      const shipmentNumber = shipmentId ? shipmentId.split("-")[1] : null;
      try {
        // Verificar si las propiedades existen antes de acceder a ellas

        if (shipmentNumber) {
          localStorage.setItem(
            "numberNoti",
            (parseInt(localStorage.getItem("numberNoti")) + 1).toString()
          );
          setNoti(localStorage.getItem("numberNoti"));
          const notiString =
            "El envío #" +
            shipmentNumber +
            " se ha inicializado" +
            "@" +
            // formatNotiDate(new Date(), "es") +
            // "\r\n" +
            convertirHoraLocal(
              incomingData?.data?.data?.shipmentAdded?.departure,
              gmt
            );
          localStorage.getItem("notifications");
          const notiStringENG =
            "Shipment #" +
            shipmentNumber +
            " has initialized" +
            "@" +
            // formatNotiDate(new Date(), "en") +
            //"\r\n" +
            convertirHoraLocal(
              incomingData?.data?.data?.shipmentAdded?.departure,
              gmt
            );
          localStorage.getItem("notificationsENG");
          localStorage.setItem("notifications", notiString);
          localStorage.setItem("notificationsENG", notiStringENG);
        }
      } catch (error) {
        console.error("Error en la suscripción:", error);
      }
    },
  });
  //subscription a los viajes cerrados
  const {
    loading: updatedShipLoading,
    error: updatedShipError,
    data: updatedShipData,
  } = useSubscription(SHIPMENTS_UPDATED_SUBSCRIPTION, {
    onData: (subscriptionData) => {
      if (handleTokenExpiration()) logOut();
      localStorage.setItem(
        `numberNoti`,
        (parseInt(localStorage.getItem(`numberNoti`)) + 1).toString()
      );
      setNoti(localStorage.getItem(`numberNoti`));
      notiString =
        "El envío #" +
        subscriptionData?.data?.data?.shipmentUpdated?.shipment_id.split(
          "-"
        )[1] +
        " ha llegado a destino" +
        "@" +
        // formatNotiDate(new Date(), "es") +
        // "\r\n" +
        convertirHoraLocal(
          subscriptionData?.data?.data?.shipmentUpdated?.arrival,
          gmt
        );
      localStorage.getItem(`notifications`);
      notiStringENG =
        "Shipment #" +
        subscriptionData?.data?.data?.shipmentUpdated?.shipment_id.split(
          "-"
        )[1] +
        " has arrived to destination" +
        "@" +
        // formatNotiDate(new Date(), "en") +
        // "\r\n"+

        convertirHoraLocal(
          subscriptionData?.data?.data?.shipmentUpdated?.arrival,
          gmt
        );
      localStorage.getItem(`notificationsENG`);
      localStorage.setItem(`notifications`, notiString);
      localStorage.setItem(`notificationsENG`, notiStringENG);
    },
  });
  //subscription a los checkpoints
  const {
    loading: shipCheckpointsLoading,
    error: shipCheckpointsError,
    data: shipsCheckpointsData,
  } = useSubscription(SHIPMENTS_CHECKPOINTS_SUBSCRIPTION, {
    onData: (subscriptionData) => {
      if (handleTokenExpiration()) logOut();
      let data = subscriptionData?.data?.data?.shipmentChecked?.checkpoints;
      let lastCheckpoint = data[data?.length - 1];

      localStorage.setItem(
        `numberNoti`,
        (parseInt(localStorage.getItem(`numberNoti`)) + 1).toString()
      );
      setNoti(localStorage.getItem(`numberNoti`));
      notiString =
        "El envío #" +
        subscriptionData?.data?.data?.shipmentChecked?.shipment_id.split(
          "-"
        )[1] +
        " ha recibido un checkpoint de " +
        lastCheckpoint?.responsible_name +
        " en " +
        subscriptionData?.data?.data?.shipmentChecked?.checkpoints[
          subscriptionData?.data?.data?.shipmentChecked?.checkpoints?.length - 1
        ]?.location +
        "@" +
        // formatNotiDate(new Date(), "es") +
        // "\r\n" +
        convertirHoraLocal(
          subscriptionData?.data?.data?.shipmentChecked?.last_checkpoint
            ?.timestamp,
          gmt
        );
      localStorage.getItem(`notifications`);
      notiStringENG =
        "Shipment #" +
        subscriptionData?.data?.data?.shipmentChecked?.shipment_id.split(
          "-"
        )[1] +
        " has received a checkpoint from " +
        subscriptionData?.data?.data?.shipmentChecked?.checkpoints[
          subscriptionData?.data?.data?.shipmentChecked?.checkpoints?.length - 1
        ]?.responsible_name +
        " in " +
        subscriptionData?.data?.data?.shipmentChecked?.checkpoints[
          subscriptionData?.data?.data?.shipmentChecked?.checkpoints?.length - 1
        ]?.location +
        "@" +
        convertirHoraLocal(
          subscriptionData?.data?.data?.shipmentChecked?.last_checkpoint
            ?.timestamp,
          gmt
        );
      localStorage.getItem(`notificationsENG`);
      localStorage.setItem(`notifications`, notiString);
      localStorage.setItem(`notificationsENG`, notiStringENG);
    },
  });
  //query para obtener el username
  const {
    loading: usernameLoading,
    error: usernameError,
    data: usernameData,
  } = useQuery(GET_USERNAME, {
    variables: {
      user_id,
    },
  });

  //HANDLERS --------------------------------------------------------------------------------------------------
  //handlers de los dropdowns
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setLogOutMenu(!logOutMenu);
  };

  const handleClick2 = (e) => {
    setAnchorEl2(e.currentTarget);
    localStorage.setItem(`numberNoti`, "0");
  };

  const handleClick3 = (e) => {
    setAnchorEl3(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setLogOutMenu(!logOutMenu);
  };

  //handler logout
  const handleLogout = async () => {
    if (
      window.confirm(
        localStorage.getItem("language") === "es"
          ? "¿Estás seguro que quieres cerrar sesión?"
          : "Are you sure you want to log out?"
      )
    ) {
      // localStorage.setItem("token", "");
      // localStorage.setItem("expiration", "");
      // localStorage.setItem("notifications", "");
      // localStorage.setItem("notificationsENG", "");
      // localStorage.setItem("numberNoti", 0);
      // localStorage.setItem("access", "");
      // localStorage.setItem("assigned_to", null);
      // localStorage.setItem("assigned_to_name", null);
      // localStorage.setItem("tempAlert", false);
      // localStorage.setItem("intrusion", false);
      // localStorage.setItem("mbt", "");
      // localStorage.setItem("dateFilter", null);
      // localStorage.setItem("dateInterval", null);
      // window.location.replace("/");
      logOut();
    }
  };

  //handler profile
  const handleProfile = () => {
    window.location.replace("/my-profile");
  };

  //handler del boton "ver mas" en el dropdown de las notificaciones
  const handleSeeMoreButton = () => {
    // setFirstIndexNoti(firstIndexNoti + 10);
    setLastIndexNoti(lastIndexNoti + 5);
  };

  //handler del cambio de idioma
  const handleLanguage = (language) => {
    localStorage.setItem("language", language);
    setLanguage(language);
    window.location.reload("/");
  };

  //handler del click en el logo
  const handleLogoClick = () => {
    window.location.replace("/");
  };

  return (
    <div className={styles.nav}>
      <img className={styles.logo} src={logoHtrace} alt="" />
      {/* LENGUAJE */}
      <div className={styles.languageDropdown}>
        <button onClick={handleClick3} className={styles.languageButton}>
          {language === "en" && (
            <img src={ukFlag} alt="" className={styles.flagIcon2} />
          )}
          {language === "es" && (
            <img src={spainFlag} alt="" className={styles.flagIcon} />
          )}
          <BiChevronDown size={window.screen.width > 800 ? "1.4vw" : "2.5vw"} />
        </button>
        <Menu
          disableScrollLock={true}
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl3}
          open={open3}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            width: window.screen.width > 800 ? "10%" : "15%",
            height: "10vw",
          }}
        >
          <MenuItem>
            {language === "es" && (
              <img
                src={ukFlag}
                alt=""
                className={styles.flagIcon2}
                onClick={() => handleLanguage("en")}
              />
            )}
            {language === "en" && (
              <img
                src={spainFlag}
                alt=""
                className={styles.flagIcon}
                onClick={() => handleLanguage("es")}
              />
            )}
          </MenuItem>
        </Menu>
      </div>
      <div className={styles.userData}>
        {/* NOTIFICACIONES */}
        <>
          <button className={styles.notification} onClick={handleClick2}>
            <img
              src={notificationIcon}
              alt=""
              className={styles.notificationIcon}
            />
            {parseInt(localStorage.getItem("numberNoti")) > 0 && (
              <span className={styles.notiAlert}>{noti}</span>
            )}
          </button>
          <Menu
            disableScrollLock={true}
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              width: window.screen.width > 800 ? "30vw" : "40vw",
              height: "20vw",
            }}
          >
            {
              // notificaciones en español -----------------------------------------------------------------------------
              localStorage.getItem("language") === "es" &&
                localStorage.getItem("notifications") &&
                localStorage
                  .getItem("notifications")
                  .split("\r\n")
                  .slice(0, lastIndexNoti)
                  .map((n) => {
                    return (
                      <div
                        key={n.split("@")[0] + n.split("@")[1] + Math.random()}
                        style={{ width: "auto" }}
                      >
                        {n && (
                          <MenuItem
                            sx={{
                              color: "#1B1464",

                              fontSize:
                                window.screen.width > 800 ? "1.1vw" : "2vw",
                              borderBottom: "2px solid lightgrey",
                              cursor: "default",
                              whiteSpace: "normal",
                              width: "20vw",
                            }}
                          >
                            <div>
                              <div className={styles.notiText}>
                                {n.split("@")[0]}
                              </div>
                              <div className={styles.notiDate}>
                                {n.split("@")[1]}
                              </div>
                            </div>
                          </MenuItem>
                        )}
                      </div>
                    );
                  })
            }
            {
              //notificaciones en ingles -----------------------------------------------------------------
              localStorage.getItem("language") === "en" &&
                localStorage.getItem("notificationsENG") &&
                localStorage
                  .getItem("notificationsENG")
                  .split("\r\n")
                  .slice(0, lastIndexNoti)
                  .map((n) => {
                    return (
                      <div
                        key={n.split("@")[0] + n.split("@")[1] + Math.random()}
                        style={{ width: "auto" }}
                      >
                        {n && (
                          <MenuItem
                            // key={n}
                            sx={{
                              color: "#1B1464",

                              fontSize:
                                window.screen.width > 800 ? "1.1vw" : "2vw",
                              borderBottom: "2px solid lightgrey",
                              cursor: "default",
                              whiteSpace: "normal",
                              width: "20vw",
                            }}
                          >
                            <div>
                              <div>{n.split("@")[0]}</div>
                              <div className={styles.notiDate}>
                                {n.split("@")[1]}
                              </div>
                            </div>
                          </MenuItem>
                        )}
                      </div>
                    );
                  })
            }
            {!localStorage.getItem("notifications") && (
              <MenuItem
                sx={{
                  color: "#1B1464",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                }}
              >
                {localStorage.getItem("language") === "es"
                  ? "No tienes notificaciones nuevas"
                  : "No new notifications"}
              </MenuItem>
            )}
            {noti > 5 && (
              <MenuItem
                onClick={handleSeeMoreButton}
                sx={{
                  color: "#1B1464",
                  fontFamily: "'Quattrocento Sans', sans-serif",
                  fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                  justifyContent: "center",
                }}
              >
                {localStorage.getItem("language") === "es"
                  ? "Ver más"
                  : "See more"}
              </MenuItem>
            )}
          </Menu>
        </>
        {/* AVATAR  y  Y username CONTAINER */}
        <div className={styles.avatar_nameContainer}>
          <Avatar
            sx={{
              height: window.screen.width > 800 ? "2vw" : "4vw",
              width: window.screen.width > 800 ? "2vw" : "4vw",
              // marginTop: "0.4vw",
              // marginRight: "0.3vw",
            }}
            alt=""
            src=""
          />
      
        </div>

        {/* MENU USUARIO  */}
        <div onClick={handleClick} className={styles.dropdownButton}>
        <div className={styles.username} onClick={handleClick}>
            {user_id && usernameData?.user?.username}
            <BiChevronDown size={window.screen.width > 800 ? "1.4vw" : "2.5vw"} />
          </div>
        

          <Menu
            disableScrollLock={true}
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={logOutMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{ width: window.screen.width > 800 ? "13vw" : "18vw" }}
          >
            {/* MI PERFIL */}
            {/* <MenuItem
              value="profile"
              onClick={handleProfile}
              sx={{
                color: "#1B1464",
                fontFamily: "'Quattrocento Sans', sans-serif",
                fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
              }}
            >
              <FormattedMessage id="my_profile">My profile</FormattedMessage>
            </MenuItem> */}
            {/* LOGOUT */}
            <MenuItem
              value="logout"
              onClick={handleLogout}
              sx={{
                color: "#1B1464",
                fontFamily: "'Quattrocento Sans', sans-serif",
                fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
              }}
            >
              <FormattedMessage id="logout">Logout</FormattedMessage>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
