import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import styles from "./MyBranches.module.scss";
import {
  CREATE_BRANCH,
  DELETE_BRANCH,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_BRANCHES,
  UPDATE_BRANCH,
  UPDATE_COMPANY,
} from "../../../queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { BsSearch } from "react-icons/bs";
import CustomButton from "../../Buttons/CustomButton";
import { set } from "date-fns";
import {
  closeWebSocketConnection,
  isTokenExpired,
  logOut,
} from "../../../helpers";

export default function MyBranches({ company_id, branchesNumber }) {
  //QUERIES/MUTATIONS ----------------------------------------------------------------------------------
  //query para traerme los datos de la compañia
  const [
    getCompanyInfo,
    {
      // loading: companyDetailLoading,
      error: companyDetailError,
      data: companyDetailData,
    },
  ] = useLazyQuery(GET_COMPANY_DETAIL_BRANCHES, { fetchPolicy: "no-cache" });

  //mutation para crear branch
  const [
    createBranch,
    {
      data: branchData,
      loading: createBranchLoading,
      error: createBranchError,
    },
  ] = useMutation(CREATE_BRANCH, {
    refetchQueries: [
      { query: GET_COMPANY_DETAIL_BRANCHES, variables: { company_id } },
    ],
  });
  //mutation para updatear branch
  const [
    updateBranch,
    {
      data: updatedBranchData,
      loading: updateBranchLoading,
      error: updateBranchError,
    },
  ] = useMutation(UPDATE_BRANCH, {
    refetchQueries: [
      { query: GET_COMPANY_DETAIL_BRANCHES, variables: { company_id } },
    ],
  });
  //mutation para eliminar branch
  const [
    deleteBranch,
    {
      data: deleteBranchData,
      loading: deleteBranchLoading,
      error: deleteBranchError,
    },
  ] = useMutation(DELETE_BRANCH, {
    refetchQueries: [
      { query: GET_COMPANY_DETAIL_BRANCHES, variables: { company_id } },
    ],
  });
  //ESTADOS LOCALES --------------------------------------------------------------
  //estado para el modal de agregar branch
  const [modalAddBranch, setModalAddBranch] = useState(false);
  const [showAllBranches, setShowAllBranches] = useState(false);
  //estado para el edit branch
  const [editBranches, setEditBranches] = useState(false);
  const [editBranch, setEditBranch] = useState({});
  const [refetch, setRefetch] = useState(false);
  //estado que guarda los input del edit branch
  const [branchesDataState, setBranchesDataState] = useState(
    companyDetailData?.company?.branches?.filter((b) =>
      showAllBranches ? b : b.status === "ACTIVE"
    )
  );

  //estado que guarda los input del add branch
  const [addBranchState, setAddBranchState] = useState({
    name: "",
    address: "",
    type: "",
    owned: false,
  });

  //estados para paginación
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //estado para el input de busqueda por name
  const [searchInput, setSearchInput] = useState("");

  let info = [];
  let indexOfLastElement;
  let indexOfFirstElement;
  let currentElements;
  //CUANDO SE INICIA EL COMPONENTE -----------------------------------------------------------------
  useEffect(() => {

    async function fetchData() {
      try {
        const result = await getCompanyInfo({
          variables: {
            company_id,
          },
        });
        // console.log(companyDetailData?.company?.branches);
        //muestro las branches activas
        setBranchesDataState(
          result?.data?.company?.branches?.filter((b) =>
            showAllBranches ? b : b?.status === "ACTIVE"
          )
        );

   
        branchesNumber(
          result?.data?.company?.branches?.filter((b) =>
            showAllBranches ? b : b?.status === "ACTIVE"
          )?.length
        );
      } catch (error) {
        console.log("error en el useeffect de mybranches", error);
      }
    }
    fetchData();

    // eslint-disable-next-line
  }, [
    //  companyDetailData, branchData,
    updatedBranchData,
    updateBranchLoading,
    showAllBranches,
    refetch,
  ]);

  //CUANDO BUSCO EN LA BARRA DE BUSQUEDA QUE ME LLEVE A LA PAG 1
  useEffect(() => {
    setPage(1);
  }, [searchInput]);

  //HANDLERS DEL EDIT BRANCHES -------------------------------------------------------------------------------
  const handleEditBranches = () => {
    setEditBranches(true);
  };
  const handleEditBranch = (e, b) => {
    setEditBranch(b);
  };

  const handleEditBranchesChange = (e, b) => {
    e.preventDefault();
    setEditBranch((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSaveBranchesData = async (e) => {
    e.preventDefault();
    setEditBranches(false);
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        // Crear una copia del objeto editBranch sin el campo "created y last_modified"
        const { created, last_modified, ...branchToUpdate } = editBranch;

        await updateBranch({
          variables: {
            branch: branchToUpdate,
          },
        });

        setEditBranch({});
        setEditBranches(false);
        setRefetch(!refetch);
        setPage(1);
        setShowAllBranches(false);
        alert(
          localStorage.getItem("language") === "es"
            ? "Sucursal actualizada"
            : "Branch updated"
        );
        // window.location.reload();
      } else {
        setBranchesDataState(companyDetailData?.company?.branches);
      }
    } catch (error) {
      console.log("error en la mutation de updateBranches", error);
    }
  };

  //handler del cancel edit branches
  const handleCancelEditBranches = () => {
    setEditBranches(false);
    setEditBranch({});
    setBranchesDataState(
      companyDetailData?.company?.branches?.filter((b) =>
        showAllBranches ? b : b.status === "ACTIVE"
      )
    );
  };

  // HANDLERS DEL ADD BRANCH -------------------------------------------------------------------------------

  //handlers del modal de add branch
  const handleAddBranchModal = () => {
    setModalAddBranch(true);
  };

  const handleModalAddBranchClose = () => {
    setModalAddBranch(false);
    setAddBranchState({
      name: "",
      address: "",
      type: "",
      owned: false,
    });
  };

  //handler de los input del add branch
  const handleAddBranchChange = (e) => {
    // e.preventDefault();

    if (e.target.name === "owned") {
      setAddBranchState({
        ...addBranchState,
        owned: e.target.checked,
      });
    } else {
      setAddBranchState({
        ...addBranchState,
        [e.target.name]: e.target.value,
      });
    }
  };

  //handler del add branch
  const handleAddBranch = async (e) => {
    e.preventDefault();

    try {
      console.log("creado branch", addBranchState);
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        await createBranch({
          variables: {
            company_id,
            branch: addBranchState,
          },
        });
        setAddBranchState({});
        setModalAddBranch(false);
        alert("Branch created");
        setRefetch(!refetch);
      }
    } catch (error) {
      console.log("error al agregar sucursal", error);
    }
  };
  //delete Branch
  const handleDeleteBranch = async (e, b) => {
    console.log(b.branch_id);
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres eliminar la sucursal?"
            : "Are you sure you want to delete the branch?"
        )
      ) {
        await deleteBranch({
          variables: {
            branch_id: b.branch_id,
          },
        });
        alert(
          localStorage.getItem("language") === "es"
            ? "La sucursal pasó a estado inactivo"
            : "The branch is now inactive"
        );
        setPage(1);
        setRefetch(!refetch);
        setShowAllBranches(false);
      }
    } catch (error) {
      console.log("error al eliminar branch", error);
    }
  };
  //handler del input de busqueda por name
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  //HANDLER DE PAGINACION --------------------------------------------------------------
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const showAllBranchesToggle = () => {
    setShowAllBranches(!showAllBranches);
    setPage(1);
  };

  // ME GUARDO LA INFO EN UNA VARIABLE COPIA -------------------------------------------------------
  info = companyDetailData?.company?.branches?.filter((b) =>
    showAllBranches ? b : b.status === "ACTIVE"
  );

  // PARA LA BUSQUEDA POR NOMBRE -----------------------------------------------------
  if (searchInput !== "") {
    let infoCopy = info;
    info = infoCopy?.filter((b) =>
      b?.name?.toLowerCase().includes(searchInput.toLowerCase())
    );
  }

  //PARA PAGINAR -------------------------------------------------------------------------
  indexOfLastElement = page * rowsPerPage;
  indexOfFirstElement = indexOfLastElement - rowsPerPage;
  currentElements = info?.slice(indexOfFirstElement, indexOfLastElement);

  //LOADINGS ---------------------------------------------------------------------------
  if (createBranchLoading || updateBranchLoading || deleteBranchLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: "18px", // Tamaño de fuente deseado
    },
  }))(Tooltip);
  return (
    <div className={styles.branchContainer}>
      {/* BUSQUEDA POR NOMBRE --------------------------------------------------------------- */}
      <CustomButton
        type={"branchFilter"}
        action={showAllBranchesToggle}
        text={
          showAllBranches
            ? localStorage.getItem("language") === "en"
              ? "Show active branches"
              : "Mostrar sucursales activas"
            : localStorage.getItem("language") === "en"
            ? "Show all branches"
            : "Mostrar todas las sucursales"
        }
        togle={showAllBranches}
      />

      <div className={styles.inputContainer}>
        <label className={styles.searchQrLabel}>
          <BsSearch size={window.screen.width > 800 ? "1vw" : "3vw"} />
        </label>

        <input
          type="text"
          onChange={handleSearchInput}
          className={styles.searchQrInput}
          placeholder={
            localStorage.getItem("language") === "en" ? "NAME:" : "NOMBRE:"
          }
        />
      </div>
      {/* TABLA --------------------------------------------------------------- */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                size="medium"
                align="left"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                  color: "#00ABC8",
                  //fontFamily: "'Quattrocento Sans', sans-serif",
                  width: "20%",
                }}
              >
                <FormattedMessage id="name_config_may">NAME </FormattedMessage>{" "}
              </TableCell>
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                  color: "#00ABC8",
                  // fontFamily: "'Quattrocento Sans', sans-serif",
                  width: "35%",
                }}
              >
                <FormattedMessage id="address_config_may">
                  ADDRESS{" "}
                </FormattedMessage>{" "}
              </TableCell>
              <TableCell
                size="medium"
                align="center"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                  color: "#00ABC8",
                  // fontFamily: "'Quattrocento Sans', sans-serif",
                  paddingRight: "6%",
                  width: "20%",
                }}
              >
                <FormattedMessage id="type_config_may">TYPE </FormattedMessage>{" "}
              </TableCell>

              <TableCell
                size="medium"
                align="right"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                  color: "#00ABC8",
                  // fontFamily: "'Quattrocento Sans', sans-serif",
                  width: "20%",
                }}
              >
                <FormattedMessage id="status_brach">STATUS </FormattedMessage>{" "}
              </TableCell>
              {/* button edit/delete */}
              <TableCell
                size="medium"
                align="right"
                sx={{
                  fontSize: window.screen.width > 800 ? "1.1vw" : "1.5vw",

                  color: "#00ABC8",
                  // fontFamily: "'Quattrocento Sans', sans-serif",
                  width: "5%",
                }}
              >
                {" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentElements
              ?.filter((b) => (showAllBranches ? b : b.status === "ACTIVE"))
              .map((b, index) => {
                return (
                  <TableRow key={b?.branch_id}>
                    {/* NAME  ----------------------------------------------------------------------------------*/}
                    <TableCell
                      size="small"
                      sx={{
                        border: "none",
                        padding: "0.5%",
                        fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                        backgroundColor:
                          currentElements?.indexOf(b) % 2 === 0
                            ? "#FAFAFA"
                            : "#D9F1F5",

                        // fontFamily: "'Quattrocento Sans', sans-serif",
                        color: "#1B1464",
                      }}
                    >
                      {editBranch?.branch_id === b.branch_id ? (
                        <input
                          type="text"
                          defaultValue={b.name}
                          onChange={(e) => handleEditBranchesChange(e, b)}
                          // name={`name_${branchesDataState?.indexOf(b)}`}
                          name="name"
                          style={{
                            border: "none",
                            borderBottom: "0.15vw solid grey",

                            textAlign: "center",
                            backgroundColor: "transparent",
                            fontSize:
                              window.screen.width > 800 ? "1vw" : "1.5vw",
                            // fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "100%",
                          }}
                        />
                      ) : (
                        <span className={styles.branches_data}>{b.name}</span>
                      )}
                    </TableCell>
                    {/* ADDRESS  -------------------------------------------------------------------------------------------*/}
                    <TableCell
                      align="center"
                      size="small"
                      sx={{
                        border: "none",
                        padding: "0.5%",
                        fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                        backgroundColor:
                          currentElements?.indexOf(b) % 2 === 0
                            ? "#FAFAFA"
                            : "#D9F1F5",

                        // fontFamily: "'Quattrocento Sans', sans-serif",
                        color: "#1B1464",
                      }}
                    >
                      {editBranch?.branch_id === b.branch_id ? (
                        <input
                          type="text"
                          defaultValue={b.address}
                          onChange={handleEditBranchesChange}
                          // name={`address_${branchesDataState?.indexOf(b)}`}
                          name="address"
                          style={{
                            border: "none",
                            borderBottom: "0.15vw solid grey",

                            textAlign: "center",
                            backgroundColor: "transparent",
                            fontSize:
                              window.screen.width > 800 ? "1vw" : "1.5vw",
                            //fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "100%",
                          }}
                        />
                      ) : (
                        <div className={styles.branches_data}>
                          {b?.address?.length > 25 ? (
                            <>
                              <CustomTooltip
                                title={b?.address}
                                PopperProps={{
                                  style: { fontSize: "1.5vw" },
                                }}
                              >
                                <span
                                  id={`addressTooltip${index}`}
                                  className={styles.address}
                                  style={{ cursor: "pointer" }}
                                >
                                  {b?.address.slice(0, 25) + "..."}
                                </span>
                              </CustomTooltip>
                            </>
                          ) : (
                            <div className={styles.branches_data}>
                              {b?.address}
                            </div>
                          )}
                        </div>
                      )}
                    </TableCell>
                    {/* TYPE ---------------------------------------------------------------------------------------------*/}
                    <TableCell
                      /* align="center" */
                      size="small"
                      align="center"
                      sx={{
                        border: "none",
                        padding: "0.5%",
                        fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                        backgroundColor:
                          currentElements?.indexOf(b) % 2 === 0
                            ? "#FAFAFA"
                            : "#D9F1F5",

                        //fontFamily: "'Quattrocento Sans', sans-serif",
                        color: "#1B1464",
                        /*  textAlign: "right",  */
                        paddingRight: "6%",
                      }}
                    >
                      {editBranch?.branch_id === b.branch_id ? (
                        <input
                          type="text"
                          defaultValue={b.type}
                          onChange={handleEditBranchesChange}
                          // name={`type_${branchesDataState?.indexOf(b)}`}
                          name="type"
                          style={{
                            border: "none",
                            borderBottom: "0.15vw solid grey",
                            // width: "auto",
                            textAlign: "center",
                            backgroundColor: "transparent",
                            fontSize:
                              window.screen.width > 800 ? "1vw" : "1.5vw",
                            //fontFamily: "'Quattrocento Sans', sans-serif",
                            color: "#1B1464",
                            width: "100%",
                          }}
                        />
                      ) : (
                        <span className={styles.branches_data}>{b.type}</span>
                      )}
                    </TableCell>
                    {/* STATUS */}
                    <TableCell
                      align="right"
                      size="small"
                      sx={{
                        border: "none",
                        padding: "0.5%",
                        fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                        backgroundColor:
                          currentElements?.indexOf(b) % 2 === 0
                            ? "#FAFAFA"
                            : "#D9F1F5",
                        //
                        //fontFamily: "'Quattrocento Sans', sans-serif",
                        color: "#1B1464",
                        // paddingLeft: "3vw",
                      }}
                    >
                      {editBranch?.branch_id === b.branch_id ? (
                        <select
                          name="status"
                          // name={`status_${branchesDataState?.indexOf(b)}`}
                          defaultValue={b?.status}
                          onChange={handleEditBranchesChange}
                          className={styles.select}
                        >
                          <option value="ACTIVE">
                            {localStorage.getItem("language") === "en"
                              ? "Active"
                              : "Activo"}
                          </option>
                          <option value="INACTIVE">
                            {localStorage.getItem("language") === "en"
                              ? "Inactive"
                              : "Inactivo"}
                          </option>
                        </select>
                      ) : localStorage.getItem("language") === "en" ? (
                        b?.status.toUpperCase()
                      ) : b?.status === "ACTIVE" ? (
                        "ACTIVO"
                      ) : (
                        "INACTIVO"
                      )}
                    </TableCell>
                    {/* edit button */}
                    <TableCell
                      size="small"
                      sx={{
                        border: "none",
                        padding: "0.5%",
                        fontSize: window.screen.width > 800 ? "1vw" : "1.5vw",
                        backgroundColor:
                          currentElements?.indexOf(b) % 2 === 0
                            ? "#FAFAFA"
                            : "#D9F1F5",

                        // fontFamily: "'Quattrocento Sans', sans-serif",
                        color: "#1B1464",
                      }}
                    >
                      {!editBranches ? (
                        <button
                          className={styles.deleteButton}
                          onClick={(e) => handleDeleteBranch(e, b)}
                        >
                          <FormattedMessage id="delete">
                            delete
                          </FormattedMessage>
                        </button>
                      ) : (
                        <button
                          className={styles.editBranch}
                          onClick={(e) => handleEditBranch(e, b)}
                        >
                          <FormattedMessage id="edit">Edit</FormattedMessage>
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {branchesDataState?.length > 10 ? (
        <div className={styles.pagination}>
          <Pagination
            count={Math.ceil(info?.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            size={window.screen.width <= 1000 ? "small" : "large"}
          />
        </div>
      ) : (
        ""
      )}
      <br />
      <div className={styles.branchesButtonContainer}>
        {!editBranches ? (
          <>
            <button
              onClick={handleEditBranches}
              className={styles.editBranchButton}
            >
              <FormattedMessage id="edit_branches_button">
                Edit branches
              </FormattedMessage>
            </button>
            {/* MIS BRANCHES => AGREGAR BRANCH -------------------------------------------------------------------------------- */}
            <button
              onClick={handleAddBranchModal}
              className={styles.editBranchButton}
            >
              <FormattedMessage id="add_branch_button">
                Add branch
              </FormattedMessage>
            </button>
            <Modal open={modalAddBranch} onClose={handleModalAddBranchClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "white",
                  border: "0.15vw solid #00ABC8",
                  borderRadius: "2vw",
                  // fontFamily: "'Quattrocento Sans', sans-serif",
                  boxShadow: 10,
                  pt: 2,
                  px: 4,
                  pb: 3,
                  backgroundColor: "white",
                  width: "30vw",
                  height: "20vw",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h2 className={styles.addBranchMainTitle}>
                  <FormattedMessage id="add_branch_button">
                    Add branch
                  </FormattedMessage>
                </h2>
                <form className={styles.addBranchContainer}>
                  <div className={styles.addBranchSection}>
                    <span className={styles.addBranchTitle}>
                      <FormattedMessage id="name_config">
                        Name{" "}
                      </FormattedMessage>{" "}
                    </span>
                    <input
                      type="text"
                      onChange={handleAddBranchChange}
                      name="name"
                      className={styles.addBranchData}
                    />
                  </div>
                  <div className={styles.addBranchSection}>
                    <span className={styles.addBranchTitle}>
                      <FormattedMessage id="branch_address_config">
                        Address:{" "}
                      </FormattedMessage>{" "}
                    </span>
                    <input
                      type="text"
                      onChange={handleAddBranchChange}
                      name="address"
                      className={styles.addBranchData}
                    />
                  </div>
                  <div className={styles.addBranchSection}>
                    <span className={styles.addBranchTitle}>
                      <FormattedMessage id="branch_type_config">
                        Type:{" "}
                      </FormattedMessage>{" "}
                    </span>
                    <input
                      type="text"
                      onChange={handleAddBranchChange}
                      name="type"
                      className={styles.addBranchData}
                    />
                  </div>
                  <div className={styles.addBranchSection}>
                    <span className={styles.addBranchText}>
                      <FormattedMessage id="owned_config">
                        The company owns the branch
                      </FormattedMessage>{" "}
                      <input
                        type="checkbox"
                        value={true}
                        name="owned"
                        onChange={handleAddBranchChange}
                        defaultChecked={false}
                        className={styles.addBranchCheckbox}
                      />
                    </span>
                  </div>
                  <button
                    type="submit"
                    onClick={handleAddBranch}
                    className={styles.addBranchButton}
                    disabled={
                      !addBranchState.name ||
                      !addBranchState.address ||
                      !addBranchState.type
                        ? true
                        : false
                    }
                  >
                    <FormattedMessage id="add_branch_button">
                      Add branch
                    </FormattedMessage>
                  </button>
                </form>
              </Box>
            </Modal>
          </>
        ) : (
          <div className={styles.branchesButtonContainer}>
            <button
              onClick={handleSaveBranchesData}
              className={styles.editBranchButton}
            >
              <FormattedMessage id="save_button">Save</FormattedMessage>
            </button>
            <button
              onClick={handleCancelEditBranches}
              className={styles.cancelButton}
            >
              <FormattedMessage id="cancel_button">Cancel</FormattedMessage>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
