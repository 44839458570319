import React from 'react';


export default function MyProfile () {


    const handleBackToDashboardButton = () => {
        window.location.replace("/");
    }

    return (
        <div>
            Info sobre mi perfil
            <button onClick={handleBackToDashboardButton}>
                Volver al dashboard
            </button>
        </div>
    )
}