import { CircularProgress } from "@mui/material";
import React, { useContext, useRef, useLayoutEffect, useEffect } from "react";
import { PlacesContext } from "../../context/places/PlacesContext";
import styles from "./gps.module.css";
import { Map } from "mapbox-gl";
import { MapContext } from "../../context/map/MapContext";
import BtnMyLocation from "./BtnMyLocation";
import RcWayTable from "./rc_wayTable/RcWayTable";
import { useState } from "react";
import { calcBoundaries } from "../../helpers";

const MapView = () => {
  const { isLoading, devices } = useContext(PlacesContext);
  const [time, setTime] = useState(false);
  const [noData, setNoData] = useState(true);
  const { setMap } = useContext(MapContext);
  let boundaries = {};
  const devs = [];

  devices.forEach((dev) => {
    if (dev.active == true) {
      if (dev.geolocation != null) {
        devs.push({
          id: dev.geo_id,
          lng: dev.geolocation.lng,
          lat: dev.geolocation.lat,
        });
      }
    }
  });

  boundaries = calcBoundaries(devs);

  const divMap = useRef(null);

  useLayoutEffect(() => {
    setInterval(() => {
      setTime(true);
    }, 2000);
    //map settings
    if (!isLoading) {
      const map = new Map({
        container: divMap.current, // container ID
        style: "mapbox://styles/mapbox/streets-v10?optimize=true", // style URL
      });

      setMap(map);
      //si hay dispositivos centro el mapa segun cantidad de dispositivos. ver que pasa si tengo solo uno!!

      if (devs.length >= 2) {
        map?.fitBounds(
          [
            [boundaries.min_coords?.lng, boundaries.min_coords?.lat],
            [boundaries.max_coords?.lng, boundaries.max_coords?.lat],
          ],
          { padding: { top: 200, bottom: 200, left: 200, right: 200 } }
        );
      } else {
        map.flyTo({
          zoom: 14,
          center: [boundaries.min_coords?.lng, boundaries.min_coords?.lat],
          pitch: 60,
          duration: 10000,
        });
      }
    }
  }, [isLoading, devices]);

  if (isLoading) {
    while (!time) {
      return (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <div className={styles.no_devices}>
        {localStorage.getItem("language") === "es"
          ? "No se encontraron dispositivos"
          : "No devices found"}
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {/* TITULO------------------ */}
        {/* <h2 className={styles.title_container}>Vehículos en tránsito</h2> */}

        <div className={styles.map_and_info_container}>
          {/* MAPA------------------ */}

          <div className={styles.mapContainer}>
            {devs.length >= 2 && (
              <div className={styles.buttonContainer}>
                <BtnMyLocation boundaries={boundaries} />
              </div>
            )}

            <div ref={divMap} className={styles.map}></div>
          </div>
          <div className={styles.sideInfoContainer}>
            {/* TABLA------------------ */}
            <RcWayTable devices={devices} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MapView;
