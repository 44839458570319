/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  ReferenceDot,
  ReferenceArea,
  Brush,
  Legend,
} from "recharts";

import styles from "./RegistroTemperaturaIntrusion.module.scss";
import temperatura from "./temperatura.png";
import commentIcon from "./icons8-comment-48 (1).png";
import CircularProgress from "@mui/material/CircularProgress";

import { FormattedMessage, useIntl } from "react-intl";

import { ADD_COMMENT, GET_SHIPMENT_COMMENTS } from "../../queries";
import { useMutation } from "@apollo/client";

import {
  Button,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import { ZoomContext } from "../../context/grafico/ZoomProvider";
import { set } from "date-fns";
import { getTimestamp } from "../../helpers";

// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function RegistroTemperaturaIntrusion({
  shipment_id,
  departure,
  branch_departure,
  branch_arrival,
  ship_temp_range,
  status,
  temperature_readings,
  intrusions,

  graphOnly,
  gmt,

  checkpoints,
  last_checkpoint,
  comments,
  loading,
  x,
  y,
  z,
  contentData,
  type,
}) {
  const [commentObject, setCommentObject] = useState({
    timestamp: null,
    value: null,
    text: "",
  });
  const [
    addComment,
    {
      loading: commentAddedLoading,
      error: commentAddedError,
      data: commentAddedData,
    },
  ] = useMutation(
    ADD_COMMENT,
    {
      variables: {
        shipment_id: shipment_id,
        comment_text: commentObject.text,
        timestamp: commentObject.timestamp,
      },
    },
    { fetchPolicy: "network-only" }
  );
  //funcion para convertir los segundos en formado dd-hh-mm-ss
  const convertSeconds = (totalSeconds) => {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };
  const intl = useIntl();

  const [openDialog, setOpenDialog] = useState(false);
  const [loading2, setLoading] = useState(true);
  const [dataState, setDataState] = useState([]);
  const [dataState2, setDataState2] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [initialData, setInitialData] = useState([]);

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [top, setTop] = useState("dataMax+1");
  const [bottom, setBottom] = useState("dataMin-1");
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [clickTimeout, setClickTimeout] = useState(null);
  const [zoomOutBoolean, setZoomOutBoolean] = useState(false);

  //ESTRUCTURA DONDE SE VA A GUARDAR LA data0 QUE VA EN EL GRAFICO ----------------------------------------------------

  let data0 = [
    {
      value: temperature_readings?.[0]?.cv
        ? temperature_readings?.[0]?.cv
        : temperature_readings?.[0]?.value,
      counter: 0,
      formattedCounter: "0d 0h 0m 0s",
      intrusion: 0,
      timestamp: temperature_readings?.[0]?.timestamp,
      millisec: Date.parse(temperature_readings?.[0]?.timestamp),
      last_checkpoint: last_checkpoint?.temperature,
      last_responsible_name: last_checkpoint?.responsible_name,
      last_location: last_checkpoint?.location,
      last_label: last_checkpoint?.label,
      x: x,
      y: y,
      z: z,
    },
  ];

  //RECORRO LA DATA CUANDO SE INICIALIZA EL COMPONENTE -------------------------------------------------
  useEffect(() => {
    let checkpointIndex = 0;
    const data = [];
    const data2 = [];

    // Process temperature readings
    for (let i = 0; i < temperature_readings?.length; i++) {
      let hasIntrusion = false;

      for (let j = 0; j < intrusions?.length; j++) {
        if (temperature_readings[i]?.counter === intrusions[j]?.init_counter) {
          hasIntrusion = true;
          break;
        } else if (
          temperature_readings[i]?.counter === intrusions[j]?.final_counter
        ) {
          hasIntrusion = false;
        }
      }

      let checkpoint = checkpoints[checkpointIndex];
      if (
        checkpoint &&
        Date.parse(checkpoint?.timestamp) <=
          Date.parse(temperature_readings[i]?.timestamp)
      ) {
        checkpointIndex++;
      } else {
        checkpoint = null;
      }

      data.push({
        value: temperature_readings[i]?.cv
          ? temperature_readings[i]?.cv
          : temperature_readings[i]?.value,
        counter: temperature_readings[i]?.counter,
        formattedCounter: convertSeconds(temperature_readings[i]?.counter),
        intrusion: hasIntrusion ? 1 : 0,
        timestamp: temperature_readings[i]?.timestamp.toLocaleString(),
        millisec: Date.parse(temperature_readings[i]?.timestamp),
        // x: contentData?.shipment?.acceleration[i]?.x,
        // y: contentData?.shipment?.acceleration[i]?.y,
        // z: contentData?.shipment?.acceleration[i]?.z,
      });
    }
    //process acceleration
    for (let i = 0; i < contentData?.shipment?.acceleration?.length; i++) {
      data2.push({
        counter: contentData?.shipment?.acceleration[i]?.counter,
        timestamp:
          contentData?.shipment?.acceleration[i]?.timestamp?.toLocaleString(),
        millisec: Date.parse(contentData?.shipment?.acceleration[i]?.timestamp),
        x: contentData?.shipment?.acceleration[i]?.x,
        y: contentData?.shipment?.acceleration[i]?.y,
        z: contentData?.shipment?.acceleration[i]?.z,
      });
    }
    // Process checkpoints
    checkpoints?.forEach((checkpoint) => {
      data.push({
        value: checkpoint?.temperature || null,
        checkpoint: checkpoint?.temperature || null,
        responsible_name: checkpoint?.responsible_name || null,
        location: checkpoint?.location || "",
        label: checkpoint?.label || "",
        timestamp: checkpoint?.timestamp.toLocaleString(),
        millisec: Date.parse(checkpoint?.timestamp),
        intrusion: 0,
      });
    });

    // Process comments
    comments
      ?.filter((comment) => comment.timestamp !== null)
      ?.forEach((comment) => {
        const commentTimestamp = Date.parse(comment.timestamp);
        const matchingTempReading = temperature_readings?.find(
          (reading) => Date.parse(reading?.timestamp) === commentTimestamp
        );

        // If no matching temperature reading found, find the closest one
        const closestTempReading = matchingTempReading
          ? matchingTempReading
          : temperature_readings?.length > 0
          ? temperature_readings.reduce((prev, curr) => {
              const prevDiff = Math.abs(
                Date.parse(prev.timestamp) - commentTimestamp
              );
              const currDiff = Math.abs(
                Date.parse(curr.timestamp) - commentTimestamp
              );
              return currDiff < prevDiff ? curr : prev;
            }, temperature_readings[0])
          : null;

        // Add comment to the closest matching temperature reading
        if (closestTempReading) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].millisec === Date.parse(closestTempReading.timestamp)) {
              data[i].comment = comment.text;
              break;
            }
          }
        }
      });

    data.sort((a, b) => a.millisec - b.millisec);

    setData(data);
    setDataState(data);
    setOriginalData(data);
    setDataState2(data2);
    setEndIndex(data?.length - 1);
  }, [temperature_readings, checkpoints, comments, zoomOutBoolean]);

  //para comentarios

  //LOADING ---------------------------------------------------------------------------///

  while (loading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const tooltipStyle = {
        background: "#ffffff",
        padding: "10px",
        border: "1px solid #ccc",
      };

      const checkpointData = payload.find(
        (item) => item.dataKey === "checkpoint"
      );
      const commentData = payload.find(
        (item) => item.payload.comment !== undefined
      );
      const tempData = payload.find((item) => item.dataKey === "value");
      const intrusionData = payload.find(
        (item) => item.dataKey === "intrusion"
      );

      // Use the temperature from tempData or from commentData if available
      const temperature = tempData
        ? tempData.value
        : commentData && commentData.payload.value
        ? commentData.payload.value
        : null;

      return (
        <div style={tooltipStyle}>
          <p>
            <FormattedMessage id="date">fecha</FormattedMessage>:
            {getTimestamp(label)}
          </p>

          {checkpointData && (
            <p style={{ color: "blue" }}>
              {`Checkpoint: ${
                checkpointData.payload.label ? checkpointData.payload.label : ""
              } - ${checkpointData.payload.location} - ${
                checkpointData.payload.responsible_name
              }`}
            </p>
          )}

          {commentData && (
            <p style={{ color: "grey" }}>
              <FormattedMessage id="comment">comentario</FormattedMessage>:"
              {commentData.payload.comment}"
            </p>
          )}
          {temperature !== null && (
            <p style={{ color: "#00ABC8" }}>Temp: {temperature}°C</p>
          )}

          {intrusionData && intrusionData.value !== undefined && (
            <p style={{ color: "#FF001F" }}>
              {`Intrusion: ${
                intrusionData.value === 1 || intrusionData.value === 0
                  ? intrusionData.value
                  : 0
              }`}
            </p>
          )}
        </div>
      );
    }

    return null;
  };
  const CustomTooltip2 = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const tooltipStyle = {
        background: "#ffffff",
        padding: "10px",
        border: "1px solid #ccc",
      };

      const intrusionData = payload.find(
        (item) => item.dataKey === "intrusion"
      );

      return (
        <div style={tooltipStyle}>
          <p>
            <FormattedMessage id="date">fecha</FormattedMessage>:
            {getTimestamp(label)}
          </p>

          {intrusionData && intrusionData.value !== undefined && (
            <p style={{ color: "#FF001F" }}>
              {`Intrusion: ${
                intrusionData.value === 1 || intrusionData.value === 0
                  ? intrusionData.value
                  : 0
              }`}
            </p>
          )}
        </div>
      );
    }

    return null;
  };
  const handleChartClick = (e) => {
    if (clickTimeout) {
      //doble click
      clearTimeout(clickTimeout);
      setClickTimeout(null);

      if (e && e.activeLabel && e.activePayload) {
        setCommentObject({
          timestamp: e.activeLabel,
          value: e.activePayload[0].payload.value,
          text: "",
        });

        setOpenDialog(true);
      }
    } else {
      //simple click
      const timeout = setTimeout(() => {
        setClickTimeout(null);
      }, 300);
      setClickTimeout(timeout);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleConfirmDialog = async () => {
    try {
      const newComment = commentObject.text.trim();

      setOpenSnackbar(true);
      setLoading(true);
      setOpenDialog(false);
      if (newComment) {
        await addComment({
          variables: {
            shipment_id: shipment_id,
            comment_text: newComment,
            timestamp: commentObject.timestamp,
          },
          refetchQueries: [
            {
              query: GET_SHIPMENT_COMMENTS,
              variables: { shipment_id: shipment_id },
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error al enviar comentario:", error);
    }
  };

  const handleChange = (event) => {
    setCommentObject({ ...commentObject, text: event.target.value });
  };

  const getAxisYDomain = (from, to, ref, data, offset) => {
    console.log(data);
    console.log(from);
    console.log(to);

    const refData = data
      .slice(from, to + 1)
      .filter((item) => item && item[ref] !== null && item[ref] !== undefined);
    if (refData.length === 0) return [0, 0];
    let [bottom, top] = [refData[0][ref], refData[0][ref]];

    refData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });

    return [(bottom | 0) - offset, (top | 0) + offset];
  };

  const zoom = () => {
    if (refAreaLeft === refAreaRight || !refAreaRight) {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    let from = refAreaLeft;
    let to = refAreaRight;

    if (from > to) [from, to] = [to, from];

    const fromIndex = data.findIndex((item) => item.millisec === from);
    const toIndex = data.findIndex((item) => item.millisec === to);

    if (fromIndex === -1 || toIndex === -1) {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    const [bottom, top] = getAxisYDomain(fromIndex, toIndex, "value", data, 3);
    const newData = data.slice(fromIndex, toIndex + 1);

    setLeft(data[fromIndex].millisec);
    setRight(data[toIndex].millisec);
    setBottom(bottom);
    setTop(top);
    setData(newData);
    setRefAreaLeft("");
    setRefAreaRight("");
    setStartIndex(0);
    setEndIndex(data?.length - 1);
    // setZoom2(55);
  };

  const zoomOut = () => {
    setLeft("dataMin");
    setRight("dataMax");
    setTop("dataMax+1");
    setBottom("dataMin-1");
    setRefAreaLeft("");
    setRefAreaRight("");
    setData(originalData);
    setStartIndex(0);
    setEndIndex(data?.length - 1);
    setZoomOutBoolean(!zoomOutBoolean);
  };

  const CustomTickX = (props, axis) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16} // Ajusta este valor según sea necesario para alinear el texto
          textAnchor="middle"
          fill="grey"
          fontSize="0.7vw"
          style={{ userSelect: "none" }}
        >
          {getTimestamp(payload.value)}
        </text>
      </g>
    );
  };
  const CustomTickY = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={6}
          textAnchor="end"
          fill="grey"
          fontSize="0.7vw"
          style={{ userSelect: "none" }} // Evita la selección del texto
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <>
      {/* dialogo para carga de comentarios */}
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Ingresar Comentario</DialogTitle>
          {/* //traducir */}
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="comment-input"
              label={intl.formatMessage({ id: "ingrese_comentario" })}
              type="text"
              fullWidth
              value={commentObject.text}
              onChange={handleChange}
              placeholder={`${getTimestamp(commentObject.timestamp)}- ${
                commentObject.value
              }°C`}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              <FormattedMessage id="cancel">Cancelar</FormattedMessage>
            </Button>
            <Button onClick={handleConfirmDialog} color="primary">
              <FormattedMessage id="save">save</FormattedMessage>
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            Comentario enviado con éxito
          </Alert>
        </Snackbar>
      </>
      {(left !== "dataMin" || right !== "dataMax") && (
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            onClick={zoomOut}
            sx={{ backgroundColor: "#1B1464", textTransform: "none" }}
          >
            Zoom out
          </Button>
        </div>
      )}
      {graphOnly ? (
        dataState?.[1] ? (
          <div className={styles.graphContainer}>
            {/* GRAFICO solo para dropdown de reportes */}
            {/* intrussions graph */}
            <div className={styles.titulo_grafico}>
              <FormattedMessage id="intrusions">Intrusiones</FormattedMessage>
            </div>
            <ResponsiveContainer width="97%" height="20%">
              <LineChart
                width="50%"
                height="50%"
                data={data}
                syncId="anyId"
                margin={{
                  top: window.screen.width < 1050 ? 2 : 6,
                  right: window.screen.width < 1050 ? 10 : 30,
                  left: 18,
                  bottom: 9,
                }}
                onClick={handleChartClick}
                // onMouseDown={(e) => setRefAreaLeft(e.activeLabel)}
                // onMouseMove={(e) => setRefAreaRight(e.activeLabel)}
                // onMouseUp={zoom}
              >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis
                  dataKey="millisec"
                  type="number"
                  // ticks={data.map((d) => d.millisec)}
                  tick={<CustomTickX />}
                  tickMargin={6}
                  tickLine={true}
                  tickCount={20}
                  tickFormatter={getTimestamp}
                  domain={["dataMin", "dataMax"]}
                  //  domain={[left, right]}
                />

                <Tooltip content={<CustomTooltip2 />} />
                <YAxis
                  yAxisId="intrusionaxis"
                  dataKey="intrusion"
                  type="category"
                  tick={{
                    color: "#00ABC8",
                    fontSize: "0.7vw",
                  }}
                  tickMargin={6}
                  tickCount={10}
                  tickLine={false}
                  domain={["0", "1"]}
                  tickFormatter={(value) =>
                    value === 0
                      ? intl.formatMessage({ id: "closed" })
                      : intl.formatMessage({ id: "open" })
                  }
                >
                  {/* <Label
                    value={
                      localStorage.getItem("language") === "en"
                        ? "Intrusions"
                        : "Intrusiones"
                    }
                    angle={-90}
                    position="left"
                  
                    fontSize="0.8vw"
                    fill="#FF001F"
                    fontWeight="lighter"
                    dy={-30}
                  /> */}
                </YAxis>

                <Line
                  yAxisId="intrusionaxis"
                  type="step"
                  dataKey="intrusion"
                  stroke="#FF001F"
                  dot={false}
                />
                {/* {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="intrusionaxis"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null} */}
              </LineChart>
            </ResponsiveContainer>

            {/* temperatura */}
            <div className={styles.titulo_grafico}>
              <div>
                <FormattedMessage id="temperature">
                  Temperatura
                </FormattedMessage>
                
              </div>
              <div>{type}</div>
            </div>
            <ResponsiveContainer
              width="97%"
              height="60%"
              style={{ marginBottom: "3%" }}
            >
              <LineChart
                width="50%"
                height="50%"
                data={data}
                syncId="anyId"
                margin={{
                  top: window.screen.width < 1050 ? 2 : 6,
                  right: window.screen.width < 1050 ? 10 : 30,
                  left: 18,
                  bottom: 9,
                }}
                onClick={(e) => handleChartClick(e, "temp")}
                onMouseDown={(e) => setRefAreaLeft(e.activeLabel)}
                onMouseMove={(e) => setRefAreaRight(e.activeLabel)}
                onMouseUp={zoom}
              >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis
                  dataKey="millisec"
                  type="number"
                  tick={<CustomTickX />}
                  tickMargin={6}
                  tickLine={true}
                  tickCount={20}
                  tickFormatter={getTimestamp}
                  domain={["dataMin", "dataMax"]}
                />
                <YAxis
                  yAxisId="left"
                  dataKey="value"
                  type="number"
                  allowDataOverflow={true}
                  tick={<CustomTickY />}
                  tickCount={10}
                  tickLine={true}
                  padding={{ top: 10, bottom: 10 }}
                  domain={[
                    (dataMin) => Math.min(dataMin, ship_temp_range.min),
                    (dataMax) => Math.max(dataMax, ship_temp_range.max),
                  ]}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="value"
                  stroke="#00ABC8"
                  dot={false}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="checkpoint"
                  stroke="transparent"
                  dot={{
                    stroke: "#00ABC8",
                    strokeWidth: 5,
                    fill: "white",
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine
                  y={ship_temp_range?.max}
                  stroke="none"
                  strokeDasharray="5 5"
                  label={`Max: ${ship_temp_range?.max}`}
                  yAxisId="left"
                />
                <ReferenceLine
                  y={ship_temp_range?.min}
                  stroke="none"
                  strokeDasharray="5 5"
                  label={`Min: ${ship_temp_range?.min}`}
                  yAxisId="left"
                />
                {ship_temp_range?.min != null &&
                  ship_temp_range?.max != null && (
                    <ReferenceArea
                      yAxisId="left"
                      y1={ship_temp_range.min}
                      y2={ship_temp_range.max}
                      strokeOpacity={0.3}
                      fill="var(--greenArea)"
                    />
                  )}
                {dataState
                  .filter((data) => data.comment)
                  .map((data, index) => (
                    <ReferenceDot
                      key={index}
                      x={data.millisec}
                      y={data.value}
                      yAxisId="left"
                      r={0}
                      label={({ viewBox }) => (
                        <image
                          x={viewBox.x - 12}
                          y={viewBox.y - 12}
                          href={commentIcon}
                          width={20}
                          height={20}
                          style={{ color: "red", border: "1px solid red" }}
                        />
                      )}
                    />
                  ))}
                <Brush y={305} />
              </LineChart>
            </ResponsiveContainer>

            {/* aceleracion */}
            <div className={styles.titulo_grafico}>
              <FormattedMessage id="acceleration">Aceleración</FormattedMessage>
            </div>
            <ResponsiveContainer
              width="97%"
              height="60%"
              style={{ marginBottom: "3%" }}
            >
              <LineChart
                width="50%"
                height="50%"
                data={dataState2}
                syncId="anyId"
                margin={{
                  top: window.screen.width < 1050 ? 2 : 6,
                  right: window.screen.width < 1050 ? 10 : 30,
                  left: 18,
                  bottom: 9,
                }}
                onClick={handleChartClick}
                // onMouseDown={(e) => setRefAreaLeft(e.activeLabel)}
                // onMouseMove={(e) => setRefAreaRight(e.activeLabel)}
                // onMouseUp={zoom}
              >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis
                  dataKey="millisec"
                  // scale='time'
                  type="number"
                  ticks={dataState2?.millisec}
                  tick={{
                    color: "#00ABC8",
                    fontSize: "0.7vw",
                  }}
                  tickMargin={6}
                  tickLine={true}
                  tickCount={20}
                  tickFormatter={getTimestamp}
                  domain={["dataMin", "dataMax"]}
                ></XAxis>
                <YAxis
                  allowDataOverflow={true}
                  type="number"
                  yAxisId="1"
                  domain={["auto", "auto"]}
                  tick={{
                    color: "#00ABC8",
                    fontSize: "0.7vw",
                    // fontFamily: "'Quattrocento Sans', sans-serif",
                  }}
                  tickCount={10}
                  tickLine={true}
                  padding={{ top: 10 }}
                >
                  {/* <Label
                    value={
                      localStorage.getItem("language") === "en"
                        ? "Acceleration(G)"
                        : "Aceleracion(G)"
                    }
                    angle={-90}
                    position="left"
                    // fontFamily="'Quattrocento Sans', sans-serif"
                    fontSize="0.8vw"
                    fill="#00ABC8"
                    fontWeight="lighter"
                  /> */}
                </YAxis>
                <Tooltip
                  labelFormatter={(label) => `Fecha: ${getTimestamp(label)}`}
                  formatter={(value) =>
                    `Valor: ${parseFloat(value).toFixed(2)}`
                  }
                />
                <Legend />
                <Line
                  yAxisId="1"
                  type="monotone"
                  dataKey="x"
                  stroke="mediumaquamarine"
                  dot={false}
                  // animationDuration={300}
                />
                <Line
                  yAxisId="1"
                  type="monotone"
                  dataKey="y"
                  stroke="orange"
                  // animationDuration={300}
                  dot={false}
                />
                <Line
                  yAxisId="1"
                  type="monotone"
                  dataKey="z"
                  stroke="steelBlue"
                  // animationDuration={300}
                  dot={false}
                />
                {/* {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null} */}
                {/* <Brush /> */}
              </LineChart>
            </ResponsiveContainer>

            {/* <div className={styles.textContainer}>
              <div className={styles.text}>
                X: <div className={styles.textLineBlue}></div>
              </div>
              <div className={styles.text}>
                Y: <div className={styles.textLineYellow}></div>
              </div>
              <div className={styles.text}>
                Z: <div className={styles.textLineCyan}></div>
              </div>
            </div> */}
          </div>
        ) : (
          <div className={styles.noData}>
            <FormattedMessage id="no_data_register">No data</FormattedMessage>
          </div>
        )
      ) : (
        <div className={styles.allContainer}>
          <div className={styles.firstLine}>
            <div className={styles.imageAndTitle}>
              <img className={styles.icon} src={temperatura} alt="" />
              <p className={styles.title}>
                {" "}
                <FormattedMessage id="temp_graph_title">
                  Temperature/intrusion register
                </FormattedMessage>{" "}
              </p>
            </div>
            <div className={styles.infoBox}>
              <div className={styles.eachBox}>
                <div className={styles.infoIDTitle}>ID</div>
                <div className={styles.info}>{shipment_id}</div>
              </div>
              <div className={styles.eachBox}>
                <div className={styles.infoTitle}>
                  <FormattedMessage id="origin_tab_title">
                    ORIGIN
                  </FormattedMessage>
                </div>
                <div className={styles.info}>{branch_departure}</div>
              </div>
              <div className={styles.eachBox}>
                <div className={styles.infoIDTitle}>
                  <FormattedMessage id="departure_tab_title">
                    DEPARTURE
                  </FormattedMessage>
                </div>
                <div className={styles.info}>{departure}</div>
              </div>
              <div className={styles.eachBox}>
                <div className={styles.infoTitle}>
                  <FormattedMessage id="status_tab_title">
                    STATUS
                  </FormattedMessage>
                </div>
                {status === "SUCCESSFUL" && (
                  <div className={styles.info}>
                    <FormattedMessage id="succ_select">
                      Successful
                    </FormattedMessage>
                  </div>
                )}
                {status === "UNCERTAIN" && (
                  <div className={styles.info}>
                    <FormattedMessage id="uncert_select">
                      Uncertain
                    </FormattedMessage>
                  </div>
                )}
                {status === "FAILED" && (
                  <div className={styles.info}>
                    <FormattedMessage id="fail_select">Failed</FormattedMessage>
                  </div>
                )}
              </div>
              <div className={styles.eachBox}>
                <div className={styles.infoTitle}>
                  <FormattedMessage id="destination_tab_title">
                    DESTINATION
                  </FormattedMessage>
                </div>
                <div className={styles.info}>{branch_arrival}</div>
              </div>
            </div>
          </div>

          {dataState?.[1] ? (
            <div className={styles.graphContainer}>
              {/* GRAFICO  temp */}

              <ResponsiveContainer width="105%" height="85%">
                <LineChart
                  width="100%"
                  height="50vw"
                  data={dataState}
                  margin={{
                    top: window.screen.width < 1050 ? 2 : 6,
                    right: window.screen.width < 1050 ? 10 : 30,
                    left: 18,
                    bottom: 25,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />

                  <XAxis
                    dataKey="millisec"
                    type="number"
                    ticks={dataState?.millisec}
                    tick={{
                      color: "#00ABC8",
                      fontSize: "0.7vw",
                    }}
                    tickMargin={6}
                    tickLine={true}
                    tickCount={20}
                    tickFormatter={getTimestamp}
                    domain={["dataMin", "dataMax"]}
                  >
                    <Label
                      value={
                        localStorage.getItem("language") === "en"
                          ? "Time (days-hours-minutes-seconds)"
                          : "Tiempo (días-horas-minutos-segundos)"
                      }
                      offset={-20}
                      position="insideBottom"
                      fontFamily="'Quattrocento Sans', sans-serif"
                      fontSize="0.8vw"
                      stroke="#1B1464"
                      strokeWidth={0.3}
                    />
                  </XAxis>
                  {/* temp */}
                  <YAxis
                    yAxisId="left"
                    dataKey="value"
                    type="number"
                    tick={{
                      color: "#00ABC8",
                      fontSize: "0.7vw",
                    }}
                    tickCount={10}
                    tickLine={true}
                    padding={{ top: (window.screen.width * 6.25) / 100 }}
                    domain={[0, "dataMax"]}
                  >
                    <Label
                      value={
                        localStorage.getItem("language") === "en"
                          ? "Temperature(°C)"
                          : "Temperatura(°C)"
                      }
                      angle={-90}
                      position="left"
                      //fontFamily="'Quattrocento Sans', sans-serif"
                      fontSize="0.8vw"
                      fill="#00ABC8"
                      fontWeight="lighter"
                    />
                  </YAxis>

                  {/* intrusions */}
                  <YAxis
                    yAxisId="right"
                    dataKey="intrusion"
                    type="category"
                    tick={{
                      color: "#00ABC8",
                      fontSize: "0.7vw",
                    }}
                    tickMargin={6}
                    tickCount={10}
                    tickLine={false}
                    orientation="right"
                    padding={{ bottom: (window.screen.width * 20.8) / 100 }}
                    domain={["0", "1"]}
                  >
                    <Label
                      value={
                        localStorage.getItem("language") === "en"
                          ? "Intrusions"
                          : "Intrusiones"
                      }
                      angle={-90}
                      position="insideRight"
                      fontFamily="'Quattrocento Sans', sans-serif"
                      fontSize="0.8vw"
                      fill="#FF001F"
                      fontWeight="lighter"
                    />
                  </YAxis>

                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="value"
                    stroke="#00ABC8"
                    dot={false}
                  />

                  <Line
                    yAxisId="right"
                    type="step"
                    dataKey="intrusion"
                    stroke="#FF001F"
                    dot={false}
                  />
                  {/* checkpoints line */}
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="checkpoint"
                    stroke="#00ABC8"
                    dot={{ stroke: "#00ABC8", strokeWidth: 5, fill: "white" }}
                  />

                  <ReferenceLine
                    y={ship_temp_range?.max} // Usamos el valor máximo del primer punto de datos como ejemplo
                    stroke="orange"
                    strokeDasharray="5 5"
                    label={`Max: ${data0[0].max}`}
                    yAxisId="left"
                  />

                  <ReferenceLine
                    y={data0[0]?.min} // Usamos el valor mínimo del primer punto de datos como ejemplo
                    stroke="orange"
                    strokeDasharray="5 5"
                    label={`Min: ${data0[0].min}`}
                    yAxisId="left"
                  />
                  <Tooltip content={<CustomTooltip />} />
                  {refAreaLeft && refAreaRight ? (
                    <ReferenceArea
                      yAxisId="left"
                      x1={refAreaLeft}
                      x2={refAreaRight}
                      strokeOpacity={0.3}
                    />
                  ) : null}
                  <Brush />
                </LineChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className={styles.noData}>
              <FormattedMessage id="no_data_register">No data</FormattedMessage>
            </div>
          )}
        </div>
      )}
    </>
  );
}
