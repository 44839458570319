import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MyUsers from "../MyUsers/MyUsers";
import { BiChevronDown } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
import styles from "./ConfigLevel0.module.scss";
// import { parseJwt } from "../../../helpers";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CreateUser from "../CreateUser/CreateUser";
import MyDevices from "../MyDevices/MyDevices";
import CreateDevice from "../CreateDevice/CreateDevice";
import MyCompanies from "../MyCompanies/MyCompanies";
import MyDistributors from "../MyDistributors/MyDistributors";
import CreateDistributor from "../CreateDistributor/CreateDistributor";

export default function ConfigLevel0() {
  //ESTADOS LOCALES --------------------------------------------------------------------------------------
  //estado para el filtrado de tipos de usuarios
  const [myUsersFilter, setMyUsersFilter] = useState("Admin");

  //estado del edit user
  const [editUsers, setEditUsers] = useState(false);

  //estado para el modal de create user
  const [modalCreateUser, setModalCreateUser] = useState(false);

  //estado para el modal de create device
  const [modalCreateDevice, setModalCreateDevice] = useState(false);

  //estado para el modal de create device
  const [modalCreateDistributor, setModalCreateDistributor] = useState(false);
  //estado para setear distribuidores y mandarselos a create user
  const [distributors,setDistributors] = useState([])

  //   HANDLERS --------------------------------------------------------------------------------
  //handler del modal de create user
  const handleModalCreateUser = () => {
    setModalCreateUser(true);
  };

  //handler del close del modal de create user
  const handleModalCreateUserClose = () => {
    setModalCreateUser(false);
  };

  //handler del modal de create device
  const handleModalCreateDevice = () => {
    setModalCreateDevice(true);
  };

  //handler del close del modal de create device
  const handleModalCreateDeviceClose = () => {
    setModalCreateDevice(false);
  };

  //handler del modal de create distributor
  const handleModalCreateDistributor = () => {
    setModalCreateDistributor(true);
  };

  //handler del close del modal de create distributor
  const handleModalCreateDistributorClose = () => {
    setModalCreateDistributor(false);
  };

  return (
    <div className={styles.container}>
      {/* MIS DISTRIBUIDORES --------------------------------------------------------------------------- */}
      <Accordion sx={{ textAlign: "center" }}>
        <AccordionSummary
          expandIcon={<BiChevronDown />}
          aria-controls="panel2a-content"
          sx={{ fontSize: "1.5vw", color: "#1B1464" }}
        >
          <FormattedMessage id="my_distributors">
            My distributors
          </FormattedMessage>
        </AccordionSummary>
        <AccordionDetails>
          <MyDistributors  setDistributors={setDistributors}/>
          {/* MIS DISTRIBUIDORES => CREAR DISTRIBUIDOR ------------------------------------------------------------------- */}
          <button
            onClick={handleModalCreateDistributor}
            className={styles.createDistributorButton}
          >
            <FormattedMessage id="create_distributor">
              Create new distributor
            </FormattedMessage>
          </button>
          <Modal
            open={modalCreateDistributor}
            onClose={handleModalCreateDistributorClose}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                border: "0.15vw solid #00ABC8",
                borderRadius: "2vw",
                fontFamily: "'Quattrocento Sans', sans-serif",
                boxShadow: 10,
                pt: 2,
                px: 4,
                pb: 3,
                backgroundColor: "white",
                width: "20vw",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CreateDistributor />
            </Box>
          </Modal>
        </AccordionDetails>
      </Accordion>
      {/* MIS COMPAÑIAS ------------------------------------------------------------------------------ */}
      <Accordion sx={{ textAlign: "center" }}>
        <AccordionSummary
          expandIcon={<BiChevronDown />}
          aria-controls="panel2a-content"
          sx={{ fontSize: "1.5vw", color: "#1B1464" }}
        >
          <FormattedMessage id="companies_config">
            My companies
          </FormattedMessage>
        </AccordionSummary>
        <AccordionDetails>
          <MyCompanies />
        </AccordionDetails>
      </Accordion>
      {/* MIS USUARIOS -----------------------------------------------------------------------------  */}
      <Accordion sx={{ textAlign: "center" }}>
        <AccordionSummary
          expandIcon={<BiChevronDown />}
          aria-controls="panel2a-content"
          sx={{ fontSize: "1.5vw", color: "#1B1464" }}
        >
          <FormattedMessage id="users_config">My users</FormattedMessage>
        </AccordionSummary>
        <AccordionDetails>
          {/* MIS USUARIOS => EDITAR USUARIO ----------------------------------------------------------------- */}
          <MyUsers company_id={null} usersFilter={myUsersFilter} />
          {/* MIS USUARIOS => CREAR USUARIO -------------------------------------------------------------------- */}
          {!editUsers && (
            <>
              <button
                onClick={handleModalCreateUser}
                className={styles.createUserButton}
              >
                <FormattedMessage id="create_user_button">
                  Create new user
                </FormattedMessage>
              </button>
              <Modal
                open={modalCreateUser}
                onClose={handleModalCreateUserClose}
              >
                <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "white",
                      border: "0.15vw solid #00ABC8",
                      borderRadius: "2vw",
  
                      boxShadow: 10,
                      pt: 2,
                      px: 4,
                      pb: 3,
                      backgroundColor: "white",
                      width: "30vw",
                      height: "34vw",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                >
                  <CreateUser company_id={null} distributors={distributors} />
                </Box>
              </Modal>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<BiChevronDown />}
          aria-controls="panel2a-content"
          sx={{ fontSize: "1.5vw", color: "#1B1464"}}
        >
          <FormattedMessage id="devices_config">My devices</FormattedMessage>
        </AccordionSummary>
        <AccordionDetails>
          {/* MIS DISPOSITIVOS ------------------------------------------------------------------- */}
          <MyDevices company_id={null} />
          {/* MIS DISPOSITIVOS => CREAR DISPOSITIVO ------------------------------------------------------------------- */}
          <button
            onClick={handleModalCreateDevice}
            className={styles.createDeviceButton}
          >
            <FormattedMessage id="create_device">
              Create new device
            </FormattedMessage>
          </button>
          <Modal
            open={modalCreateDevice}
            onClose={handleModalCreateDeviceClose}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // width: 900,
                bgcolor: "white",
                border: "0.15vw solid #00ABC8",
                borderRadius: "2vw",
                fontFamily: "'Quattrocento Sans', sans-serif",
                boxShadow: 10,
                pt: 2,
                px: 4,
                pb: 3,
                backgroundColor: "white",
                width: "18%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CreateDevice />
            </Box>
          </Modal>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
