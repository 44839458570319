import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./MyCompanies.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_COMPANIES,
  DELETE_COMPANY,
  GET_DISTRIBUTORS,
} from "../../../queries";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CompanyDetail from "../CompanyDetail/CompanyDetail";
import CreateCompany from "../CreateCompany/CreateCompany";
import { parseJwt } from "../../../helpers";
import { BsSearch } from "react-icons/bs";
import { CircularProgress } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BiCaretDown } from "react-icons/bi";
import CreateCompnay2 from "../CreateCompany/CreateCompnay2";

export default function MyCompanies() {
  //LEVEL DEL USUARIO LOGGEADO ------------------------------------------------------------------
  const level = parseJwt(localStorage.getItem("token")).lvl
    ? parseJwt(localStorage.getItem("token")).lvl
    : 0;

  // ESTADOS LOCALES ----------------------------------------------------------------------------
  //estados para paginación
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //estado para el modal del detalle de compañia
  const [modalCompanyDetail, setModalCompanyDetail] = useState(false);

  //estado para el modal de edit compañia
  const [modalEditCompany, setModalEditCompany] = useState(false);

  //estado para guardar el id de la compañia a la cual quiero ver en detalle
  const [companyToSeeDetail, setCompanyToSeeDetail] = useState("");

  //estado para el modal de create company
  const [modalCreateCompany, setModalCreateCompany] = useState(false);

  //estado para el input de busqueda por id
  const [searchInput, setSearchInput] = useState("");

  //estado y variable para el dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //estado para guardarme el filtro de belongs
  const [belongsFilter, setBelongsFilter] = useState("");

  //QUERIES / MUTATIONS ------------------------------------------------------------------------

  //lazy query para traer todas las compañias
  const [
    getAllCompanies,
    {
      data: allCompaniesData,
      loading: allCompaniesLoading,
      error: allCompaniesError,
    },
  ] = useLazyQuery(GET_COMPANIES);

  //mutation para eliminar compañia
  const [
    deleteCompany,
    {
      // data: deleteCompanyData,
      loading: deleteCompanyLoading,
      error: deleteCompanyError,
    },
  ] = useMutation(DELETE_COMPANY);

  //query para traerme todos los distribuidores
  const {
    data: allDistributorsData,
    loading: allDistributorsLoading,
    error: allDistributorsError,
  } = useQuery(GET_DISTRIBUTORS);

  //CUANDO SE INICIA EL COMPONENTE SE OBTIENE DATO DE TODAS LAS COMPAÑIAS (SUDO) -----------------------------------------------------------
  useEffect(() => {
    getAllCompanies();
  }, [belongsFilter]);

  //CUANDO CAMBIA EL FILTRO O INSERTO UNA BUSQUEDA -----------------------------------------------------------------
  useEffect(() => {
    setPage(1);
  }, [belongsFilter, searchInput]);

  // ME GUARDO LA INFO EN UNA VARIABLE COPIA -------------------------------------------------------
  let info = allCompaniesData?.companies;

  // PARA LA BUSQUEDA POR ID -----------------------------------------------------
  if (searchInput !== "") {
    let infoCopy = info;
    info = infoCopy?.filter((c) =>
      c?.company_id?.toLowerCase().includes(searchInput.toLowerCase())
    );
  }

  // HANDLERS --------------------------------------------------------------------------
  //handler de la paginacion
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  //handler para el modal del detalle de compañia
  const handlerModalDetailCompany = (id) => {
    setModalCompanyDetail(true);
    setCompanyToSeeDetail(id);
  };

  //handler para el modal de editar compañia
  const handlerModalEditCompany = (id) => {
    setModalEditCompany(true);
    setCompanyToSeeDetail(id);
  };

  //handler para el modal de create company
  const handleModalCreateCompany = () => {
    setModalCreateCompany(true);
  };

  //handler del close del modal de create company
  const handleCloseModalCreatecompany = () => {
    setModalCreateCompany(false);
  };

  //handler para eliminar compañia
  const handlerDeleteCompany = async (id) => {

    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que deseas eliminar la compañía?"
            : "Are you sure you want to delete company?"
        )
      ) {
        await deleteCompany({
          variables: {
            company_id: id,
          },
        });
        alert(
          localStorage.getItem("language") === "en"
            ? "Company deleted"
            : "Compañía eliminada"
        );
        window.location.reload("/");
      }
    } catch (error) {
      console.log("error en la mutation de deleteCompany", error);
    }
  };

  //handler del input de busqueda por id
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  //handler del dropdown de belongs
  const handleDropdown = (e) => {
    setAnchorEl(e.currentTarget);
  };

  //handler del close del belongs
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  //handler del belongs filter
  const handleBelongsFilter = (value) => {
    setBelongsFilter(value);
    setAnchorEl(null);
  };

  //PARA PAGINAR -------------------------------------------------------------------------
  let indexOfLastElement = page * rowsPerPage;
  let indexOfFirstElement = indexOfLastElement - rowsPerPage;
  const currentElements =
    belongsFilter !== ""
      ? info
        ?.filter((c) => c.distributor_id === belongsFilter)
        ?.slice(indexOfFirstElement, indexOfLastElement)
      : info?.slice(indexOfFirstElement, indexOfLastElement);

  //MANEJO DE ERRORES -----------------------------------------------------------------------
  if (allCompaniesError)
    console.log("error en el get all companies", allCompaniesError);
  if (deleteCompanyError)
    console.log("error al eliminar la compañia", deleteCompanyError);
  if (allDistributorsError)
    console.log("error al buscar los distributors", allDistributorsError);

  //LOADINGS
  if (allCompaniesLoading || deleteCompanyLoading || allDistributorsLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.companiesContainer}>
      {allCompaniesData?.companies?.[0] ? (
        <>
          {/* BUSQUEDA POR ID --------------------------------------------------------------- */}
          <div className={styles.inputContainer}>
            <label className={styles.searchQrLabel}>
              <BsSearch size="1vw" />
            </label>
            <input
              type="text"
              onChange={handleSearchInput}
              className={styles.searchQrInput}
              placeholder={
                localStorage.getItem("language" === "en")
                  ? "COMPANY ID:"
                  : "ID DE COMPAÑÍA:"
              }
            />
          </div>
          {/* <div className={styles.tableContainer}> */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* HEAD ID -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      
                      color: "#00ABC8",
  
                    }}
                  >
                    ID
                  </TableCell>
                  {/* HEAD NAME -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      
                      color: "#00ABC8",
  
                    }}
                  >
                    <FormattedMessage id="name_config_company">
                      NAME{" "}
                    </FormattedMessage>
                  </TableCell>
                  {/* HEAD LOCATION -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      
                      color: "#00ABC8",
  
                    }}
                  >
                    <FormattedMessage id="location_config_company">
                      LOCATION
                    </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD CONTACT INFO -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      
                      color: "#00ABC8",
  
                    }}
                  >
                    <FormattedMessage id="contact_info_config_company">
                      CONTACT INFO
                    </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD CREADA -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      
                      color: "#00ABC8",
  
                    }}
                  >
                    <FormattedMessage id="created_config">
                      CREATED
                    </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD STATUS -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      
                      color: "#00ABC8",
  
                    }}
                  >
                    <FormattedMessage id="status_tab_title">
                      STATUS
                    </FormattedMessage>{" "}
                  </TableCell>
                  {/* HEAD BELONGS TO -------------------------------------------------------------------------------------- */}
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      
                      color: "#00ABC8",
  
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <FormattedMessage id="belongs_to_may">
                        BELONGS TO
                      </FormattedMessage>{" "}
                      <button
                        className={styles.dropdownButton}
                        onClick={handleDropdown}
                      >
                        <BiCaretDown />
                      </button>
                    </div>
                    <Menu
                     disableScrollLock={true}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseDropdown}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{ borderRadius: "2vw", width: "auto" }}
                    >
                      {belongsFilter !== "" && (
                        <MenuItem
                          onClick={() => handleBelongsFilter("")}
                          sx={{
                            borderRadius: "2vw",
                            color: "#1B1464",
        
                            fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          }}
                        >
                          <FormattedMessage id="all_select">
                            All
                          </FormattedMessage>
                        </MenuItem>
                      )}
                      {allDistributorsData?.distributors?.map((d) => {
                        return (
                          belongsFilter !== d.distributor_id && (
                            <MenuItem key={d?.distributor_id}
                              onClick={() =>
                                handleBelongsFilter(d.distributor_id)
                              }
                              sx={{
                                borderRadius: "2vw",
                                color: "#1B1464",
            
                                fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                              }}
                            >
                              {d.name} ({d.distributor_id})
                            </MenuItem>
                          )
                        );
                      })}
                    </Menu>
                  </TableCell>
                  <TableCell
                    size="medium"
                    align="center"
                    sx={{
                      fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                      
                      color: "#00ABC8",
  
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentElements?.map((c) => {
                  return (
                    <TableRow key={c?.company_id}>
                      {/* ID ------------------------------------------------------ */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          backgroundColor:
                            currentElements?.indexOf(c) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          
      
                          color: "#1B1464",
                        }}
                      >
                        {c?.company_id.split("-")[1]}
                      </TableCell>
                      {/* NOMBRE ------------------------------------------------------ */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          backgroundColor:
                            currentElements?.indexOf(c) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          
      
                          color: "#1B1464",
                        }}
                      >
                        {c?.name}
                      </TableCell>
                      {/* LOCACION ------------------------------------------------------ */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          backgroundColor:
                            currentElements?.indexOf(c) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          
      
                          color: "#1B1464",
                        }}
                      >
                        {c?.location}
                      </TableCell>
                      {/* INFO DE CONTACTO ------------------------------------------------------ */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          backgroundColor:
                            currentElements?.indexOf(c) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          
      
                          color: "#1B1464",
                        }}
                      >
                        {c?.contact_info ? c?.contact_info : "-"}
                      </TableCell>
                      {/* CREADA ------------------------------------------------------ */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          backgroundColor:
                            currentElements?.indexOf(c) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          
      
                          color: "#1B1464",
                        }}
                      >
                        {c?.created.split("T")[0] +
                          " " +
                          c?.created.split("T")[1].split(".")[0]}
                      </TableCell>
                      {/* STATUS ------------------------------------------------------ */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          backgroundColor:
                            currentElements?.indexOf(c) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          
      
                          color: "#1B1464",
                        }}
                      >
                        {localStorage.getItem("language") === "en" &&
                          c?.status.charAt(0).toUpperCase() +
                          c?.status.slice(1)}
                        {localStorage.getItem("language") === "es" &&
                          (c?.status === "ACTIVE" ? "Activa" : "Inactiva")}
                      </TableCell>
                      {/* BELONGS TO ------------------------------------------------------ */}
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          backgroundColor:
                            currentElements?.indexOf(c) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          
      
                          color: "#1B1464",
                        }}
                      >
                        {c?.distributor_id}
                      </TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        sx={{
                          border: "none",
                          fontSize: window.screen.width > 800 ? "1.1vw" : "2vw",
                          backgroundColor:
                            currentElements?.indexOf(c) % 2 === 0
                              ? "#FAFAFA"
                              : "#D9F1F5",
                          
      
                          color: "#1B1464",
                        }}
                      >
                        <div className={styles.buttonsContainer}>
                          {/* VER DETALLES ------------------------------------------------------- */}
                          <button
                            className={styles.editCompaniesButton}
                            onClick={() =>
                              handlerModalDetailCompany(c.company_id)
                            }
                          >
                            <FormattedMessage id="details">
                              See details
                            </FormattedMessage>
                          </button>
                          {/* EDITAR -------------------------------------------------------- */}
                          <button
                            className={styles.editCompaniesButton}
                            onClick={() =>
                              handlerModalEditCompany(c.company_id)
                            }
                          >
                            <FormattedMessage id="edit_device_button">
                              Edit
                            </FormattedMessage>
                          </button>
                          {/* BORRAR ---------------------------------------------------------------------- */}
                          <button
                            className={styles.deleteButton}
                            onClick={() => handlerDeleteCompany(c.company_id)}
                          >
                            <FormattedMessage id="delete_device_button">
                              Delete
                            </FormattedMessage>
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* PAGINACION ---------------------------------------------------------------------------------------- */}
          {allCompaniesData?.companies?.length > 10 ? (
            <div className={styles.pagination}>
              <Pagination
                count={Math.ceil(info?.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                size={window.screen.width <= 1000 ? "small" : "large"}
              />
            </div>
          ) : (
            ""
          )}
          {/* </div> */}
        </>
      ) : (
        <div className={styles.noCompanies}>
          <FormattedMessage id="no_companies_data">
            No companies data
          </FormattedMessage>{" "}
        </div>
      )}
      {/* CREAR COMPAÑIA ------------------------------------------------------ */}
      {level < 2 && (
        <div>
          <button
            onClick={handleModalCreateCompany}
            className={styles.createCompanyButton}
          >
            <FormattedMessage id="create_company">
              Create company
            </FormattedMessage>
          </button>
          <Modal
            open={modalCreateCompany}
            onClose={handleCloseModalCreatecompany}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                border: "0.15vw solid #00ABC8",
                borderRadius: "2vw",
                fontFamily: "'Quattrocento Sans', sans-serif",
                boxShadow: 10,
                pt: 2,
                px: 4,
                pb: 3,
                backgroundColor: "white",
                width: "70%",
                height: "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <CreateCompany /> */}
              <CreateCompnay2/>
            </Box>
          </Modal>
        </div>
      )}
      {/* MODAL DETALLE DE COMPAÑIA -------------------------------------------------------------------- */}
      <Modal
        open={modalCompanyDetail}
        onClose={() => setModalCompanyDetail(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            border: "2px solid #00ABC8",
            borderRadius: 10,
            fontFamily: "'Quattrocento Sans', sans-serif",
            boxShadow: 10,
            pt: 2,
            px: 4,
            pb: 3,
            backgroundColor: "white",
            width: window.screen.width < 1050 ? "75%" : "85%",
            height: "70%",
            overflowY: "auto",
          }}
        >
          <CompanyDetail
            company_id={companyToSeeDetail}
            defaultEditData={false}
          />
        </Box>
      </Modal>
      {/* MODAL EDITAR COMPAÑIA ------------------------------------------------------------------------------- */}
      <Modal open={modalEditCompany} onClose={() => setModalEditCompany(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            border: "2px solid #00ABC8",
            borderRadius: 10,
            fontFamily: "'Quattrocento Sans', sans-serif",
            boxShadow: 10,
            pt: 2,
            px: 4,
            pb: 3,
            backgroundColor: "white",
            width: window.screen.width < 1050 ? "75%" : "85%",
            height: "70%",
            overflowY: "auto",
          }}
        >
          <CompanyDetail
            company_id={companyToSeeDetail}
            defaultEditData={true}
          />
        </Box>
      </Modal>
    </div>
  );
}
