import React, { useState, useEffect } from "react";
import { GET_DEVICE_COMMENT, ADD_DEVICE_COMMENT } from "../../../queries";
import { useMutation, useLazyQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./DevicesComments.module.scss";
import { CircularProgress } from "@mui/material";

export default function DevicesComments({ device_id }) {
  const intl=useIntl();
  // ESTADOS LOCALES ---------------------------------------------------------------------
  //estado para el boton add comment
  const [isAddComment, setIsAddComment] = useState(false);

  //estado para el input del comment
  const [commentAdded, setCommentAdded] = useState("");

  // QUERIES/MUTATIONS --------------------------------------------------------------------
  //lazy query para updatear cuando se agrega un comentario
  const [
    getComments,
    {
      loading: lazyCommentsLoading,
      error: lazyCommentsError,
      data: lazyCommentsData,
    },
  ] = useLazyQuery(GET_DEVICE_COMMENT, {
    variables: {
      device_id,
    },
  });

  //mutation para agregar comentario
  const [
    addComment,
    {
      loading: commentAddedLoading,
      error: commentAddedError,
      data: commentAddedData,
    },
  ] = useMutation(ADD_DEVICE_COMMENT, {
    variables: {
      device_id,
      comment_text: {
          text: commentAdded
      },
    },
  });

  //CUANDO SE INICIA EL COMPONENTE ------------------------------------------------------------------
  useEffect(async () => {
    await getComments();
  }, []);

  //HANDLERS ----------------------------------------------------------------------------------
  //handler del input de comentario agregado
  const handleOnChangeComment = (e) => {
    setCommentAdded(e.target.value);
  };

  //handler del confirm del comentario agregado
  const handleConfirmComment = async () => {
    try {
      if (
        window.confirm(
          localStorage.getItem("language") === "es"
            ? "¿Estás seguro que quieres guardar cambios?"
            : "Are you sure you want to confirm changes?"
        )
      ) {
        await addComment();
        alert("Comment added");
        setCommentAdded("");
        setIsAddComment(false);
        await getComments();
        // window.location.reload();
      }
    } catch (error) {
      console.log("hubo un error en el comentario agregado", error);
    }
  };

  //MANEJO DE ERRORES ------------------------------------------------------------------------
  if (lazyCommentsError)
    console.log("hubo un error en la carga de comentarios", lazyCommentsError);

  if (commentAddedError)
    console.log("hubo un error en el comentario agregado", commentAddedError);

  //LOADING ------------------------------------------------------------------------------------
  if (lazyCommentsLoading || commentAddedLoading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  };


  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        <FormattedMessage id="comments_of_device">
          Comments of device
        </FormattedMessage>{" "}
        {device_id.split("-")[1]}
      </h3>
      {/* AGREGAR COMENTARIO -------------------------------------------------------- */}
      <div>
        {!isAddComment && (
          <button onClick={() => setIsAddComment(true)} className={styles.button}><FormattedMessage id='add_comment'>Add comment</FormattedMessage></button>
        )}
        {isAddComment && (
          <div className={styles.inputContainer}>
            <input
              type="text"
              placeholder={intl.formatMessage({id: 'write_comment'})}
              onChange={handleOnChangeComment}
              className={styles.input}
              maxLength="256"
            />
            <button onClick={handleConfirmComment} className={styles.button}><FormattedMessage id='save_button'>Confirm</FormattedMessage></button>
            <button onClick={() => setIsAddComment(false)} className={styles.button}><FormattedMessage id='cancel_button'>Cancel</FormattedMessage></button>
          </div>
        )}
      </div>
      {/* COMENTARIOS ---------------------------------------------------- */}
      <div className={styles.commentsContainer}>
        {lazyCommentsData?.device?.comments[0] ? (
          lazyCommentsData?.device?.comments?.map((c) => {
            return (
              <div className={styles.eachComment}>
                <p className={styles.text}>{c.text}</p>
                <br />
                <div className={styles.author}>{(c?.author === "0" ? "SUDO" : c?.author)  + '  ' + c?.date?.split('T')[0] + ' ' + '|' + ' ' + c?.date?.split('T')[1].split('.')[0]}</div>
              </div>
            );
          })
        ) : (
          <div className={styles.noComments}>
            <FormattedMessage id="no_comments_device">
              No comments on this device
            </FormattedMessage>
          </div>
        )}
      </div>
    </div>
  );
}
