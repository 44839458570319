import React from "react";
import buttonStyle from "./buttonStyle.module.scss";

const CustomButton = ({
  type,
  calibrateDeviceParams,
  submitCalibrationParams,
  disabled,
  action,
  text,
  togle
}) => {
  switch (type) {
    case "calibrate":
      return (
        <button
          className={buttonStyle.my_button}
          onClick={calibrateDeviceParams?.action}
        >
          {calibrateDeviceParams?.text}
        </button>
      );

    case "submitCalibrateParams":
      return (
        <button
          className={buttonStyle.my_button}
          onClick={submitCalibrationParams?.action}
          disabled={disabled}
        >
          {submitCalibrationParams?.text}
        </button>
      );

      case "branchFilter":
        return (
          <button
            className={togle? buttonStyle.branches_filter_button: buttonStyle.branches_filter_button_active}
            onClick={action}
          >
            {text}
          </button>
        );
        case "userFilter":
          return (
            <button
            className={togle? buttonStyle.branches_filter_button: buttonStyle.branches_filter_button_active}
              onClick={action}
            >
              {text}
            </button>
          );
        case "operatorFilter":
          return (
            <button
            className={togle? buttonStyle.branches_filter_button: buttonStyle.branches_filter_button_active}
              onClick={action}
            >
              {text}
            </button>
          );
    default:
    
          
      break;
  } 
};

export default CustomButton;
