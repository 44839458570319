import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Pagination,
  CircularProgress,
  Modal,
  Box,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./reports2.module.scss";
import { useCompare } from "./compareButton/hooks";
import {
  GET_COMPANY_DETAIL,
  GET_SHIPMENTS_PAG,
  GET_SHIPMENTS_PAG_TO_HEADER,
  GET_SHIPMENT_DETAIL,
} from "../../queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { fetchReportFile, convertirHoraLocal } from "../../helpers";
import { company_id, language } from "../../const";
import CompareButton from "./compareButton/CompareButton";

import Comments from "../Comments/Comments";
import ShipmentsToCompare from "./shipmentsToCompare/ShipmentsToCompare";
import rombo_amarillo from "./rombos/rombo_amarillo.png";
import rombo_rojo from "./rombos/rombo_rojo.png";
import rombo_verde from "./rombos/rombo_verde.png";
import DropdownData from "./dropdownData/DropdownData";
import { AiOutlineDownload } from "react-icons/ai";
import { HiOutlineClock } from "react-icons/hi";
import { saveAs } from "file-saver";
import { DatesContext } from "../../context/dates/Dates";
import { MdOutlineContentCopy } from "react-icons/md";

import { ToastContainer, toast } from "react-toastify";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
//estilo el modal de los contents de los viajes en transito
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 300,
  bgcolor: "white",
  border: "2px solid #00ABC8",
  borderRadius: 10,
  fontFamily: "'Quattrocento Sans', sans-serif",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Reports2 = ({ access_parced }) => {
  // if (!parseJwt(localStorage.getItem("token")).SUDO) {
  //   if (access_parced.dash_report === false) logOut();
  // }
  const intl = useIntl();
  const { from, to } = useContext(DatesContext);
  const { handleCheck, arrayState, clearSelection, errorState, error } =
    useCompare();

  //ESTADO PARA MODAL DE COMPARAR ENVIOS
  const [compareModal, setCompareModal] = useState(false);
  const [dataToCompare, setDataTocompare] = useState([]);
  const [errorText, setErrorText] = useState("");
  const [expanded, setExpanded] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const onAction = async (data) => {
    setDataTocompare(data);
    handleOpenCompare();
  };

  //si trato de comparar y no seleccione envios o seleccione solo uno tira este msj
  const errorMsj = () => {
   
setErrorText(intl.formatMessage({ id: "select_shipments_to_compare_error_msj" }));

    setTimeout(() => {
      setErrorText("");
    }, 4000);
  };
  //estados para la paginacion
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState("");

  //estado para guardarme la info filtrada
  const [filters, setFilters] = useState({
    status: null,
    from_date: from,
    to_date: to,
    origin_id: "",
    origin_op_id: "",
    destination_id: "",
    destination_op_id: "",
    item_id: "",
    in_transit: false,
    qr: "",
    shipment_id: "",
    branch_id: "",
    operator_id: "",
    temperature_range: "",
  });

  //estado para el modal de comentarios
  const [comments, setComments] = useState(false);

  //estado para guardar el id del shipment para ver sus comentarios
  const [shipToSeeComments, setShipToSeeComments] = useState("");

  //estado para el input de busqueda por id
  const [searchInput, setSearchInput] = useState("");

  const [checkpointsInterval, setCheckpointsInterval] = useState([]);
  const useStyles = makeStyles((theme) => ({
    customAccordionSummary: {
      padding: 0,
      margin: 0,
    },
  }));
  const classes = useStyles();

  // QUERIES/MUTATIONS ---------------------------------------------------------------------------------
  //query para traerme las branches de la compañia al principio
  const {
    // loading: companyDetailLoading,
    // error: companyDetailError,
    data: company_detail,
  } = useQuery(GET_COMPANY_DETAIL, {
    variables: {
      company_id,
    },
  });

  //lazy query para traerme cuando submiteo filtros
  const [
    filterData,
    {
      loading: filtersLoading,
      // error: filtersError,
      data: filtersResult,
    },
  ] = useLazyQuery(GET_SHIPMENTS_PAG_TO_HEADER);
  const [
    dataToCsv,
    {
      loading: toCsvLoading,
      // error: filtersError,
      data: toCsv,
    },
  ] = useLazyQuery(GET_SHIPMENT_DETAIL);

  // VARIABLE PARA GUARDAR LA DATA -----------------------------------------------------
  let dataLength = "";
  let data = "";

  //CADA VEZ QUE CAMBIO DE PAGINA VUELVO A HACER LA QUERY ------------------------------------------------------------------------
  useEffect(() => {
    if (typeof data !== "string") {
      filterData({
        variables: {
          company_id,
          status: filters.status,
          from_date: from,
          to_date: to,
          origin_id: filters.origin_id,
          origin_op_id: filters.origin_op_id,
          destination_id: filters.destination_id,
          destination_op_id: filters.destination_op_id,
          item_id: filters.item_id,
          page,
          per_page: rowsPerPage,
          in_transit: false,
          qr: filters.qr,
          shipment_id: filters.shipment_id,
          branch_id: filters.branch_id,
          operator_id: filters.operator_id,
          temperature_range: filters.temperature_range,
        },
      });
    }
  }, [page, from, to]);

  //HANDLERS ---------------------------------------------------------------------------------------------
  //handler cuando cambio filtros
  const handleChange = function (e) {
    if (e.target.value === "") {
      setFilters({
        ...filters,
        [e.target.name]: null,
      });
    } else {
      setFilters({
        ...filters,
        [e.target.name]: e.target.value,
      });
    }
  };

  //handler cuando submiteo los filtros
  const handleFilter = async (e) => {
    e.preventDefault();
    setPage(1);

    try {
      await filterData({
        variables: {
          company_id,
          status: filters.status,
          from_date: from,
          to_date: to,
          origin_id: filters.origin_id,
          origin_op_id: filters.origin_op_id,
          destination_id: filters.destination_id,
          destination_op_id: filters.destination_op_id,
          item_id: filters.item_id,
          page,
          per_page: rowsPerPage,
          in_transit: false,
          qr: filters.qr,
          shipment_id:
            filters.shipment_id === "" || !filters.shipment_id
              ? ""
              : `SHI-${filters.shipment_id}-${company_id.split("-")[1]}`,
          branch_id: filters.branch_id,
          operator_id: filters.operator_id,
          temperature_range: filters.temperature_range,
        },
      });
      dataLength = filtersResult?.shipments?.total;
      data = filtersResult?.shipments?.selectedItems;
    } catch (error) {
      console.log(error);
    }
  };
  //seleccion de checkpoints
  const onSelected = (selected_checks) => {
    setCheckpointsInterval(selected_checks);
  };
  //handler descarga de reporte
  const handleDownload = async (e, _id, shipment_id) => {
    e.stopPropagation(); // con esto evito que el accordion se abra o cierrre cuando descargo un reporte
    await fetchReportFile(_id, checkpointsInterval, shipment_id);
  };

  //handler de la paginacion
  const handleChangePage = async (event, value) => {
    setPage(value);
    setExpanded([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
  };

  //handler del modal de comments
  const handleCommentsModal = (id) => {
    setComments(true);
    setShipToSeeComments(id);
  };

  //handler del close del modal de comments
  const handleOnCloseComments = () => {
    setComments(false);
  };

  //handler open modal compare shipments
  const handleOpenCompare = () => {
    setCompareModal(true);
  };
  //handler close comparar shipments
  const handleCloseCompare = () => {
    setCompareModal(false);
    clearSelection();
    setErrorText("");
  };

  //CUANDO ENCUENTRE RESULTADOS LA DATA VA A SER ESTA -------------------------------------------------------------
  if (filtersResult?.shipments?.selectedItems) {
    dataLength = filtersResult?.shipments?.total;
    data = filtersResult?.shipments?.selectedItems;
  }

  // PARA LA BUSQUEDA POR NOMBRE -----------------------------------------------------
  if (searchInput !== "") {
    let infoCopy = data;
    data = infoCopy?.filter((s) =>
      s?.shipment_id
        ?.split("-")[1]
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    );
  }



  const handleChangeAccordion = (index) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = isExpanded;
  
      if (isExpanded) {
        setTimeout(() => {
          const accordionElement = document.getElementById(`accordion-${index}`);
          if (accordionElement) {
            const accordionRect = accordionElement.getBoundingClientRect();
            const accordionHeight = window.innerWidth * 0.45; // Calcula la altura en píxeles de 45vw
            const scrollOffset = accordionRect.bottom - window.innerHeight /* + accordionHeight  */ 
  
            window.scrollBy({
              top: scrollOffset,
              behavior: 'smooth',
            });
          }
        }, 500); // Ajusta el retraso si es necesario
      }
  
      return newExpanded;
    });
  };

  const getTimestamp = (tick, csv) => {
    let date = new Date(tick);
    const timezoneOffset = date.getTimezoneOffset();
    date.setHours(date.getHours() /*  - (timezoneOffset / 60) */);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Asegura que tenga 2 dígitos
    const day = String(date.getDate()).padStart(2, "0"); // Asegura que tenga 2 dígitos
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Asegura que tenga 2 dígitos
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12; // Si hours es 0, se muestra como 12

    let strTime = "";

    if (csv) {
      strTime = `${year}/${month}/${day} ${formattedHours}:${minutes} ${ampm}`;
    } else {
      strTime = `${month}/${day} ${formattedHours}:${minutes} ${ampm}`;
    }
    return strTime;
  };

  //este codigo lo devolvio el chat pero no lo revise, es para bajar csv x intervalos de checkpoints ojoooo!!!
  const downloadCsv2 = async (e, s) => {
    let response;
    e.stopPropagation();
    console.log(checkpointsInterval);

    try {
      response = await dataToCsv({
        variables: {
          shipment_id: s.shipment_id,
        },
      });
    } catch (error) {
      console.log(error);
    }

    const origin_branch = company_detail?.company?.branches
      ?.map((b) => b?.branch_id === s?.origin_id && b?.name)
      .filter((branch) => branch !== false);
    const ope_sender = company_detail?.company?.operators
      ?.map(
        (o) =>
          o?.operator_id === s?.origin_op_id &&
          `${o.name} (${o.operator_id.split("-")[0]}-${
            o.operator_id.split("-")[1]
          })`
      )
      .filter((ope) => ope !== false);
    const destination_branch = company_detail?.company?.branches
      ?.map((b) => b.branch_id === s?.destination_id && b.name)
      .filter((branch) => branch !== false);
    const ope_receiver = company_detail?.company?.operators
      ?.map(
        (o) =>
          o.operator_id === s?.destination_op_id &&
          `${o.name} (${o.operator_id.split("-")[0]}-${
            o.operator_id.split("-")[1]
          })`
      )
      .filter((ope) => ope !== false);

    let csvContent = "DATA\n";
    csvContent +=
      "shipment_id,origin,sender,departure,destination,receiver,arrival,status\n";
    csvContent += `${s.shipment_id},${origin_branch},${ope_sender},${s.departure},${destination_branch},${ope_receiver},${s.arrival},${s.status}\n\n`;
    csvContent += "READINGS\n";
    csvContent += "timestamp,value\n";

    const readings = response?.data?.shipment?.temperature_readings;

    if (checkpointsInterval.length === 2) {
      const [from, to] = checkpointsInterval;
      const fromTimestamp = new Date(from).getTime();
      const toTimestamp = new Date(to).getTime();

      // Filter readings within the interval and add checkpointsInterval[0] and [1]
      csvContent += `\n${from},0\n`;
      readings
        ?.filter((reading) => {
          const readingTimestamp = new Date(reading.timestamp).getTime();
          return (
            readingTimestamp >= fromTimestamp && readingTimestamp <= toTimestamp
          );
        })
        ?.forEach((reading) => {
          csvContent += `\n${getTimestamp(reading?.timestamp, true)},${
            reading.cv ? reading?.cv?.toFixed(2) : reading?.value?.toFixed(2)
          }`;
        });
      csvContent += `\n${to},0\n`;
    } else {
      readings?.forEach((reading) => {
        csvContent += `\n${getTimestamp(reading?.timestamp, true)},${
          reading.cv ? reading?.cv?.toFixed(2) : reading?.value?.toFixed(2)
        }`;
      });
    }

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `${s.shipment_id}.csv`);
  };
  const copyToClipboard = async (e, text) => {
    e.stopPropagation();

    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
       
        toast.success(
         intl.formatMessage({ id: "text_copied_to_clipboard" })
        );
      } catch (err) {
        console.error("Error al copiar al portapapeles: ", err);
        toast.error(
          intl.formatMessage({ id: "error_copying_to_clipboard" })
        );
      }
    } else {
      // Fallback method for non-HTTPS environments
      try {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        // textArea.focus();
        textArea.select();

        const successful = document.execCommand("copy");
        document.body.removeChild(textArea);

        if (successful) {
        
          toast.success(
            intl.formatMessage({ id: "text_copied_to_clipboard" })
           );
        } else {
          throw new Error("Fallback: Unable to copy text");
        }
      } catch (err) {
        console.error("Error al copiar al portapapeles (fallback): ", err);
      }
    }
  };

  return (
    <div className={styles.container}>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={styles.title}>
        <FormattedMessage id="reports_title">Reports</FormattedMessage>
        <br />
      </div>
      {/* BUSCADOR------------------------------------------------------------------ */}

      {/* FILTRADOS */}
      <div className={styles.formContainer}>
        <form className={styles.filter} onSubmit={handleFilter}>
          <div className={styles.origen_destino}>
            {/* ORIGEN */}
            <select
              className={styles.select}
              onChange={handleChange}
              name="origin_id"
              defaultValue="origen"
            >
              <option className={styles.option} disabled hidden value="origen">
                {localStorage.getItem("language") === "en"
                  ? "Origin"
                  : "Origen"}
              </option>
              <option value="" className={styles.option}>
                {localStorage.getItem("language") === "en" ? "All" : "Todos"}
              </option>
              {company_detail?.company?.branches.map((b) => (
                <option value={b.branch_id} key={b.branch_id}>
                  {b.name}
                </option>
              ))}
            </select>
            {/* DESTINO */}
            <select
              className={styles.select}
              onChange={handleChange}
              name="destination_id"
              defaultValue="destino"
            >
              <option disabled hidden value="destino">
                {localStorage.getItem("language") === "en"
                  ? "Destination"
                  : "Destino"}
              </option>
              <option value="">
                {localStorage.getItem("language") === "en" ? "All" : "Todos"}
              </option>
              {company_detail?.company?.branches.map((b) => (
                <option value={b.branch_id} key={b.branch_id}>
                  {b.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.destinatario_remitente}>
            {/* DESTINATARIO  */}
            <select
              className={styles.select}
              onChange={handleChange}
              name="destination_op_id"
              defaultValue="destinatario"
            >
              <option
                className={styles.option}
                disabled
                hidden
                value="destinatario"
              >
                {localStorage.getItem("language") === "en"
                  ? "Receiver"
                  : "Destinatario"}
              </option>
              <option value="" className={styles.option}>
                {localStorage.getItem("language") === "en" ? "All" : "Todos"}
              </option>
              {company_detail?.company?.operators.map((o) => (
                <option value={o.operator_id} key={o.operator_id}>
                  {o.name} (
                  {o.operator_id.split("-")[0] +
                    "-" +
                    o.operator_id.split("-")[1]}
                  )
                </option>
              ))}
            </select>
            {/* REMITENTE  */}
            <select
              className={styles.select}
              onChange={handleChange}
              name="origin_op_id"
              defaultValue="remitente"
            >
              <option
                className={styles.option}
                disabled
                hidden
                value="remitente"
              >
                {localStorage.getItem("language") === "en"
                  ? "Sender"
                  : "Remitente"}
              </option>
              <option value="" className={styles.option}>
                {localStorage.getItem("language") === "en" ? "All" : "Todos"}
              </option>
              {company_detail?.company?.operators.map((o) => (
                <option value={o.operator_id} key={o.operator_id}>
                  {o.name} (
                  {o.operator_id.split("-")[0] +
                    "-" +
                    o.operator_id.split("-")[1]}
                  )
                </option>
              ))}
            </select>
          </div>

          <div className={styles.estado_codBarras_qr_id}>
            {/* STATUS */}
            <select
              className={styles.select_status}
              onChange={handleChange}
              name="status"
              defaultValue="estado"
            >
              <option disabled hidden value="estado">
                {localStorage.getItem("language") === "en"
                  ? "Shipment status"
                  : "Estado del envío"}
              </option>
              <option value="">
                {localStorage.getItem("language") === "en" ? "All" : "Todos"}
              </option>
              <option value="SUCCESSFUL">
                {localStorage.getItem("language") === "en"
                  ? "Successful"
                  : "Conforme"}
              </option>
              <option value="UNCERTAIN">
                {localStorage.getItem("language") === "en"
                  ? "Uncertain"
                  : "A revisión"}
              </option>
              <option value="FAILED">
                {localStorage.getItem("language") === "en"
                  ? "Failed"
                  : "No conforme"}
              </option>
            </select>
            {/* CODIGO DE BARRAS */}
            <input
              className={styles.select_barcode}
              onChange={handleChange}
              type="text"
              name="item_id"
              placeholder={
               intl.formatMessage({ id: "reports_barcode" })
              }
            ></input>

            {/* BUSQUEDA X QR ----------------------------------------------------------------- */}
            <input
              className={styles.select_qr}
              onChange={handleChange}
              type="text"
              name="qr"
              placeholder="QR"
            />

            {/* BUSQUEDA POR ID --------------------------------------------------------------- */}
            <input
              type="text"
              onChange={handleChange}
              className={styles.select_id}
              name="shipment_id"
              placeholder="ID"
            />
            {/* Branch id */}
            <select
              className={styles.select_branch}
              onChange={handleChange}
              name="branch_id"
              defaultValue="Branch"
            >
              <option disabled hidden value="Branch">
                {localStorage.getItem("language") === "en"
                  ? "Branch"
                  : "Sucursal"}
              </option>
              <option value="">
                {localStorage.getItem("language") === "en" ? "All" : "Todos"}
              </option>
              {company_detail?.company?.branches.map((b) => (
                <option value={b.branch_id} key={b.branch_id}>
                  {b.name}
                </option>
              ))}
            </select>
            {/* Operator_id */}

            <select
              className={styles.select_operator}
              onChange={handleChange}
              name="operator_id"
              defaultValue="operator"
            >
              <option
                className={styles.option}
                disabled
                hidden
                value="operator"
              >
                {localStorage.getItem("language") === "en"
                  ? "Operator"
                  : "Operador"}
              </option>
              <option value="" className={styles.option}>
                {localStorage.getItem("language") === "en" ? "All" : "Todos"}
              </option>
              {company_detail?.company?.operators.map((o) => (
                <option value={o.operator_id} key={o.operator_id}>
                  {o.name} (
                  {o.operator_id.split("-")[0] +
                    "-" +
                    o.operator_id.split("-")[1]}
                  )
                </option>
              ))}
            </select>
            {/* temp_range */}
            <select
              className={styles.select_operator}
              onChange={handleChange}
              name="temperature_range"
              defaultValue="temp_range"
            >
              <option
                className={styles.option}
                disabled
                hidden
                value="temp_range"
              >
                {localStorage.getItem("language") === "en"
                  ? "Temp range"
                  : "Rango térmico"}
              </option>
              <option value="" className={styles.option}>
                {localStorage.getItem("language") === "en" ? "All" : "Todos"}
              </option>
              {company_detail?.company?.alert_params?.temperature_alerts?.map(
                (t) => (
                  <option value={t.name} key={t.name}>
                    {t?.name}
                  </option>
                )
              )}
            </select>
          </div>
        </form>
      </div>

      {/* BUSCAR */}
      <div className={styles.searchButtonContainer}>
        <button
          type="submit"
          className={styles.searchButton}
          onClick={handleFilter}
        >
          <FormattedMessage id="search_button">Search</FormattedMessage>
        </button>

        {/* BOTON COMPARAR------------------------------------------------------ */}

        {/* si selecciono 2 envio o mas aparecen los botones de comparar y eliminar seleccion */}

        <CompareButton
          arrayState={arrayState}
          onAction={onAction}
          errorMsj={errorMsj}
          type="shipments"
        />

        <div className={styles.clearButtonContainer}>
          {/* BUTTON CLEAR............................... */}
          <button
            onClick={clearSelection}
            className={styles.clearButton}
            disabled={arrayState.length < 1 ? true : false}
          >
            <FormattedMessage id="clear">Clear</FormattedMessage>
          </button>
        </div>
      </div>
      {/* TABLA */}

      <div className={styles.tableContainer}>
        {/* mensaje de error------------------------------ */}
        <div className={styles.error_text}>{errorText}</div>
        {filtersLoading ? (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        ) : (
          <div>
            {/* Por si no hay filtros puestos */}
            {typeof data === "string" ? (
              <div className={styles.message1}>
                <FormattedMessage id="initial_message">
                  Please select searching settings
                </FormattedMessage>
              </div>
            ) : data?.[0] ? (
              <>
                {/* HEADER----------------------------------------------------  */}
                <div className={styles.headerContainer}>
                  <div
                    className={styles.header_item}
                    style={{ width: "5%", textAlign: "center" }}
                  >
                    ID
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "8%", textAlign: "center" }}
                  >
                    QR
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "10%", textAlign: "center" }}
                  >
                  
                    <FormattedMessage id="reports_origin">Origin</FormattedMessage>
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "10%", textAlign: "center" }}
                  >
                    
                    <FormattedMessage id="reports_sender">Sender</FormattedMessage>
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "7%", textAlign: "center" }}
                  >
                    
                    <FormattedMessage id="reports_departure">Departure</FormattedMessage>
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "10%", textAlign: "center" }}
                  >
                    
                    <FormattedMessage id="reports_destination"/>
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "10%", textAlign: "center" }}
                  >
                    
                    <FormattedMessage id="reports_receiver">Receiver</FormattedMessage>
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "7%", textAlign: "center" }}
                  >
                   
                    <FormattedMessage id="reports_arrival">Arrival</FormattedMessage>
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "4%", textAlign: "center" }}
                  >
                   
                    <FormattedMessage id="reports_type">Type</FormattedMessage>
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "4%", textAlign: "center" }}
                  >
                   
                    <FormattedMessage id="reports_status">Status</FormattedMessage>
                  </div>
                  <div
                    className={styles.header_item}
                    style={{ width: "10%", textAlign: "center" }}
                  >
                    
                    <FormattedMessage id="reports_reports">Reports</FormattedMessage>
                  </div>
                </div>

                {/* CADA ENVIO----------------------------------------------------  */}
                {data?.map((s, index) => (
                  <div
                    className={styles.check_data_container}
                    key={s?.shipment_id}
                  >
                    <div className={styles.checkbox}>
                      <input
                        type="checkbox"
                        name="shipsTocompare"
                        onChange={handleCheck}
                        value={s?.shipment_id}
                      />
                    </div>
                    <PrivateRoute>
                      <Accordion
                        disableGutters={true}
                        sx={{
                          width: "97%",
                          position: "relative",
                          left: "0.5%",
                        }}
                        style={
                          data.indexOf(s) % 2 === 0
                            ? { background: "var(--white)" }
                            : { background: "#fafafa" }
                        }
                        key={index}
                        expanded={expanded[index]}
                        onChange={handleChangeAccordion(index)}
                        id={`accordion-${index}`} // Añade esta línea
                      >
                        <AccordionSummary
                          className={`${classes.customAccordionSummary} MuiAccordionSummary-root MuiAccordionSummary-gutters css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root`}
                        >
                          <div className={styles.dataContainer}>
                            {/* id--------- */}
                            <div
                              className={styles.data}
                              style={{ width: "5%" }}
                            >
                              {s?.shipment_id.split("-")[1]}
                            </div>
                            {/* qr--------- */}
                            <div
                              className={styles.data}
                              style={{ width: "8%" }}
                            >
                              {s?.qr}{" "}
                              <MdOutlineContentCopy
                                onClick={(e) => copyToClipboard(e, s?.qr)}
                              />
                            </div>
                            {/* origin----- */}
                            <div
                              className={styles.data}
                              style={{ width: "10%" }}
                            >
                              {company_detail?.company?.branches?.map(
                                (b) => b?.branch_id === s?.origin_id && b?.name
                              )}
                            </div>
                            {/* sender--------- */}
                            <div
                              className={styles.data}
                              style={{ width: "10%" }}
                            >
                              {company_detail?.company?.operators?.map(
                                (o) =>
                                  o?.operator_id === s?.origin_op_id &&
                                  `${o.name} `
                              )}
                            </div>
                            {/* departure----- */}
                            <div
                              className={styles.data}
                              style={{ width: "7%" }}
                            >
                              {/* {getTimestamp(s?.departure,false)} */}
                              {
                                convertirHoraLocal(
                                  s?.departure,
                                  company_detail?.company?.gmt
                                ).split("  ")[0]
                              }
                              <br />
                              {
                                convertirHoraLocal(
                                  s?.departure,
                                  company_detail?.company?.gmt
                                ).split("  ")[1]
                              }
                            </div>
                            {/* destination-------- */}
                            <div
                              className={styles.data}
                              style={{ width: "10%" }}
                            >
                              {company_detail?.company?.branches?.map(
                                (b) =>
                                  b.branch_id === s?.destination_id && b.name
                              )}
                            </div>
                            {/* reciever-------- */}
                            <div
                              className={styles.data}
                              style={{ width: "10%" }}
                            >
                              {company_detail?.company?.operators?.map(
                                (o) =>
                                  o.operator_id === s?.destination_op_id &&
                                  `${o.name} `
                              )}
                            </div>
                            {/* arrival--------- */}
                            <div
                              className={styles.data}
                              style={{ width: "7%" }}
                            >
                              {/* {getTimestamp(s?.arrival,false)} */}
                              {
                                convertirHoraLocal(
                                  s?.arrival,
                                  company_detail?.company?.gmt
                                ).split("  ")[0]
                              }
                              <br />
                              {
                                convertirHoraLocal(
                                  s?.arrival,
                                  company_detail?.company?.gmt
                                ).split("  ")[1]
                              }
                            </div>
                            {/* type--------- */}
                            <div
                              className={styles.data}
                              style={{ width: "4%" }}
                            >
                              {s?.temperature_range?.name}
                            </div>
                            {/* status */}
                            <div
                              className={styles.data}
                              style={{ width: "4%" }}
                            >
                              {s?.status === "SUCCESSFUL" && (
                                <img
                                  alt=""
                                  src={rombo_verde}
                                  className={styles.rombo}
                                />
                              )}
                              {s?.status === "UNCERTAIN" && (
                                <img
                                  alt=""
                                  src={rombo_amarillo}
                                  className={styles.rombo}
                                />
                              )}
                              {s?.status === "FAILED" && (
                                <img
                                  alt=""
                                  src={rombo_rojo}
                                  className={styles.rombo}
                                />
                              )}
                            </div>

                            {/* reports------------- */}
                            {/* PDF */}
                            <div
                              className={styles.downloadContainer}
                              style={{ width: "9%" }}
                            >
                              <button
                                onClick={(e) =>
                                  handleDownload(e, s?._id, s?.shipment_id)
                                }
                                className={styles.button}
                                disabled={
                                  checkpointsInterval.length === 1 &&
                                  expanded[index]
                                    ? true
                                    : false
                                }
                              >
                                <AiOutlineDownload size="2vw" />
                                <br />
                                <div
                                  className={
                                    checkpointsInterval.length === 2 &&
                                    expanded[index] &&
                                    styles.button_selected_interval_text
                                    // : styles.button_text
                                  }
                                >
                                  PDF{" "}
                                  {checkpointsInterval.length === 2 &&
                                    expanded[index] && <HiOutlineClock />}
                                </div>
                              </button>

                              {/* CSV */}

                              <button
                                onClick={(e) => downloadCsv2(e, s)}
                                className={styles.button}
                                disabled={
                                  checkpointsInterval.length === 1 &&
                                  expanded[index]
                                    ? true
                                    : false
                                }
                                // style={{position:"relative", left:"30px",border:"solid red 1px"}}
                              >
                                <AiOutlineDownload size="2vw" />
                                <br />
                                <div
                                  className={
                                    checkpointsInterval.length === 2 &&
                                    expanded[index] &&
                                    styles.button_selected_interval_text
                                    // : styles.button_text
                                  }
                                >
                                  CSV{" "}
                                  {checkpointsInterval.length === 2 &&
                                    expanded[index] && <HiOutlineClock />}
                                </div>
                              </button>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          className={classes.accordionDetails}
                          sx={{
                            height: "49vw",
                          }}
                        >
                          <DropdownData
                            comments={
                              <div
                                className={styles.data}
                                style={{ width: "10%" }}
                              >
                                <button
                                  className={styles.commentsButton}
                                  onClick={() =>
                                    handleCommentsModal(s?.shipment_id)
                                  }
                                >
                                  <FormattedMessage id="content_button">
                                    OPEN
                                  </FormattedMessage>
                                </button>
                              </div>
                            }
                            shipment_id={s.shipment_id}
                            expanded={expanded}
                            index={index}
                            gmt={company_detail?.company?.gmt}
                            data={data}
                            onSelected={onSelected}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </PrivateRoute>
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.message2}>
                <FormattedMessage id="no_results">
                  No results found
                </FormattedMessage>
              </div>
            )}
          </div>
        )}
      </div>

      {/* ------pagination--------- */}
      {data?.length && dataLength > 15 && searchInput === "" ? (
        <div className={styles.pagination}>
          <Pagination
            count={Math.ceil(dataLength / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            size={window.screen.width <= 1000 ? "small" : "large"}
          />{" "}
        </div>
      ) : (
        ""
      )}
      {/* MODAL COMENTARIOS ----------------------------------------------------------------------------------------- */}
      <Modal open={comments} onClose={handleOnCloseComments}>
        <Box
          sx={{
            ...modalStyle,
            height: "30vw",
            width: "20vw",
            overflowY: "auto",
          }}
        >
          <Comments shipment_id={shipToSeeComments} />
        </Box>
      </Modal>

      {/* MODAL GRAFICO DE COMPARACION DE ENVIOS --------------------------------------------------------*/}
      <Modal open={compareModal} onClose={handleCloseCompare}>
        <Box
          sx={{
            ...modalStyle,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            border: "0.15vw solid #00ABC8",
            borderRadius: 10,
            fontFamily: "'DM Sans', sans-serif",
            boxShadow: 10,
            pt: 2,
            px: 4,
            pb: 3,
            width: window.screen.width > 800 ? "60%" : "70%",
            height: window.screen.width > 800 ? "40vw" : "60vw",
          }}
        >
          <ShipmentsToCompare
            dataToCompare={dataToCompare}
            gmt={company_detail?.company?.gmt}
            type="shipments"
          />
          {/* <Ejemplo/> */}
        </Box>
      </Modal>
    </div>
  );
};

export default Reports2;
