import React from 'react';
import { parseJwt } from '../../helpers';
import ConfigLevel0 from './ConfigLevel0/ConfigLevel0';
import ConfigLevel2 from './ConfigLevel2/ConfigLevel2';
import ConfigLevel3 from './ConfigLevel3/ConfigLevel3';
import styles from './Configuracion.module.scss';
import { FormattedMessage } from 'react-intl';
import { BsTypeH1 } from 'react-icons/bs';


export default function Configuracion({access_parced}) {

    //guardo el level del usuario en una variable
    const level = parseJwt(localStorage.getItem('token')).lvl;

    return (
        <div className={styles.container}>
            <h2 className={styles.title}><FormattedMessage id="config_title">Settings</FormattedMessage></h2>
            {/* LEVEL 0 - SUDO ---------------------------------------------------- */}
            {parseJwt(localStorage.getItem('token')).SUDO && <ConfigLevel0 />}
            {/* LEVEL 2 - COMPAÑIAS ADMIN ---------------------------------------------- */}
            {(level === 2 || level === 0 || level === 1 || level ===3 ) && <ConfigLevel2  level={level} access_parced={access_parced}/>}
            {/* LEVEL 3 - OTROS ------------------------------------------------------------
            {level === 3 && <ConfigLevel3 />} */}
        </div>
    )
    //return <h1>configuration</h1>
}