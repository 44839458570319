import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import styles from "./BranchesConMasAlertas.module.scss";
import { GET_COMPANY_DETAIL } from "../../../queries";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";

export default function BranchesConMasAlertas({ branchesData, company_id, loading, allData }) {
  const [maxPercentage, setMaxPercentage] = useState(0);

  // query para traer las branches de la compañía
  const { data: company_detail } = useQuery(GET_COMPANY_DETAIL, {
    variables: {
      company_id,
    },
  });

  // Obtener el porcentaje máximo
  useEffect(() => {
    let max = 0;
    if (Array.isArray(branchesData)) {
      branchesData.forEach((b) => {
        if (b.count > max) {
          max = b.count;
        }
      });
    }
    setMaxPercentage(max);
  }, [branchesData]);
    return (
    <div className={styles.barContainer}>
      <div className={styles.title}>
        <FormattedMessage id="branches_alerts_title">Branches with more alerts</FormattedMessage>
      </div>
      {!loading ? (
        <div className={styles.infoContainer}>
          {branchesData?.[0] ? (
            <>
              {branchesData?.map((b) => (
                <div key={b._id} className={styles.eachBranch}>
                  <span className={styles.barTitle}>
                    {company_detail?.company?.branches?.map(
                      (br) => br.branch_id === b._id && `${br.name}`
                    )}
                  </span>
                  <ProgressBar
                    maxCompleted={maxPercentage}
                    completed={b.count.toString()}
                    width={window.screen.width > 800 ? '100%' : '80%'}
                    height={window.screen.width > 800 ? '1.2vw' : '2vw'}
                    baseBgColor={"#EEEEEE"}
                    borderRadius={".3vw .3vw .3vw .3vw"}
                    isLabelVisible={true}
                    bgColor={"var(--cyan)"}
                    className={styles.bar}                   
                  />
                </div>
              ))}
            </>
          ) : (
            <div className={styles.noData}>
              <FormattedMessage id="no_alerts">No alerts</FormattedMessage>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
