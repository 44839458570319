import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_CALIBRATION } from "../../../queries";
import styles from "./charts.module.scss";
import { CircularProgress } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Bar,
  ReferenceLine,
} from "recharts";

const CalAccChart = ({ calibration_id, expanded, index }) => {
  const [accData, setAccData] = useState([]);
  const [ticksState, setTicksState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataState, setDataState] = useState([]);

  const [
    chartsData,
    {
      loading: chartsDataLoading,
      error: chartsDataError,
      data: chartsDataResult,
    },
  ] = useLazyQuery(GET_CALIBRATION);

  //ESTRUCTURA DONDE SE VA A GUARDAR LA DATA QUE VA EN EL GRAFICO ----------------------------------------------------
  let data2 = [
    {
      counter: chartsDataResult?.calibration?.acceleration[0]?.counter,
      timestamp: chartsDataResult?.calibration?.acceleration[0]?.timestamp,
      millisec: Date.parse(
        chartsDataResult?.calibration?.acceleration[0]?.timestamp
      ),
      x: chartsDataResult?.calibration?.acceleration[0]?.x,
      y: chartsDataResult?.calibration?.acceleration[0]?.y,
      z: chartsDataResult?.calibration?.acceleration[0]?.z,
    },
  ];
  let ticks = [];

  const getTimestamp = (tick) => {
    let date = new Date(tick);
    const timezoneOffset = date.getTimezoneOffset();
    date.setHours(date.getHours() /* + (timezoneOffset / 60) */);

    var month = date.getMonth() + 1; // Los meses van de 0 a 11, por lo que hay que sumarle 1
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // el 0 se convierte en 12
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = month + "/" + day + " " + hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  useEffect(() => {
    const fetchCalibrations = async () => {
      try {
        await chartsData({ variables: { calibration_id } });
        // setData(chartsDataResult);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCalibrations();
    for (
      let i = 0;
      i < chartsDataResult?.calibration?.acceleration?.length;
      i++
    ) {
      data2.push({
        counter: chartsDataResult?.calibration?.acceleration[i]?.counter,
        timestamp:
          chartsDataResult?.calibration?.acceleration[
            i
          ]?.timestamp?.toLocaleString(),
        millisec: Date.parse(
          chartsDataResult?.calibration?.acceleration[i]?.timestamp
        ),
        x: chartsDataResult?.calibration?.acceleration[i]?.x,
        y: chartsDataResult?.calibration?.acceleration[i]?.y,
        z: chartsDataResult?.calibration?.acceleration[i]?.z,
      });

      setDataState(data2);
    }
  }, [chartsDataResult]);

  //seteo los ticks para el grafico

  useEffect(() => {
    dataState?.forEach((d) => {
      ticks.push({
        counter: d?.counter,
        timestamp: d?.timestamp,
        millisec: Date.parse(d?.timestamp),
      });
    });

    setTicksState(ticks);
  }, [dataState]);

  while (loading) {
    setTimeout(() => setLoading(false), 2000);
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.chartContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width="50%"
          height="50%"
          data={dataState}
          margin={{
            top: window.screen.width < 1050 ? 2 : 6,
            right: window.screen.width < 1050 ? 10 : 30,
            left: 18,
            bottom: 9,
          }}
        >
          <CartesianGrid d strokeDasharray="1 1" />
          <XAxis
            dataKey="millisec"
            // scale='time'
            type="number"
            ticks={ticksState?.millisec}
            tick={{
              color: "#00ABC8",
              fontSize: "0.7vw",
              fontFamily: "'Quattrocento Sans', sans-serif",
            }}
            tickMargin={6}
            tickLine={true}
            tickCount={10}
            //tickFormatter={(tick) => convertSeconds(tick)}
            tickFormatter={getTimestamp}
            domain={["dataMin", "dataMax"]}
          >
       
          </XAxis>
          <YAxis
            allowDataOverflow={true}
            type="number"
            yAxisId="1"
            domain={["auto", "auto"]}
            tick={{
              color: "#00ABC8",
              fontSize: "0.7vw",
              fontFamily: "'Quattrocento Sans', sans-serif",
            }}
            tickCount={10}
            tickLine={true}
            padding={{ top: (window.screen.width * 6.25) / 300 }}
          >
            <Label
              value={
                localStorage.getItem("language") === "en"
                  ? "Acceleration(G)"
                  : "Aceleracion(G)"
              }
              angle={-90}
              position="left"
              fontFamily="'Quattrocento Sans', sans-serif"
              fontSize="0.8vw"
              fill="#00ABC8"
              fontWeight="lighter"
            />
          </YAxis>
          <Tooltip
            labelFormatter={(label) => `Fecha: ${getTimestamp(label)}`}
            formatter={(value) => `Valor: ${value}`}
          />
          <Legend />
          <Line
            yAxisId="1"
            type="monotone"
            dataKey="x"
            stroke="mediumaquamarine"
            dot={false}
            // animationDuration={300}
          />
          <Line
            yAxisId="1"
            type="monotone"
            dataKey="y"
            stroke="orange"
            // animationDuration={300}
            dot={false}
          />
          <Line
            yAxisId="1"
            type="monotone"
            dataKey="z"
            stroke="steelBlue"
            // animationDuration={300}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CalAccChart;
