import React, { useState, useLayoutEffect } from "react";
import MapView from "./MapView";
import MapProvider from "../../context/map/MapProvider";
import PlacesProvider from "../../context/places/PlacesProvider";

const RcWay = () => {
  return (
    <>
      <PlacesProvider>
        <MapProvider>
          <MapView />
        </MapProvider>
      </PlacesProvider>
    </>
  );
};
export default RcWay;
